import React from 'react';
import axios from 'axios';
import SearchForm from '../../component/SearchForm';
import { Link, withRouter } from 'react-router-dom';
import Pagination from '../../component/Pagination';
import TicketStatus from '../../component/TicketStatus';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import { RenderDatePicker, RenderSearchForm } from '../../component/formInput';
import { SocketUtils, StringUtils } from '../../utils/javascriptUtils';
import { connect } from 'react-redux';
import TicketStaticSelect from '../../component/TicketStaticSelect';

var _ = require('lodash');

function TicketDatePicker(from_date, to_date, onDateChange, clearDataFilter) {
  return ( 
    <React.Fragment>
      <div style={{display: 'inline-flex', marginRight:10 }}>
        <span className="input-group-text" style={{height:'fit-content'}}>
          <i style={{ height:20 }} className="icon-calendar22"></i>
        </span>
        <Field
          name="from_date"
          placeholder="Từ ngày"
          dateFormat="dd/MM/yyyy"
          onChangeAction={(value)=>onDateChange(value, to_date)}
          component={RenderDatePicker}
        />
      </div>
      <div style={{display: 'inline-flex', marginRight:10 }}>
        <span className="input-group-text" style={{height:'fit-content'}}>
          <i style={{ height: 20 }} className="icon-calendar22"></i>
        </span>
        <Field
          name="to_date"
          placeholder="Đến ngày"
          dateFormat="dd/MM/yyyy"
          onChangeAction={(value)=>onDateChange(from_date, value)}
          component={RenderDatePicker}
        />
      </div>
      <div style={{display: 'inline-flex',height:'fit-content'}}>
        <button onClick={()=>clearDataFilter()} style={{whiteSpace:'nowrap', width:'inherit'}} className="btn btn-warning">x</button>
      </div>
    </React.Fragment>
  )
}

function UserServiceLabel(item) {
  let inner_service_label = "Khác";
  if(item.user_product && !["VPS", "CLOUD_SERVER_PRO"].includes(item.user_product.product.product_type)) {
    inner_service_label = (
      <span>
        {item.user_product.domain}
        <br/>
        <sub style={{color: "#999999"}}>{item.user_product.product.name}</sub>
      </span>
    )
  }
  if(item.user_product && ["VPS", "CLOUD_SERVER_PRO"].includes(item.user_product.product.product_type)) {
    inner_service_label = (
      <span>
        {item.user_product.ip_address}
        <br/>
        <sub style={{color: "#999999"}}>{item.user_product.product.name}</sub>
      </span>
    )
  }
  return inner_service_label;
}

function TicketRows(ticket_data) {
  const ticket_rows = _.map(ticket_data, function(i, key) {
    return ( 
      <tr key={key}>
        <td><Link to={"/user/ticket/detail/"+i.id}>{i.code}</Link></td>
        <td>{i.title}</td>
        <td>{i.department.name}</td>
        <td>{TicketStatus(i.status)}</td>
        <td>{i.updated_date}</td>
        <td>{UserServiceLabel(i)}</td>
        <td>
          <div className="icons-list">
            <div className="dropdown">
              <a href="#" className="list-icons-item" data-toggle="dropdown">
                <i className="icon-menu9"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to={"/user/ticket/detail/"+i.id} className="dropdown-item">
                  <i className="icon-list-numbered"></i> Chi tiết
                </Link>
              </div>
            </div>
          </div>
        </td>
      </tr>
    )
  })
  return ticket_rows;
}

class TicketTable extends React.Component {
  constructor(props) {
    super(props);
    const { ticket_status } = this.props.routeProps.match.params;
    const search_value = new URLSearchParams(this.props.location.search).get("search")
    this.state = {
      table_data: [],
      table_query: {
        current_page: 1,
        number_of_row: 20,
        sort_by: "",
        sort_asc: false,
        ticket_status: ticket_status ? ticket_status : "all",
        search_field: "search",
        search_value: search_value ? search_value : "",
        from_date: null,
        to_date: null
      },
      ticket_static: {}
    }
    this.channel = SocketUtils.socketStore();
    this.channel.join();
  }

  socketLiveReload() {
    const {table_query} = this.state;
    const {userProfile, ticketModifyReload, agencyTicketModifyReload} = this.props;
    const self = this;
    let channel_name = "tadu_customer_ticket_modify";
    if(userProfile && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      channel_name = "agency_customer_ticket_modify";
      agencyTicketModifyReload();
    }
    this.channel.on(channel_name, response => {
      // Chỉ live reload với đúng khách hàng của ticket này.
      if(userProfile && userProfile.email == response.customer_email) {
        self.staicReload(table_query);
        self.listReload(table_query);
        ticketModifyReload();
      }
    })
  }

  componentDidMount() {
    this.staicReload(this.state.table_query);
    this.listReload(this.state.table_query);
    this.socketLiveReload();
  }

  gotoPage = (page) => {
    const table_query = {
      ...this.state.table_query,
      current_page: page
    };
    this.listReload(table_query);
  }

  // Tải và cập nhật số lượng của các loại yêu cầu.
  staicReload = (table_query) => {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/ticket/static',
      withCredentials: true,
      data: {
        from_date: table_query.from_date,
        to_date: table_query.to_date
      }
    }).then(function (response) {
      self.setState({
        ...self.state,
        ticket_static: response.data.data
      })
    });
  }

  // Tải và cập nhật danh sách yêu cầu
  listReload = (table_query) => {
    const self = this;
    axios({
      method: 'post',
      url: window.api_url + '/user/ticket/list',
      withCredentials: true,
      data: table_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        table_query: table_query,
        table_data: response.data.data
      })
    });
  }
  
  onTicketStaticChange = (ticket_status) => {
    const { history } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1,
      ticket_status: ticket_status
    };
    history.push("/user/ticket/"+ ticket_status) 
    this.listReload(table_query);
  }
  
  onDateChange = (from_date, to_date) => {
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1,
      from_date: moment(from_date).format("YYYY-MM-DD 00:00:00"),
      to_date: moment(to_date).format("YYYY-MM-DD 23:59:59")
    };
    this.setState({
      ...this.state,
      table_query: table_query
    });
    if(from_date && to_date) {
      this.listReload(table_query);
      this.staicReload(table_query);
    }
  }

  clearDataFilter = () => {
    const { updateField, reset } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1,
      from_date: null,
      to_date: null
    };
    
    reset();
    this.listReload(table_query);
    this.staicReload(table_query);
  }

  onSearchSubmit = (values) => {
    const { history } = this.props;
    const search_value = values.search ? values.search : ""
    const table_query = {
      ...this.state.table_query,
      search_value: search_value,
      current_page: 1,
      ticket_status: "all"

    }
    if (!StringUtils.isEmptyOrSpaces(search_value)) {
      history.push("/user/ticket?search=" + search_value);
    } else {
      history.push("/user/ticket/all")
    }
    this.listReload(table_query);
    
  }
  
  onKeyPess = (key_code) => {
    const { history } = this.props;
    if (key_code == "Escape") {
      history.push("/user/ticket/all")
      this.clearDataFilter();
    }
  }

  render() {
    const { table_query, table_data, ticket_static } = this.state;
    const { handleSubmit } = this.props;
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Danh sách yêu cầu</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body">
              <form style={{display:"inline-flex"}} onSubmit={handleSubmit(this.onSearchSubmit)}>
                <div style={{ width:"100%", paddingRight:5}}>
                  <Field
                    name="search"
                    placeholder="Mã hoặc Tiêu đề"
                    defaultValue={table_query.search_value}
                    onKeyAction={(ev) => this.onKeyPess(ev)}
                    component={RenderSearchForm}
                  />
                </div>
              </form>
              {StringUtils.isEmptyOrSpaces(table_query.search_value) && <div className="row">
                <div className="col-md-6">
                  <TicketStaticSelect ticket_static={ticket_static} onTicketStaticChange={this.onTicketStaticChange} />
                </div>
                <div className="col-md-6">
                  <form style={{ float: 'right', display: 'inline-flex' }} onSubmit={handleSubmit(this.onDateChange)} className="form-horizontal">
                    {TicketDatePicker(table_query.from_date, table_query.to_date, this.onDateChange, this.clearDataFilter)}
                  </form>
                </div>
              </div>}
            </div>
          </div>
        </div>
        <div className="row"> 
          <div className="col-md-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-togglable table-hover default footable-loaded footable">
                  <thead>
                    <tr>
                      <th>Mã</th>
                      <th>Tiêu Đề</th>
                      <th>Phòng Ban</th>
                      <th>Trạng Thái</th>
                      <th>Ngày</th>
                      <th>Dịch Vụ</th>
                      <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                          <i className="icon-menu-open2"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_data.data && TicketRows(table_data.data)}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination query={table_query} data={table_data} handle={this.gotoPage}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({
  updateField: (fieldName, value) =>
    dispatch({
      meta: { form: "TicketTable", field: fieldName },
      payload: value,
      type: "@@redux-form/CHANGE"
    }),
  ticketModifyReload: () => dispatch.messageModify.ticketModifyReload(),
  agencyTicketModifyReload: () => dispatch.messageModify.agencyTicketModifyReload()
});

const TicketTableWrapper = reduxForm({
  form: 'TicketTable',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(TicketTable);

export default connect(mapState,mapDispatch)(withRouter(TicketTableWrapper));