import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { defaults, Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import BlockUI from '../../component/BlockUI';
import { DateUtil, FormatterUtils, NumberUtils } from '../../utils/javascriptUtils';

// Set Responsive for line chart
defaults.global.maintainAspectRatio = false;

var _ = require('lodash')

function CPUUsageGraph(labels, cpu_data) {
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'CPU Usage (%)',
        type:'line',
        lineTension: 0.1,
        fill: 'start',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'red',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: cpu_data,
        options: {
          maintainAspectRatio : false
        }
      }
    ]
  };
  return (
    <React.Fragment>
      <h3><i className="icon-chip"></i> CPU Usage</h3>
      <article style={{height: "60vh"}} className="canvas-container">
        <Line data={data} height={150} width={500} />
      </article>
    </React.Fragment>
  )
}

function MemoryUsageGraph(labels , memData) {
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Memory Usage (GB)',
        type:'line',
        data: memData,
        fill: "start",
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
      }
    ]
  };
  return (
    <React.Fragment>
      <h3><i className="icon-floppy-disk"></i> Memory Usage</h3>
      <article style={{height: "60vh"}} className="canvas-container">
        <Line data={data} height={150} width={500} />
      </article>
    </React.Fragment>
  )
}

function NetworkTraffic(labels, NetworkInData, NetworkOutData) {
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Netin (KB/s)',
        type:'line',
        lineTension: 0.1,
        fill: "start",
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'red',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: NetworkInData,
      },
      {
        label: 'Netout (KB/s)',
        type:'line',
        data: NetworkOutData,
        fill: "start",
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
      }
    ]
  };
  return (
    <React.Fragment>
      <h3><i className="icon-sphere3"></i> Network Traffic</h3>
      <article style={{height: "60vh"}} className="canvas-container">
        <Line data={data} height={150} width={500} />
      </article>
    </React.Fragment>
  )
}

function DiskChart(labels, diskData) {
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Disk (GB)',
        type:'line',
        lineTension: 0.1,
        fill: "start",
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'red',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: diskData
      },
    ]
  };
  return (
    <React.Fragment>
      <h3><i className="icon-drive"></i> Disk Usage</h3>
      <article style={{height: "60vh"}} className="canvas-container">
        <Line data={data} height={150} width={500} />
      </article>
    </React.Fragment>
  )
}

function InodeChart(labels, inodeData) {
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Disk Inode',
        type:'line',
        lineTension: 0.1,
        fill: "start",
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'red',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: inodeData
      },
    ]
  };
  return (
    <React.Fragment>
      <h3><i className="icon-drive"></i> Disk Inode</h3>
      <article style={{height: "60vh"}} className="canvas-container">
        <Line data={data} height={150} width={500} />
      </article>
    </React.Fragment>
  )
}

function IOChart(labels, IOReadData, IOWriteData) {
  
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'IO Read (MB/s)',
        type:'line',
        lineTension: 0.1,
        fill: "start",
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'red',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: IOReadData,
      },
      {
        label: 'IO Write (MB/s)',
        type:'line',
        data: IOWriteData,
        fill: "start",
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
      }
    ]
  };
  return (
    <React.Fragment>
      <h3><i className="icon-sphere3"></i> IO Chart</h3>
      <article style={{height: "60vh"}} className="canvas-container">
        <Line data={data} height={150} width={500} />
      </article>
    </React.Fragment>
  )
}

function ActionButtonGroup(vmData,onStartVM, onPowerOffVM, onRestartVM, startActionLoading, powerOffActionLoading, restartActionLoading ) {
  return (
    <React.Fragment>
      <center>
        <br/>
        <h3>Actions</h3>
      </center>
      <div style={{justifyContent: "center"}} className="card-body row" >
        <button disabled={startActionLoading || vmData.status === 1} onClick={() => onStartVM()} style={{margin: 5, alignItems: "center"}} className="card col-md-1 product-item">
          <i className="icon-play4"></i> 
          Start
        </button>
        <button disabled={powerOffActionLoading || vmData.status === 0} onClick={() => onPowerOffVM()} style={{margin: 5, alignItems: "center"}} className="card col-md-1 product-item">
          <i className="icon-switch"></i> 
          Shutdown
        </button>
        <button disabled={restartActionLoading} onClick={() => onRestartVM()} style={{margin: 5, alignItems: "center"}} className="card col-md-1 product-item">
          <i className="icon-sync"></i> 
          Reset
        </button>
      </div>
    </React.Fragment>
  )
}

function PercentUsageBar(data) {
    // Thay đổi màu sắc theo phần % CPU và RAM sử dụng.
    const usage_percent_color = (data < 50 && "#4CAF50") || (data > 50 && data < 100 && "orange") || (data > 100 && "red")
    const totalBarCss = {
      minWidth:"150px",
      maxWidth: "200px",
      backgroundColor: "#ddd"
    }
    
    const usageBarCss = {
      width: `${data}%`,
      height: "10px",
      backgroundColor: usage_percent_color,
      textAlign: "center",
      lineHeight: "10px",
      color: "white"
    }
    return (
      <div style={totalBarCss}>
        <div style={usageBarCss}></div>
      </div>
    )
}

function VPSInfoGroup(vmData, userProductData) {

  return (
    <React.Fragment>
      <div style={{border: 'none'}} className="card-body table-responsive">
        <table className="table text-nowrap border">
          <thead> 
            <tr className="table-active">
              <th colSpan="2">Thông tin</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Uptime</td>
              <td>{DateUtil.secondToTime(vmData.uptime)}</td>
            </tr>
            <tr>
              <td>Tên Dịch Vụ</td>
              <td><strong>{userProductData.product.name}</strong></td>
            </tr>
            <tr>
              <td>Khách Hàng</td>
              <td><strong>{vmData.email}</strong></td>
            </tr>
            <tr>
              <td>IP Address</td>
              <td><strong>{userProductData.ip_address}</strong></td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                {vmData.status === 0 && <span className="text-danger">VPS is off</span> }
                {vmData.status === 1 && <span className="text-green">VPS is on</span> }
                {vmData.status === 2 && <span className="text-grey">VPS is suspended</span> }
              </td>
            </tr>
            <tr>
              <td>CPU Usage</td>
              <td>
                {vmData.used_cpu}% 
                <br/>
                {PercentUsageBar(FormatterUtils.round2Decimals(vmData.used_cpu))}
              </td>
            </tr>
            <tr>
              <td>Memory Usage</td>
              <td>
                ({vmData.used_ram} MB of {vmData.ram} MB) 
                <br/>
                {PercentUsageBar(NumberUtils.getPercentOfTwoNumber(vmData.used_ram, vmData.ram))}
              </td>
            </tr>
            <tr>
              <td>Bootdisk Size</td>
              <td className="text-green">{vmData.disk} GB</td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

function BackupRows(backupData) {
  const inner_tr = _.map(backupData, (i, index) => {
    return (
      <tr key={index}>
        <td>
          {moment(i.backup_date).format("DD-MM-YYYY")}
        </td>
      </tr>
    )  
  })
  return (
    <React.Fragment>
    <div style={{border: 'none', marginLeft: 20}}>
      <h3><i className="icon-database2"></i> Backup List</h3>
    </div>
    <div style={{border: 'none', height: inner_tr.length > 0 ? 200 : null}} className="card-body table-responsive">
      <table className="table text-nowrap border">
        <thead> 
          <tr className="table-active">
            <td>Date</td>
          </tr>
        </thead>
        <tbody>
          {inner_tr}
        </tbody>
      </table>
    </div>
    </React.Fragment>
  )
}

function GraphGroup(labels, cpuData, memData, DiskData, InodeData, IOReadData, IOWriteData, NetworkInData, NetworkOutData) {
  return (
    <div style={{border: "none"}} className="card-body">
      {CPUUsageGraph(labels, cpuData)}
      <br/>
      {MemoryUsageGraph(labels, memData)}
      <br/>
      {NetworkTraffic(labels, NetworkInData, NetworkOutData)}
      <br/>
      {DiskChart(labels, DiskData)}
      <br/>
      {InodeChart(labels, InodeData)}
      <br/>
      {IOChart(labels, IOReadData, IOWriteData)}
    </div>
  )
}

class VPSManagePage extends React.Component {
  intervalStatus;
  intervalRRDStatic;
  constructor(props) {
    super(props);
    this.state = {
      vm_data: null,
      user_product_obj: null,
      action_type: null,
      backup_list: [],
      isLoading: true,
      chartLabels: [],
      dataCPU: [],
      dataMemory: [],
      dataNetworkIn: [],
      dataNetworkOut: [],
      dataDisk: [],
      dataInode: [],
      dataIORead: [],
      dataIOWrite: [],
      startActionLoading: false, 
      powerOffActionLoading: false, 
      restartActionLoading: false, 
    }
  }

  getUserProductById = () => {
    const { user_product_id } = this.props.routeProps.match.params;
    const self = this;

    return axios({
      method: 'get',
      url: window.api_url+ `/user/service/${user_product_id}`,
      withCredentials: true
    }).then(function (resp) {
      if(resp.data && !resp.data.isError && resp.data.data.status === "RUNNING") {
        self.loadVMStatus();
        self.loadVMStatic();

        self.setState({
          ...self.state,
          user_product_obj: resp.data.data
        })
      }else {
        self.setState({
            ...self.state,
            user_product_obj: null,
            vm_data: null,
            isLoading: false
        })
      }
    })
  }

  loadVMStatus = async () => {
    const { user_product_id } = this.props.routeProps.match.params;
    const self = this;

    return await axios({
      method: 'get',
      url: window.api_url+ `/user/vm/status/${user_product_id}`,
      withCredentials: true
    }).then(function (resp) {
      if(resp.data && !resp.data.isError) {
        self.setState({
          ...self.state,
          vm_data: resp.data.data,
          isLoading: false
        })
        self.intervalStatus = setTimeout(self.loadVMStatus.bind(this), 15000);
      }else {
        self.setState({
          ...self.state,
          isLoading: false
        })
      }
      // Trường hợp VM đã bị PAUSED (SUSPEND) từ kythuat.
      if(resp.data.data && resp.data.data.status === 2) {
        clearTimeout(self.intervalStatus);
        clearTimeout(self.intervalRRDStatic);
      }
    })
  }

  loadVMStatic = async () => {
    const { user_product_id } = this.props.routeProps.match.params;
    const self = this;

    return await axios({
      method: 'get',
      url: window.api_url + `/user/vm/static/${user_product_id}`,
      withCredentials: true
    }).then(function (resp) {
      if(resp.data && !resp.data.isError) {
        self.setState({
          ...self.state,
          chartLabels: _.reverse(_.map(resp.data.data, (i) => DateUtil.secondToDate(i.time)), 'asc'),
          dataCPU: _.reverse(_.map(resp.data.data, (i) => parseFloat(i.cpu_stats.cpu)) , 'asc'),
          dataMemory: _.reverse(_.map(resp.data.data, (i) => parseFloat(i.ram)) , 'asc'),
          dataDisk: _.reverse(_.map(resp.data.data, (i) => parseFloat(NumberUtils.MBtoGB(i.disk_stats.disk))) , 'asc'),
          dataInode: _.reverse(_.map(resp.data.data, (i) => parseFloat(i.disk_stats.inode)) , 'asc'),
          dataIORead: _.reverse(_.map(resp.data.data, (i) => parseFloat(NumberUtils.ByteToMB(i.disk_stats.io_read))) , 'asc'),
          dataIOWrite: _.reverse(_.map(resp.data.data, (i) => parseFloat(NumberUtils.ByteToMB(i.disk_stats.io_write))) , 'asc'),
          dataNetworkIn:  _.reverse(_.map(resp.data.data, (i) => parseFloat(NumberUtils.ByteToKB(i.network_stats.net_in))) , 'asc'),
          dataNetworkOut: _.reverse(_.map(resp.data.data, (i) => parseFloat(NumberUtils.ByteToKB(i.network_stats.net_out))) , 'asc'),
        })
        self.intervalRRDStatic = setTimeout(self.loadVMStatic.bind(this), 60000 * 30);
      }
    })
  }

  startVM = async () => { 
    const { user_product_id } = this.props.routeProps.match.params;
    this.setState({
      startActionLoading: true 
    })
    const self = this;
    
    return await axios({
      method: 'post',
      url: window.api_url + `/user/vm/start`,
      withCredentials: true,
      data: {
        user_product_id: user_product_id
      }
    }).then(function (resp) {
      if(resp.data && !resp.data.isError) {
        self.setState({
          startActionLoading: false 
        })
        self.getUserProductById();                
      }else {
        self.setState({
          startActionLoading: false 
        })
        self.getUserProductById();
      }
    })
  }

  powerOffVM = async () => { 
    const { user_product_id } = this.props.routeProps.match.params;
    
    const self = this;
    const alert_confirm = window.confirm("Bạn có chắc sẽ Shutdown VPS ?");
    
    if(alert_confirm) {
        this.setState({
            powerOffActionLoading: true 
        })
        return await axios({
          method: 'post',
          url: window.api_url + `/user/vm/power_off`,
          withCredentials: true,
          data: {
            user_product_id: user_product_id
          }
        }).then(function (resp) {
          if(resp.data && !resp.data.isError) {
            self.setState({
              powerOffActionLoading: false 
            })
            self.getUserProductById();                
          }else {
            self.setState({
              powerOffActionLoading: false 
            })
            self.getUserProductById();
          }
        })
    }
  }
  
  restartVM = async () => { 
    const { user_product_id } = this.props.routeProps.match.params;
    this.setState({
      restartActionLoading: true 
    })
    const self = this;
    
    return await axios({
      method: 'post',
      url: window.api_url + `/user/vm/restart`,
      withCredentials: true,
      data: {
        user_product_id: user_product_id
      }
    }).then(function (resp) {
      if(resp.data && !resp.data.isError) {
        self.setState({
          restartActionLoading: false 
        })
        self.getUserProductById();                
    }else {
      self.setState({
        restartActionLoading: false 
      })
        self.getUserProductById();
      }
    })
  }

  componentDidMount() {
    this.getUserProductById();
  }

  componentWillUnmount() {
    /*
      stop Load status from continuing to run even
      after unmounting this component. Notice we are calling
      'clearTimeout()` here rather than `clearInterval()` 
    */
   clearTimeout(this.intervalStatus);
   clearTimeout(this.intervalRRDStatic);
  }

  render() {
    const { vm_data, user_product_obj, isLoading, dataCPU, chartLabels, dataMemory, dataDisk, dataInode, dataIORead, dataIOWrite, dataNetworkIn, dataNetworkOut, startActionLoading, powerOffActionLoading, restartActionLoading } = this.state;
    return(
      <React.Fragment>
        {isLoading && <BlockUI />}
        {!isLoading && <div>
          <ToastContainer />
           {(!vm_data || !user_product_obj) && <div className="card">
             <div className="card-body">
               <span>Dịch vụ không tồn tại !</span>
             </div>
           </div>}
           {vm_data && user_product_obj && <div>
             <div className="page-header">
               <div className="page-header-content header-elements-md-inline">
                 <div className="page-title d-flex">
                   <h4>
                     <i className="icon-paragraph-justify2 mr-2"></i>
                     <span className="text-semibold">Quản Lý Cloud Server</span>
                   </h4>
                 </div>
               </div>
             </div>
             <br/>
             <div className="card card-white">
                {vm_data.status !== 2 && ActionButtonGroup(
                    vm_data,
                    this.startVM,
                    this.powerOffVM,
                    this.restartVM,
                    startActionLoading,
                    powerOffActionLoading,
                    restartActionLoading
                   )}
                 {VPSInfoGroup(vm_data, user_product_obj)}
                 {vm_data.status !== 2 && GraphGroup(
                  chartLabels,
                  dataCPU,
                  dataMemory,
                  dataDisk,
                  dataInode,
                  dataIORead,
                  dataIOWrite,
                  dataNetworkIn,
                  dataNetworkOut,
                 )}
                 {/* {BackupRows(backup_list)} */}
             </div>
           </div>}
        </div>}
      </React.Fragment>
    )
  }
}

const mapState = state => ({

});

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, mapDispatch)(withRouter(VPSManagePage));