import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

var numeral = require('numeral');

const mapState = state => ({
  cart: state.cart,
  login: state.app.login,
  currentEmail: state.app.currentEmail
});

const mapDispatch = (dispatch) => ({
  loadCart: () => dispatch.cart.loadCart(),
  emptyCart:() => dispatch.cart.emptyCart()
});

function MomoInnerHtml(result_data) {
  let invoice_status = <h4>Thanh toán thành công đơn hàng <strong className="price">{result_data.invoice_code}</strong></h4>
  // Kiểm tra trạng thái đơn hàng và hiển thị thông tin ra màn hình.
  if (result_data.status == "CANCELED") {
    invoice_status = <h4>Đơn hàng <strong style={{ color: 'grey' }}>{result_data.invoice_code}</strong> Đã được huỷ hoặc hết thời gian chờ thanh toán. </h4>
  }
  if(result_data.invoice_code.startsWith('MOMO') && result_data.status == "FINISH") {
    invoice_status = (
      <h4> 
        Nạp tiền thành công vào tài khoản TADU thông qua hoá đơn <strong className="price">{result_data.invoice_code}</strong> được thanh toán bằng ví Momo 
        <br/> 
        <Link to={"/user/billing/primary"}> <i className="icon-arrow-right5"></i> Quay về tài khoản chính  </Link>
      </h4>
    )
  }
  if(result_data.invoice_code.startsWith('MOMO') && result_data.status == "CANCELED") {
    invoice_status = (
      <h4> 
        Hoá đơn <strong style={{ color: 'grey' }}>{result_data.invoice_code}</strong> nạp tiền vào tài khoản thông qua ví MOMO đã bị huỷ hoặc hết thời gian chờ thanh toán. 
        <br/> 
        <Link to={"/user/billing/primary"}> <i className="icon-arrow-right5"></i> Quay về tài khoản chính  </Link>
      </h4>
    )
  }
	if (result_data.status == "OTHER_ERROR") {
    invoice_status = <h4><strong>{result_data.message}</strong></h4>
  }
  let inner_html = (
    <React.Fragment>
      <br/>
        <h4>{invoice_status}</h4>
      <br/>
    </React.Fragment>)
  return inner_html
        
}

class MomoReturnPage extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      invoice: {},
      isLoading: true,
      error_message: null
    }
  }

  componentDidMount() {
    const { emptyCart } = this.props;
    const params = Object.fromEntries(new URLSearchParams(this.props.location.search));
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/momo-return',
      data: params,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          result_data: response.data.data,
          isLoading: false
        })
      }
      emptyCart();
    });
  }

  render() {
    const { isLoading, result_data } = this.state;
    return (
      <div className="container">
        <React.Fragment>
          <div className="row">
            <h1 className="page-title sub-page-title">TRẠNG THÁI THANH TOÁN</h1>
          </div>
          {!isLoading && <div style={{ backgroundColor: "white"}}>
          <div style={{margin:"20px"}}>
            {MomoInnerHtml(result_data)}
          </div>
          </div>}
          {isLoading &&<img width="30" src="/assets/img/squares.gif" />}
        </React.Fragment>
      </div>
    )
  }
}

export default connect(mapState,mapDispatch)(withRouter(MomoReturnPage));