import React from 'react'
import '../css/login.css';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import axios from 'axios';
import ReduxLoginFormField from "../component/ReduxLoginFormField";
import { Link as RouterLink } from 'react-router-dom';

class UpdatePasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isTokenVaild: true,
            isDone: false
        };
    }

    submit(values) {
        const self = this;
        const {token} = this.props.routeProps.match.params;

        return axios({
            method: 'post',
            url: window.api_url + '/auth/update_password/' + token,
            data: {
                ...values
            },
            withCredentials: true
        }).then(function (response) {
            if (response.data.isError) {
                if (response.data.error === "token_error") {
                    self.setState({
                        isTokenVaild: false,
                        isDone: true
                    });
                } else {
                    throw new SubmissionError(response.data.error);
                }
            } else {
                self.setState({isDone: true});
            }
        });
    }


    render() {
        const {error, handleSubmit, submitting, pristine, reset} = this.props;
        const {isDone, isTokenVaild} = this.state;

        return (
            <React.Fragment>
                <div className="navbar navbar-inverse">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/">
                            <img src="/assets/img/lglogo.png" alt="tadu.cloud"/>
                        </a>
                    </div>
                </div>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="content d-flex justify-content-center align-items-center">
                            <form className="login-form" onSubmit={handleSubmit(this.submit.bind(this))}>
                                <div className="card mb-0">
                                    <div className="card-body login-body">
                                        <div className="text-center mb-3">
                                            <div className="text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" style={{border: "none"}}>
                                                <a href="/">
                                                    <img width="205" src="/assets/img/lglogo.png" alt="tadu.cloud"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="text-center mb-3">
                                            <h5 className="mb-0">Đổi Mật Khẩu Mới</h5>
                                        </div>

                                        {!isDone && 
                                        <React.Fragment>
                                            <Field
                                                name="password"
                                                type="password"
                                                iconClass="icon-lock2"
                                                placeholder="Mật khẩu mới (ít nhất 6 ký tự)"
                                                component={ReduxLoginFormField}/>

                                            <div className="form-group">
                                                <button type="submit" className={"btn bg-blue btn-block " + (submitting ? "disabled" : "")}>
                                                    Đổi mật khẩu <i className="icon-arrow-right14 position-right"></i>
                                                </button>
                                            </div>
                                        </React.Fragment>}

                                        {isDone && isTokenVaild && <div className="alert alert-success border-0">
                                            Đổi mật khẩu thành công!
                                        </div>}

                                        {!isTokenVaild && <div className="alert alert-danger border-0">
                                            Token bị sai hoặc hết hạn!
                                        </div>}

                                        {isDone && <div className="form-group">
                                            <RouterLink className="btn btn-light btn-block" to="/">
                                                Trở về trang đăng nhập
                                            </RouterLink>
                                        </div>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

const UpdatePasswordPageWrapper = reduxForm({
    form: 'updatePassword'
})(UpdatePasswordPage);


export default UpdatePasswordPageWrapper;