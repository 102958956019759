import axios from 'axios';
import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router';
import { RenderTextArea } from '../../component/formInput';

class CSSConfig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    componentDidMount() {
        
    }
    
    submit = (value) => {
        axios({
            method: 'post',
            url: window.api_url+ '/user/agency/css_update',
            data: {
                css_backend: value.css_backend
            },
            withCredentials: true
        }).then(function (response) {
            if(!response.data.isError) {
                alert("Lưu Thành Công")
            }
        })
    }
    
    render() {
        const { handleSubmit } = this.props;
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold"> Chỉnh Sửa Giao Diện (CSS)</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="card panel-flat">
                    <div class="card-body">
                        <form onSubmit={handleSubmit(this.submit)} o class="form-horizontal" role="form">
                            <div class="form-group">
                                Quý khách có thể chỉnh sửa giao diện bằng cách viết lại CSS cho giao diện để có một thương hiệu riêng hoàn hảo.
                                <a href="https://trogiup.tadu.vn/chinh-sua-trang-quan-tri-thuong-hieu-rieng/">Xem hướng dẫn sử dụng tại đây.</a>
                            </div>
                            <div class="form-group">
                                <Field name="css_backend" cols="30" rows="20" style={{width: "100%"}} component={RenderTextArea}></Field>
                        
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary bg-blue">Cập nhật <i class="icon-arrow-right14 position-right"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapState = state => ({
    initialValues: {
        // css_backend: 
    }
});

const mapDispatch = dispatch => ({
    
});

export default reduxForm({
    form: 'CSSConfig',
    destroyOnUnmount: true,
    enableReinitialize: true
})(withRouter(CSSConfig));