import {reducer as formReducer} from 'redux-form'
import {init} from '@rematch/core'
import * as models from './models/models'

function counter(state = 0, action) {
    switch (action.type) {
        case 'INCREMENT':
            return state + 1;
        case 'DECREMENT':
            return state - 1;
        default:
            return state;
    }
}

// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
const store = init({
    models,
    redux: {
        reducers: {
            counter,
            // you have to pass formReducer under 'form' key,
            // for custom keys look up the docs for 'getFormState'
            form: formReducer
        }
    }
});

export default store;