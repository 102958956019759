import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
class ShowWhoisModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            whois_result: {},
            isLoading: true
        }
    }
    componentDidMount() {
        const { domain } = this.props;
        const self = this;
        axios({
            method: 'get',
            url: window.api_url + '/domain/' + domain + "/whois",
            withCredentials: true
        }).then(function (response) {
            self.setState({
                ...self.state,
                isLoading: false,
                whois_result: response.data.data
            })
        })
    }
    render() {
        const { title } = this.props;
        const { isLoading, whois_result } = this.state;
        const modalConfig = { backdrop: 'static', show: this.props.show, bssize: "medium", onHide: this.props.onHide, submitting: this.props.submitting };
        
        return <div style={{ width: '30%' }}>
            <Modal
                {...modalConfig}
                aria-labelledby="contained-modal-title-lg">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-sm"><center>{title}</center> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <img width="30" src="/assets/img/squares.gif" /> : ReactHtmlParser(whois_result)}
                </Modal.Body>
                <Modal.Footer>
                    <button class="btn btn-warning btn-reg" onClick={this.props.onHide}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    }
};


export default ShowWhoisModal;
