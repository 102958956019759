import React from 'react'
import {Link} from "react-router-dom";
import {connect} from 'react-redux'
import axios from 'axios';
import RecentOrderTable from './RecentOrderTable';
import RecentTicket from './RecentTicket';
import RecentAgencyOrderTable from './RecentAgencyOrderTable';
import LineChart from './LineChart';
import AlertLabel from '../../component/AlertLabel';
var numeral = require('numeral');

class DashboardPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      domainStatic: {},
      vpsStatic: {},
      hostingStatic: {},
      emailStatic: {},
      billingStatic: {}
    };
  }

  componentDidMount() {
    const self = this;

    axios({
      method: 'get',
      url: window.api_url + '/auth/user/info',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        userInfo: response.data.data
      })
    });

    axios({
      method: 'get',
      url: window.api_url + '/user/vps/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        vpsStatic: response.data.data
      })
    });

    axios({
      method: 'get',
      url: window.api_url + '/user/domain/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        domainStatic: response.data.data
      })
    });

    axios({
      method: 'get',
      url: window.api_url + '/user/email/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        emailStatic: response.data.data
      })
    });

    axios({
      method: 'get',
      url: window.api_url + '/user/hosting/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        hostingStatic: response.data.data
      })
    });

    axios({
      method: 'get',
      url: window.api_url + '/user/billing/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        billingStatic: response.data.data
      })
    });
  }

  render() {
    const {signoutAsync} = this.props;
    const {userInfo, vpsStatic, hostingStatic, domainStatic, emailStatic, billingStatic} = this.state;
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
              <div className="page-title d-flex">
                <h4>
                  <i className="icon-paragraph-justify2 mr-2"></i>
                  <span className="text-semibold">Dashboard</span>
                  <br />
                  {domainStatic.domain_need_upload_photo > 0 &&
                    <div className="alert alert-warning alert-styled-left alert-dismissible" style={{fontSize: 15, width: "1160px"}}>
                      Có {domainStatic.domain_need_upload_photo} tên miền cần cập nhật CMND, quý khách vui lòng cập nhật để tránh tên miền bị đóng lại (suspend). Xin cám ơn!
                      <br/>
                      <Link to={"/service/domain/pending"}>Nhấn vào đây để cập nhật CMND</Link>
                    </div>
                  }
                  </h4>
              </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-4">
            <div className="card card-body dashboard-panel">
              <div className="row">
                <div className="col-md-4 text-center">
                  <i className="icon-user icon-5x"></i>
                </div>
                <div className="col-md-8">
                  <h2>TÀI KHOẢN</h2>
                  <Link to="/user_profile">Thông tin cá nhân</Link>
                  <br/>
                  <Link to="/change_password">Đổi mật khẩu</Link>
                  <br/>
                  <a href="#" onClick={signoutAsync}>Thoát</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-body dashboard-panel">
              <div className="row">
                <div className="col-md-4 text-center">
                  <i className="icon-cash icon-5x"></i>
                </div>
                <div className="col-md-8">
                  <h2>SAO KÊ</h2>
                  <span>Xem sao kê:</span>
                  <br/>
                  <Link to="/user/billing/primary">
                    Tài khoản chính: <strong className="cash">{numeral(billingStatic.primary).format('0,0')} đ</strong>
                  </Link>
                  <br/>
                  <Link to="/user/billing/secondary">
                    Tài khoản khuyến mãi: <strong className="cash">{numeral(billingStatic.secondary).format('0,0')} đ</strong>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card card-body dashboard-panel">
              <div className="row">
                <div className="col-md-4 text-center">
                  <i className="icon-help icon-5x"></i>
                </div>
                <div className="col-md-8">
                  <h2>HỖ TRỢ</h2>
                  <Link to="/user/ticket/create">Gửi yêu cầu mới</Link>
                  <br/>
                  <Link to="/user/ticket/all">Yêu cầu đã gửi</Link>
                  <br/>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        {userInfo && userInfo.role == "AGENCY" && <div className="card card-body">
          <LineChart />
        </div>}

        <div className="row">
          <div className="col-md-3">
            <div className="card card-body dashboard-panel no-padding">
              <ul className="list-group">
                <div className="font-weight-semibold dashboard-panel-header">
                  <i className="icon-earth icon-2x"></i>
                  <br/>
                  <div>tên miền</div>
                </div>
                <br/>
                <Link to="/service/running/domain" className="list-group-item list-group-item-action">
                  Dịch vụ đang sử dụng {domainStatic.running > 0 && <span className="badge badge-primary ml-2">{domainStatic.running}</span>}
                </Link>
                <Link to="/service/expiring/domain" className="list-group-item list-group-item-action">
                  Dịch vụ sắp hết hạn {domainStatic.expiring > 0 && <span className="badge badge-warning ml-2">{domainStatic.expiring}</span>}
                </Link>
                <Link to="/service/expired/domain" className="list-group-item list-group-item-action">
                  Dịch vụ hết hạn {domainStatic.suspend > 0 && <span className="badge badge-warning ml-2">{domainStatic.suspend}</span>}
                </Link>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-body dashboard-panel no-padding">
              <ul className="list-group">
                <div className="font-weight-semibold dashboard-panel-header">
                  <i className="icon-server icon-2x"></i>
                  <br/>
                  <div>web hosting</div>
                </div>
                <br/>
                <Link to="/service/running/hosting" className="list-group-item list-group-item-action">
                  Dịch vụ đang sử dụng {hostingStatic.running > 0 && <span className="badge badge-primary ml-2">{hostingStatic.running}</span>}
                </Link>
                <Link to="/service/expiring/hosting" className="list-group-item list-group-item-action">
                  Dịch vụ sắp hết hạn {hostingStatic.expiring > 0 && <span className="badge badge-warning ml-2">{hostingStatic.expiring}</span>}
                </Link>
                <Link to="/service/expired/hosting" className="list-group-item list-group-item-action">
                  Dịch vụ hết hạn {hostingStatic.suspend > 0 && <span className="badge badge-warning ml-2">{hostingStatic.suspend}</span>}
                </Link>
                <div className="dashboard-panel-button text-center">
                  <Link to="/products/hosting" className="btn bg-orange">Đăng ký mới</Link>
                </div>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-body dashboard-panel no-padding">
              <ul className="list-group">
                <div className="font-weight-semibold dashboard-panel-header">
                  <i className="icon-cloud icon-2x"></i>
                  <br/>
                  <div>cloud vps</div>
                </div>
                <br/>
                <Link to="/service/running/vps" className="list-group-item list-group-item-action">
                  Dịch vụ đang sử dụng {vpsStatic.running > 0 && <span className="badge badge-primary ml-2">{vpsStatic.running}</span>}
                </Link>
                <Link to="/service/expiring/vps" className="list-group-item list-group-item-action">
                  Dịch vụ sắp hết hạn {vpsStatic.expiring > 0 && <span className="badge badge-warning ml-2">{vpsStatic.expiring}</span>}
                </Link>
                <Link to="/service/expired/vps" className="list-group-item list-group-item-action">
                  Dịch vụ hết hạn {vpsStatic.suspend > 0 && <span className="badge badge-warning ml-2">{vpsStatic.suspend}</span>}
                </Link>
                <div className="dashboard-panel-button text-center">
                  <Link to="/products/vps" className="btn bg-orange">Đăng ký mới</Link>
                </div>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card card-body dashboard-panel no-padding">
              <ul className="list-group">
                <div className="font-weight-semibold dashboard-panel-header">
                  <i className="icon-mail-read icon-2x"></i>
                  <br/>
                  <div>email server</div>
                </div>
                <br/>
                <Link to="/service/running/email" className="list-group-item list-group-item-action">
                  Dịch vụ đang sử dụng {emailStatic.running > 0 && <span className="badge badge-primary ml-2">{emailStatic.running}</span>}
                </Link>
                <Link to="/service/expiring/email" className="list-group-item list-group-item-action">
                  Dịch vụ sắp hết hạn {emailStatic.expiring > 0 && <span className="badge badge-warning ml-2">{emailStatic.expiring}</span>}
                </Link>
                <Link to="/service/expired/email" className="list-group-item list-group-item-action">
                  Dịch vụ hết hạn {emailStatic.suspend > 0 && <span className="badge badge-warning ml-2">{emailStatic.suspend}</span>}
                </Link>
                <div className="dashboard-panel-button text-center">
                  <Link to="/products/email" className="btn bg-orange">Đăng ký mới</Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card border-top-warning">
              <div className="card-header bg-light">
                <h6 className="card-title">Đơn hàng gần nhất</h6>
              </div>
              {userInfo && userInfo.role == "AGENCY" ? <RecentAgencyOrderTable /> : <RecentOrderTable/>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card border-top-warning">
              <div className="card-header bg-light">
                  <h6 className="card-title">Hỗ Trợ gần nhất</h6>
              </div>
              <RecentTicket/>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


const mapState = state => ({

});

const mapDispatch = (dispatch) => ({
    signoutAsync: () => dispatch.app.signoutAsync()
});


export default connect(mapState, mapDispatch)(DashboardPage);