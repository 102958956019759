import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import { RenderInputWithDiv } from '../../../component/formInput';
import { CartUtils } from '../../../utils/javascriptUtils';

const selector = formValueSelector("ModalDomain");

const validate = values => {
  // Kiểm tra tên miền đúng định dạng .
  const reg_domain = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,})$/); 
  const errors = {};

  if(!values.domain_name || values.domain_name.replace(/^(www\.)/,"").trim() === "") {
    errors.domain_name = "Vui lòng nhập tên miền !";
  }else if (!values.domain_name.replace(/^(www\.)/,"").trim().match(reg_domain)) {
    errors.domain_name = "Tên miền " + values.domain_name + " không đúng định dạng.";
  }
  return errors;
}

function DomainResultCheck(submitting) {
  let domain_result_check = (
    <React.Fragment>
      <div className="form-group">
        <Field name="domain_name" label="" placeholder="Nhập tên miền..." component={RenderInputWithDiv}></Field>
      </div>
      <div className="text-center">
        <button type="submit" className="btn bg-orange" disabled={submitting}>Kiểm Tra Tên Miền</button>
      </div>
    </React.Fragment>
    )
    
  return domain_result_check;

}

class ModalDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      domainResult: {}
    }
    this.handleCheckDomain = this.handleCheckDomain.bind(this);
    this.handleAddProductToCart = this.handleAddProductToCart.bind(this);

  }
  handleAddProductToCart() {
    const {addItem, updateCart, history, product_price_selected, domain_name, cart} = this.props;
    const { domainResult } = this.state;
    const domain_exist = CartUtils.FindDomainExist(domain_name, cart);

    // Object các dịch vụ Email, Hosting, SSL...
    const item = {
      id: uuidv4(),
      group_id: null,
      product: product_price_selected.product,
      price_is_year: product_price_selected.is_year,
      month: 12,
      quantity: 1,
      domain_name: domain_name.toLowerCase().replace(/^(www\.)/,"").trim(),
      domain_profile_id: null,
      template_id: null, //template id để sử dung cho vps.
      user_product_id: null, //Dịch vụ cần gia hạn
      is_renew: false,
      is_upgrade: false,
      is_tranfer: false,
      is_delivery: false,
      parent_id: null,
      order: 0 //Vị trí của item trong giỏ hàng
    }
    // Dịch vụ tên miền thêm mới nếu chưa có người sử dụng !
    if (!domainResult.is_exist && domainResult.price && !domain_exist) {
      const domainItem = {
        id: uuidv4(),
        group_id: null,
        product: domainResult.price.product,
        price_is_year: domainResult.price.is_year, 
        // price: domainResult.price,
        month: 12,
        quantity: 1,
        domain_name: domain_name.toLowerCase().replace(/^(www\.)/,"").trim(),
        domain_profile_id: null,
        template_id: null, //template id để sử dung cho vps.
        user_product_id: null, //Dịch vụ cần gia hạn
        is_renew: false,
        is_upgrade: false,
        is_tranfer: false,
        is_delivery: false,
        parent_id: null,
        order: 0 //Vị trí của item trong giỏ hàng
      }
//      addItem(domainItem);
    }
    addItem(item);
    updateCart().then(() => {
      history.push('/products/cart');
    });
  }
  // This handle use to check domain exist and create new Domain for customer
  handleCheckDomain(value) {
    const self = this;
    self.setState({
      isLoading: true
    })
    axios({
      method: 'get',
      url: window.api_url + '/domain/' + value.domain_name.toLowerCase().replace(/^(www\.)/,"").trim() + "/check",
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          ...self.state,
          isLoading: false,
          domainResult: response.data.data
        })
        self.handleAddProductToCart();
      }else if(response.data.error == "invalid_domain") {
        self.setState({
          ...self.state,
          isLoading: false
        })
        toast.error("Tên miền không hỗ trợ.");
      }
      // Trường hợp người dụng nhập tên miền có sub domain.
      else{
        self.setState({
          ...self.state,
          isLoading: false,
          domainResult: {}
        })
        self.handleAddProductToCart();
      }
    })
  }
   
  componentDidMount() {
    const {loadCart} = this.props;
    loadCart();
  }
  
  render() {
    const { title, handleSubmit, submitting } = this.props;
    const { isLoading } = this.state;
    const modalConfig = { backdrop: 'static', show: this.props.show, bssize: "medium", onHide: this.props.onHide, submitting: this.props.submitting };
    return (
      <Modal
        {...modalConfig}
        aria-labelledby="contained-modal-title-lg">
        <ToastContainer />
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm"><center>{title}</center> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <img width="30" src="/assets/img/squares.gif" />}
          {!isLoading && <form className="form-horizontal" role="form" onSubmit={handleSubmit(this.handleCheckDomain)}>
            {DomainResultCheck(submitting)}
          </form>}
        </Modal.Body>
      </Modal>
    )
  }
};
const mapState = state => ({
  domain_name:selector(state,"domain_name"),
  cart: state.cart
});

const mapDispatch = dispatch => ({
  addItem: (item) => dispatch.cart.addItem(item),
  updateCart: () => dispatch.cart.updateCart(),
  loadCart: () => dispatch.cart.loadCart()
});

export default (connect(mapState, mapDispatch)(
  reduxForm({
    form: 'ModalDomain',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate
})(withRouter(ModalDomain))));
