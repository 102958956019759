// import './css/style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import store from './store';
import {routes, loginRoutes, agencyRoutes, customBrandRoutes, redirectRoutes} from './layout/Routes'
import MainLayout from './layout/Main'
import {NotFound} from './layout/NotFound'
import {connect} from 'react-redux'
import BlockUI from './component/BlockUI'
import 'react-toastify/dist/ReactToastify.css';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  window.api_url = "http://localhost:4000/api";
} else {
  window.api_url = "https://api3.tadu.cloud/api";
}

function LoginLayout() {
  return(
    <Switch>
        {loginRoutes.map((route, index) => (
            <Route key={index} exact={route.exact} path={route.path} render={route.render}/>
        ))}
        {redirectRoutes.map((route, index) => (
            <Route key={index} exact={route.exact} path={route.path} render={route.render}/>
        ))}
      <Route component={NotFound} />
    </Switch>
  )
}


class App extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.checkTokenAsync();
    this.props.loadUserAsync();
    this.props.getUserToken();
  }

  render() {
    const { isLoading, login, userProfile } = this.props;
    const agency_has_custom_brand = userProfile && userProfile.has_custom_brand;
    return(
      <React.Fragment>
        {isLoading && <BlockUI/>}
        {!login && !isLoading && <LoginLayout />}
        {login &&!isLoading && userProfile && <MainLayout>
            <Switch>
                {routes.map((route, index) => (
                  <Route key={index} exact={route.exact} path={route.path} render={route.render}/>
                ))}
                {redirectRoutes.map((route, index) => (
                  <Route key={index} exact={route.exact} path={route.path} render={route.render}/>
                ))}
                {/* Chỉ sử dụng các route bên dưới nếu người sử dụng là đại lý */}
                {userProfile && userProfile.role == "AGENCY" && agencyRoutes.map((agencyRoute, index) => (
                  <Route key={index} exact={agencyRoute.exact} path={agencyRoute.path} render={agencyRoute.render}/>
                ))}
                {/* Chỉ sử dụng các route bên dưới nếu người dùng là đại lý thương hiệu */}
                {userProfile && userProfile.role == "AGENCY" && agency_has_custom_brand && customBrandRoutes.map((customBrandRoutes, index) => (
                  <Route key={index} exact={customBrandRoutes.exact} path={customBrandRoutes.path} render={customBrandRoutes.render}/>
                ))}
            <Route component={NotFound} />
          </Switch>
        </MainLayout>}
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  login: state.app.login,
  isLoading: state.app.isLoading,
  userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({
  checkTokenAsync: () => dispatch.app.checkTokenAsync(),
  loadUserAsync: () => dispatch.userProfile.loadUserAsync(),
  getUserToken: () => dispatch.app.getUserToken()
});

const AppContainer = connect(mapState, mapDispatch)(App);

let domContainer = document.getElementById('root');

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <AppContainer/>
    </Provider>
  </Router>, domContainer
);