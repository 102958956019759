import React from 'react'
import axios from 'axios';
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

var _ = require('lodash');
var numeral = require('numeral');



function OrderTimeSettingBtn(time_value, agency_time_option, product_type, setProductTimeOrder, removeProductTimeOrder) {
	const is_time_match = _.find(agency_time_option, (i) => i.time_template == time_value && i.product_type == product_type)
	let inner_btn_html = <button onClick={() => setProductTimeOrder(product_type, time_value)} className="btn bg-info">Áp dụng</button>
	if (is_time_match) {
		inner_btn_html = <button onClick={() => removeProductTimeOrder(is_time_match.id)} className="btn bg-danger">Huỷ</button>
	}
	return inner_btn_html;
}

function TimeOptionRows(time_option, agency_time_option, product_type, setProductTimeOrder, removeProductTimeOrder) {
	agency_time_option = _.forEach(agency_time_option, (i) => i.product_type == product_type);
	if(product_type == "EMAIL") {
		time_option = _.drop(time_option, 3);
	}
	const inner_time_option = _.map(time_option, (i, index) => {
			return <React.Fragment>
				<div key={index} style={{ marginTop: 10 }} className="row">
					<div style={{ fontSize: '18px' }} className="col-sm-10">
						{i.time_template} Tháng
				</div>
					<div className="col-sm-2">
						{OrderTimeSettingBtn(i.time_template, agency_time_option, product_type, setProductTimeOrder, removeProductTimeOrder)}
					</div>
				</div>
			</React.Fragment>
	})
	return inner_time_option
}

function ProductTimeConfigToggle(time_option, agency_time_option, product_type, title, setProductTimeOrder, removeProductTimeOrder) {
	return (
		<React.Fragment>
			<div style={{ border: 'solid 1px', borderRadius: '5px' }}>
				<h3 style={{ margin: '10px' }} data-toggle="collapse" data-target={"#" + product_type}>{title}</h3>
				<div style={{ margin: '10px', borderTop: 'solid 1px'}} id={product_type} className="collapse">
					{TimeOptionRows(time_option, agency_time_option, product_type, setProductTimeOrder, removeProductTimeOrder)}
				</div>
			</div>
			<br/>
		</React.Fragment>)
}
class OrderTimeConfig extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			time_option: [],
			agency_time_option: []
		}
	}

	reloadPage = () => {
		const self = this;
		const time_data = [
			{ time_template: 1},
			{ time_template: 3},
			{ time_template: 6},
			{ time_template: 12},
			{ time_template: 24},
			{ time_template: 36},
			{ time_template: 48},
			{ time_template: 60}
		]
		axios({
				method: 'get',
				url: window.api_url + '/agency/time/product/order/list',
				withCredentials: true
		}).then(function (response) {
			self.setState({
					...self.state,
				time_option: time_data,
				agency_time_option: response.data.data
			})
		});
	}

	componentDidMount() {
		this.reloadPage()
	}

	setProductTimeOrder = (product_type, time_value) => {
		const self = this;
		axios({
			method: 'post',
			url: window.api_url + '/agency/time/product/order/create',
			withCredentials: true,
			data: {
				product_type: product_type,
				time_template: time_value
			}
		}).then(function (response) {
			if(!response.data.isError) {
				self.reloadPage()
			}
		});
	}
	
	removeProductTimeOrder = (time_order_id) => {
		const self = this;
		axios({
			method: 'post',
			url: window.api_url + '/agency/time/product/order/remove',
			withCredentials: true,
			data: {
				id: time_order_id
			}
		}).then(function (response) {
			if(!response.data.isError) {
				self.reloadPage()
			}
		});
	}

	render() {
		const { time_option, agency_time_option } = this.state;
		const product_types = [
			{title: "Share Hosting", product_type: "HOSTING"},
			{title: "VPS", product_type: "VPS"},
			{title: "Email", product_type: "EMAIL"},
		]
		const time_config_rows = _.map(product_types, (i, index) =>
			<div key={index}>
				{ProductTimeConfigToggle(
					time_option,
					agency_time_option,
					i.product_type,
					i.title,
					this.setProductTimeOrder,
					this.removeProductTimeOrder
				)}
			</div>
		)
		return (
			<React.Fragment>
				<div className="page-header">
					<div className="page-header-content header-elements-md-inline">
						<div className="page-title d-flex">
							<h4>
								<i className="icon-paragraph-justify2 mr-2"></i>
								<span className="text-semibold">Cấu hình thời gian đặt hàng</span>
							</h4>
						</div>
					</div>
				</div>
				<br />
				<div className="row">
					<div className="card card-body">
						{time_config_rows}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

const mapState = state => ({
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(withRouter(OrderTimeConfig));