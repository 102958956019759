import React from 'react'
import '../css/login.css';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import axios from 'axios';
import ReduxLoginFormField from "../component/ReduxLoginFormField";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { RenderInputWithDiv } from '../component/formInput';
import { connect } from 'react-redux';
// Lấy Cookies ref_code từ trang tadu.cloud chuyển hướng qua.
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
class RegisterPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDone: false,
      ref_code_disable_field: false
    };
  }

  submit(values) {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/auth/register',
      data: {
        ...values
      },
      withCredentials: true
    }).then(function (response) {
      if (response.data.isError) {
        response.data.error.referral_code && self.setState({ref_code_disable_field: false});
        throw new SubmissionError(response.data.error);
      }else {
        self.setState({isDone: true});
      }
    });
  }

  componentDidMount() {
    const { updateField } = this.props;
    const ref_code = getCookie("ref_code");
    // Nếu người dùng được chuyển hướng đăng ký từ ref code của đại lý.
    // Thực hiện gán người dùng đó vào đại lý.
    if(ref_code != "") {
      updateField("referral_code", ref_code);
      this.setState({ref_code_disable_field: true});
    }
  }


  render() {
    const {error, handleSubmit, submitting, pristine, reset} = this.props;
    const {isDone, ref_code_disable_field} = this.state;

    return (
      <React.Fragment>
        <div className="navbar navbar-inverse">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <img src="/assets/img/lglogo.png" alt="tadu.cloud"/>
            </a>
          </div>
        </div>
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content d-flex justify-content-center align-items-center">
              <form className="login-form" onSubmit={handleSubmit(this.submit.bind(this))}>
                <div className="card mb-0">
                  <div className="card-body login-body">
                    <div className="text-center mb-3">
                      <div className="text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" style={{border: "none"}}>
                        <a href="/">
                          <img width="205" src="/assets/img/lglogo.png" alt="tadu.cloud"/>
                        </a>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      <h5 className="mb-0">Đăng ký tài khoản mới?</h5>
                      <span className="d-block text-muted">Để sử dụng những tính năng riêng biệt</span>
                    </div>
                    {!isDone && 
                    <React.Fragment>
                      <Field
                        name="email"
                        type="text"
                        iconClass="icon-mention"
                        placeholder="Email"
                        component={ReduxLoginFormField}/>
                      
                      <Field
                        name="password"
                        type="password"
                        iconClass="icon-lock2"
                        placeholder="Mật khẩu (ít nhất 6 ký tự)"
                        component={ReduxLoginFormField}/>

                      <Field
                        name="full_name"
                        type="text"
                        iconClass="icon-user"
                        placeholder="Họ tên"
                        component={ReduxLoginFormField}/>

                      <Field
                        name="phone"
                        type="text"
                        iconClass="icon-phone2"
                        placeholder="Số điện thoại"
                        component={ReduxLoginFormField}/>

                      {!ref_code_disable_field && <Field
                        name="referral_code"
                        placeholder="Mã giới thiệu (nếu có)."
                        component={RenderInputWithDiv}/>}

                      <div className="form-group">
                        <button type="submit" className={"btn bg-blue btn-block " + (submitting ? "disabled" : "")}>
                          Đăng ký <i className="icon-arrow-right14 position-right"></i>
                        </button>
                      </div>

                      <RouterLink className="btn btn-light btn-block" to="/">
                        Trở về trang đăng nhập
                      </RouterLink>
                    </React.Fragment>}

                    {isDone && <div className="alert alert-success border-0">
                        Đăng ký thành công, quý khách vui lòng kiểm tra mail (cả trong spam) để kích hoạt tài khoản trước khi đăng nhập, xin cám ơn quý khách!
                    </div>}

                    {isDone && <div className="form-group">
                        <RouterLink className="btn btn-light btn-block" to="/">
                            Trở về trang đăng nhập
                        </RouterLink>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

const mapState = state => ({

});

const mapDispatch = (dispatch) => ({
  updateField: (fieldName, value) =>
    dispatch({
      meta: { form: "register", field: fieldName },
      payload: value,
      type: "@@redux-form/CHANGE"
    }),
});

const RegisterPageWrapper = reduxForm({
  form: 'register'
})(RegisterPage);


export default connect(mapState, mapDispatch)(withRouter(RegisterPageWrapper));