import axios from 'axios';
import React from 'react';
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import { withRouter } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';
import { RenderHTMLSelect, RenderTextEditor } from '../../component/formInput';
import ReactHtmlParser from 'react-html-parser';

var _ = require('lodash');

function FeedbackRows(data) {
    const feedback_inner_rows = _.map(data, (i, key) => 
        <div key={key} className="card" style={{ backgroundColor: 'white' }} >
               <div className="card-body">
                   {ReactHtmlParser(i.content)}
                   <span className="display-block text-muted">
                       {i.create_date}
                   </span>
               </div>
           </div>
    )

    return feedback_inner_rows;
    
}

const mapState = state => ({
   
});

const mapDispatch = (dispatch) => ({

});

class FeedbackForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result_data: [],
            alert_label: null
        }
    }

    reload = () => {
        const self = this;
        axios({
            method: 'get',
            url: window.api_url + '/user/feedback',
            withCredentials: true
        }).then(function (response) {
            if (response.data.data) {
                self.setState({
                    result_data: response.data.data,
                })
            }
        });
    }

    componentDidMount() {
        this.reload();
    }

    submit = (values) => {
        const {reset} =  this.props;
        const self = this;
        return axios({
            method: 'post',
            url: window.api_url + '/user/feedback/create',
            data: {
                type: values.type,
                content: values.content,
                create_date: moment().format("YYYY-MM-DD hh:mm:ss")
            },
            withCredentials: true
        }).then(function (response) {
            if (!response.data.isError) {
                self.reload();
                reset();
            } else {
                throw new SubmissionError(response.data.error)
            }
        });
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        const { result_data } = this.state;
        const feedback_type = [
            { label: "Góp ý xây dựng tính năng mới", value: "NEW_FEATURE" },
            { label: "Chức năng bị lỗi, không hoạt động", value: "ERROR" }
        ];
        return (
            <React.Fragment>
                <div className="page-header">
                    <h4>
                        <i className=" icon-paragraph-justify2 position-left"></i>
                        <span className="text-semibold"> Báo Lỗi Và Góp Ý Chức Năng</span>
                    </h4>
                </div>
                {result_data && result_data.length > 0  && FeedbackRows(result_data)}
                <div className="card" style={{ backgroundColor: 'white' }} >
                    <form onSubmit={handleSubmit(this.submit)}>
                        <br />
                        <div style={{ margin: 20 }} className="form-group">
                            <label>
                                Hãy nói cho chúng tôi biết quý khách cần gì, và những gì quý khách nói rất quan trọng và quý giá đối với chúng tôi. Xin cám ơn quý khách!
                                <p style={{color:'red'}}>(Không gửi yêu cầu hỗ trợ kỹ thuật tại đây.)</p>
                            </label>
                            <Field
                                name="type"
                                options={feedback_type}
                                placeholder="Vui lòng chọn"
                                component={RenderHTMLSelect}
                            />
                            <Field
                                name="content"
                                placeholder="Tiêu để cần hỗ trợ"
                                defaultValue=""
                                component={RenderTextEditor}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
                                Gửi
                            </button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}


const FeedbackFormWrapper = reduxForm({
    form: 'ticketForm',
    destroyOnUnmount: true,
    enableReinitialize: true
    // validate
})(FeedbackForm);

export default connect(mapState, mapDispatch)(withRouter(FeedbackFormWrapper));