import React from 'react';

class SearchForm extends React.Component {

  constructor(props) {
    super(props);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      event.preventDefault();
      this.props.onEsc();
    }
  }

  render() {
    const {onSearchSubmit, onEsc, onSearchTxtChange, searchValue, placeHolder} = this.props;

    return(
      <form onSubmit={onSearchSubmit}>
        <div className="input-group">
          <input type="text" 
            className="form-control border-right-0" 
            placeholder={placeHolder} name="search"
            value={searchValue ? searchValue : ""} onChange={onSearchTxtChange} onKeyDown={this.onKeyDown}/>
          <div className="input-group-append">
            <button type="submit" className="btn btn-primary btn-xlg">Tìm</button>
          </div>
        </div>
      </form>
    )
  }

}


export default SearchForm;