import '../css/style.css';
import React from 'react';
import axios from 'axios';

class FileRender extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        file_url: null
      }
  }

  componentDidMount() {
    const {file_name, directory} = this.props;
    const self = this;
    if(file_name != null &&  directory != null) {
      return axios({
        method: 'get',
        url: window.api_url + `/download_file/${directory}/` + file_name.replace("images/upload/","").trim(),
        withCredentials: true
      }).then(res => {
        if(!res.data.isError) {
          self.setState({
            file_url: res.data.data
          })
        }
      })
    }
  }

  componentDidUpdate(preProps, preState) {
    if(preProps.src_input != this.props.src_input) {
      this.setState({
        file_url: this.props.src_input
      })
    }
  }

  render() {
    const { file_url } = this.state;
    const {height, width, style, isDisplay, file_name} = this.props;
    return (
      file_url && <React.Fragment>
        {!isDisplay && <a href={file_url} target="_blank">{file_name}</a>}
        {isDisplay && <a href={file_url} target="_blank"><img height={height} width={width} style={style} src={file_url} alt=""/></a>}
      </React.Fragment>
    )
    
  }
}

export default FileRender;