import React from 'react';
import ReactJson from 'react-json-view'
const json_example = [{
    "is_error": "false",
    "vps": [{
        "id": 2,
        "name": "VPS#1",
        "price": 100000,
        "CPU": "1 core",
        "HDD": "20GB [SSD Cloud Storage]",
        "RAM": "1024 MB",
        "Bandwidth": "Unlimited",
        "Công nghệ ảo hóa": "KVM / LXC",
        "Hệ điều hành": "Linux",
        "Backup": "Hàng tuần",
        "Panel": "Free Direct Admin",
        "is_domain_check": "0",
        "url": "/dang-ky/vps/2"
    }, {
        "id": 280,
        "name": "VPS#2",
        "price": 160000,
        "CPU": "1 core",
        "HDD": "30GB [SSD Cloud Storage]",
        "RAM": "2048 MB",
        "Bandwidth": "Unlimited",
        "Công nghệ ảo hóa": "KVM / LXC",
        "Hệ điều hành": "Linux / Windows",
        "Backup": "Hàng tuần",
        "Panel": "Free Direct Admin",
        "is_domain_check": "0",
        "url": "/dang-ky/vps/280"
    }, {
        "id": 281,
        "name": "VPS#3",
        "price": 320000,
        "CPU": "2 core",
        "HDD": "45GB [SSD Cloud Storage]",
        "RAM": "3072 MB",
        "Bandwidth": "Unlimited",
        "Công nghệ ảo hóa": "KVM / LXC",
        "Hệ điều hành": "Linux / Windows",
        "Backup": "Hàng tuần",
        "Panel": "Free Direct Admin",
        "is_domain_check": "0",
        "url": "/dang-ky/vps/281"
    }, {
        "id": 282,
        "name": "VPS#4",
        "price": 520000,
        "CPU": "4 core",
        "HDD": "75GB [SSD Cloud Storage]",
        "RAM": "4096 MB",
        "Bandwidth": "Unlimited",
        "Công nghệ ảo hóa": "KVM / LXC",
        "Hệ điều hành": "Linux / Windows",
        "Backup": "Hàng tuần",
        "Panel": "Free Direct Admin",
        "is_domain_check": "0",
        "url": "/dang-ky/vps/282"
    }, {
        "id": 288,
        "name": "VPS#5",
        "price": 1200000,
        "CPU": "4 core",
        "HDD": "150GB [SSD Cloud Storage]",
        "RAM": "8G",
        "Bandwidth": "Unlimited",
        "Công nghệ ảo hóa": "KVM / LXC",
        "Hệ điều hành": "Linux / Windows",
        "Backup": "Hàng tuần",
        "Panel": "Free Direct Admin",
        "is_domain_check": "0",
        "url": "/dang-ky/vps/288"
    }, {
        "id": 289,
        "name": "VPS#6",
        "price": 2500000,
        "CPU": "8 core",
        "HDD": "300GB [SSD Cloud Storage]",
        "RAM": "16G",
        "Bandwidth": "Unlimited",
        "Công nghệ ảo hóa": "KVM / LXC",
        "Hệ điều hành": "Linux / Windows",
        "Backup": "Hàng tuần",
        "Panel": "Free Direct Admin",
        "is_domain_check": "0",
        "url": "/dang-ky/vps/289"
    }],
    "host": [{
        "id": 259,
        "name": "Sinh viên",
        "price": 25000,
        "Dung lượng": "300 MB [SSD]",
        "Băng Thông": "unlimited",
        "Sub-domain": "10",
        "Email": "10",
        "FTP": "unlimited",
        "Database": "1",
        "Addon domain": "0",
        "Park domain": "3",
        "is_domain_check": "1",
        "url": "/dang-ky/hosting/cau-hinh/259/domain/is_registry_domain"
    }, {
        "id": 260,
        "name": "Cá nhân",
        "price": 45000,
        "Dung lượng": "750 MB [SSD]",
        "Băng Thông": "unlimited",
        "Sub-domain": "unlimited",
        "Email": "unlimited",
        "FTP": "unlimited",
        "Database": "1",
        "Addon domain": "0",
        "Park domain": "unlimited",
        "is_domain_check": "1",
        "url": "/dang-ky/hosting/cau-hinh/260/domain/is_registry_domain"
    }, {
        "id": 261,
        "name": "Cá nhân ++",
        "price": 65000,
        "Dung lượng": "1,5 GB [SSD]",
        "Băng Thông": "unlimited",
        "Sub-domain": "unlimited",
        "Email": "unlimited",
        "FTP": "unlimited",
        "Database": "2",
        "Addon domain": "0",
        "Park domain": "unlimited",
        "is_domain_check": "1",
        "url": "/dang-ky/hosting/cau-hinh/261/domain/is_registry_domain"
    }, {
        "id": 262,
        "name": "Bán chuyên nghiệp",
        "price": 100000,
        "Dung lượng": "2 GB [SSD]",
        "Băng Thông": "unlimited",
        "Sub-domain": "unlimited",
        "Email": "unlimited",
        "FTP": "unlimited",
        "Database": "3",
        "Addon domain": "1",
        "Park domain": "unlimited",
        "is_domain_check": "1",
        "url": "/dang-ky/hosting/cau-hinh/262/domain/is_registry_domain"
    }, {
        "id": 263,
        "name": "Chuyên nghiệp",
        "price": 135000,
        "Dung lượng": "3 GB [SSD]",
        "Băng Thông": "unlimited",
        "Sub-domain": "unlimited",
        "Email": "unlimited",
        "FTP": "unlimited",
        "Database": "5",
        "Addon domain": "2",
        "Park domain": "unlimited",
        "is_domain_check": "1",
        "url": "/dang-ky/hosting/cau-hinh/263/domain/is_registry_domain"
    }, {
        "id": 264,
        "name": "Doanh nghiệp",
        "price": 270000,
        "Dung lượng": "7 GB [SSD]",
        "Băng Thông": "unlimited",
        "Sub-domain": "unlimited",
        "Email": "unlimited",
        "FTP": "unlimited",
        "Database": "10",
        "Addon domain": "3",
        "Park domain": "unlimited",
        "is_domain_check": "1",
        "url": "/dang-ky/hosting/cau-hinh/264/domain/is_registry_domain"
    }, {
        "id": 265,
        "name": "Thương mại điện tử",
        "price": 380000,
        "Dung lượng": "10 GB [SSD]",
        "Băng Thông": "unlimited",
        "Sub-domain": "unlimited",
        "Email": "unlimited",
        "FTP": "unlimited",
        "Database": "15",
        "Addon domain": "5",
        "Park domain": "unlimited",
        "is_domain_check": "1",
        "url": "/dang-ky/hosting/cau-hinh/265/domain/is_registry_domain"
    }],
    "email": [{
        "id": 273,
        "name": "Mail Economy #1",
        "price": 50000,
        "Dung lượng": "5 GB",
        "Địa chỉ Email": "5",
        "Email Forwarder": "5",
        "Mail list": "1",
        "Parked Domains": "0",
        "Mã hóa SSL": null,
        "SpamAssassin & Kaspersky": null,
        "Catch all": null,
        "SPF / DKIM / DomainKeys": null,
        "Backup SMTP": null,
        "is_domain_check": "1",
        "url": "/dang-ky/email/cau-hinh/273/domain/is_registry_domain"
    }, {
        "id": 274,
        "name": "Mail Economy #2",
        "price": 100000,
        "Dung lượng": "10 GB",
        "Địa chỉ Email": "20",
        "Email Forwarder": "20",
        "Mail list": "2",
        "Parked Domains": "0",
        "Mã hóa SSL": null,
        "SpamAssassin & Kaspersky": null,
        "Catch all": null,
        "SPF / DKIM / DomainKeys": null,
        "Backup SMTP": null,
        "is_domain_check": "1",
        "url": "/dang-ky/email/cau-hinh/274/domain/is_registry_domain"
    }, {
        "id": 275,
        "name": "Mail Server #1",
        "price": 180000,
        "Dung lượng": "20 GB",
        "Địa chỉ Email": "50",
        "Email Forwarder": "50",
        "Mail list": "3",
        "Parked Domains": "0",
        "Mã hóa SSL": null,
        "SpamAssassin & Kaspersky": null,
        "Catch all": null,
        "SPF / DKIM / DomainKeys": null,
        "Backup SMTP": null,
        "is_domain_check": "1",
        "url": "/dang-ky/email/cau-hinh/275/domain/is_registry_domain"
    }, {
        "id": 276,
        "name": "Mail Server #2",
        "price": 300000,
        "Dung lượng": "50 GB",
        "Địa chỉ Email": "100",
        "Email Forwarder": "100",
        "Mail list": "10",
        "Parked Domains": "2",
        "Mã hóa SSL": null,
        "SpamAssassin & Kaspersky": null,
        "Catch all": null,
        "SPF / DKIM / DomainKeys": null,
        "Backup SMTP": null,
        "is_domain_check": "1",
        "url": "/dang-ky/email/cau-hinh/276/domain/is_registry_domain"
    }, {
        "id": 277,
        "name": "Mail Server #3",
        "price": 450000,
        "Dung lượng": "100 GB",
        "Địa chỉ Email": "200",
        "Email Forwarder": "200",
        "Mail list": "15",
        "Parked Domains": "3",
        "Mã hóa SSL": null,
        "SpamAssassin & Kaspersky": null,
        "Catch all": null,
        "SPF / DKIM / DomainKeys": null,
        "Backup SMTP": null,
        "is_domain_check": "1",
        "url": "/dang-ky/email/cau-hinh/277/domain/is_registry_domain"
    }, {
        "id": 278,
        "name": "Mail Server #4",
        "price": 600000,
        "Dung lượng": "190 GB",
        "Địa chỉ Email": "300",
        "Email Forwarder": "300",
        "Mail list": "20",
        "Parked Domains": "5",
        "Mã hóa SSL": null,
        "SpamAssassin & Kaspersky": null,
        "Catch all": null,
        "SPF / DKIM / DomainKeys": null,
        "Backup SMTP": null,
        "is_domain_check": "1",
        "url": "/dang-ky/email/cau-hinh/278/domain/is_registry_domain"
    }, {
        "id": 279,
        "name": "Mail Server #5",
        "price": 750000,
        "Dung lượng": "220 GB",
        "Địa chỉ Email": "499",
        "Email Forwarder": "499",
        "Mail list": "40",
        "Parked Domains": "7",
        "Mã hóa SSL": null,
        "SpamAssassin & Kaspersky": null,
        "Catch all": null,
        "SPF / DKIM / DomainKeys": null,
        "Backup SMTP": null,
        "is_domain_check": "1",
        "url": "/dang-ky/email/cau-hinh/279/domain/is_registry_domain"
    }],
    "ssl": [{
        "id": 356,
        "name": "COMODO POSITIVE SSL",
        "price": 18750,
        "Chính sách bảo hiểm": "$10.000",
        "Số domain được bảo mật": "1",
        "Độ tin cậy": "Cơ bản",
        "Thanh địa chỉ màu xanh": "Không",
        "SANs": "Không có",
        "is_domain_check": "1",
        "url": "/dang-ky/chung-thuc-ssl/356/domain/is_registry_domain"
    }]
}]
                    

class GetProductList extends React.Component {
    render() {
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold"> Lấy danh sách sản phẩm</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card panel-flat">
                            <div className="card-body">
                                <p>Lấy danh sách sản phẩm Share Hosting, VPS, Email Server, SSL.</p>
                                <p style={{color:"red"}}>
                                    <strong>Lưu ý: </strong>
                                    http://me.xxxx.com/ là đường dẫn thương hiệu riêng của đại lý. <a target="_blank" href="https://trogiup.tadu.vn/thiet-lap-thuong-hieu-rieng/">Hướng dẫn cấu hình thương hiệu riêng</a>.
                                </p>
                                <p style={{ fontSize: "18px", textDecoration: "underline", marginTop: "25px" }}>Ứng dụng</p>
                                <p>Dùng để xây dựng trang danh sách sản phẩm VPS, Share Hosting, EMail Server, SSL.</p>
                                <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px"}}>Sử dụng</p>
                                <ul style={{listStyle: "none", padding: "25px", border: "1px solid #ddd"}}>
                                    <li style={{paddingBottom: "10px"}}>
                                        <i className="icon-files-empty"></i> GET
                                        <span style={{float: "right"}}>Method</span>
                                    </li>
                                    <li style={{paddingBottom: "10px"}}>
                                        <i className="icon-link"></i> http://me.xxxx.com/rest/product-list/<span className="label label-default">product-type</span>
                                        <span style={{float: "right"}}>Link</span>
                                    </li>
                                    <li>
                                        <i className="icon-list"></i> application/json
                                        <span style={{float: "right"}}>Content-Type</span>
                                    </li>
                                </ul>
                                <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px"}}>URL fields</p>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Biến</th>
                                            <th>Loại</th>
                                            <th>Mô tả</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{textTransform: "uppercase"}}>product-type</td>
                                            <td>Có thể nhập hoặc không</td>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <strong>Để trống:</strong> sẽ lấy danh sách tất cả các sản phẩm Share Hosting, VPS, Email Server, SSL.
                                                    </li>
                                                    <li>
                                                        <strong>vps:</strong> Lấy danh sách các gói dịch vụ VPS.
                                                    </li>
                                                    <li>
                                                        <strong>host:</strong> Lấy danh sách các gói dịch vụ Share Hosting.
                                                    </li>
                                                    <li>
                                                        <strong>email:</strong> Lấy danh sách các gói dịch vụ Email Server.
                                                    </li>
                                                    <li>
                                                        <strong>ssl:</strong> Lấy danh sách các gói dịch vụ SSL.
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br/>
                                <ReactJson src={json_example} theme="monokai" />
                                <br/>
                                <p>Để có kết quả như trên, <span className="label label-default">product-type</span> chúng ta để trống.</p>
                                <ul>
                                    <li><strong>is_error:</strong> false nghĩa là API trả về thành công, true nghĩa là API gặp lỗi.</li>
                                    <li>
                                        <strong>vps</strong>: danh sách các gói dịch vụ VPS.
                                        <ul>
                                            <li><strong>id</strong>: ID của sản phẩm</li>
                                            <li><strong>name</strong>: tên gói dịch vụ, đại lý có thể sửa tên trong cấu hình sản phẩm</li>
                                            <li><strong>price</strong>: giá bán sản phẩm / tháng, đại lý có thể sửa giá trong cấu hình sản phẩm</li>
                                            <li><strong>is_domain_check</strong>: "0" có nghĩa là không cần check domain khi đăng ký sản phẩm, khi khách hàng nhấn "Đặt mua" sẽ không cần phải xác định mua cho domain nào.</li>
                                            <li><strong>url</strong>: Đây là đường dẫn gắn vào button "Đặt mua", sau khi khách hàng nhấn "Đặt mua" sẽ chuyển trang theo đường dẫn này. Phía trước đường dẫn cần cộng thêm đường dẫn thương hiệu riêng của đại lý trong tadu. Ví dụ:
                                                <ul>
                                                    <li><strong>url</strong> có kết quả <strong>/dang-ky/vps/2</strong></li>
                                                    <li>Đường dẫn thương hiệu riêng của đại lý <strong>http://me.xxxx.com</strong></li>
                                                    <li>sau khi khách hàng nhấn "Đặt mua" sẽ chuyển qua trang <strong>http://me.xxxx.com/dang-ky/vps/2</strong></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>host</strong>: danh sách các gói dịch vụ Share Hosting.
                                        <ul>
                                            <li><strong>id</strong>: ID của sản phẩm</li>
                                            <li><strong>name</strong>: tên gói dịch vụ, đại lý có thể sửa tên trong cấu hình sản phẩm</li>
                                            <li><strong>price</strong>: giá bán sản phẩm / tháng, đại lý có thể sửa giá trong cấu hình sản phẩm</li>
                                            <li><strong>is_domain_check</strong>: "1" có nghĩa là cần check domain khi đăng ký sản phẩm, khi khách hàng nhấn "Đặt mua" sẽ cần phải xác định mua cho domain nào. Như
                                                <a href="https://tadu.cloud/">tadu.cloud</a> khi khách hàng nhấn "Đặt mua" đối với sản phẩm Share Hosting, hệ thống sẽ popup khung để khách hàng nhập domain cho gói hosting, kiểm tra domain, và có thể mua luôn domain này nếu domain chưa ai đăng ký. Nếu domain đã được đăng ký hệ thống sẽ tự động chuyển qua bước tiếp theo.</li>
                                            <li><strong>url</strong>: sau khi khách hàng nhấn "Đặt mua", nhập domain cho hosting sẽ chuyển trang theo đường dẫn này. Phía trước đường dẫn cần cộng thêm đường dẫn thương hiệu riêng của đại lý trong tadu và biến <strong>is_registry_domain</strong> sẽ được thay vào là "0" hoặc "1", biến <strong>domain</strong> sẽ được thay vào domain cho gói Share Hosting Ví dụ:
                                                <ul>
                                                    <li><strong>url</strong> có kết quả <strong>/dang-ky/hosting/cau-hinh/260/domain/is_registry_domain</strong></li>
                                                    <li>Đường dẫn thương hiệu riêng của đại lý <strong>http://me.xxxx.com</strong></li>
                                                    <li>Domain dùng để chạy gói hosting này: <strong>test.com</strong></li>
                                                    <li>Sẽ chuyển qua trang <strong>http://me.xxxx.com/dang-ky/hosting/cau-hinh/260/test.com/is_registry_domain</strong> biến <strong>is_registry_domain</strong> sẽ báo hệ thống biết khách hàng có mua kèm domain luôn hay không:
                                                        <ul>
                                                            <li><strong>Không mua kèm domain:</strong>: http://me.xxxx.com/dang-ky/hosting/cau-hinh/260/test.com/0</li>
                                                            <li><strong>Có mua kèm domain:</strong>: http://me.xxxx.com/dang-ky/hosting/cau-hinh/260/test.com/1</li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>Những sản phẩm khác cũng có cấu trúc tương tự.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default GetProductList;