import React from 'react';
import axios from 'axios';
import SearchForm from '../../component/SearchForm';
import { Link } from 'react-router-dom';

var _ = require('lodash');

function DomainPendingRows(data) {
    return _.map(data,(domain_profile, key) => (
        <tr key={key}>
            <td>
                <Link to={"/user/domain/profile/detail/" + domain_profile.id}>{domain_profile.user.email}</Link>
                <span className="display-block text-muted">
                    {domain_profile.user.full_name} - {domain_profile.user.phone}
                </span>
            </td>
            <td>
                {domain_profile.email} - {domain_profile.full_name}
                <span className="display-block text-muted">
                    {domain_profile.is_company ? domain_profile.vat_no : domain_profile.id_number} - 
                    {domain_profile.address}
                </span>
            </td>
            <td>
                {!domain_profile.is_company && <span className="badge badge-flat border-primary text-primary-600">Cá nhân</span>}
                {domain_profile.is_company && <span className="badge badge-flat border-success text-success-600">Công ty</span>}
            </td>
            <td>
                {domain_profile.user_products && domain_profile.user_products.map((user_product, key) => (
                    <React.Fragment key={key}>
                        <a href={"http://" + user_product.domain} target="_black">
                            <span>{user_product.domain}</span>
                        </a>
                        <br/>
                    </React.Fragment>
                ))}
            </td>
            <td>
                <div className="icons-list">
                    <div className="dropdown">
                        <a href="#" className="list-icons-item" data-toggle="dropdown">
                            <i className="icon-menu9"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link to={"/user/domain/profile/detail/" + domain_profile.id} className="dropdown-item">
                                <i className="icon-list-numbered"></i> Chi tiết
                            </Link>
                            {!domain_profile.is_company && <Link to={"/user/domain/profile/id_upload/"+domain_profile.id} className="dropdown-item">
                                    <i className="icon-upload4"></i> Cập nhật CMND
                                </Link>}
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    ))
}

class DomainPending extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            table_data: [],
            table_query: {
                current_page: 1,
                number_of_row: 30,
                sort_by: "",
                sort_asc: false,
                search_field: "search",
                search_value: ""
            }
        }
        this.reload = this.reload.bind(this);
        this.onSearchTxtChange = this.onSearchTxtChange.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    componentDidMount() {
        this.reload(this.state.table_query);
    }


    reload(table_query) {
        const self = this;

        axios({
            method: 'post',
            url: window.api_url + '/user/domain/pending',
            withCredentials: true,
            data: table_query
        }).then(function (response) {
            self.setState({
                ...self.state,
                table_query: table_query,
                table_data: response.data.data
            })
        });
    }

    onSearchTxtChange(event) {
        const table_query = {
            ...this.state.table_query,
            search_value: event.target.value
        };

        this.setState({
            ...this.state,
            table_query: table_query
        });
    }

    onSearchSubmit(event) {
        const table_query = {
            ...this.state.table_query,
            current_page: 1
        };

        this.reload(table_query);
        event.preventDefault();
    }

    clearSearch() {
        const table_query = {
            ...this.state.table_query,
            current_page: 1,
            search_value: ""
        };
        this.reload(table_query);
    }

    render() {
        const {table_query, table_data} = this.state;
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold">Danh Sách Bản Khai Cần Cập Nhật CMND</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-body">
                            <SearchForm 
                                placeHolder="Tên miền"
                                onSearchSubmit={this.onSearchSubmit} 
                                onSearchTxtChange={this.onSearchTxtChange}
                                searchValue={table_query.search_value}
                                onEsc={this.clearSearch}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-body">
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Khách hàng</th>
                                            <th>Bản khai</th>
                                            <th style={{width: "130px"}}>Loại bản khai</th>
                                            <th>Tên miền áp dụng</th>
                                            <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                                                <i className="icon-menu-open2"></i>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DomainPendingRows(table_data.data)}
                                    </tbody>
                                </table>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default DomainPending;