import axios from 'axios';
import moment from 'moment';
import { objectToFormData } from 'object-to-formdata';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { RenderFileUpload, RenderTextEditor } from '../../component/formInput';
import TicketStatus from '../../component/TicketStatus';
import { StringUtils, SocketUtils } from '../../utils/javascriptUtils';
import BlockUI from '../../component/BlockUI';
import FileRender from '../../component/FileRender';
import { toast, ToastContainer } from 'react-toastify';

var _ = require('lodash');

function TicketTable(data) {
  return (
		<div className="table-responsive">
    	<table className="table table-togglable table-hover">
    	  <thead>
    	  <tr>
    	    <th data-toggle="true">Mã</th>
    	    <th data-hide="phone">Khách Hàng</th>
    	    <th data-hide="phone">Trạng thái</th>
    	    <th data-hide="phone,tablet">Ngày</th>
    	    <th data-hide="phone,tablet">Dịch vụ</th>
    	    <th data-hide="phone,tablet">Nhân viên</th>
    	  </tr>
    	  </thead>
    	  <tbody>
    	    <tr>
    	      <td>{data.code}</td>
    	      <td>
    	        {data.user.email}
    	        <br/>
    	        <small className="display-block text-muted text-size-small">{data.user.full_name} - {data.user.phone}</small>
    	      </td>
    	      <td><span className="label label-success">{TicketStatus(data.status)}</span></td>
    	      <td>{data.updated_date}</td>
    	      <td>
    	        {data.user_product ? data.user_product.domain : "Khác" }
    	        <span className="display-block text-muted">{data.user_product && data.user_product.product.name}</span>
    	      </td>
    	      <td></td>
    	    </tr>
    	  </tbody>
    	</table>
		</div>)
}

function TicketCommentRows(ticket_comments, customer_id) {
  const inner_ticket_rows = _.map(ticket_comments, function(i, index) { 
    let inner_border_top_color = "#00bcd4"; //Info
    // Nếu là chủ thể cuả ticket, hệ thống sẽ chú thích bằng viền màu đỏ
    if(customer_id == i.user.id){
      inner_border_top_color = "#ff5722";// Warning
    }
    return <div key={index} className="row">
      <div className="col-md-12">
        <div style={{borderTop:"2px solid " +inner_border_top_color }} className={"card"}>
          <div className="card-body">
            <div className="panel-heading">
              <h6 className="panel-title text-info">
                {i.user.full_name} - [ {i.created_Date} ]
              </h6>
              {ReactHtmlParser(i.content)}
            </div>
          </div>
          {!StringUtils.isEmptyOrSpaces(i.file_url) && <div  className="card-footer">
            <i className="icon-download7"></i> 
            <FileRender directory="ticket-upload" file_name={i.file_url} isDisplay={false} />
          </div>}
          {i.total_like > 0 && <div className="card-footer">
            <span className="badge bg-success badge-pill"> 
            <i className="icon-thumbs-up2"> </i> {i.total_like}  
            </span>
          </div>}
        </div>
      </div>
  </div>})

  return inner_ticket_rows;
}

class AgencyTicketDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket: null,
      isLoading: false
    }
    this.submit = this.submit.bind(this);
    this.reload = this.reload.bind(this);
    this.channel = SocketUtils.socketStore();
    this.channel.join();
  }

  socketLiveReload() {
    const self = this;
    const {userProfile, agencyTicketModifyReload} = this.props;
    this.channel.on("agency_ticket_modify", response => {
      // Chỉ live reload với đúng khách hàng của ticket này.
      if(userProfile && userProfile.email == response.agency_admin_email) {
        self.reload();
        agencyTicketModifyReload();
      }
    })
  }

  sendSocketChannel = (agency_ticket_id) => {
    const {agencyTicketModifyReload} = this.props;
    this.channel.push("agency_customer_ticket_modify", {agency_ticket_id: agency_ticket_id, body: "Success To Send Agency Ticket Modify"});
    agencyTicketModifyReload();
  }

  reload() {
    const { agency_ticket_id } = this.props.match.params;
    const self = this;
    axios({
      method: 'get',
      url: window.api_url + '/user/agency/ticket/detail/'+agency_ticket_id,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          ticket: response.data.data
        })
      }
    });
  }

  submit(values) {
    const { agency_ticket_id } = this.props.match.params;
    const { reset } = this.props; 
    const self = this;
    const data_value = {
      ticket_id: agency_ticket_id,
      content: values.content,
      created_Date: moment().format("YYYY-MM-DD hh:mm:ss"),
      ticket_from: "no data",
      admin_read: false, // TODO admin read,
      file_upload: values.file_upload && values.file_upload[0],
      note: " ",
      is_tranfer: false
    }
    // convert Object to FormData
    const formData = objectToFormData(data_value);
    this.setState({
        isLoading: true
    })
    return axios({
      method: 'post',
      url: window.api_url + '/user/agency/ticket_comment/reply',
      data: formData,
      withCredentials: true
    }).then(function (response) {
      self.setState({
        isLoading: false
      })
      if(!response.data.isError){
        self.reload();
        self.sendSocketChannel(agency_ticket_id);
        reset();
      }else {
        throw new SubmissionError(response.data.error)
      }
    });
  }

  updateTicketStatus = (status) => {
    const { agency_ticket_id } = this.props.match.params;
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/agency/ticket/status/update',
      data: {
        agency_ticket_id: agency_ticket_id,
        status: status
      },
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError){
        self.reload();
        self.sendSocketChannel(agency_ticket_id);
        toast.success("Chuyển đổi trạng thái thành công !.");
      }else {
        throw new SubmissionError(response.data.error)
      }
    });
  }

  componentDidMount() {
    const self = this;
    this.reload();
    this.socketLiveReload();
  }

  componentDidUpdate(prevProps, prevState) {
    const ticket_id = prevProps.routeProps.match.params.agency_ticket_id;
    const new_ticket_id = this.props.routeProps.match.params.agency_ticket_id;
    if(ticket_id != null && ticket_id != new_ticket_id) {
      this.reload();
    }
  }
  
  render() {
    const {handleSubmit, submitting} = this.props;
    const { ticket, isLoading } = this.state;
    return(
      <React.Fragment>
			<ToastContainer />
      {isLoading && <BlockUI/>}
      {/* Content area */}
        {!ticket && <div>
          <div className="card card-body">
            <span> Không tìm thấy dịch vụ hỗ trợ !</span>
          </div>
        </div>}
        {ticket && <React.Fragment>
          {/* Page header */}
          <div className="page-header">
            <h4>
              <i className="icon-paragraph-justify2 position-left"></i>
              <span className="text-semibold">Trả Lời Mã Hỗ Trợ <a>#{ticket.code}</a></span>
            </h4>
          </div>
          {/* Page header */}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {TicketTable(ticket)}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card border-top-warning">
                <div className="card-body border-top-lg">
                  <div className="panel-heading">
                    <h6 className="panel-title text-warning">
                    {ticket.title} - [ {ticket.created_date} ]
                    </h6>
                  </div>
                  <div className="panel-body">
                    {ReactHtmlParser(ticket.content)} 
                    <br/>
                    <span>
                      {ticket.note && 
                        <div>
                          <strong> Ghi Chú </strong> 
                          <p style={{borderTop:"1px solid "}}>{ticket.note}</p>
                        </div>}
                    </span> 
                  </div>
                </div>
                {!StringUtils.isEmptyOrSpaces(ticket.file_url) && <div className="card-footer">
                  <i className="icon-download7"></i> 
                  <FileRender directory="ticket-upload" file_name={ticket.file_url} isDisplay={false} />
                </div>}
              </div>
            </div>
          </div>
          {TicketCommentRows(ticket.ticket_comments, ticket.user_id)}
          {ticket.status != "FCLOSED" && <div className="card panel-white">
            <div>
              <form encType="multipart/form-data" onSubmit={handleSubmit(this.submit)}>
                <br/>
                <div style={{margin:20}} className="form-group">
                  <Field
                    name="content"
                    placeholder="Tiêu để cần hỗ trợ"
                    defaultValue=""
                    component={RenderTextEditor}
                  />
                  <Field
                    name="file_upload"
                    label="File đính kèm"
                    fileAccept="image/*,.zip"
                    component={RenderFileUpload}
                  />
                </div>
                <div className="card-footer">
                    <button style = {{ marginRight: "10px" }} type="submit" className={"btn bg-success " + (submitting ? "disabled" : "")}>
                        Trả lời 
                    </button>
                    <a style={{cursor: 'pointer'}} onClick={()=> this.updateTicketStatus("FCLOSED")} className={"btn border-slate text-slate-800 btn-flat"}>
                        Đóng
                    </a>
                </div>
              </form>
            </div>
          </div>}
          {ticket.status == "FCLOSED" && <div className="modal-footer">
            <a style={{cursor: 'pointer'}} onClick={()=> this.updateTicketStatus("BPROCESS")} className={"btn border-slate text-slate-800 btn-flat"}>
              Mở Lại Ticket
            </a>
          </div>}
        </React.Fragment>}
        {/*content area */}
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({
  updateField: (fieldName, value) =>
  dispatch({
    meta: { form: "ticketDetail", field: fieldName },
    payload: value,
    type: "@@redux-form/CHANGE"
  }),
  agencyTicketModifyReload: () => dispatch.messageModify.agencyTicketModifyReload()
});

const TicketDetailWrapper = reduxForm({
  form: 'ticketDetail',
  destroyOnUnmount: true,
  enableReinitialize: true,
  // validate
})(withRouter(AgencyTicketDetail));

export default connect(mapState, mapDispatch)(withRouter(TicketDetailWrapper));
