import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

var numeral = require('numeral');
var _ = require("lodash");

function TaduInvoiceOptionInner(index_key, item, handleAddTaduInvoiceToCart) {
  const tadu_phone_meta = _.map(item.product_meta, (i,key) => <p key={key}>{i.name} <strong>{i.value && ": " + i.value}</strong></p>);
  return (
    <div key={index_key} className="col-md-3">
      <div className="card card-body dashboard-panel product-item">
        <h1>{item.product.name}</h1>
        {tadu_phone_meta}
        <h6 className="text-orange">
          <strong>{numeral(item.price12 * 10).format('0,0')} đ</strong>
        </h6>
        <p style={{ fontSize: "12px" }}>(Giá trên chưa bao gồm VAT)</p>
        <br/>
        <p>
          <button className="btn btn-warning btn-xlg" onClick={()=>handleAddTaduInvoiceToCart(item)} >
            Đặt Mua Ngay
          </button> 
        </p>
      </div>
    </div>)
}
class TaduInvoicePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product_prices: {}
    }
    this.handleAddTaduInvoiceToCart = this.handleAddTaduInvoiceToCart.bind(this);
  }
  handleAddTaduInvoiceToCart(product_price) {
    const {addItem, updateCart, history} = this.props;
    const item = {
      id: uuidv4(),
      group_id: null,
      product: product_price.product,
      price_is_year: product_price.is_year,
      month: 120, //Hoá đơn điện tử mặc định là 10 năm
      quantity: 1,
      domain_name: null,
      domain_profile_id: null,
      template_id: null, //template id để sử dung cho vps.
      user_product_id: null, //Dịch vụ cần gia hạn
      is_renew: false,
      is_upgrade: false,
      is_tranfer: false,
      is_delivery: false,
      parent_id: null,
      order: 0 //Vị trí của item trong giỏ hàng
    }
    addItem(item);
    updateCart().then(() => {
      history.push('/products/cart');
    });
  }
  componentDidMount() {
    const self = this;

    axios({
      method: 'get',
      url: window.api_url + '/product/price_with_meta/tadu_invoice',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        product_prices: response.data.data
      })
    });
  }
  
  render() {
    const {product_prices} = this.state;
    const tadu_phone_rows = _.map(product_prices , (item, key) => TaduInvoiceOptionInner(key, item, this.handleAddTaduInvoiceToCart));
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Đăng Ký Mới Hoá Đơn Điện Tử</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          {tadu_phone_rows}
        </div>
      </React.Fragment>
    )
  }
}
const mapState = state => ({
  
});

const mapDispatch = (dispatch) => ({
  addItem: (item) => dispatch.cart.addItem(item),
  updateCart: () => dispatch.cart.updateCart()
});
export default connect(mapState, mapDispatch)(withRouter(TaduInvoicePage));