import React from 'react';

var _ = require('lodash');

function InvoiceStatic(data) {
  let invoice_static ={
    paid: 0,
    trial: 0,
    refund: 0,
    new: 0,
    cancel: 0,
    init: 0,
    unpaid: 0
  } 
  
  _.forEach(data, function(i) {
    if(i.status == "INIT") {
      invoice_static.init = i.count_number;
    }
    if(i.status == "UNPAID") {
        invoice_static.unpaid = i.count_number;
    }
    if(i.status == "PAID") {
      invoice_static.paid = i.count_number;
    }
    if(i.status == "TRIAL") {
      invoice_static.trial = i.count_number;
    }
    if(i.status == "REFUND") {
      invoice_static.refund = i.count_number;
    }
    if(i.status == "NEW") {
      invoice_static.new = i.count_number;
    }
    if(i.status == "CANCEL") {
      invoice_static.cancel = i.count_number;
    }
  })
  return invoice_static;
}

class InvoiceStatusSelect extends React.Component {

  constructor(props) {
  super(props);
  }

  render() {
  const { onInvoiceStatusChange, invoice_static } = this.props;
  const invoice_static_data = InvoiceStatic(invoice_static);
  const styles = {
    color: "white",
    marginRight: 3,
    borderColor: 'transparent',
    borderRadius: "3px"
  }
  return(
    <React.Fragment>
      <button onClick={() => onInvoiceStatusChange("all")} style={styles} className="badge bg-slate">TẤT CẢ ĐƠN HÀNG</button>
      <button onClick={() => onInvoiceStatusChange("new")} style={styles} className="badge badge-info">ĐĂNG KÝ MỚI: {invoice_static_data.new}</button>
      <button onClick={() => onInvoiceStatusChange("init")} style={styles} className="badge badge-danger">CHỜ CHẤP NHẬN: {invoice_static_data.init}</button>
      <button onClick={() => onInvoiceStatusChange("unpaid")} style={styles} className="badge badge-danger">CHỜ THANH TOÁN: {invoice_static_data.unpaid + invoice_static_data.trial}</button>
      <button onClick={() => onInvoiceStatusChange("paid")} style={styles} className="badge badge-success">ĐÃ THANH TOÁN: {invoice_static_data.paid}</button>
      {/* <button onClick={() => onInvoiceStatusChange("trial")} style={styles} className="badge bg-indigo">DÙNG THỬ: {invoice_static_data.trial}</button> */}
      <button onClick={() => onInvoiceStatusChange("cancel")} style={styles} className="badge badge-secondary">ĐÃ HUỶ: {invoice_static_data.cancel}</button>
      <button onClick={() => onInvoiceStatusChange("refund")} style={styles}  className="badge badge-primary">ĐÃ HOÀN TIỀN: {invoice_static_data.refund}</button>
    </React.Fragment>
  )
  }
}


export default InvoiceStatusSelect;