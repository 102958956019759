import React from 'react';
import { withRouter } from 'react-router';

class MobilePaymentRedirect extends React.Component {
  constructor() {
    super();
    this.state = { time: {}, seconds: 5 };
    this.timer = 0;
  }
  
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    
    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  
  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }
  
  startTimer = () => {
    if (this.timer == 0 && this.state.seconds > 0) {
    this.timer = setInterval(this.countDown, 1000);
    }
  }
  
    countDown = async () => {
    	// Remove one second, set state so a re-render happens.
    	let seconds = this.state.seconds - 1;
    	this.setState({
    	time: this.secondsToTime(seconds),
    	seconds: seconds,
    	});
		
    	// Check if we're at zero.
    	if (seconds === 0) { 
				clearInterval(this.timer);
				this.onOpenApp();
    	}
  }

  onOpenApp() {
    const { mobile_device } = this.props.match.params;
    let payment_method = this.props.match.params.payment_method;
    
    if(!payment_method) {
			payment_method = "products/cart"
    } 
    const params = this.props.location.search;
    let url = null;
    if(mobile_device === "ios") {
			url = `idtadu://app/${payment_method}/${params}`
    }
    if(mobile_device === "android") {
			url = `tadu.id.mobile://${payment_method}/${params}`
    }
    if(url) {
			window.location.href = url;
    }
  }
  
  render() {

  return (
    <div style={{
        border: "none",
        fontSize: "18px", 
        height: "100%",
        backgroundColor: "red",
        backgroundImage: "linear-gradient(#ec8d8d, #ff6c00)",
        margin: 0
      }} 
      className="card card-body justify-content-center text-center bg-transparent">
      <div>
        <img src="/assets/img/mobile-app-logo.png" alt="tadu.cloud" width="98px"/>
        <br/>
        <br/>
        <p>
        <i className="text-white mb-3"> 
          Vui lòng đợi trong {this.state.time.s} giây (s) để hướng <br/> hoặc nhấn vào nút chuyển hướng 
        </i>
        </p>
        <br/>
        <button id="btnRedirect" disabled={this.state.seconds !== 0} onClick={() => this.onOpenApp()} className="btn btn-danger">Chuyển Hướng</button>
      </div>
    </div>
  )
  }
}



export default withRouter(MobilePaymentRedirect);