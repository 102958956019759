import React from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import TicketStatus from '../../component/TicketStatus';

class RecentTicket extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tickets: []
        }
    }

    componentDidMount() {
        const self = this;

        axios({
            method: 'get',
            url: window.api_url + '/user/ticket/last',
            withCredentials: true
        }).then(function (response) {
            self.setState({
                ...self.state,
                tickets: response.data.data
            })
        });
    }
    
    render() {
        const {tickets} = this.state;

        return(
            <React.Fragment>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Mã</th>
                                <th>Tiêu đề</th>
                                <th>Phòng ban</th>
                                <th>Trạng thái</th>
                                <th>Ngày</th>
                                <th>Dịch vụ</th>
                                <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                                    <i className="icon-menu-open2"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map((ticket, key) => (
                            <tr key={key}>
                                <td>
                                    <Link to={"/user/ticket/detail/"+ ticket.id}>{ticket.code}</Link>
                                </td>
                                <td>{ticket.title}</td>
                                <td>{ticket.department.name}</td>
                                <td>{TicketStatus(ticket.status)}</td>
                                <td>{ticket.created_date}</td>
                                <td>
                                    {ticket.user_product ? ticket.user_product.domain : "Khác"}
                                    <span className="display-block text-muted">{ticket.user_product && ticket.user_product.product.name}</span>
                                </td>
                                <td>
                                    <div className="icons-list">
                                        <div className="dropdown">
                                            <a href="#" className="list-icons-item" data-toggle="dropdown">
                                                <i className="icon-menu9"></i>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <Link to={"/user/ticket/detail/"+ ticket.id} className="dropdown-item">
                                                    <i className="icon-list-numbered"></i> Chi tiết
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }

}

export default RecentTicket;