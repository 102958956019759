import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import AlertLabel from '../../component/AlertLabel';
import { RenderInputWithDiv, RenderSelect, RenderRadioCheckBox } from '../../component/formInput';
import ModalAddNewCustomer from './modals/ModalAddNewCustomer';

var _ = require('lodash');
var numeral = require('numeral');

const mapState = state => ({
  initialValues: {
    discount_code: state.cart.discount_code,
    agency_customer_id: state.cart.agency_customer_id,
    is_agency_customer_regis: state.cart.agency_customer_id && true
  },
  cart: state.cart,
  login: state.app.login,
  userProfile: state.userProfile.data,
  agency_customer_list: state.customerAgency.list
});

const mapDispatch = (dispatch) => ({
  updateCart: () => dispatch.cart.updateCart(),
  loadCart: () => dispatch.cart.loadCart(),
  setCart: (cart) => dispatch.cart.setCart(cart),
  setNote: (note) => dispatch.cart.setNote(note),
  addItem: (cart)=>dispatch.cart.addItem(cart), 
  deleteItem: (item) => dispatch.cart.deleteItem(item),
  addDomainWhois: (item) => dispatch.cart.addDomainWhois(item),
  setDiscountCode: (discountCode) => dispatch.cart.setDiscountCode(discountCode),
  loadCustomerList: () => dispatch.customerAgency.loadCustomerList(),
  setAgencyCustomer: (agency_customer_id) => dispatch.cart.setAgencyCustomer(agency_customer_id),
});

const select_style = {
  width:"auto",
  padding:5
}   

function CheckItemRegisStatus(item) {
  let item_status = (
    <span className="badge badge-flat border-success text-success-600">
      Đăng ký mới
    </span>
  )
  if(item.is_renew) {
    item_status = (
      <span className="badge badge-flat border-primary text-primary-600">
        Gia Hạn
      </span>)
  }else if(item.is_upgrade) {
    item_status = (
      <span className="badge badge-flat border-info text-info-600">
        Nâng Cấp
      </span>)
  } else if (item.is_tranfer) {
    item_status = (
      <span className="badge badge-flat border-danger text-danger-600">
        Chuyển về TADU
      </span>)
  }
  return item_status;
}

function CurrentValueSelected(data, current_value) {
  return _.find(data, (i) => i.value == current_value);
}

function InnerProductPromotion(item) {
  var inner_product_promotion = null;
  if (item.extra_month != 0) {
    inner_product_promotion = <span style={{color:'orange'}}>tặng { item.extra_month } tháng</span>
  }
  return inner_product_promotion;
}
 
function InnerAddonPrice(item, cart) {
  let inner_addon_price = null;
  const addon_item = _.find(cart.items, (i) => i.parent_id === item.group_id);
  if (addon_item != null) {
    inner_addon_price =  <span style={{color:'orange'}}><br/> + { numeral(addon_item.price * (item.month/12)).format("0,0") } đ</span>
  }
  return inner_addon_price;
};

function IPAddonPrice(item, cart) {
  var inner_addon_price = null;
  const ip_addons = _.filter(cart.items, (i) => i.parent_id === item.group_id);
  const ip_addon_sum = _.sumBy(ip_addons, (i) => i.sub_total);
  const total_ip_addon = _.filter(cart.items, (i) => i.parent_id === item.group_id).length;
  if (ip_addon_sum) {
    inner_addon_price =  <span style={{color:'orange'}}><br/> + { numeral(ip_addon_sum).format("0,0") } đ ({total_ip_addon} IP)</span>
  }
  return inner_addon_price;
}


function InnerTotalProductPrice(item, cart) {
  var inner_total_product_price = null;
  if (item.product.product_type === "COM_DOMAIN" || item.product.product_type === "VN_DOMAIN" || item.product.product_type ==="SSL") {
    inner_total_product_price = (
      <strong>
        <span className="item_price0">
          {numeral(item.sub_total).format("0,0")} đ
        </span> 
        {InnerAddonPrice(item, cart)}
        <br/>
      </strong>)
  }else if(item.product.product_type === "TADU_INVOICE") {
    inner_total_product_price = ( 
      <strong>
        <span className="item_price0">
          {numeral(item.price * (item.month / 12)).format("0,0")} đ
        </span> 
        <span style={{color:'orange'}}><br/> + { numeral(item.init_price).format("0,0") } đ</span>
        <br/>
      </strong>)
  }else { 
    inner_total_product_price = (
      <strong>
        <span className="item_total_money0">
          {numeral(item.sub_total).format("0,0")} đ
        </span>
        <br/>
      </strong>)
  }

  return inner_total_product_price;
}

function InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange) {
  let inner_time_select = (
      <React.Fragment>
        <select 
          className="form-control"
          disabled={item.total === 0}
          style={{maxWidth:"100px",minWidth:"100px"}} 
          value={selected_time && selected_time.value}
          onChange={(event) => handleTimeChange(event.target.value)}>
            {time_options}
        </select>
      </React.Fragment>
      )
  // Kiểm tra sản phẩm upgrade.
  if(item.is_upgrade) {
    inner_time_select = <React.Fragment>
        {item.day > 0 && <span> {item.day} Ngày<br/></span>}
        {item.month > 0 && <span> {item.month} Tháng<br/></span>}
    </React.Fragment>
  }
  return inner_time_select;
}

function DomainItemOption(item, cart, addDomainWhois, handleTimeChange) {
  const addon_item = _.find(cart.items, (i) => i.parent_id === item.group_id);
  const time_data = [
    { value: 12 , label: '1 năm' },
    { value: 24 , label: '2 năm' },
    { value: 36 , label: '3 năm' },
    { value: 48 , label: '4 năm' },
    { value: 60, label: '5 năm'}
  ];
    
  const time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);

  const selected_time =  CurrentValueSelected(time_data, item.month);
  
  return (
    <React.Fragment>
      <td>
        <h6>Tên Miền <strong>#{item.domain_name}</strong></h6>
        {item.product.product_type != "VN_DOMAIN" &&
          <span>
            <input id={"addition-domain-cost_"+item.id} type="checkbox" onChange={() => addDomainWhois(item)} checked={addon_item ? true : false}/>
            <label htmlFor={"addition-domain-cost_"+item.id}> Ẩn thông tin tên miền <span style={{ color:"orange" }}> (+ giá tiền / năm) </span> </label>
          </span>}
      </td>
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
      </td>
    </React.Fragment>
  )
}

function VPSOption(item, cart, templates, handleTemplateChange, handleTimeChange) {

  let time_data = [
    { value: 1, label: '1 tháng'},
    { value: 3, label: '3 tháng'},
    { value: 6, label: '6 tháng'},
    { value: 12, label: '1 năm'},
    { value: 24, label: '2 năm'},
    { value: 36, label: '3 năm'},
    { value: 48, label: '4 năm'},
    { value: 60, label: '5 năm'}
  ];

  //vps 0 and cloud server pro 0 do not support 1 month
  if(item.product.id == 375 || item.product.id == 398) {
    time_data = _.drop(time_data, 1);
  }	

  const time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);

  //cloud server pro 0, pro 1 vps 0 and vps 1 do not support windows
  if (item.product.id == 375 || item.product.id == 2 || item.product.id == 398 || item.product.id == 399 ) {
    templates = _.filter(templates, (i) => i.is_linux );
  }

  let templates_option = _.map(templates, (i, index) => <option key={index} value={i.id}>{i.name + " " + i.version}</option>);

  const selected_template = _.find(templates, template => template.id === item.template_id);

  const selected_time =  CurrentValueSelected(time_data, item.month);
  return (
    <React.Fragment>
      <td>
        <h6>{item.product.name} </h6>
        {item.user_product_id && <h6>{item.ip_address} </h6>}
        {!item.user_product_id && <select 
          className="form-control"
          style={select_style} 
          value={selected_template && selected_template.id} 
          onChange={(event) => handleTemplateChange(event.target.value)}>
            {templates_option}
        </select>}
      </td> 
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
        {InnerProductPromotion(item)}
        {IPAddonPrice(item, cart)}
      </td>
    </React.Fragment> 
  )
}

function HostingOption(item, cart, hosting_types, handleTimeChange, handleOperationSystemChange) {

  const time_data = [
    { value: 12 , label: '1 năm' },
    { value: 24 , label: '2 năm' },
    { value: 36 , label: '3 năm' },
    { value: 48 , label: '4 năm' },
    { value: 60, label: '5 năm'}
  ];

  const time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);

  const os_data = [
      {label: "Linux" , value: "LINUX_HOSTING"},
      {label: "Windows" , value: "WINDOW_HOSTING"}
  ]

  const options_os = _.map(os_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);
  
  const selected_hosting_type = _.find(hosting_types, (os) => os.id === item.product.id);
  
  const selected_time =  CurrentValueSelected(time_data, item.month);

  return (
    <React.Fragment>
      <td>
        <h6>Hosting {item.product.name} <strong>#{item.domain_name}</strong></h6>
        {!item.user_product_id && <select 
          className="form-control"
          style={select_style} 
          value={selected_hosting_type && selected_hosting_type.product_type} 
          onChange={(event) => handleOperationSystemChange(event.target.value)}>
            {options_os}
        </select>}
      </td>
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
        {InnerProductPromotion(item)}
        {IPAddonPrice(item, cart)}
      </td>
    </React.Fragment>
  )
}

function EmailOption(item, handleTimeChange) {
    
  const time_data = [
    { value: 12 , label: '1 năm' },
    { value: 24 , label: '2 năm' },
    { value: 36 , label: '3 năm' },
    { value: 48 , label: '4 năm' },
    { value: 60, label: '5 năm'}
  ];

  const time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);
  const selected_time = CurrentValueSelected(time_data, item.month);

  return (
    <React.Fragment>
      <td>
        <h6>{item.product.name} <strong>#{item.domain_name}</strong></h6>
      </td>
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
        {InnerProductPromotion(item)}
      </td>
    </React.Fragment>
  )
}

function SSLOption(item, handleTimeChange) {

  const time_data = [
    { value: 12 , label: '1 năm' }
  ];

  const time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);

  const selected_time = CurrentValueSelected(time_data, item.month);

  return (
    <React.Fragment>
      <td>
        <h6>{item.product.name} <br/> <strong>#{item.domain_name}</strong></h6>
      </td>
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
      </td>
    </React.Fragment>
  )
}

function TaduPhoneOption(item, handleTimeChange) {
  const time_data = [
    { value: 12 , label: '1 năm' },
    { value: 24 , label: '2 năm' },
    { value: 36 , label: '3 năm' },
    { value: 48 , label: '4 năm' },
    { value: 60 , label: '5 năm' },
  ];
    
  const time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);
  const selected_time = CurrentValueSelected(time_data, item.month);

  return (
    <React.Fragment>
      <td>
        <h6>{item.product.name}</h6>
      </td>
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
      </td>
    </React.Fragment>
  )
}

// Sử dụng cho IP mua thêm.
function IPOption(item, handleQuantityChange) {
  const ip_quantity_data = _.range(1, 31);
  const ip_quantity_options = _.map(ip_quantity_data, (i,index) => <option key={i, index} value={i}>{i} IP</option>);
  
  const selected_ip_quantity = _.find(ip_quantity_data, (i) => i == item.quantity);
  return (
    <React.Fragment>
      <td>
        <h6>{item.product.name}</h6>
        {item.service_parent && ["LINUX_HOSTING", "WINDOW_HOSTING"].includes(item.service_parent.product.product_type) && <span> <strong>#Hosting: </strong> {item.service_parent.domain}</span>}
        {item.service_parent && ["VPS", "CLOUD_SERVER_PRO"].includes(item.service_parent.product.product_type) &&  <span> <strong>#VPS: </strong>{item.service_parent.ip_address}<br/></span>}
        {item.is_renew && <span><strong>#IP:</strong> {item.ip_address}</span>}
        <select 
          className="form-control"
          style={select_style} 
          value={selected_ip_quantity && selected_ip_quantity}
          onChange={(event) => handleQuantityChange(event.target.value)}>
            {ip_quantity_options}
        </select>
      </td>
      <td>
        <span>{item.day} ngày <br/> {item.month} tháng</span>
      </td>
    </React.Fragment>
  )
}

// Sử dụng cho whois mua thêm
function WhoisPrivacyOption(item) {
  return (
    <React.Fragment>
      <td>
        <h6>{item.product.product_type}</h6>
        <p><strong>#{item.domain_name}</strong></p>
      </td>
      <td>
        <span>{item.day} ngày <br/> {item.month} tháng</span>
      </td>
    </React.Fragment>
  )
}

function TaduInvoiceOption(item) {

  return (
    <React.Fragment>
      <td>
        <h6><strong>{item.product.name}</strong></h6>
      </td>
      <td>
        <h6>{item.month / 12} Năm</h6>
      </td>
    </React.Fragment>
  )
}

function CloudBackupOption(item, handleTimeChange) {
  const time_data = [
    { value: 1, label: '1 tháng'},
    { value: 3, label: '3 tháng'},
    { value: 6, label: '6 tháng'},
    { value: 12, label: '1 năm'},
    { value: 24, label: '2 năm'},
    { value: 36, label: '3 năm'},
    { value: 48, label: '4 năm'},
    { value: 60, label: '5 năm'}
  ];

  const selected_time = CurrentValueSelected(time_data, item.month);
  let time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);

  return (
    <React.Fragment>
      <td>
        <h6><strong>{item.product.name}</strong></h6>
      </td>
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
      </td>
    </React.Fragment>
  )
}

function DigitalSignatureOption(item, handleTimeChange) {
  const time_data = [
    { value: 12, label: '1 năm'},
    { value: 24, label: '2 năm'},
    { value: 36, label: '3 năm'},
    { value: 48, label: '4 năm'}
  ];

  const selected_time =  CurrentValueSelected(time_data, item.month);
  let time_options = _.map(time_data, (i, index) => <option key={index} value={i.value}>{i.label}</option>);

  return (
    <React.Fragment>
      <td>
        <h6><strong>{item.product.name}</strong></h6>
      </td>
      <td>
        {InnerTimeOptionSelect(item, time_options, selected_time, handleTimeChange)}
      </td>
    </React.Fragment>
  )
}

class CartItemOption extends React.Component {
  render() {
    const { item, cart, templates, operation_systems, addDomainWhois, handleTemplateChange, handleTimeChange, handleOperationSystemChange, handleQuantityChange } = this.props;
    let inner_html = <h6><strong>{item.product.name}</strong></h6>

    if(["COM_DOMAIN", "VN_DOMAIN"].includes(item.product.product_type)) {
      inner_html = DomainItemOption(item, cart, addDomainWhois, handleTimeChange)
    }
  
    if(["VPS", "CLOUD_SERVER_PRO"].includes(item.product.product_type)) {
      inner_html =  VPSOption(item, cart, templates, handleTemplateChange, handleTimeChange);
    }

    if(["LINUX_HOSTING", "WINDOW_HOSTING"].includes(item.product.product_type)) {
      inner_html = HostingOption(item, cart, operation_systems, handleTimeChange, handleOperationSystemChange);
    }

    if(item.product.product_type === "EMAIL") {
      inner_html = EmailOption(item,handleTimeChange);
    }
    
    if(item.product.product_type === "SSL") {
      inner_html = SSLOption(item,handleTimeChange);
    }

    if(item.product.product_type === "TADU_PHONE") {
      inner_html = TaduPhoneOption(item,handleTimeChange);
    }

    if(item.product.product_type === "WHOIS_PRIVACY") {
      inner_html = WhoisPrivacyOption(item)
    }
    if(item.product.product_type === "IP") {
      inner_html =  IPOption(item, handleQuantityChange);
    }
    if(item.product.product_type == "TADU_INVOICE") {
      inner_html = TaduInvoiceOption(item)
    }
    if(item.product.product_type == "CLOUD_BACKUP") {
      inner_html = CloudBackupOption(item, handleTimeChange)
    }
    if(item.product.product_type == "DIGITAL_SIGNATURE") {
      inner_html = DigitalSignatureOption(item, handleTimeChange)
    }
    return (
      <React.Fragment>
        {inner_html}
      </React.Fragment>
    )   
  }
}

const CartItemOptionWrapper = connect(mapState, mapDispatch)(CartItemOption);

class CartItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      time_options: [],
      product_price: null
    }
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  handleRemoveItem() {
    const { item, deleteItem, cart } = this.props;
    // Delete by group id
    deleteItem(item.group_id);
  }

  render() {
    const { key, item, templates, operation_systems, handleTemplateChange, handleTimeChange, cart, handleOperationSystemChange, handleQuantityChange } = this.props;
    return(
      <tr>
        <td>
          {CheckItemRegisStatus(item)}
        </td>
        <CartItemOptionWrapper 
          templates={templates} 
          operation_systems={operation_systems}  
          handleTemplateChange={handleTemplateChange} 
          handleTimeChange={handleTimeChange} 
          handleOperationSystemChange={handleOperationSystemChange} 
          handleQuantityChange={handleQuantityChange}
          item={item} />
        <td style={{whiteSpace: 'nowrap'}} className="text-right">
          {InnerTotalProductPrice(item, cart)}
          <button style={{padding: 0}} className="btn btn-link btn-remove" onClick={()=>{this.handleRemoveItem()}}>
            <i className="icon-trash"></i>Không mua nữa
          </button>
        </td>
      </tr>
    )
  }
}

class ShopingCartPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      operation_systems: [],
      cart_note: "",
      alert_label: null,
      is_agency_customer_regis: false,
      is_show_add_customer_modal: false,
      isLoading: false
    }
  }

  discountCodeSubmit = (value) => {
    const { setDiscountCode, updateCart} = this.props;
    const self = this;
    self.isLoading = true;
    setDiscountCode(value.discount_code);
    updateCart().then(() =>{
            self.isLoading = false;

      if(self.props.cart.discount_code) {
        self.setState({
          alert_label: {status: "SUCCESS", message: self.props.cart.promo_msg}
        })
      }else {
        self.setState({
          alert_label: {status: "ERROR", message: "Mã giảm giá không hợp lệ, vui lòng sử dụng một mã khác !"}
        })
      }
    });
  }

  onRemoveDiscountCodeSubmit = () => {
    const { setDiscountCode, updateCart} = this.props;
    const self = this;
    self.isLoading = true;

    setDiscountCode(null);
    updateCart().then(() =>{
            self.isLoading = false;

      if(!self.props.cart.discount_code) {
        self.setState({
          alert_label: {status: "INFO", message: "Bạn đã hủy bỏ mã giảm giá !"}
        })
      }
    });
  }

  componentDidMount() {
    const {loadCart, loadCustomerList, userProfile} = this.props;
    const self = this;
    loadCart();
    loadCustomerList();
    axios({
      method: 'get',
      url: window.api_url + '/product/vps/template',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        templates: response.data.data
      })
    });
    
    axios({
      method: 'get',
      url: window.api_url + '/product/price/hosting',
      withCredentials: true
    }).then(function (response) {
      const operation_systems =  _.map(response.data.data, (item) => item.product);
      self.setState({
        ...self.state,
        operation_systems: operation_systems
      })
    });
   
  }

  // Action change selectbox
  // Buy Service for Admin or Customer
  onChangeItemRegisFor = (status) => {
    const { setAgencyCustomer, updateCart } = this.props;
    if(status) {
      this.setState({
        is_agency_customer_regis: status
      })
    }else {
      setAgencyCustomer(null);
      updateCart();
      this.setState({
        is_agency_customer_regis: status
      })
    }
  }

  setAgencyCustomer = (agency_customer_id) => {
    const { setAgencyCustomer, updateCart } = this.props;
    setAgencyCustomer(agency_customer_id);
    updateCart();
  }

  handleQuantityChange = (current_item) => { // onQuantityChange and updateCart
    const {cart, setCart, updateCart} = this.props;
    return function(selected_item_value) {
      current_item.quantity = parseInt(selected_item_value);
      setCart(cart);
      updateCart();
    }
  }
   
  handleTimeChange = (current_item) => { // onChangeTime and updateCart
    const {cart, setCart, updateCart} = this.props;
    return function(selected_item_value) {
      current_item.month = parseInt(selected_item_value);
      const addon_item = _.find(cart.items, (i) => i.parent_id === current_item.group_id);
      // Tìm ra IP addon để setup tháng theo dịch vụ cha (VPS, HOSTING).
      const ip_addon_items = _.filter(cart.items, (i) => i.product.product_type == "IP" && i.parent_id === current_item.group_id);
      if(addon_item) {
        addon_item.month = parseInt(selected_item_value);
      }
      // Xử lý gán tháng vào các dịch vụ IP addon theo parent service
      if(ip_addon_items) {
        _.forEach(ip_addon_items, (i) => i.month = parseInt(selected_item_value));
      }
      setCart(cart);
      updateCart();
    }
  }

  handleTemplateChange = (current_item) => { // onChangeTemplate and updateCart
    const {cart, setCart, updateCart} = this.props;
    return function (selected_item_id) {
      current_item.template_id = parseInt(selected_item_id);
      setCart(cart);
      updateCart();
    }
  }

  handleOperationSystemChange = (current_item) => { // onChangeOperation and updateCart
    const {cart, setCart, updateCart} = this.props;
    const { operation_systems } = this.state;
    return function (selected_operation_system) {
      // Cắt chuỗi để lấy hệ điều hành
      const current_hosting_selected_type = current_item.product.product_type.substr(0, current_item.product.product_type.indexOf('_',1))
      
      // chuyển đỗi sang hệ điều hành mong muốn
      const new_hosting_selected_type = current_item.product.code.replace(current_hosting_selected_type, selected_operation_system.split('_',1))
      // lấy thông tin product từ hệ điều hành mới
      let get_new_hosting = _.find(operation_systems, function (item) {
        return item.code === new_hosting_selected_type
      })
      // thay đổi hệ điều hành
      current_item.product = get_new_hosting;
      current_item.product_id = get_new_hosting.id;
      // cật nhật lại cart
      setCart(cart);
      updateCart();
    }
  }

  onNoteTxtChange = (event) => {
    const {setNote} = this.props;
    setNote(event.target.value);
  }

  onNextBtnClick = () => {
    const { updateCart, history, login, cart } = this.props;
    const domain_types = ["COM_DOMAIN", "VN_DOMAIN", "DIGITAL_SIGNATURE"];
    
    const regis_domains = _.filter(cart.items, (i) => domain_types.includes(i.product.product_type) && !i.is_renew); 
    // Kiểm tra giỏ hàng có tên miền đăng ký mới hoặc chuyển về TADU thì yêu cầu khách hàng khai tên miền
    // Lưu ý: Tên miền gia hạn thì lấy bản khai cũ.
    if(regis_domains.length > 0) {
      updateCart().then(() => {
        history.push('/products/domain-profile');
      });
    }else {
      updateCart().then(() => {
        history.push('/products/payment-method');
      });
    }
    
  }

  // Đối với hóa đơn 0đ, thì chuyển trang trang hoàn tất đơn hàng.
  onSuccessFreeOrder = () => {
    const self = this;
    const { history } = this.props;
    this.setState({
      isLoading: true
    })
    return axios({
      method: 'post',
      url: window.api_url + '/user/cart/payment',
      data: {
        payment_type: "BANK_TRANSFER",
      },
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError){
        self.setState({
          isLoading: false
        })
        
        history.push("/products/payment-method/" + response.data.data);
      }
    });
  }
  
  handleShowModal = () => {
    this.setState({
      is_show_add_customer_modal: true
    })
  } 

  handleHideModal = () => {
    const {loadCustomerList} = this.props;
    this.setState({
      is_show_add_customer_modal: false
    })
    loadCustomerList();
  } 
   
  render() {
    const { cart, deleteItem, addItem, updateCart, initialValues, handleSubmit, userProfile, agency_customer_list } = this.props;
    const { templates, domain_addon_list, operation_systems, alert_label, is_agency_customer_regis, is_show_add_customer_modal, isLoading } = this.state;
    const customer_options =  _.map(agency_customer_list, (item) => ({ value:item.id, label: item.full_name + " - " + item.email }));
    const agency_customer_selected =  _.find(customer_options, (item) => item.value == initialValues.agency_customer_id);
    const service_regis_for = [
      { label:"Đăng Ký Cho Đại Lý", value: false },
      { label:"Đăng Ký Cho Khách Hàng", value: true }
    ];
    // Kiểm tra giỏ hàng có dịch vụ ĐK mới thì mở select box ĐK cho cho KH của đại lý.
    const has_regis_item = _.filter(cart.items, (i) => !i.is_renew && !i.is_upgrade); 
    // Kiểm tra giỏ hàng có dịch vụ gia hạn hoặc update của KH của đại lý.
    const has_agency_customer_service = _.filter(cart.items, (i) => i.user_product_id); 
    
    const CartItemRows  = cart && cart.items && cart.items.length > 0 && _.map(cart.items, (item,key) => {
      
      if(!item.parent_id) { // Not show WHOIS_PRIVACY product
        return(
          <React.Fragment key={key}>
            <CartItem 
                item={item} 
                domain_addon_list={domain_addon_list}
                cart={cart}
                updateCart={updateCart}
                templates={templates} 
                operation_systems={operation_systems}
                addItem={addItem}
                deleteItem={deleteItem}
                handleTemplateChange={this.handleTemplateChange(item)}
                handleTimeChange={this.handleTimeChange(item)}
                handleOperationSystemChange={this.handleOperationSystemChange(item)}
                handleQuantityChange={this.handleQuantityChange(item)}/>
          </React.Fragment>
        )
      }
    })

    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Giỏ Hàng</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        {cart && cart.items.length > 0 && alert_label && <AlertLabel message={alert_label.message} status={alert_label.status}/>}
        {cart && cart.items.length === 0 && <div className="row">
          <div className="col-md-12">
            <div className="card card-body text-align-center">
              <h6>Không có sản phẩm nào trong giỏ hàng của bạn!</h6>
            </div>
          </div>
        </div>}
        {cart && cart.items && cart.items.length > 0 && <div className="row">
          <div className="col-md-12">
            <div className="row">
                <div className="col-md-9">
                  <div className="table-responsive card">
                    <table className="table table-lg footable default">
                      <thead>
                        <tr style={{whiteSpace: 'no-wrap'}} className="bg-blue">
                          <th style={{ width: "150px" }}>Loại</th>
                          <th style={{ width: "350px" }}>Sản phẩm</th>
                          <th style={{ width: "140px" }}>Thời gian</th>
                          <th style={{ width: "200px" }} className="text-align-right">Thành tiền</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CartItemRows}
                      </tbody>
                    </table>
                  </div>
                  <div className="card">
                    <div style={{ margin:"20px"}} >
                      <p>Yêu cầu hỗ trợ:</p>
                      <textarea className="form-control" rows="5" name="note" value={cart && cart.note} onChange={this.onNoteTxtChange}/>
                    </div>
                  </div>
              </div> 
              <div className="col-md-3">
                <form onSubmit={handleSubmit(this.discountCodeSubmit)}  role="form">
                  <Field disabled={cart.discount_code && true}  name="discount_code"  component={RenderInputWithDiv} placeholder="Nhập mã giảm giá tại đây" >
                  </Field>
                    {!cart.discount_code && !this.isLoading && <button className="form-control btn btn-warning" type="submit">Áp dụng mã giảm giá</button>}
                    {cart.discount_code && !this.isLoading && <button className="form-control btn btn-danger" onClick={() => this.onRemoveDiscountCodeSubmit()} >Hủy Bỏ Mã Giảm Giá</button>}                 
                </form>
                <br/>
                <div style={{ backgroundColor:'white' }}>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th>Tổng tiền:</th>
                          <td className="text-right">{numeral(cart.sub_total).format("0,0")}</td>
                        </tr>
                        <tr>
                          <th>Giảm giá:</th>
                          <td className="text-right">{numeral(cart.discount_money).format("0,0")}</td>
                        </tr>
                        <tr>
                          <th>Còn lại:</th>
                          <td className="text-right">{numeral(cart.remain_money).format("0,0")}</td>
                        </tr>
                        <tr>
                          <th>VAT: <span className="font-weight-normal">(10%)</span></th>
                          <td className="text-right">{numeral(cart.vat).format('0,0')}</td>
                        </tr>
                        <tr>
                          <th>Thành tiền:</th>
                          <td className="text-right text-primary">
                            <h5 className="font-weight-semibold">{numeral(cart.total).format('0,0')}</h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* Service Reqis For */}
                    {/*  Agency Only  */}
                    {has_regis_item.length > 0 && userProfile.role == "AGENCY" && initialValues && <div style={{ textAlign: 'right', margin: '10px'}}>
                      <form>
                        <Field
                          name="is_agency_customer_regis"
                          disabled={has_agency_customer_service.length > 0 && true}
                          options={service_regis_for}
                          defaultValue={true}
                          component={RenderRadioCheckBox}
                          onChangeAction={(value)=>this.onChangeItemRegisFor(value)}
                        />
                      </form>
                      {( initialValues.agency_customer_id || is_agency_customer_regis ) && <div style={{margin: "5px"}} className="row">
                       <div style={{ paddingRight: '5px'}} className="col-sm-10">
                           <form role="form">
                           {customer_options.length > 0 && <Field
                              name="agency_customer_id"
                              options={customer_options}
                              disabled={has_agency_customer_service.length > 0 && true}
                              defaultValue={agency_customer_selected}
                              styles={{control: provided => ({ ...provided, minWidth: 120})}}
                              placeholder={"Chọn khách hàng"}
                              onChangeAction = {(value) => this.setAgencyCustomer(value)}
                              component={RenderSelect}/>}
                          </form>
                        </div>
                        <div style={{ padding: '0px'}} className="col-sm-2">
                          <button disabled={has_agency_customer_service.length > 0 && true} onClick={()=> this.handleShowModal()} style={{ whiteSpace: 'nowrap',width: "inherit"}} className="btn bg-slate">
                            <span className="icon-plus2"></span>
                          </button>
                        </div>
                      </div>}
                    </div>}
                    <div className="text-right mt-3 mr-3">
                      {cart.total > 0 && <button className="btn btn-warning btn-xlg" onClick={this.onNextBtnClick}>
                        Tiếp tục <span className="icon-arrow-right7"></span>
                      </button>}
                      {cart.total === 0 && <button className="btn btn-success btn-xlg" onClick={this.onSuccessFreeOrder}>
                        Hoàn tất <span className="icon-check"></span>
                      </button>}
                    </div>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      {is_show_add_customer_modal && <ModalAddNewCustomer onHide={this.handleHideModal} show={is_show_add_customer_modal} />}
      </React.Fragment>
    )
  }
}
export default connect(mapState, mapDispatch)(reduxForm({
  form: 'shopingCartPage',
  destroyOnUnmount: true,
  enableReinitialize: true
})(withRouter(ShopingCartPage)));