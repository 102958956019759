import axios from 'axios';
import { objectToFormData } from 'object-to-formdata';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { RenderFileUpload } from '../../component/formInput';
import AlertLabel from '../../component/AlertLabel';
import { isArray } from 'lodash';
import BlockUI from '../../component/BlockUI';
var _ = require('lodash');

class IDUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state={
       domain_profile: null,
       alert_label: null,
       is_loading: false
    }
  }
  
  componentDidMount() { 
    const self = this;
    const { domain_profile_id } = this.props.match.params;
    axios({
      method: 'get',
      url: window.api_url + '/user/domain/profile/' + domain_profile_id,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError){
        self.setState({
          domain_profile: response.data.data,
        })
      }
    });
  }
  
  submit = (values) => {
    this.setState({
      is_loading: true
    })
    const { domain_profile_id } = this.props.match.params;
    const self = this;
    const data_value =  {
      domain_profile_id: domain_profile_id,
      file_upload_1: values.file_upload_1 && values.file_upload_1[0],
      file_upload_2: values.file_upload_2 && values.file_upload_2[0],
      company_file: values.company_file && values.company_file[0]
    }
    // convert Object to FormData
    const formData = objectToFormData(data_value);
    return axios({
      method: 'post',
      url: window.api_url + '/user/domain/profile/upload_id_image',
      data: formData,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          alert_label: {status: "SUCCESS", message: "Cập nhật CMND thành công."},
          is_loading: false
        })
      }else {
        throw new SubmissionError(response.data.error)
      }
    });
  }

  onPreviewImg = (image_1, image_2) => {
    this.setState({
      image_1: image_1,
      image_2: image_2,
    })
  }

  render() {
    const { handleSubmit, submitting} = this.props;
    const { domain_profile, alert_label, image_1, image_2, company_file, is_loading} = this.state;
    return (
      <React.Fragment>
        {is_loading && <BlockUI/>}
        {domain_profile && <div className="content">
          <div className="row">
            <h1 className="page-title sub-page-title">Cập Nhật CMND Bản Khai: <span style={{color: "#1E88E5"}}>{domain_profile.email}</span></h1>
          </div>
          

          <div className="card" style={{ backgroundColor:'white' }} >
            {alert_label && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
            <form onSubmit={handleSubmit(this.submit)}>
              <br/>
              <div style={{margin:20}} className="form-group">
                {!domain_profile.is_company && 
                  <React.Fragment>
                    <Field
                      name="file_upload_1"
                      label="CMND mặt trước"
                      fileAccept = "image/*"
                      defaultValue = {domain_profile.image_1}
                      src_preview = {image_1}
                      width = {250}
                      is_preview={true}
                      onChangeAction={(value)=> this.onPreviewImg(URL.createObjectURL(value[0]), image_2)}
                      component={RenderFileUpload}
                    />
                    <Field
                      name="file_upload_2"
                      label="CMND mặt sau"
                      fileAccept = "image/*"
                      defaultValue = {domain_profile.image_2}
                      src_preview = {image_2}
                      width = {250}
                      is_preview={true}
                      onChangeAction={(value)=>this.onPreviewImg(image_1, URL.createObjectURL(value[0]))}
                      component={RenderFileUpload}
                    />
                  </React.Fragment>}
                {domain_profile.is_company && 
                  <Field
                    name="company_file"
                    label="Giấy phép kinh doanh"
                    fileAccept = "image/*"
                    defaultValue = {domain_profile.image_1}
                    src_preview = {image_1}
                    height = {85}
                    width = {250}
                    is_preview={true}
                    onChangeAction={(value)=> this.onPreviewImg(URL.createObjectURL(value[0]), null)} 
                    component={RenderFileUpload}
                  />
                }
              </div>
              <div className="modal-footer">
                <button type="submit" disabled={(!image_1 && !image_2) && true} className={"btn bg-primary " + ((!image_1 && !image_2) ? "disabled" : "")}>
                  Cập Nhật
                </button>
              </div>
            </form>
          </div>
      </div>}
      </React.Fragment>
    )
  }
}


const TicketFormWrapper = reduxForm({
  form: 'idUploadForm',
  destroyOnUnmount: true,
  enableReinitialize: true
})(IDUploadForm);

export default withRouter(TicketFormWrapper);