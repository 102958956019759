import React from 'react'

function InvoiceStatus(status) {
	return(
		<React.Fragment>
			{status == "PAID" && <span className="badge badge-success">ĐÃ THANH TOÁN</span>}
			{["UNPAID","TRIAL"].includes(status) && <span className="badge badge-danger">CHỜ THANH TOÁN</span>}
			{/* {status == "TRIAL" && <span className="badge badge-warning">DÙNG THỬ</span>} */}
			{status == "REFUND" && <span className="badge badge-primary">ĐÃ HOÀN TIỀN</span>}
			{status == "NEW" && <span className="badge badge-info">MỚI</span>}
			{status == "CANCEL" && <span className="badge badge-secondary">HỦY</span>}
		</React.Fragment>
	)
}


export default InvoiceStatus;