import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import ProductSelect from '../services/ProductSelect';
import SearchForm from '../../component/SearchForm';
import Pagination from '../../component/Pagination';
import { readBuilderProgram } from 'typescript';
import { reduxForm, Field } from 'redux-form';
import { StringUtils } from '../../utils/javascriptUtils';
import ProductConfigModal from './modal/ProductConfigModal';
import { RenderSearchForm } from '../../component/formInput';

var _ = require('lodash');
var numeral = require('numeral');

function ProductNameLabel(tadu_price, agency_price) {
  let inner_product_name = (
    <React.Fragment>
        {agency_price && agency_price.product_name ? agency_price.product_name : "Chưa cấu hình"}	
        <br/>
        <small className="text-muted">{tadu_price && tadu_price.product.name}</small>
    </React.Fragment>
  )
  // Dịch vụ hosting cần thêm ProductType để xác định Linux và Windows
  if(["LINUX_HOSTING", "WINDOW_HOSTING"].includes(tadu_price.product.product_type)) {
    inner_product_name = (
      <React.Fragment>
        {agency_price && agency_price.product_name ? agency_price.product_name : "Chưa cấu hình"}	
        <br/>
        <small className="text-muted">{tadu_price && tadu_price.product.name + " [ "+tadu_price.product.product_type+" ]"}</small>
      </React.Fragment>
    )
  }

  return inner_product_name;
}

function IsYearPrice(agency_price, tadu_price, product_type) {
  let inner_html = (
    <td>
      {agency_price ? numeral(agency_price).format("0,0") + " đ" : "Chưa cấu hình"} 	
      <br/>
      <small className="text-muted">{numeral(tadu_price).format("0,0") + " đ"}</small>
    </td>
  )
  // Đối với các sản phẩm theo năm, không hỗ trợ giá theo tháng.
  if(["COM_DOMAIN", "VN_DOMAIN", "TADU_INVOICE", "SSL", "WHOIS_PRIVACY"].includes(product_type)) {
    inner_html = (
      <td>
        Không hỗ trợ 	
      </td>
    )
  }
  return inner_html;
}

function ProductPriceRows(group_price, handleShowModal, handleResetPriceById) {
  const agency_price = group_price.agency_price ? group_price.agency_price : {};
  const tadu_price = group_price.tadu_price;

  const inner_html_td =
    <React.Fragment>
      {/* Tên Sản Phẩm */}
      <td>
        {ProductNameLabel(tadu_price, agency_price)}
      </td>
      {/* Giá khởi tạo */}
      <td>
        {agency_price ? numeral(agency_price.init_price + agency_price.service_price).format("0,0") + " đ" : "Chưa cấu hình"} 	
        <br/>
        <small className="text-muted">{numeral(tadu_price.init_price + tadu_price.service_price).format("0,0") + " đ"}</small>
      </td>
      {/* Giá 1 tháng */}
      {IsYearPrice(agency_price.price1, tadu_price.price1, tadu_price.product.product_type)}
      {/* Giá 3 tháng */}
      {IsYearPrice(agency_price.price3, tadu_price.price3, tadu_price.product.product_type)}
      {/* Giá 6 tháng */}
      {IsYearPrice(agency_price.price6, tadu_price.price6, tadu_price.product.product_type)}
      {/* Giá 12 tháng */}
      <td>
        {agency_price ? numeral(agency_price.price12).format("0,0") + " đ" : "Chưa cấu hình"} 	
        <br/>
        <small className="text-muted">{numeral(tadu_price.price12).format("0,0") + " đ"}</small>
      </td>
      <td>
        <div className="icons-list">
          <div className="dropdown">
            <a className="list-icons-item" data-toggle="dropdown">
              <i className="icon-menu9"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <a onClick={() => handleShowModal(agency_price, tadu_price)} className="dropdown-item">
                <i className="icon-list-numbered"></i> Cấu hình.
              </a>
              {agency_price.id && <a onClick={() => handleResetPriceById(agency_price.id)} className="dropdown-item">
                <i className="icon-rotate-cw3"></i> Reset về giá TADU.
              </a>}
            </div>
          </div>
        </div>
    </td>
    </React.Fragment>
  return inner_html_td;
}

function ProductGroup(data, handleShowModal, handleResetPriceById) {
  const inner_html_rows = _.map(data, (product_group, index) => {
    return (
      product_group && <tr key={index}>
        {ProductPriceRows(product_group, handleShowModal, handleResetPriceById)}
      </tr>
    )
  })
  return inner_html_rows;
}

class ProductConfig extends React.Component {
  constructor(props) {
    super(props);
    const { product_type } = this.props.routeProps.match.params;
    const search_value = new URLSearchParams(this.props.location.search).get("search")
    this.state = {
      table_data: [],
      table_query: {
        current_page: 1,
        number_of_row: 50,
        sort_by: "",
        sort_asc: false,
        search_field: "search",
        search_value: search_value ? search_value : "",
        product_type: product_type,
        is_show_modal_config: false,
      },
      agency_price_obj: null,
      tadu_price_obj: null
    }
  }

  componentDidMount() {
    const { table_query } = this.state;
    this.listReload(table_query);
  }

  gotoPage = (page) => {
  const table_query = {
    ...this.state.table_query,
    current_page: page
  };
  this.listReload(table_query);
  }

  listReload = (table_query) => {
    const self = this;
    axios({
      method: 'post',
      url: window.api_url + '/agency/product/price/list',
      withCredentials: true, 
      data: table_query
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          ...self.state,
          table_query: table_query,
          table_data: response.data.data
        })
      }
    });
  }
  
  onProductSelect = (product_type) => {
    const { history } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1,
      product_type: product_type
    };
    this.listReload(table_query);
    history.push("/agency/config/product/"+ product_type)
  }

  onSearchSubmit = (values) => {
    const search_value = values.search ? values.search : "";
    const { history } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: search_value,
      current_page: 1,
      product_type: "all"
    };
    history.push("/agency/config/product/?search=" + search_value);
    this.listReload(table_query);
  }
  
  onKeyPess = (key_code) => {
    const { history } = this.props;
    if (key_code == "Escape") {
      history.push("/agency/config/product/all")
      this.clearDataFilter();
    }
  }

  clearDataFilter = () => {
    const { reset } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1
    };
    this.setState({
      ...this.state,
      table_query: table_query
    });
    reset();
    this.listReload(table_query);
  }

  // Show modal config agency product price.
  handleShowModal = (agency_price_obj, tadu_price_obj) => {
    this.setState({
    ...this.state,
      agency_price_obj: agency_price_obj,
      tadu_price_obj: tadu_price_obj, // Send tadu price to check product type and product is year 
      is_show_modal_config: true
    })
  }

  // Hide modal and reload data.
  handleHideModal = () => {
    const { table_query } = this.state;
    this.setState({
    ...this.state,
      is_show_modal_config: false
    })
    this.listReload(table_query);
  }

  // Reset Agency Price by ProductPrice id
  handleResetPriceById = (id) => {
    const { table_query } = this.state;
    const alert_confirm = window.confirm("Bạn có chắc Reset sản phẩm này về giá Tadu?.");
    if (alert_confirm) {
      const self = this;
      axios({
        method: 'post',
        url: window.api_url + '/agency/product/price/delete',
        withCredentials: true,
        data: {
          id: id
        }
      }).then(function (response) {
        if (!response.data.isError) {
          alert("Reset giá thành công !")
          self.listReload(table_query);
        }
      });
    }
  }

  // Reset All Price to Tadu price
  handleResetAllPrice = () => {
    const { table_query } = this.state;
    const result = window.confirm("Bạn có chắc Reset tất cả sản phẩm về giá Tadu?.");
    if (result) {
      const self = this;
      axios({
        method: 'post',
        url: window.api_url + '/agency/product/price/delete/all',
        withCredentials: true
      }).then(function (response) {
        if (!response.data.isError) {
          alert("Reset giá thành công !")
          self.listReload(table_query);
        }
      });
    }
  }

  render() {
    const {table_query, table_data, tadu_price_obj, agency_price_obj, is_show_modal_config} = this.state;
    const { handleSubmit } = this.props;
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Cấu hình giá sản phẩm</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body">
              <form style={{display:"inline-flex"}} onSubmit={handleSubmit(this.onSearchSubmit)}>
                <div style={{ width:"100%", paddingRight:5}}>
                  <Field
                    name="search"
                    placeholder="Dịch vụ"
                    defaultValue = {table_query.search_value}
                    onKeyAction={(ev)=> this.onKeyPess(ev)}
                    component={RenderSearchForm}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div style={{ margin: 10 }} className="text-align-right">
                <button style={{ marginRight: 10 }} onClick={() => this.handleResetAllPrice()} type="button" className="btn btn-primary">
                  <i className="icon-rotate-cw3"></i>	Reset tất cả về giá Tadu
                </button>
                <ProductSelect product_type_selected={table_query.product_type} is_product_config={true} onProductSelect={this.onProductSelect}/>
              </div>
              <div className="table-responsive">
                <table className="table table-togglable table-hover default footable-loaded footable">
                  <thead>
                    <tr>
                      <th data-toggle="true">
                        Tên dịch vụ đại lý
                        <br/>
                        <small className="text-muted" >Tên TADU</small>
                      </th>
                      <th data-hidden="phone">Giá khởi tạo</th>
                      <th data-hidden="phone">Giá gia hạn 1 tháng</th>
                      <th data-hidden="phone">Giá gia hạn 3 tháng</th>
                      <th data-hidden="phone">Giá gia hạn 6 tháng</th>
                      <th data-hidden="phone">Giá gia hạn 12 tháng</th>
                      <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                        <i className="icon-menu-open2"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {table_data && ProductGroup(table_data.data, this.handleShowModal, this.handleResetPriceById)}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination query={table_query} data={table_data} handle={this.gotoPage}/>
          </div>
        </div>
        {is_show_modal_config && <ProductConfigModal show={is_show_modal_config} agency_price_obj={agency_price_obj} tadu_product_obj={tadu_price_obj} onHide={this.handleHideModal} />}
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({
  
});
const ProductConfigWrapper = reduxForm({
  form: 'ProductConfig',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ProductConfig);

export default connect(mapState, mapDispatch)(withRouter(ProductConfigWrapper));