import React from 'react';
import { FormatterUtils } from '../utils/javascriptUtils';
var moment = require('moment');

function UserProductStatus(user_product) {
  const now = moment();
  const expired = moment(user_product.expired);
  const duration = Math.round(expired.diff(now, "days", true));
  return(
    <React.Fragment>
      {user_product.status === "RUNNING" && duration > 30 && <span className="badge badge-primary">ĐANG SỬ DỤNG</span>}
      {user_product.status === "RUNNING" && duration > 0 && duration <= 30 && <span className="badge badge-info">CÒN {Math.round(duration)} NGÀY HẾT HẠN</span>}
      {user_product.status === "RUNNING" && duration < 0  && <span className="badge badge-danger">HẾT HẠN</span>}
      {user_product.status === "RUNNING" && duration == 0  && <span className="badge badge-warning">HẾT HẠN TRONG HÔM NAY</span>}
      {(user_product.status === "EXPIRED") && <span className="badge badge-danger">
        HẾT HẠN VÀ SUSPENDED
        <br/>
        <small>Ngày: {moment(user_product.suspend_date).format("DD-MM-YYYY HH:mm:ss")}</small>
      </span>}
    </React.Fragment>
  )
}


export default UserProductStatus;