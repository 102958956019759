import React from 'react';

class ReduxFormField extends React.Component {
  render() {
    const {input, values, label, placeholder, required, type, readOnly, meta: { touched, error }} = this.props;
    return (
      <React.Fragment>
        <div className="form-group row">
          <label className={"col-form-label col-lg-2 " + (error != null ? 'font-weight-semibold text-danger' : '')}>
            {label} {required && <span style={{color: "red"}}>*</span>}
          </label>
          <div className="col-lg-10">
            {type !== "select2" && <input 
              className={"form-control " + (error != null ? 'border-danger' : '')} {...input} 
              readOnly={readOnly} 
              placeholder={placeholder} 
              type={type}/>}
            
            {type === "select2" && <select className={"form-control select2 " + (error != null ? 'border-danger' : '')} {...input}>
              {values.map((i, index) => (
                <option key={index} value={i.value} >{i.label}</option>
              ))}
            </select>}

            {touched && error && <span className="form-text text-danger">{error}</span>}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ReduxFormField