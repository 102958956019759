import React from 'react'
import '../css/login.css';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import ReduxLoginFormField from "../component/ReduxLoginFormField";

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_not_active: false,
      is_resend_active_mail: false
    }
  }

  submit(values) {
    const self = this;

    return axios({
      method: 'post',
      url: window.api_url + '/auth/login',
      data: {
        ...values
      },
      withCredentials: true
    }).then(function (response) {
      if (response.data.isError) {
        self.setState({
          email_login: values.email,
          is_resend_active_mail: false,
          is_not_active: response.data.error.is_not_active
        })

        throw new SubmissionError(response.data.error);
      }else {
        window.location.href = "/";
      }
    });
  }

  onResendActiveMail() {
    const {email_login} = this.state;
    const self = this;

    return axios({
      method: 'post',
      url: window.api_url + '/auth/resend_active',
      data: {
        email: email_login
      },
      withCredentials: true
    }).then(function (response) {
      if (!response.data.isError) {
        self.setState({
          is_resend_active_mail: true
        })
      }
    });
  }

  render() {
    const {handleSubmit, submitting} = this.props;
    const { is_not_active, is_resend_active_mail } = this.state;

    return (
      <React.Fragment>
        <div className="navbar navbar-inverse">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <img src="/assets/img/lglogo.png" alt="tadu.cloud"/>
            </a>
          </div>
        </div>
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content d-flex justify-content-center align-items-center">
              <form className="login-form" onSubmit={handleSubmit(this.submit.bind(this))}>
                <div className="card mb-0">
                  <div className="card-body login-body">
                    <div className="text-center mb-3">
                      <div className="text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" style={{border: "none"}}>
                        <a href="/">
                          <img width="205" src="/assets/img/lglogo.png" alt="tadu.cloud"/>
                        </a>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      <h5 className="mb-0">Đăng nhập tài khoản</h5>
                      <span className="d-block text-muted">Để sử dụng những tính năng riêng biệt</span>
                    </div>
                    {/* Đối với tài khoản chưa được active, cần hiện thị btn "gửi lại email xác thực" cho khách hàng */}
                    {!is_resend_active_mail && is_not_active && <div className="alert alert-warning">
                      Tài khoản của quý khách hiện chưa được xác thực (active), quý khách vui lòng kiểm tra email (cả trong spam) để xác thực tài khoản trước khi đăng nhập vào hệ thống, xin cám ơn quý khách! 
                      ( <span style={{color:"#42A5F5", cursor: 'pointer'}} onClick={()=> this.onResendActiveMail()}> Gửi lại mail xác nhận </span>)
                    </div>}
                    {/* In ra thông báo cho khách hàng nếu mail xác thực đã được gửi */}
                    {is_resend_active_mail && <div className="alert alert-success">
                      Quý khách vui lòng kiểm tra mail (cả trong spam) để kích hoạt tài khoản trước khi đăng nhập, xin cám ơn quý khách !
                    </div>}
                    <Field
                      name="email"
                      type="text"
                      iconClass="icon-mention"
                      placeholder="Email"
                      component={ReduxLoginFormField}/>
                    <Field
                      name="password"
                      type="password"
                      iconClass="icon-lock2"
                      placeholder="Mật khẩu"
                      component={ReduxLoginFormField}
                    />

                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-7"></div>
                        <div className="col-sm-5 text-right">
                          <RouterLink to="/forget_password">
                            Quên mật khẩu?
                          </RouterLink>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button type="submit" className={"btn bg-blue btn-block " + (submitting ? "disabled" : "")}>
                        Đăng nhập <i className="icon-arrow-right14 position-right"></i>
                      </button>
                    </div>

                    <div className="content-divider text-muted form-group">
                      <span>Bạn chưa có tài khoản?</span>
                    </div>

                    <div className="form-group">
                      <RouterLink className="btn btn-light btn-block" to="/register">
                        Đăng ký mới
                      </RouterLink>
                    </div>

                    <div className="form-group text-center">
                        <a href="https://apps.apple.com/us/app/taduid/id1617575090"><img src="/assets/img/apple_store.png" href="google.com" width="140" /></a>
                        <a href="https://play.google.com/store/apps/details?id=tadu.id.mobile"><img src="/assets/img/googleplay.png" width="140" /></a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}

const LoginPageWrapper = reduxForm({
  form: 'login'
})(LoginPage);


export default LoginPageWrapper;