import React from 'react';
import axios from 'axios';
import SearchForm from '../../component/SearchForm';
import { Link, withRouter } from 'react-router-dom';
import Pagination from '../../component/Pagination';
import InvoiceStatus from '../../component/InvoiceStatus';
import AlertLabel from '../../component/AlertLabel';
import { Field, reduxForm } from 'redux-form';
import { RenderDatePicker } from '../../component/formInput';
import { connect } from 'react-redux';
import moment from 'moment';
var numeral = require('numeral');
var _ = require('lodash');

function BillingRows(data) {
  const billing_rows = _.map(data, function(i, index) {
    return (
      <tr key={index} className={i.amount < 0 && "alpha-orange"} style={{borderBottom: "1px solid #ddd"}}>
        <td>
          {i.code}
          <br/>
          <small>{i.description}</small>
        </td>
        <td>
          {numeral(i.amount).format("0,0")}
        </td>
        <td>{numeral(i.total).format("0,0")}</td>
        <td>{moment(i.bill_date).format("DD/MM/YYYY hh:mm:ss")}</td>
      </tr>
      )
  })
  return billing_rows;
}

class BillingTable extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      table_data: [],
      table_query: {
        current_page: 1,
        number_of_row: 20,
        sort_by: "",
        sort_asc: false,
        from_date: null,
        to_date: null,
        account_type: this.props.account_type,
        alert_label: null
      },
      is_show_account_deposit_modal: false
    }
  }

  componentDidMount() {
    this.reload(this.state.table_query);
  }

  gotoPage = (page) => {
    const table_query = {
      ...this.state.table_query,
      current_page: page
    };
    this.reload(table_query);
  }
  
  reload = (table_query) => {
    const self = this;
    const { account_type } = this.props; 

    axios({
      method: 'post',
      url: window.api_url + '/user/billing/' + account_type,
      withCredentials: true,
      data: table_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        table_query: table_query,
        table_data: response.data.data
      })
    });
  }

  onSearchSubmit = (values) => {
    const table_query = {
    ...this.state.table_query,
      current_page: 1,
      from_date: moment(values.from_date).format("YYYY-MM-DD hh:mm:ss"),
      to_date: moment(values.to_date).format("YYYY-MM-DD hh:mm:ss")
    };
    if(values.from_date && values.to_date) {
      this.reload(table_query);
    }else {
      this.clearDataFilter();
    }
  }
  
  clearDataFilter = () => {
    const { reset } = this.props;
    const table_query = {
      ...this.state.table_query,
      current_page: 1,
      from_date: null,
      to_date: null
    };
    this.setState({
      ...this.state,
      table_query: table_query
    });
    reset();
    this.reload(table_query);
  }

  clearSearch = () => {
    const table_query = {
      ...this.state.table_query,
      current_page: 1,
      search_value: ""
    };
    this.reload(table_query);
  }
  onShowAccountDepositModal = () => {
    this.setState({ 
      is_show_account_deposit_modal: true
    })
  }

  onHideAccountDepositModal = () => {
    this.setState({ 
      is_show_account_deposit_modal: false
    })
    this.reload(this.state.table_query);
  }

  render() {
    const { handleSubmit } = this.props;
    const { table_query, table_data, alert_label, is_show_account_deposit_modal } = this.state;
    const { title } = this.props;
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">{title}</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            {alert_label && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
            <div className="card">
              <div className="panel-heading" style={{backgroundColor: "#fcfcfc",borderColor:"#ddd"}}>
                <div style={{ textAlign:'right' }}  className="heading-elements">
                  <form style={{ display: 'inline-flex'}} onSubmit={handleSubmit(this.onSearchSubmit)} className="form-horizontal">
                    <div style={{display: 'inline-flex', margin:10 }}>
                      <span className="input-group-text" style={{height:'fit-content'}}>
                        <i style={{ height:20 }} className="icon-calendar22"></i>
                      </span>
                      <Field
                        name="from_date"
                        placeholder="Từ ngày"
                        dateFormat="dd/MM/yyyy"
                        component={RenderDatePicker}
                        />
                    </div>
                    <div style={{display: 'inline-flex', margin:10 }}>
                      <span className="input-group-text" style={{height:'fit-content'}}>
                        <i style={{ height:20 }} className="icon-calendar22"></i>
                      </span>
                      <Field
                        name="to_date"
                        placeholder="Đến ngày"
                        dateFormat="dd/MM/yyyy"
                        component={RenderDatePicker}/>
                    </div>
                    <div style={{display: 'inline-flex',height:'fit-content', margin:10}}>
                      <button type="submit" style={{whiteSpace:'nowrap', width:'inherit'}} className="btn btn-primary"> Xem Sao Kê</button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-togglable table-hover default footable-loaded footable">
                  <thead>
                  <tr>
                    <th data-toggle="true" className="footable-visible footable-first-column">
                      Code
                      <br/>
                      <small className="display-block text-muted text-size-small">Nội dung</small></th>
                    <th data-hide="phone,tablet" className="">
                      Số tiền
                    </th>
                    <th data-hide="phone,tablet" className="">Tổng tiền</th>
                    <th data-hide="phone" className="">Ngày</th>
                  </tr>
                  </thead>
                  <tbody>
                    {table_data.data && BillingRows(table_data.data)}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination query={table_query} data={table_data} handle={this.gotoPage}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const BillingTableWrapper = reduxForm({
  form: 'billingTable',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(BillingTable);

export default withRouter(BillingTableWrapper);