import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { RenderTextEditor } from '../../component/formInput';
import AlertLabel from '../../component/AlertLabel';

var _ = require('lodash');
var numeral = require('numeral');

class AgencyMetaConfig extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      payment_method: "BANKTRANFER",
      alert_label: null
    }
  }

  onChangePaymentMethod = (payment_method_selected) => {
    this.setState({
      payment_method: payment_method_selected
    })
  }

  reloadPage = () => {
    const { updateField } = this.props;
    return axios({
      method: 'get',
      url: window.api_url + '/agency/meta',
      withCredentials: true,
    }).then(function (response) {
      if(!response.data.isError) {
        // Load initialValue
        _.forEach(response.data.data, (i) => {
          return( 
            updateField(i.text_key+"_id", i.id),
            updateField(i.text_key+"_text_value", i.text_value),
            updateField("text_key", i.text_key)
          )
        });
      }
    });
  }

  componentDidMount() {
    this.reloadPage();
  }
  
  onSubmit = (values) => {
    const self = this;
    const { payment_method } = this.state;
    const meta_id = values[payment_method + "_id"];
    const meta_value = values[payment_method + "_text_value"];
    let url = '/agency/meta/create';
    let bodyObject = {
      text_value: meta_value,
      text_key: payment_method
    }
    if(meta_id) {
      bodyObject.id = meta_id;
      url = '/agency/meta/update';
    }
    return axios({
      method: 'post',
      url: window.api_url + url,
      withCredentials: true,
      data: bodyObject
    }).then(function (response) {
      if(!response.data.isError) {
        self.reloadPage();
        self.setState({
          alert_label: {status: "SUCCESS", message: "Lưu Thông Tin Thanh Toán Thành Công !.", key: payment_method}
        })
      }
    });
  }

  render() {
    const { handleSubmit } = this.props;
    const { alert_label } = this.state;
    return (
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
              <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Cấu Hình Trang Hoàn Tất Đơn Hàng</span>
              </h4>
            </div>
          </div>
        </div>
        <br />
        <div className="card card-body">
          <div className="tabbable">
            <ul className="nav nav-tabs nav-tabs-highlight nav-justified">
              <li className="nav-item">
                <a className="nav-link active" onClick={() => this.onChangePaymentMethod("BANKTRANFER")} href="#meta-tab1" data-toggle="tab">Chuyển khoản ngân hàng</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => this.onChangePaymentMethod("COD")} href="#meta-tab2" data-toggle="tab">Thu phí tận nơi</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => this.onChangePaymentMethod("OFFICEPAID")} href="#meta-tab3" data-toggle="tab">Thu phí tại văn phòng</a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            {/* Form thông tin thanh toán bằng tài khoản ngân hàng */}
            <form className="tab-pane active" id="meta-tab1" onSubmit={handleSubmit(this.onSubmit)}>
              {alert_label && alert_label.key == "BANKTRANFER" && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
              <Field name={"BANKTRANFER_text_value"}  defaultValue="" component={RenderTextEditor} />
              <div style={{textAlign: 'center'}}>
                <button type="submit" className="btn bg-primary">Cập nhật</button>
                {/* <a className="btn">Xem trước</a> */}
              </div>
            </form>
            {/* Form thông tin thu phí tận nơi */}
            <form className="tab-pane" id="meta-tab2" onSubmit={handleSubmit(this.onSubmit)}>
              {alert_label &&  alert_label.key == "COD" && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
              <Field name={"COD_text_value"}  defaultValue="" component={RenderTextEditor} />
              <div style={{textAlign: 'center'}}>
                <button type="submit" className="btn bg-primary">Cập nhật</button>
                {/* <a className="btn">Xem trước</a> */}
              </div>
            </form>
            {/* Form thông tin thu phí tại ngân hàng */}
            <form className="tab-pane" id="meta-tab3" onSubmit={handleSubmit(this.onSubmit)}>
              {alert_label &&  alert_label.key == "OFFICEPAID" && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
              <Field name={"OFFICEPAID_text_value"}  defaultValue="" component={RenderTextEditor} />
              <div style={{textAlign: 'center'}}>
                <button type="submit" className="btn bg-primary">Cập nhật</button>
                {/* <a className="btn">Xem trước</a> */}
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
});

const mapDispatch = (dispatch) => ({
  updateField: (fieldName, value) =>
    dispatch({
      meta: { form: "agencyMetaConfig", field: fieldName },
      payload: value,
      type: "@@redux-form/CHANGE"
    }),
});
const AgencyMetaConfigWrapper = reduxForm({
  form: 'agencyMetaConfig',
  destroyOnUnmount: true,
  enableReinitialize: true
  // validate
})(AgencyMetaConfig);

export default connect(mapState, mapDispatch)(withRouter(AgencyMetaConfigWrapper));