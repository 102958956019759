import React from 'react'

function DomainProfileStatus(is_company) {
    return(
        <React.Fragment>
            {is_company && <span className="badge badge-flat border-info text-info-600">CÔNG TY</span>}
            {!is_company && <span className="badge badge-flat border-primary text-primary-600">CÁ NHÂN</span>}
        </React.Fragment>
    )
}


export default DomainProfileStatus;