import React from 'react';
import ChangeNameModal from './modals/ChangeNameModal';
import { withRouter } from 'react-router';


function AllowProductChangeName(productType) {
  switch(productType) {
    case "VPS": {
      return true;
    }
    case "CLOUD_SERVER_PRO": {
      return true;
    }
    case "CLOUD_BACKUP" : {
      return  true;
    }
    case "DIGITAL_SIGNATURE" : {
      return  true;
    }
    default: {
      return false;
    }
      
  }
}

class ProductName extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_show_modal: false,
    }
    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);
  }
  
  handleShowModal() {
    this.setState({
      is_show_modal: true
    })
  }

  handleHideModal() {
    // Reload lại đúng các dữ liệu query sau khi hoàn tất thay đỗi tên
    const { tableQuery, destroy } = this.props;
    this.setState({
      is_show_modal: false
    })
    this.props.reload(tableQuery)
    
  }

  render() {
    const {userProduct} = this.props;
    const {is_show_modal} = this.state;
    const types_with_domain_title = ["COM_DOMAIN", "VN_DOMAIN", "TADU_PHONE", "SSL","EMAIL" , "LINUX_HOSTING", "WINDOW_HOSTING"];
    
    return(
      <React.Fragment>
        {userProduct.name && ["VPS", "CLOUD_SERVER_PRO", "CLOUD_BACKUP", "DIGITAL_SIGNATURE"].includes(userProduct.product.product_type) && userProduct.name}
        {!userProduct.name && ["VPS", "CLOUD_SERVER_PRO", "CLOUD_BACKUP", "DIGITAL_SIGNATURE"].includes(userProduct.product.product_type) && "Chưa đặt tên"}
        {types_with_domain_title.includes(userProduct.product.product_type) && userProduct.domain}
        {AllowProductChangeName(userProduct.product.product_type) && <span style={{color:"#42A5F5", cursor: 'pointer'}} className="changename" onClick={()=> this.handleShowModal()}> [Sửa] </span>}
        <span className="display-block text-muted">{userProduct.ip_address}</span>
        <span className="display-block text-muted">{userProduct.product.name}</span>
        {is_show_modal && <ChangeNameModal key={"Key_" + userProduct.id} title="Sửa tên dịch vụ" show={is_show_modal} onHide={this.handleHideModal} userProduct={userProduct} />}
      </React.Fragment>
      
    )
  }

}

export default withRouter(ProductName);