import React from 'react'
import '../css/login.css';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import axios from 'axios';
import ReduxLoginFormField from "../component/ReduxLoginFormField";
import { Link as RouterLink } from 'react-router-dom';

class ForgetPasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.random = Math.floor(Math.random() * 100)
        this.state = {isDone: false};
    }

    submit(values) {
        const self = this;
        return axios({
            method: 'post',
            url: window.api_url + '/auth/forget_password',
            data: {
                ...values
            },
            withCredentials: true
        }).then(function (response) {
            if (response.data.isError) {
                throw new SubmissionError(response.data.error);
            } else {
                self.setState({isDone: true});
            }
        });
    }


    render() {
        const {error, handleSubmit, submitting, pristine, reset} = this.props;
        const {isDone} = this.state;
        const random = this.random;

        return (
            <React.Fragment>
                <div className="navbar navbar-inverse">
                    <div className="navbar-header">
                        <a className="navbar-brand" href="/">
                            <img src="/assets/img/lglogo.png" alt="tadu.cloud"/>
                        </a>
                    </div>
                </div>
                <div className="page-content">
                    <div className="content-wrapper">
                        <div className="content d-flex justify-content-center align-items-center">
                            <form className="login-form" onSubmit={handleSubmit(this.submit.bind(this))}>
                                <div className="card mb-0">
                                    <div className="card-body login-body">
                                        <div className="text-center mb-3">
                                            <div className="text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" style={{border: "none"}}>
                                                <a href="/">
                                                    <img width="205" src="/assets/img/lglogo.png" alt="tadu.cloud"/>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="text-center mb-3">
                                            <h5 className="mb-0">Quên mật khẩu?</h5>
                                            <span className="d-block text-muted">Nhập email để nhận mật khẩu mới.</span>
                                        </div>

                                        {!isDone && 
                                        <React.Fragment>
                                            <Field
                                                name="email"
                                                type="text"
                                                iconClass="icon-mention"
                                                placeholder="Email"
                                                component={ReduxLoginFormField}/>

                                            <div className="form-group">
                                                <img className="captcha" height="80px" width="100%" src={window.api_url + '/auth/captcha?r=' + random} alt="captcha"/>
                                            </div>

                                            <Field
                                                name="captcha"
                                                type="text"
                                                iconClass="icon-lock2"
                                                placeholder="Captcha"
                                                component={ReduxLoginFormField}/>

                                            <div className="form-group">
                                                <button type="submit" className={"btn bg-blue btn-block " + (submitting ? "disabled" : "")}>
                                                    Gửi mail <i className="icon-arrow-right14 position-right"></i>
                                                </button>
                                            </div>
                                        </React.Fragment>}

                                        {isDone && <div className="alert alert-success border-0">
                                            Quý khách vui lòng kiểm tra mail (cả trong spam) để thay đổi mật khẩu mới, xin cám ơn quý khách!
                                        </div>}

                                        {isDone && <div className="form-group">
                                            <RouterLink className="btn btn-light btn-block" to="/">
                                                Trở về trang đăng nhập
                                            </RouterLink>
                                        </div>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

const ForgetPasswordPageWrapper = reduxForm({
    form: 'forgetPassword'
})(ForgetPasswordPage);


export default ForgetPasswordPageWrapper;