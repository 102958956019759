import React from 'react';
import {connect} from 'react-redux'
import axios from 'axios';
import { Link as RouterLink, Link } from 'react-router-dom';
import { SocketUtils } from '../utils/javascriptUtils';

var _ = require('lodash');

function TicketRepliedRows(data, user_profile) {
  return (_.map(data, function (i, index){
    const direct_url = user_profile.role == "AGENCY" && user_profile.is_ticket && i.code.startsWith("AG") ? "/user/agency/ticket/detail/" : "/user/ticket/detail/"; 
    const code_color = user_profile.role == "AGENCY" && user_profile.is_ticket && i.code.startsWith("AG")  ? "orange" : null; 
    
    return <div style={{borderBottom: "1px solid #ddd"}} key={index}>
        <div className="media-title">
            <Link style={{color: code_color}} to={direct_url+i.id} className="font-weight-semibold">{i.code}</Link>
            <span className="text-muted float-right font-size-sm">{i.updated_date}</span>
          <br/>
          <small className="text-muted">{i.user.email}</small>
        </div>
        <span>{i.title}</span>
      </div>
    })
  )
}

// Thông báo mới sử dụng cho "KH lẻ"
function MessageBody(ticket_list, user_profile) {
  return (
    <div className="media-body">
      {ticket_list.length > 0 ? TicketRepliedRows(ticket_list, user_profile) : "Không có thông báo mới"}
    </div>
  )
}


class TopBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
    };
    this.channel = SocketUtils.socketStore();
    this.channel.join();
  }

  updateLastestTicketSeenDate = (e) => {
    e.preventDefault();
    const { getLastestTicketNotifyStatic } = this.props;
    axios({
      method: 'post',
      url: window.api_url + '/user/update/last_ticket_seen',
      withCredentials: true
    }).then(function (_) {
      getLastestTicketNotifyStatic(); 
    });
  }
    
  componentDidMount() {
    const { ticketModifyReload, agencyTicketModifyReload, userProfile, getLastestTicketNotifyStatic} = this.props;
    const self = this;
    this.props.loadUserAsync();
    axios({
      method: 'get',
      url: window.api_url + '/auth/user/info',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        fullName: response.data.data.full_name
      })
    });
    ticketModifyReload();
    agencyTicketModifyReload();
    getLastestTicketNotifyStatic();

    // Live reload ticket cho kỹ thuật của TADU
    this.channel.on("crm_ticket_modify", response => {
      ticketModifyReload();
      getLastestTicketNotifyStatic();
    })
    this.channel.on("agency_ticket_modify", response => {
      // Chỉ live reload với đúng đại lý của ticket này ticket này.
      if(response.agency_admin_email == userProfile.email) {
        agencyTicketModifyReload();
        getLastestTicketNotifyStatic();
      }
    })
    this.channel.on("tadu_customer_ticket_modify", response => {
      // Chỉ live reload với đúng đại lý của ticket này ticket này.
      if(response.customer_email == userProfile.email) {
        ticketModifyReload();
        getLastestTicketNotifyStatic();
      }
    })
    this.channel.on("agency_customer_ticket_modify", response => {
      // Chỉ live reload với đúng đại lý của ticket này ticket này.
      if(response.customer_email == userProfile.email) {
        agencyTicketModifyReload();
        getLastestTicketNotifyStatic();
      }
    })
    this.props.loadCart();
  }

  render() {
    const { signoutAsync, items, userProfile, ticketModify, agencyTicketModify, lastestTicketNotifyStatic } = this.props;
    const { fullName } = this.state;
    const new_response_ticket_list = _.concat(ticketModify, agencyTicketModify)
    const count = items ? _.filter(items, (item) => (item.parent_id == null)).length : 0;
    
    return (
      <React.Fragment>
        <div className="navbar navbar-expand-md navbar-light">
          <div className="navbar-brand">
            <a href="/" className="d-inline-block">
              <img src="/assets/img/lglogo.png" alt="Tadu.cloud"/>
            </a>
          </div>
      
          <div className="d-md-none">
            <button onClick={() => window.hide_sidebar()} className="hide-sidebar navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
              <i className="icon-tree5"/>
            </button>
            <button className="navbar-toggler sidebar-mobile-main-toggle" type="button">
              <i className="icon-paragraph-justify3"/>
            </button>
          </div>
      
          <div className="collapse navbar-collapse" id="navbar-mobile">
            <span className="badge ml-md-3 mr-md-auto"> </span>
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a className="navbar-nav-link dropdown-toggle caret-0" onClick={(e) => this.updateLastestTicketSeenDate(e)} data-toggle="dropdown">
                  <i className="icon-bubbles4"></i>
                  <span className="d-md-none ml-2">Thông báo</span>
                  {lastestTicketNotifyStatic > 0 && <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0">{lastestTicketNotifyStatic}</span>} 
                </a>
                <div className="dropdown-menu dropdown-menu-right dropdown-content wmin-md-400">
                  <div className="dropdown-content-body dropdown-scrollable">
                    {userProfile && new_response_ticket_list && MessageBody(new_response_ticket_list, userProfile)}
                  </div>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav">
              {userProfile && userProfile.role == "AGENCY" && !userProfile.has_custom_brand && userProfile.client_id && <li className="nav-item">
                <strong className="navbar-nav-link d-flex align-items-center">
                  Code: {userProfile.client_id}
                </strong>
              </li>}
              {/* Hộp Thư Thông Báo */}
              <li className="nav-item">
                <Link to={"/user/message"} className="navbar-nav-link d-flex align-items-center" href="#">
                  <i className="icon-envelop3"></i>
                  &nbsp;
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/user/feedback"} className="navbar-nav-link d-flex align-items-center" >
                  <i className="icon-feed"> </i>
                  <span>&nbsp;Báo lỗi và góp ý chức năng</span>
                </Link>
              </li>
              <li className="nav-item dropdown dropdown-user">
                <a href="#" className="navbar-nav-link d-flex align-items-center dropdown-toggle" data-toggle="dropdown">
                  <i className="icon-user mr-3"></i>
                  <span>{fullName}</span>
                </a>
      
                <div className="dropdown-menu dropdown-menu-right">
                  <RouterLink className="dropdown-item" to="/user_profile">
                    <i className="icon-user-plus"></i> Thông tin cá nhân
                  </RouterLink>

                  <RouterLink className="dropdown-item" to="/change_password">
                    <i className="icon-lock5"></i> Đổi mật khẩu
                  </RouterLink>

                  <a href="#" className="dropdown-item" onClick={signoutAsync}>
                    <i className="icon-switch2"></i> Đăng xuất
                  </a>
                </div>
              </li>
              <li className="nav-item">
                <RouterLink className="navbar-nav-link" to="/products/cart">
                  <i className="icon-cart5 text-orange"></i>
                  <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0">
                    {count}
                  </span>
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


const mapState = state => ({
  items: state.cart && state.cart.items,
  userProfile: state.userProfile.data,
  ticketModify: state.messageModify.ticket_data, 
  agencyTicketModify: state.messageModify.agency_ticket_data, 
  lastestTicketNotifyStatic: state.messageModify.lastestTicketNotifyStatic 
});

const mapDispatch = (dispatch) => ({
  signoutAsync: () => dispatch.app.signoutAsync(),
  loadCart: () => dispatch.cart.loadCart(),
  loadUserAsync: () => dispatch.userProfile.loadUserAsync(),
  ticketModifyReload: () => dispatch.messageModify.ticketModifyReload(),
  agencyTicketModifyReload: () => dispatch.messageModify.agencyTicketModifyReload(),
  getLastestTicketNotifyStatic: () => dispatch.messageModify.getLastestTicketNotifyStatic()
});

export default connect(mapState, mapDispatch)(TopBar);