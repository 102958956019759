import React, { Component } from 'react'

class ChangeLogPage extends Component {
  constructor(props) {
    super(props)

    this.state = { terms: null }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Lịch sử Update Hệ Thống</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="card card-body">
          {/* v1.0.5 */}
          <h3 style={{color: "#42A5F5"}}> 1.0.5 - 05-04-2021</h3>
          <p>* Thêm mới tính năng quản lý <strong>DNS</strong> cho <strong>Tên Miền</strong>.</p>
          {/* v1.0.4 */}
          <h3 style={{color: "#42A5F5"}}> 1.0.4 - 02-04-2021</h3>
          <p>* Thêm mới tính năng Console cho dịch vụ <strong>Cloud Server</strong>.</p>
          {/* v1.0.3 */}
          <h3 style={{color: "#42A5F5"}}> 1.0.3 - 23-03-2021</h3>
          <p>* Thêm mới dịch vụ <strong>Cloud Server Pro.</strong></p>
          {/* v1.0.2 */}
          <h3 style={{color: "#42A5F5"}}> 1.0.2 - 15-03-2021</h3>
          <p>* Thay đổi thông tin thanh toán thông qua hình thức <strong>Chuyển Khoản Ngân Hàng.</strong></p> 
          <p>* Cảnh báo dịch vụ hết hạn trước <strong>30 ngày</strong> (cũ 15 ngày).</p>
          {/* v1.0.1 */}
          <h3 style={{color: "#42A5F5"}}>1.0.1 - 06-03-2021</h3>
          <p>* Cập nhật tính năng quản lý dịch vụ <strong>Cloud Server</strong>.</p>
          {/* v1.0.0 */}
          <h3 style={{color: "#42A5F5"}}>1.0.0 - 27-01-2021</h3>
          <p>* Thêm mới chức năng thanh toán bằng tài khoản khuyến mãi</p>
        </div>
    </React.Fragment>
    )
  }
}

export default ChangeLogPage