import React from 'react';
class WordpressGuideline extends React.Component {
    render() {
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold"> Hướng dẫn sử dụng wordpress plugin</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card panel-flat">
                            <div className="card-body">
                                <p>
                                    <a target="_blank" href="/assets/tadu-guideline/tadu_plugin.zip" className="btn btn-primary">Download plugin wordpress tại đây</a>
                                </p>
                                <br/>
                                <p>1. Sau khi download, upload lên thư mục plugins của wordpress.</p>
                                <p>2. Login và active plugin "Tadu API".</p>
                                <p> 
                                    <img src="/assets/tadu-guideline/wp_01.png" alt=""/>
                                </p>
                                <br/>
                                <p>3. Vào theo đường dẫn <strong>Appearance -&gt; Tadu.cloud</strong>, nhập vào đường dẫn thương hiệu riêng của đại lý (ví dụ: id.xxxx.com).</p>
                                <p>
                                    <img src="/assets/tadu-guideline/wp_02.png" alt=""/>
                                </p>
                                <p>4. Nhấn <strong>Lưu cài đặt</strong></p>
                                <br/>
                                <p>Sau khi cài đặt hoàn tất, plugin sẽ tạo ra các <strong>pages</strong>:</p>
                                <ul>
                                    <li><strong>host</strong>: trang liệt kê các gói dịch vụ Share Hosting.</li>
                                    <li><strong>vps</strong>: trang liệt kê các gói dịch vụ VPS.</li>
                                    <li><strong>email</strong>: trang liệt kê các gói dịch vụ Email Server.</li>
                                    <li><strong>ssl</strong>: trang liệt kê các gói dịch vụ SSL.</li>
                                    <li>Ngoài trang chủ, các tính năng check domain và sản phẩm đặc trưng có thể sử dụng shortcode để xây dựng ra.</li>
                                </ul>
                                <p>
                                    <img src="/assets/tadu-guideline/wp_03.png" alt=""/>
                                </p>
                                <br/>
                                <p>Các shortcode có thể sử dụng:</p>
                                <ul>
                                    <li><strong>[domain_frm]</strong>: Tạo hộp chức năng kiểm tra domain (như ngoài trang chủ <a href="https://tadu.cloud/"> tadu.cloud</a>)</li>
                                    <li><strong>[json_posts cat="product-type" product_id="product-id"]</strong>: Lấy 1 sản phẩm theo ID (product-id) và thuộc loại sản phẩm nào (product-type)</li>
                                    <li><strong>[json_posts cat="product-type"]</strong>: Lấy danh sách sản phẩm theo loại sản phẩm (product-type)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default WordpressGuideline;