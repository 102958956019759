import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { RenderMoneyFormat, RenderInputWithDiv } from '../../../component/formInput';

function ConfigPriceField(product_type, is_year) {
  return <React.Fragment>
    <Field
      name="product_name"
      label="Tên dịch vụ"
      placeholder="Nhập tên dịch vụ của đại lý..."
      component={RenderInputWithDiv} />
    <Field 
      name="price_service" 
      disabled={!["COM_DOMAIN","VN_DOMAIN"].includes(product_type)} 
      label="Giá dịch vụ" 
      placeholder="Nhập giá dịch vụ..." 
      component={RenderMoneyFormat} />
    <Field 
      name="init_price" 
      disabled={!["COM_DOMAIN","VN_DOMAIN"].includes(product_type)} 
      label="Giá khởi tạo" 
      placeholder="Nhập giá khởi tạo..." 
      component={RenderMoneyFormat} />
    <Field 
      name="init_price_service" 
      disabled={!["COM_DOMAIN","VN_DOMAIN"].includes(product_type)} 
      placeholder="Nhập giá khởi tạo dịch vụ..." 
      label="Giá khởi tạo dịch vụ" 
      component={RenderMoneyFormat} />
    <Field 
      name="price1" 
      disabled={is_year}
      placeholder="Nhập giá gia hạn x1 tháng..." 
      label="Giá gia hạn x1 tháng" 
      component={RenderMoneyFormat} />
    <Field 
      name="price3" 
      disabled={is_year}
      placeholder="Nhập giá gia hạn x3 tháng..." 
      label="Giá gia hạn x3 tháng" 
      component={RenderMoneyFormat} />
    <Field 
      name="price6" 
      disabled={is_year}
      placeholder="Nhập giá gia hạn x6 tháng..." 
      label="Giá gia hạn x6 tháng" 
      component={RenderMoneyFormat} />
    <Field 
      name="price12" 
      placeholder="Nhập giá gia hạn x12 tháng" 
      label="Giá gia hạn x12 tháng" 
      component={RenderMoneyFormat} />
  </React.Fragment>
}

class ProductConfigModal extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }

  }
  componentDidMount() {

  }
  submit = (values) => {
    const { tadu_product_obj, onHide, agency_price_obj } = this.props;
    const self = this;
    let backend_url = '/agency/product/price/create'
    const bodyObject = {
      product_id: tadu_product_obj.product_id,
      product_name: values.product_name,
      agency_id: values.agency_id,
      price_service: values.price_service ? values.price_service : 0,
      price1: values.price1 ? values.price1 : 0,
      price3: values.price3 ? values.price3 : 0,
      price6: values.price6 ? values.price6 : 0,
      price12: values.price12 ? values.price12 : 0,
      init_price: values.init_price ? values.init_price : 0,
      init_price_service: values.init_price_service ? values.init_price_service : 0,
      transfer_price: tadu_product_obj.transfer_price ? tadu_product_obj.transfer_price : 0,
      tax: tadu_product_obj.tax,
      is_year: tadu_product_obj.is_year
    }
    if (agency_price_obj.id) {
      backend_url = '/agency/product/price/update';
      bodyObject.id = agency_price_obj.id;
    }
    axios({
      method: 'post',
      url: window.api_url + backend_url,
      withCredentials: true,
      data: bodyObject
    }).then(function (response) {
      if(!response.data.isError) {
        onHide();
      }
    })
  }
  
  render() {
    const { title, handleSubmit, submitting, onHide, tadu_product_obj } = this.props;
    const modalConfig = { backdrop: 'static', show: this.props.show, bssize: "medium", onHide: this.props.onHide, submitting: this.props.submitting };
    const newModal = <div style={{ width: '30%' }}>
      {tadu_product_obj && <Modal
        {...modalConfig}
        aria-labelledby="contained-modal-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Cấu hình giá sản phẩm
            <br /> <small className="text-muted">Tên sản phẩm Tadu: {tadu_product_obj.product.name}</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form-horizontal" role="form" onSubmit={handleSubmit(this.submit)}>
            {ConfigPriceField(tadu_product_obj.product.product_type, tadu_product_obj.is_year)}
            <div className="text-right">
              <a onClick={() => onHide()} className={"btn"}>
                Đóng
            </a>
              <button type="submit" className={"btn bg-blue btn-primary " + (submitting ? "disabled" : "")}>
                Lưu <i className="icon-arrow-right14 position-right"></i>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>}
    </div>
    return newModal;
  }
};
const mapState = (state, props) => ({
  initialValues: props.agency_price_obj
});

const mapDispatch = dispatch => ({
    
});

export default (connect(mapState, mapDispatch)(
  reduxForm({
    form: 'ProductConfigModal',
    destroyOnUnmount: true,
    enableReinitialize: true
  })(withRouter(ProductConfigModal))));
