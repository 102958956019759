import React from 'react';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux'
import {cities} from "../models/cities"
import {countries} from "../models/countries"
import axios from 'axios';
import { RenderInputWithDiv, RenderSelect } from '../component/formInput';
import { withRouter } from 'react-router';
import ReduxFormField from '../component/ReduxFormField';


class UserProfilePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDone: false
    };
  }
  componentDidMount() {
    const { login, history, loadUserAsync } = this.props;
    if(!login) {
      history.push('/login');
    }else {
      loadUserAsync();
    }
  }
  submit(values) {
    const self = this;
    self.setState({isDone: false});

    return axios({
      method: 'post',
      url: window.api_url + '/auth/user/update_profile',
      data: {
        ...values
      },
      withCredentials: true
    }).then(function (response) {
      if (response.data.isError) {
        throw new SubmissionError(response.data.error);
      } else {
        self.setState({isDone: true});
      }
    });
  }

  render() {
    const {error, handleSubmit, submitting, pristine, reset} = this.props;
    const {isDone} = this.state;

    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Thay Đổi Thông Tin Cá Nhân</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="card">
          <div className="card-body">
            <form className="form-horizontal" onSubmit={handleSubmit(this.submit.bind(this))}>
              <Field
                name="email"
                type="text"
                label="Email"
                readOnly={true}
                iconClass="icon-mention"
                placeholder="Email"
                component={RenderInputWithDiv}/>

              <Field
                name="full_name"
                type="text"
                label="Họ tên"
                iconClass="icon-mention"
                placeholder="Họ tên"
                component={RenderInputWithDiv}/>

              <Field
                name="phone"
                type="text"
                label="Điện thoại"
                iconClass="icon-mention"
                placeholder="Điện thoại"
                component={RenderInputWithDiv}/>

              <Field
                name="address"
                type="text"
                label="Địa chỉ"
                iconClass="icon-mention"
                placeholder="Địa chỉ"
                component={RenderInputWithDiv}/>

              <Field
                name="city"
                type="select2"
                label="Tỉnh / thành phố"
                iconClass="icon-mention"
                values={cities}
                placeholder="Tỉnh / thành phố"
                component={ReduxFormField}/>

              <Field
                name="nation"
                type="select2"
                label="Quốc gia"
                values={countries}
                iconClass="icon-mention"
                placeholder="Quốc gia"
                component={ReduxFormField}/>

              <div className="text-center">
                <button type="submit" className={"btn bg-blue btn-primary " + (submitting ? "disabled" : "")}>
                  Cập nhật <i className="icon-arrow-right14 position-right"></i>
                </button>
              </div>

              <br/>
              {isDone && <div className="alert alert-success border-0">
                Cập nhật thành công!
              </div>}
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


const UserProfilePageWrapper = reduxForm({
  form: 'userProfile'
})(UserProfilePage);

const mapState = state => ({
  login: state.app.login,
  initialValues: state.userProfile.data,
});

const mapDispatch = (dispatch) => ({
  loadUserAsync: () => dispatch.userProfile.loadUserAsync()
});

export default connect(mapState, mapDispatch)(withRouter(UserProfilePageWrapper));