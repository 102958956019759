import React from 'react';
import ReactJson from 'react-json-view'
import { Link } from 'react-router-dom';
const json_example = [{
        "is_error": "false",
        "product": {
            "id": 2,
            "name": "VPS#1",
            "price": 100000,
            "CPU": "1 core",
            "HDD": "20GB [SSD Cloud Storage]",
            "RAM": "1024 MB",
            "Bandwidth": "Unlimited",
            "Công nghệ ảo hóa": "KVM \/ LXC",
            "Hệ điều hành": "Linux",
            "Backup": "Hàng tuần",
            "Panel": "Free Direct Admin",
            "is_domain_check": "0",
            "url": "/dang-ky/vps/2"
        }
    }]
                    

class GetProductById extends React.Component {
    render() {
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold"> Lấy sản phẩm theo ID</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                <div class="col-md-12">
                    <div class="card panel-flat">
                        <div class="card-body">
                            <p>Lấy thông tin duy nhất 1 sản phẩm theo ID của sản phẩm đó.</p>
                            <p style={{color:"red"}}>
                                <strong>Lưu ý:</strong>
                                http://me.xxxx.com/ là đường dẫn thương hiệu riêng của đại lý. <a target="_blank" href="https://trogiup.tadu.vn/thiet-lap-thuong-hieu-rieng/">Hướng dẫn cấu hình thương hiệu riêng</a>.
                            </p>
                            <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px"}}>Ứng dụng</p>
                            <p>Dùng để lấy sản phẩm tiêu biểu đặt ra ngoài trang chủ, hoặc các trang khuyến mãi cho một sản phẩm đặc thù.</p>
                            <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px"}}>Sử dụng</p>
                            <ul style={{listStyle: "none", padding: "25px", border: "1px solid #ddd"}}>
                                <li style={{paddingBottom: "10px"}}>
                                    <i class="icon-files-empty"></i> GET
                                    <span style={{float: "right"}}>Method</span>
                                </li>
                                <li style={{paddingBottom: "10px"}}>
                                    <i class="icon-link"></i> http://me.xxxx.com/rest/product/<span class="label label-default">product-id</span>
                                    <span style={{float: "right"}}>Link</span>
                                </li>
                                <li>
                                    <i class="icon-list"></i> application/json
                                    <span style={{float: "right"}}>Content-Type</span>
                                </li>
                            </ul>
                            <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px"}}>URL fields</p>
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Biến</th>
                                    <th>Loại</th>
                                    <th>Mô tả</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{textTransform: "uppercase"}}>product-id</td>
                                    <td>Bắt buộc nhập</td>
                                    <td>
                                        <ul>
                                            <li>
                                                Mỗi sản phẩm sẽ có 1 ID
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px"}}>Kết quả trả về</p>
                            <br/>
                            <ReactJson src={json_example} theme="monokai" />
                            <p>Để có kết quả như trên, <span class="label label-default">product-id</span> chúng ta truyền vào "2".</p>
                            <p>Để hiểu các thông số trả về, tham khảo lại <Link to="get-product-list">hướng dẫn lấy danh sách sản phẩm</Link></p>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        )
    }
}

export default GetProductById;