import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { RenderInputWithDiv, RenderTextArea } from '../../../component/formInput';
import PasswordFormField from '../../../component/PasswordFormField';


const mapState = state => ({
});

const mapDispatch = (dispatch) => ({
});

class ModalAddNewCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
    this.submit = this.submit.bind(this);
  }
  
  componentDidMount() { 
  }
  
  submit(values) {
    const { onHide } = this.props;
    return axios({
      method: 'post',
      url: window.api_url + '/user/agency/add_customer', 
      data: {
        email: values.email,
        password: values.password,
        full_name: values.full_name,
        phone: values.phone,
        note: values.note,
        is_active: values.is_active,
        role: "CUSTOMER",
      },
      withCredentials: true
    }).then(function (response) {
      if (response.data.isError) {
        throw new SubmissionError(response.data.error);
      } else {
        onHide()
      }
    });
  }
  render() {
    const { handleSubmit, submitting } = this.props;
    const modalConfig = { backdrop: 'static', show: this.props.show, bssize: "medium", onHide: this.props.onHide, submitting: this.props.submitting };
    const newModal = <div style={{ width: '30%' }}>
      <Modal
        {...modalConfig}
        aria-labelledby="contained-modal-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-sm">Thêm mới khách hàng</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(this.submit)}>
            <br/>
            <div style={{margin:20}} className="form-group">
              <Field
                name="email"
                label="Email"
                placeholder="Nhập email"
                component={RenderInputWithDiv}
              />
              {<Field
                name="password"
                label="Mật khẩu"
                placeholder="Nhập mật khẩu"
                component={PasswordFormField}
              />}
              <Field
                name="full_name"
                label="Họ tên"
                placeholder="Họ Tên"
                component={RenderInputWithDiv}
              />
              <Field
                name="phone"
                label="Số điện thoại"
                placeholder="Số điện thoại"
                component={RenderInputWithDiv}
              />
              <Field
                name="note"
                label="Ghi chú"
                placeholder="Ghi chú"
                style={{width: "100%"}}
                component={RenderTextArea}
              />
            </div>
            <div className="modal-footer">
              <button type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
                Lưu
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
    return newModal;
  }
}

const ModalAddNewCustomerWrapper = reduxForm({
  form: 'ModalAddNewCustomer',
  destroyOnUnmount: true,
  enableReinitialize: true
  // validate
})(ModalAddNewCustomer);

export default connect(mapState, mapDispatch)(withRouter(ModalAddNewCustomerWrapper));