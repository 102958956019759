import React from 'react';
import axios from 'axios';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import { RenderInputWithDiv } from '../../component/formInput';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import BlockUI from '../../component/BlockUI';

const validate = (values) => {
  let errors = {};
  if(!values.domain) {
    errors.domain = "Không được bỏ trống trên miền !";
  }
  if(!values.auth_code) {
    errors.auth_code = "Không được bỏ trống mã code !";
  }
  return errors;
}

class DomainTransferPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false
    }
  }
  // Check price of domain
  onSubmit = (values) => {
    const self = this;
    self.setState({
      is_loading: true
    })
    axios({
      method: 'get',
      url: window.api_url + '/domain/' + values.domain + "/check",
      withCredentials: true
    }).then(function (response) {
      const domain_data = response.data.data;
      if (domain_data && domain_data.price.product.product_type != "VN_DOMAIN" && !response.data.isError) {
        self.addItemTransfer(values.domain, values.auth_code, domain_data)
      } else if(domain_data && domain_data.price.product.product_type == "VN_DOMAIN" ) {
        toast.error("Tên miền chuyển về TADU chỉ cho phép tên miền quốc tế !")
      } else {
        toast.error(response.data.error == "invalid_domain" && "Tên miền không hợp lệ !")
      }
      self.setState({
        is_loading: false
      })
    });
  }

  addItemTransfer = (domain_name, auth_code, domain_price) => {
    const { addItem, updateCart, history } = this.props;
    const item = {
      id: uuidv4(),
      group_id: null,
      product: domain_price.price.product,
      price_is_year: domain_price.price.is_year,
      // price: domainPriceResult.price,
      month: 12,
      quantity: 1,
      domain_name: domain_name.toLowerCase().replace(/^(www\.)/,"").trim(),
      domain_profile_id: null,
      template_id: null, //template id để sử dung cho vps.
      user_product_id: null, //Dịch vụ cần gia hạn
      is_renew: false,
      is_upgrade: false,
      is_tranfer: true,
      is_delivery: false,
      parent_id: null,
      order: 0, //Vị trí của item trong giỏ hàng,
      auth_code: auth_code
    }
    this.setState({
      isDomainSelect: true,
      idItemSelect: item.id
    })
    addItem(item);
    updateCart();
    history.push("/products/cart")
  }

  render() {
    const { error, handleSubmit, submitting, pristine, reset } = this.props;
    const { is_loading } = this.state;

    return (
      <div className="card">
        {is_loading  && <BlockUI />}
        <ToastContainer />
        <div className="modal-header">
          <h5 className="modal-title">Chuyển tên miền về Tadu </h5>
        </div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="modal-body">
            <Field
              name="domain"
              component={RenderInputWithDiv}
              label="Tên miền"/>
            <Field
              name="auth_code"
              component={RenderInputWithDiv}
              label="Authorization Code" />
            <span style={{ color: 'red' }}>
              <strong>*Lưu ý: </strong> Để chuyển được domain, quý khách vui lòng tắt chức năng ẩn thông tin tên miền (Whois privacy) và unlock tên miền bên nhà cung cấp dịch vụ cũ.
            </span>
          </div>

          <div className="modal-footer">
            <button type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
              Tiếp tục
            </button>
          </div>
        </form>
      </div>
    )
  }
}

const mapState = state => ({
  cart: state.cart
});

const mapDispatch = (dispatch) => ({
  addItem: (item) => dispatch.cart.addItem(item),
  loadCart: () => dispatch.cart.loadCart(),
  updateCart: () => dispatch.cart.updateCart(),
  setCart: (cart) => dispatch.cart.setCart(cart),
});

export default (connect(mapState, mapDispatch)(
  reduxForm({
    form: 'DomainTransferPage',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate
})(withRouter(DomainTransferPage))));	