import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';

var numeral = require('numeral');

class BankAccountInnerHtml extends React.Component {

  constructor(props) {
    super(props);
    this.state= {
      bank: 'Vietcombank',
      vietQrSrc: 'https://img.vietqr.io/image/970436-1014480066-compact2.jpg?amount=' + this.props.invoice.total + '&addInfo=' + this.props.invoice.code + '&accountName=Tran%20Thi%20Huynh%20Trang'
    }
    this.onBankChange = this.onBankChange.bind(this);
  }

  onBankChange(bank) {

    let vietQrSrc = ''
    if (bank === 'Vietcombank') {
      vietQrSrc = 'https://img.vietqr.io/image/970436-1014480066-compact2.jpg?amount=' + this.props.invoice.total + '&addInfo=' + this.props.invoice.code + '&accountName=Tran%20Thi%20Huynh%20Trang'
    }

    if (bank === 'ACB') {
      vietQrSrc = 'https://img.vietqr.io/image/970416-13284107-compact2.jpg?amount=' + this.props.invoice.total + '&addInfo=' + this.props.invoice.code + '&accountName=Tran%20Thi%20Huynh%20Trang'
    }

    if (bank === 'Techcombank') {
      vietQrSrc = 'https://img.vietqr.io/image/970407-19036851355011-compact2.jpg?amount=' + this.props.invoice.total + '&addInfo=' + this.props.invoice.code + '&accountName=Tran%20Thi%20Huynh%20Trang'
    }

    this.setState({
      bank: bank,
      vietQrSrc: vietQrSrc
    })
  }

  render() {
    const { invoice } = this.props;
    const { bank, vietQrSrc } = this.state;
    return (
        <React.Fragment>
          <h4><strong>Chúc mừng bạn đã đăng ký mua hàng thành công</strong></h4>
          <br/>
          <p>
            Để đơn hàng được khởi tạo, quý khách vui lòng thực hiện chuyển khoản tổng tiền <strong style={{color: "#FF8D00"}}>{numeral(invoice.total).format("0,0")} đ</strong> vào một trong những tài khoản ngân hàng hiển thị ngay bên dưới.
            <br/>
            Trong nội dung chuyển khoản quý khách vui lòng điền duy nhất mã đơn hàng là: <strong style={{color: "#FF8D00"}}>{invoice.code}</strong>.
            <br/>
          </p>
          <br/>
          <p>
            Nếu quý khách không thể ghi mã đơn hàng vào nội dung chuyển khoản, quý khách vui lòng chụp hình ủy nhiệm chi và gửi vào email: <strong>ketoan@tadu.vn</strong> với tựa đề: <strong>Ủy nhiệm chi đơn hàng <span> {invoice.code}</span></strong>.
          </p>
          <br/>
          <table style={{border: 'none'}}>
            <tbody>
            <tr>
              <td>
                <select style={{fontSize: 11, width: '300px', marginLeft: "50px"}} className="form-control"
                        value={bank}
                        onChange={(event) => this.onBankChange(event.target.value)}>
                  <option key='Vietcombank' value='Vietcombank'>Vietcombank</option>
                  <option key='ACB' value='ACB'>ACB</option>
                  <option key='Techcombank' value='Techcombank'>Techcombank</option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <img src={vietQrSrc} width="400px"/>
              </td>
            </tr>
            <tr className="text-center">
              <td>
                  <a href="#" onClick={(e) => {
                      e.preventDefault();
                      downloadImage(vietQrSrc); 
                    }}>
                    Download QR Coce
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <br/>
          <br/>
          <p>
            Chúng tôi đã gửi thông tin đơn hàng vào mail của quý khách. Quý khách vui lòng kiểm tra cả inbox và spam trong email để đảm bảo có thể thấy thông tin.
          </p>
          <p>
            <strong>Tadu xin cám ơn!</strong>
          </p>
        </React.Fragment>)
  }
}


function TaduAccountInnerHtml(invoice) {
  return (
    <React.Fragment>
      <br/>
      <h4><strong>Chúc mừng bạn đã thanh toán thành công đơn hàng <span style={{color: "#FF8D00"}}>{invoice.code}</span></strong></h4>
      <br/>
      <h5>với tổng tiền là: <strong style={{color: "#FF8D00"}}>{numeral(invoice.total).format("0,0")} đ</strong></h5>
      <br/>
    </React.Fragment>)
}

function CheckPaymentMethod(invoice) {
  let inner_content_html = TaduAccountInnerHtml(invoice);
  
  if(invoice.payment_type == "BANK_TRANSFER") {
    inner_content_html = <BankAccountInnerHtml invoice={invoice}/>;
  }
  return inner_content_html;
}

class SuccessOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: null
    }
  }

  componentDidMount() {
    const { invoice_code }  = this.props.match.params;
    const { emptyCart } = this.props;
    const self = this;
    axios({
      method: 'get',
      url: window.api_url + '/user/cart/payment/' + invoice_code,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          ...self.state,
          invoice: response.data.data
        })
      }
    });
    emptyCart();
  }
  render() {
    const { invoice } = this.state;
    return (
      <div className="container">
        {!invoice && <div className="row">
          <div className="col-md-12">
            <div className="card card-body text-align-center">
              <h6>Không có sản phẩm nào trong giỏ hàng của bạn!</h6>
            </div>
          </div>
        </div>}
        {invoice && <React.Fragment>
          <div className="row">
            <h1 className="page-title sub-page-title">ĐĂNG KÝ ĐƠN HÀNG THÀNH CÔNG</h1>
          </div>
          <div style={{ backgroundColor: "white"}} className="card">
            <div style={{margin:"20px"}}>
              {CheckPaymentMethod(invoice)}
            </div>
          </div>
        </React.Fragment>}
      </div>
    )
  }
}

async function downloadImage(imageSrc) {
  const image = await fetch(imageSrc)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.download = 'qrcode'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const mapState = state => ({
    cart: state.cart,
    login: state.app.login,
    currentEmail: state.app.currentEmail
  });
  
  const mapDispatch = (dispatch) => ({
    loadCart: () => dispatch.cart.loadCart(),
    emptyCart:() => dispatch.cart.emptyCart()
  });
  
export default connect(mapState,mapDispatch)(withRouter(SuccessOrderPage));