import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

var _ = require('lodash');
var numeral = require('numeral');

const mapState = state => ({
  cart: state.cart,
  login: state.app.login,
  currentEmail: state.app.currentEmail
});

const mapDispatch = (dispatch) => ({
  loadCart: () => dispatch.cart.loadCart(),
  emptyCart:() => dispatch.cart.emptyCart()
});

function VnpayInnerHtml(result, error_message) {
  let inner_html = (
    <React.Fragment>
      <h4 style={{padding: "15px", color: "green"}}>{result.Message}</h4>
    </React.Fragment>)

    // Code 24 đơn hàng bị huỷ.
    if(result && result.RspCode == 24) {
      inner_html = (
        <React.Fragment>
          <h4 style={{padding: "15px"}} ><strong style={{ color: 'grey' }}> {result.Message} </strong></h4>
        </React.Fragment>)
    } else if(error_message) {
      inner_html = (
        <React.Fragment>
          <h4 style={{padding: "15px"}} >{error_message.Message}</h4>
        </React.Fragment>)
    }
	// Code 0 hoá đơn thanh toán thành công.
	if(!error_message && result.code.startsWith('VNPAY') && result.RspCode == 0) {
		inner_html = (
			<h4> 
				Nạp tiền thành công vào tài khoản TADU thông qua hoá đơn <strong className="price">{result.code}</strong> được thanh toán bằng Phương Thức VNPAY 
				<br/> 
				<Link to={"/user/billing/primary"}> <i className="icon-arrow-right5"></i> Quay về tài khoản chính  </Link>
			</h4>
		)
	}
    // Code 24 đơn hàng bị huỷ.
  if(!error_message && result.code.startsWith('VNPAY') && result.RspCode == 24) {
    inner_html = (
      <h4> 
        Hoá đơn <strong style={{ color: 'grey' }}>{result.code}</strong> nạp tiền vào tài khoản thông qua Phương Thức VNPAY đã bị huỷ hoặc hết hạn. 
        <br/> 
        <Link to={"/user/billing/primary"}> <i className="icon-arrow-right5"></i> Quay về tài khoản chính  </Link>
      </h4>
    )
  }
  
  
    return inner_html
}


class VNPayReturnPage extends React.Component {
    constructor(props) {
      super(props);
      this.state={
        result: {},
        error_message: null,
        isLoading: true,
      }
    }

  componentDidMount() {
      const { emptyCart } = this.props;
      const params = Object.fromEntries(new URLSearchParams(this.props.location.search));
      const self = this;
      axios({
          method: 'post',
          url: window.api_url + '/user/vnpay-return',
          data: params,
          withCredentials: true
        }).then(function (response) {
        if(!response.data.isError) {
          self.setState({
            result: response.data.data,
            isLoading: false
          })
        }else {
          self.setState({
            error_message: response.data.error,
            isLoading: false
          })
        }
      });
      emptyCart();
    }
    render() {
      const { result, error_message ,isLoading } = this.state;
      return (
      <div className="container">
        <React.Fragment>
          <div className="row">
            <h1 className="page-title sub-page-title">TRẠNG THÁI THANH TOÁN</h1>
          </div>
            {!isLoading && <div className="card card-body" style={{margin:"20px"}}>
                {VnpayInnerHtml(result, error_message)}
            </div>}
          {isLoading &&<img width="30" src="/assets/img/squares.gif" />}
          {!result && !isLoading && <div className="card" style={{ backgroundColor: "white"}}>
            <span style={{margin:"20px"}}>Không tìm thấy đơn hàng này !.</span>
          </div>}
        </React.Fragment>
      </div>
      )
    }
}

export default connect(mapState,mapDispatch)(withRouter(VNPayReturnPage));