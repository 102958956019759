const domainExts = [
    ".com", ".net", ".info", ".org", ".vn", ".com.vn", ".net.vn"
];

const genericDomainExts =
    [   
		".biz.vn",".org.vn",".gov.vn",
        ".edu.vn",".com.co",".pro.vn",
        ".info.vn",".int.vn",".ac.vn",
        ".health.vn",".net.co",".name.vn",
        ".nom.co",".photography",".link",
        ".technology",".us",".photos",
        ".name",".pw",".biz",".futbol",
        ".equipment",".xyz",".gallery",
        ".pictures",".graphics",".rocks",
        ".lighting",".audio",".juegos",
        ".directory",".bz",".today",
        ".ws",".tips",".tv",".company",
        ".me",".systems",".agency",
        ".mobi",".management",".report",
        ".center",".supply",".support",
        ".supplies",".exposed",".email",
        ".ninja",".solutions",".ca",
        ".education",".institute",".international",
        ".schule",".gratis",".website",
        ".city",".reisen",".desi",
        ".hiphop",".network",".bargains",
        ".photo",".gift",".webcam",
        ".trade",".vacations",".works",
        ".guru",".land",".bike",
        ".clothing",".plumbing",".singles",
        ".camera",".estate",".contractors",
        ".construction",".kitchen",".enterprises",
        ".tattoo",".shoes",".sexy",
        ".domains",".academy",".computer",
        ".training",".builders",".coffee",
        ".cash",".digital",".beer",
        ".associates",".media",".toys",
        ".country",".cheap",".zone",
        ".boutique",".farm",".cc",
        ".services",".vision",".fish",
        ".tools",".parts",".industries",
        ".pub",".community",".cleaning",
        ".catering",".cards",".marketing",
        ".guitars",".bid",".events",
        ".productions",".democrat",".foundation",
        ".properties",".social",".rentals",
        ".immobilien",".watch",".cool",
        ".repair",".camp",".glass",
        ".florist",".house",".solar",
        ".exchange",".gripe",".moda",
        ".discount",".fitness",".church",
        ".life",".guide",".surf",
        ".direct",".place",".deals",
        ".kaufen",".consulting",".town",
        ".nyc",".fail",".limited",
        ".wtf",".care",".cooking",
        ".fishing",".horse",".rodeo",
        ".vodka",".republican",".vet",
        ".soy",".pink",".blue",".red",
        ".de",".viajes",".limo",
        ".codes",".partners",".holiday",
        ".condos",".maison",".tienda",
        ".flights",".cruises",".villas",
        ".expert",".holdings",".ventures",
        ".diamonds",".voyage",".recipes",
        ".careers",".dating",".capital",
        ".engineering",".tax",".fund",
        ".furniture",".claims",".finance",
        ".insure",".bayern",".lease",
        ".university",".financial",
        ".clinic",".dental",".surgery",
        ".ink",".wiki",".christmas",
        ".blackfriday",".rest",".menu",
        ".actor",".haus",".buzz",
        ".kiwi",".pics",".reviews",
        ".dance",".asia",".tel",
        ".pro",".in",".co",".tw",
        ".net.tw",".org.tw",".xxx",
        ".jp",".club",".nagoya",".tokyo",
        ".yokohama",".build",".bar",
        ".global",".press",".cn",
        ".com.cn",".sg",".com.sg",
        ".investments",".credit",".accountants",
        ".host",".loans",".creditcard",
        ".scot",".london",".vegas",
        ".healthcare",".com.tw",".tk",
        ".bentre.vn",".hanoi.vn",".danang.vn",
        ".angiang.vn",".bacgiang.vn",".backan.vn",
        ".baclieu.vn",".bacninh.vn",".baria-vungtau.vn",
        ".binhdinh.vn",".binhduong.vn",".binhphuoc.vn",
        ".binhthuan.vn",".camau.vn",".cantho.vn",
        ".caobang.vn",".daklak.vn",".daknong.vn",
        ".dienbien.vn",".dongnai.vn",".dongthap.vn",
        ".gialai.vn",".hagiang.vn",".haiduong.vn",
        ".haiphong.vn",".hanam.vn",".hatinh.vn",
        ".haugiang.vn",".hoabinh.vn",".hungyen.vn",
        ".khanhhoa.vn",".kiengiang.vn",".komtum.vn",
        ".laichau.vn",".lamdong.vn",".langson.vn",
        ".laocai.vn",".longan.vn",".namdinh.vn",
        ".nghean.vn",".ninhbinh.vn",".ninhthuan.vn",
        ".phutho.vn",".phuyen.vn",".quangbinh.vn",
        ".quangnam.vn",".quangngai.vn",".quangninh.vn",
        ".quangtri.vn",".soctrang.vn",".sonla.vn",
        ".tayninh.vn",".thaibinh.vn",".thainguyen.vn",
        ".thanhhoa.vn",".thanhphohochiminh.vn",".thuathienhue.vn",
        ".tiengiang.vn",".travinh.vn",".tuyenquang.vn",
        ".vinhlong.vn",".vinhphuc.vn",".yenbai.vn", ".kontum.vn"
    ]
const notAllowExts = [
        ".gov.vn"
    ]
// Use to check province domain.
const province_exts = [
        "Domain .bentre.vn","Domain .hanoi.vn","Domain .danang.vn",
        "Domain .angiang.vn","Domain .bacgiang.vn","Domain .backan.vn",
        "Domain .baclieu.vn","Domain .bacninh.vn","Domain .baria-vungtau.vn",
        "Domain .binhdinh.vn","Domain .binhduong.vn","Domain .binhphuoc.vn",
        "Domain .binhthuan.vn","Domain .camau.vn","Domain .cantho.vn",
        "Domain .caobang.vn","Domain .daklak.vn","Domain .daknong.vn",
        "Domain .dienbien.vn","Domain .dongnai.vn","Domain .dongthap.vn",
        "Domain .gialai.vn","Domain .hagiang.vn","Domain .haiduong.vn",
        "Domain .haiphong.vn","Domain .hanam.vn","Domain .hatinh.vn",
        "Domain .haugiang.vn","Domain .hoabinh.vn","Domain .hungyen.vn",
        "Domain .khanhhoa.vn","Domain .kiengiang.vn","Domain .komtum.vn",
        "Domain .laichau.vn","Domain .lamdong.vn","Domain .langson.vn",
        "Domain .laocai.vn","Domain .longan.vn","Domain .namdinh.vn",
        "Domain .nghean.vn","Domain .ninhbinh.vn","Domain .ninhthuan.vn",
        "Domain .phutho.vn","Domain .phuyen.vn","Domain .quangbinh.vn",
        "Domain .quangnam.vn","Domain .quangngai.vn","Domain .quangninh.vn",
        "Domain .quangtri.vn","Domain .soctrang.vn","Domain .sonla.vn",
        "Domain .tayninh.vn","Domain .thaibinh.vn","Domain .thainguyen.vn",
        "Domain .thanhhoa.vn","Domain .thanhphohochiminh.vn","Domain .thuathienhue.vn",
        "Domain .tiengiang.vn","Domain .travinh.vn","Domain .tuyenquang.vn",
        "Domain .vinhlong.vn","Domain .vinhphuc.vn","Domain .yenbai.vn", "Domain .kontum.vn"
]
export {
    domainExts,
    genericDomainExts,
    notAllowExts,
    province_exts
}



 