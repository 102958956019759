import axios from "axios";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AlertLabel from "../../component/AlertLabel";
import BlockUI from "../../component/BlockUI";
import InvoiceStatus from "../../component/InvoiceStatus";
import { ImageUtils } from "../../utils/javascriptUtils";
import InvoiceRequestModal from "./modals/InvoiceRequestModal";
import { InvoicePDFTemplate } from "./pdf_template/InvoicePDFTemplate";

var numeral = require("numeral");
var _ = require('lodash');

function EnumTranslate(str) {
  switch(str) {
    case "BANK_TRANSFER": {
      return "Đã thanh toán và chuyển khoản";
    }
    case "TADU_ACCOUNT": {
      return "Thanh toán bằng tài khoản chính";
    }
    case "REWARD_ACCOUNT": {
      return "Thanh toán bằng tài khoản khuyến mãi ";
    }
    case "AGENCY": {
      return "Đại lý";
    }
    case "CUSTOMER": {
      return "Khách hàng của đại lý";
    } 
  }
}

function UserProductInfo(user_product) {
  return (
    <React.Fragment>
      <div className="text-muted">
        <p>Code: {user_product.id}</p>
        <p>IP: {user_product.ip_address}</p>
      </div>
    </React.Fragment>
  )
}

function CheckItemRegisStatus(item) {
    let item_status = (
      <span className="badge badge-flat border-success text-success-600">
        Đăng ký mới
      </span>
    )
    if(item.is_renew) {
      item_status = (
        <span className="badge badge-flat border-primary text-primary-600">
          Gia Hạn
        </span>)
    }else if(item.is_upgrade) {
      item_status = (
        <span className="badge badge-flat border-info text-info-600">
          Nâng Cấp
        </span>)
    } else if (item.is_tranfer) {
      item_status = (
        <span className="badge badge-flat border-danger text-danger-600">
          Chuyển về TADU
        </span>)
    }
    return item_status;
  }

function VATRequestBtn(is_vat_bill, onShowInvoiceRequestModal) {
  return (
    <button style={{marginRight:10}} className="btn bg-purple" onClick={()=>onShowInvoiceRequestModal()}> 
      {!is_vat_bill ? "Yêu cầu xuất hóa đơn" : "Sửa thông tin công ty"}
    </button>
  )
}

function CancelBtn(invoice, handleCancelInvoice) {
  return (
    <a href="#" style={{marginRight:10}} href="#" onClick={()=>handleCancelInvoice(invoice.id)} type="button" className="btn btn-danger">
      Hủy đơn hàng{" "}<i className="icon-cross2"></i>
    </a>
  )
}

function TaduAccountPaidBtn(invoice, onInvoicePayment) {
  
  return (
    <React.Fragment>
      <a onClick={()=>onInvoicePayment(invoice, "TADU_ACCOUNT")} className="dropdown-item">
        {" "}<i className="icon-coin-dollar"></i>Thanh toán bằng tài khoản chính Tadu
      </a>
      <a onClick={()=>onInvoicePayment(invoice, "REWARD_ACCOUNT")} className="dropdown-item">
        {" "}<i className="icon-coin-dollar"></i>Thanh toán bằng tài khoản KM Tadu
      </a>
    </React.Fragment>
  )
}
function VNPayPaidBtn(invoice, onInvoicePayment) {
  return (
    <a onClick={()=>onInvoicePayment(invoice, "VNPAY")} className="dropdown-item">
      {" "}<i className="icon-coin-dollar"></i>Thanh toán qua cổng VNPAY
    </a>
  )
}
function MomoPaidBtn(invoice, onInvoicePayment) {
  return (
    <a onClick={()=>onInvoicePayment(invoice, "MOMO_TRANSFER")} className="dropdown-item">
      {" "}<i className="icon-coin-dollar"></i>Thanh toán qua cổng MOMO
    </a>
  )
}
function PaypalPaidBtn(invoice, onInvoicePayment) {
  return (
    <a onClick={()=>onInvoicePayment(invoice, "PAYPAL")} className="dropdown-item">
      {" "}<i className="icon-coin-dollar"></i>Thanh toán bằng PAYPAL
    </a>
  )
}

function FooterBtnGroup(data, onShowInvoiceRequestModal, handleCancelInvoice, onInvoicePayment) {
  let is_vat_bill = null;
  let vat_status = null;
  if(data.invoice) { // AgencyInvoice
    is_vat_bill = data.invoice.is_vat_bill
    vat_status = data.invoice.vat_status
  } else {
    is_vat_bill = data.is_vat_bill
    vat_status = data.vat_status
  }
  const footer_group = (
    <div className="row">
      <div className="col-sm-12">
        {/* Phương thức thanh toán */}
        {["NEW","UNPAID", "TRIAL"].includes(data.invoice_status) && data.total > 0 && <div style={{margin:10}}  className="btn-group">
          <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            Chọn phương thức thanh toán 
          </button>
            <div className="dropdown-menu" x-placement="bottom-start">
            {TaduAccountPaidBtn(data, onInvoicePayment)}
            </div>
        </div>}
        {/* Huỷ Đơn Hàng */}
        {["NEW","UNPAID"].includes(data.invoice_status) && CancelBtn(data, handleCancelInvoice)}
        {/* Yêu Cầu Xuất Hoá Đơn */}
        {data.invoice_status != "CANCEL" && vat_status != "DFINISH" && VATRequestBtn(is_vat_bill, onShowInvoiceRequestModal)}
      </div>
    </div>
  )
  return footer_group;
}

function ProductTypeLabel(item, templates, invoice_list) {
  let product_type_label = <span>{item.product.name} <strong>{item.domain}</strong></span>
  const vps_label =  _.find(templates, (i) => i.id == item.template_id)   
  const has_who_is_privacy = _.find(invoice_list,(i) => i.parent_id == item.group_id)
  const ip_addon_items = _.filter(invoice_list,(i) => i.product.product_type =="IP" && i.parent_id == item.group_id)
  if(["VPS", "CLOUD_SERVER_PRO"].includes(item.product.product_type)) {
    product_type_label = (
      <span>
        {item.product.name} 
        <br/>
        {vps_label && <strong>{vps_label.name} {vps_label.version}<br/></strong>}
        {item.user_product && UserProductInfo(item.user_product)}
        {ip_addon_items.length > 0 && <span className="text-orange">Tổng số IP: {ip_addon_items.length}</span>}
        <br/>
        {/* {ip_addon_items && <span className="text-orange">{(_.map(ip_addon_items,(i)=><span>{i.user_product && i.user_product.ip_address}<br/></span>))}</span>} */}
      </span>
    )
  }
  if(item.product.product_type == "CLOUD_BACKUP") {
    product_type_label = (
      <span>
        {item.product.name} 
      </span>
    )
  }
  if(["LINUX_HOSTING", "WINDOW_HOSTING"].includes(item.product.product_type)) {
    product_type_label = (
      <span>
        {item.product.product_type} {item.product.name} 
        <br/>
        <strong>{item.domain}<br/></strong>
        {ip_addon_items > 0 &&  <span className="text-orange"> {ip_addon_items}</span>}
      </span>
    )
  }
  if(["COM_DOMAIN", "VN_DOMAIN"].includes(item.product.product_type)) {
      product_type_label = (
        <span>
          {item.product.name} <strong>#{item.domain}</strong>
          <br/>
          {has_who_is_privacy && <span className="text-orange">+ Whois privacy</span>}
        </span>
      ) 
    }
  if(item.product.product_type == "IP") {
    product_type_label = (
      <span>
        {item.product.product_type}
        <br/>
        {item.user_product && ["VPS", "CLOUD_SERVER_PRO"].includes(item.user_product.product.product_type) && <strong> VPS # {item.user_product.ip_address}</strong>}
        {item.user_product && ["WINDOW_HOSTING", "LINUX_HOSTING"].includes(item.user_product.product.product_type) && <strong> Hosting # {item.domain}</strong>}
        <br/>
        <small className="text-muted"> Số lượng: {item.quantity} IP</small>
      </span>
    ) 
  }
  return product_type_label;
}

function AddonPriceLabel(item, invoice_list) {
  const has_who_is_privacy = _.find(invoice_list,(i) => i.parent_id == item.group_id)
  const ip_addon_items = _.filter(invoice_list,(i) => i.product.product_type =="IP" && i.parent_id == item.group_id)
  const ip_addon_sum = _.sumBy(ip_addon_items, (i) => i.price);
  let inner_addon_price_html = null;
  if(has_who_is_privacy) {
    inner_addon_price_html = (
      <span className="text-orange">+ {numeral(has_who_is_privacy.price).format("0,0")} đ</span>
    )
  }
  if (ip_addon_items.length > 0) {
    inner_addon_price_html = (
      <span className="text-orange">+ {numeral(ip_addon_sum).format("0,0")} đ</span>
    )
  }
  return inner_addon_price_html;
}

function InvoiceItemRows(invoice_items, templates) {
  const item_invoice_rows = _.map(invoice_items, function (i, key) {
  const total_price = i.price_is_year || i.is_upgrade ? i.price : i.price * i.month;
  return (
    !i.parent_id && <tr key={key} style={{borderBottom: "1px solid #ddd"}}>
      <td>
        {CheckItemRegisStatus(i)}
      </td>
      <td>
        <h6 className="no-margin">
          {ProductTypeLabel(i, templates, invoice_items)}
          <br/>
          {i.domain_profile_id && <Link to={"/user/domain/profile/detail/" + i.domain_profile_id}>[Bản khai]</Link>}
        </h6>
      </td>
      <td>
        {i.day > 0 && i.day + " Ngày " }{i.month} Tháng
        {/* Hiển thị tháng cộng thêm nếu có */}
        <br/>
        <small className="text-orange">{i.extra_month > 0 && "Tặng thêm: " + i.extra_month + " Tháng"}</small>
      </td>
      <td style={{textAlign: "right"}}>
        <span className="text-semibold">{numeral(total_price).format("0,0")} đ</span>
        <br/>
        {AddonPriceLabel(i, invoice_items)}
      </td>
    </tr>
    )
  })
  return item_invoice_rows;
}

function VATRequestInfo(data, is_agency_invoice, user_role) {
  // nếu là đơn hàng HP thông tin xuất hoá đơn sẽ nằm ở table invoice
  data = is_agency_invoice ? data.invoice : data;
  const inner_vat_info = (
  data.is_vat_bill && <div className="card-footer">
    <div className="col-md-12" style={{textAlign:"left"}}>
      <strong>Thông tin xuất hóa đơn:</strong>
      <br/>
        <strong>Tên công ty: </strong>
        {data.vat_company_name}
      <br/>
        <strong>Địa chỉ công ty: </strong>
        {data.vat_company_address}
      <br/>
        <strong>Mã số thuế: </strong>
        {data.vat_mst}
      <br/>
        <strong>Email nhận hóa đơn: </strong>
        {data.express_address}
      <br />
      {user_role == "AGENCY" &&
        <div>
          <strong>Xuất hoá đơn theo giá: </strong>
          {EnumTranslate(data.vat_type)}
          <br/>
        </div>
      }
      <strong>Thanh toán VAT: </strong>
      {EnumTranslate(data.vat_bill_type)}
    </div>
  </div>  
  )
  return inner_vat_info;
}

class InvoiceDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice_result: null,
      templates: [],
      is_show_invoice_request_modal: false,
      alert_label: null,
      logo_tadu: null,
      isLoading: false
    };
  }

  reload = () => {
    const { invoice_id } = this.props.match.params;
    const { is_agency_invoice } = this.props;
    let url = "/user/invoice/detail/";
    if (is_agency_invoice) {
      url = "/user/invoice/agency/detail/"
    }
    const self = this;
  
    axios({
      method: 'get',
      url: window.api_url + url + invoice_id,
      withCredentials: true
    }).then(function (response) {
      self.setState({
      ...self.state,
      invoice_result: response.data.data,
      })
    });

    axios({
      method: 'get',
      url: window.api_url + '/product/vps/template',
      withCredentials: true
    }).then(function (response) {
      self.setState({
      ...self.state,
      templates: response.data.data
      })
    });
  }
  
  onShowAlertMessage = (status, message) => {
    this.setState({
      alert_label: {status: status, message: message}
    })
  }

  onShowInvoiceRequestModal = () => {
    this.setState({
      is_show_invoice_request_modal: true
    })
  }

  onHideInvoiceRequestModal = () => {
    this.setState({
      is_show_invoice_request_modal: false
    })
    this.reload();
  } 
  
  handleCancelInvoice = (invoice_id) => {
    const self = this;
    axios({
      method: 'post',
      url: window.api_url + '/user/invoice_cancel',
      withCredentials: true,
      data: {
      invoice_id: invoice_id
      }
    }).then(function (response) {
      if(!response.data.isError) {
      self.onShowAlertMessage("SUCCESS", "Huỷ đơn hàng thành công.");
      self.reload();
      } else {
        self.onShowAlertMessage("ERROR", "Hủy đơn hàng thất bại.");
      }
    });
  }
  // Thanh toán bằng tài khoản TADU
  taduAccountPayment = (invoice_data) => {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/invoice/tadu_account/payment',
      data: invoice_data,
      withCredentials: true
    }).then(function (response) {
      self.setState({
        isLoading: false
      })
      if(!response.data.isError) {
        self.onShowAlertMessage("SUCCESS", "Thanh toán đơn hàng thành công.");
        self.reload();
      } else {
        self.onShowAlertMessage("ERROR", response.data.error);
      }
    });
  }

  // Thanh toán bằng tài khoản TADU
  rewardAccountPayment = (invoice_data) => {
    const self = this;
    const is_vat = invoice_data.code.startsWith("TADU-") ? " và KHÔNG tính VAT" : ".";
    const alert_confirm = window.confirm("Thanh toán bằng tài khoản khuyến mãi sẽ KHÔNG áp dụng bất kỳ chương trình khuyến mãi nào" + is_vat);
    if(alert_confirm) {
      return axios({
        method: 'post',
        url: window.api_url + '/user/invoice/reward_account/payment',
        data: invoice_data,
        withCredentials: true
      }).then(function (response) {
        self.setState({
          isLoading: false
        })
        if(!response.data.isError) {
          self.onShowAlertMessage("SUCCESS", "Thanh toán đơn hàng thành công.");
          self.reload();
        } else {
          self.onShowAlertMessage("ERROR", response.data.error);
        }
      });
    }else {
      this.setState({
        isLoading: false
      })
    }
  }
  // Thanh toán bằng tài khoản VNPAY
  vnpayAccountPayment = (invoice_data) => {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/invoice/vnpay_account/payment',
      data: invoice_data,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        window.location.href = response.data.subData
      }
    });
  }
  // Thanh toán bằng tài khoản MOMO
  momoAccountPayment = (invoice_data) => {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/invoice/momo_account/payment',
      data: invoice_data,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        window.location.href = response.data.subData.payUrl
      }
    });
  }
  // Thanh toán bằng tài khoản PAYPAL
  paypalAccountPayment = (invoice_data) => {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/invoice/paypal_account/payment',
      data: invoice_data,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        const redirect_url = response.data && _.find(response.data.subData.links, (i) => i.method === "REDIRECT");
        window.location.href = redirect_url.href
      }
    });
  }

  onInvoicePayment = (invoice, payment_method) => {
    this.setState({
      isLoading: true
    })
    if(payment_method == "TADU_ACCOUNT") {
      this.taduAccountPayment(invoice);     
    }
    if(payment_method == "REWARD_ACCOUNT") {
      this.rewardAccountPayment(invoice);     
    }
    if(payment_method == "VNPAY") {
      this.vnpayAccountPayment(invoice);
    }
    if(payment_method == "MOMO_TRANSFER") {
      this.momoAccountPayment(invoice);
    }
    if(payment_method == "PAYPAL") {
      this.paypalAccountPayment(invoice);
    }
  }

  exportPdf(invoice, templates) {
    const {logo_tadu} = this.state;
    const data_export = InvoicePDFTemplate.getDataExport(invoice, logo_tadu, templates)
    if (data_export) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(data_export).download('don-hang-'+ invoice.code);
    }   
  }
  
  componentDidMount() {
    const self = this;
    this.reload(); 
    ImageUtils.getBase64Image("/assets/img/lglogo.png", function(callback)	{
      self.setState({logo_tadu: callback}); 
    });
  }
  
  render() {
    const {invoice_result, templates, alert_label, is_show_invoice_request_modal, isLoading } = this.state;
    const { is_agency_invoice, userProfile } = this.props;
    const qr = invoice_result && 'https://img.vietqr.io/image/970436-1014480066-compact.jpg?amount=' + invoice_result.total + '&addInfo=' + invoice_result.code + "&accountName=Tran%20Thi%20Huynh%20Trang";
    return (
      <React.Fragment>
        {isLoading && <BlockUI />}
        <div className="page-header">
          <h4>
            <i className="icon-paragraph-justify2 position-left"></i>
            <span className="text-semibold"> Chi Tiết Đơn Hàng</span>
          </h4>
        </div>
        {invoice_result && <div className="card">
          <div style={{ borderBottom: "1px solid", borderBottomColor: "#ddd"}}>
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-6">
                  <h6 style={{margin:10}} className="panel-title">Hóa đơn</h6>
                </div>
                <div className="col-sm-6">
                  <button style={{float:"right"}} type="button" onClick={()=> this.exportPdf(invoice_result, templates)} className="btn btn-default btn-xs">
                    <h6 style={{margin:5}} className="panel-title"><i className="icon-printer position-left"></i> In</h6>
                  </button>
                </div>
              </div> 
            </div>
          </div>
          <div ref="exportDocument">
            <div className="card-body">
              {alert_label && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
              <div className="row">
                <div className="col-sm-6 content-group">
                  <span className="text-muted">Thông tin khách hàng:</span>
                  <ul className="list-condensed list-unstyled">
                    <li>
                      <h5>{invoice_result.user.full_name}</h5>
                    </li>
                    <li>{invoice_result.user.address}</li>
                    <li>{invoice_result.user.ward}</li>
                    <li>{invoice_result.user.city}</li>
                    <li>{invoice_result.user.nation}</li>
                    <li>{invoice_result.user.phone}</li>
                    <li>
                      <a href="#">{invoice_result.user.email}</a>
                    </li>
                  </ul>
                </div>
                <div className="col-sm-6 content-group" style={{width: "auto"}}>
                  <div style={{ float: "right" }}>
                    <span className="text-muted">Thông tin đơn hàng:</span>
                    <ul className="list-condensed list-unstyled">
                      <li>
                        <h5 className="text-uppercase text-semibold"># <strong>{invoice_result.code}</strong></h5>
                      </li>
                      <li>
                        <strong>Ngày đăng ký:</strong>{" "}
                        <span className="text-semibold">{invoice_result.created_date}</span>
                      </li>
                      {invoice_result.payment_date && <li>
                        <strong>Ngày thanh toán:</strong>{" "}
                        <span className="text-semibold">{invoice_result.payment_date}</span>
                      </li>}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-9 content-group"></div>
                <div className="col-md-6 col-lg-3">
                  <span className="text-muted">Thông Tin Thanh Toán:</span>
                  <ul className="list-condensed list-unstyled">
                    <li>
                      <h5>
                        Tổng Tiền:{" "}
                        <span style={{float:"right"}} className="text-right text-semibold">
                          {numeral(invoice_result.total).format("0,0")} đ
                        </span>
                      </h5>
                    </li>
                    <li>
                      Trạng thái:
                      <span className="text-semibold">
                        <span style={{float:"right"}} className="label label-info">{InvoiceStatus(invoice_result.invoice_status)}</span>
                      </span>
                    </li>
                    <li>
                      Hình thức thanh toán: <span style={{float:"right"}}>{invoice_result.payment_type}</span>
                    </li>
                  </ul>
                </div>
              </div>
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    <h6>Ghi chú đơn hàng: {invoice_result.note}</h6>
                    <p className="text-muted"></p>
                  </div>
                </div>
            </div>
            <div className="table-responsive">
              <table className="table table-lg">
                <thead>
                  <tr style={{backgroundColor: "#007DC6",color: "#fff"}}>
                    <th style={{ width: "150px" }} >Loại</th>
                    <th style={{ width: "350px" }} >Dịch vụ</th>
                    <th style={{ width: "140px" }} >Tháng</th>
                    <th style={{ width: "200px", textAlign: "right" }}> Thành tiền </th>
                  </tr>
                </thead>
                <tbody>
                  {InvoiceItemRows(invoice_result.invoice_items, templates)}
                </tbody>
              </table>
            </div>
            <div className="card-body">
              <div className="row invoice-payment">
                <div className="col-sm-7 text-center">
                  {invoice_result && (invoice_result.invoice_status == 'NEW' || invoice_result.invoice_status == 'TRIAL') && <div>
                    <img src={qr} width="400px"/>
                    <br/>
                    <a href="#" onClick={(e) => {
                      e.preventDefault();
                      downloadImage(qr); 
                    }}>
                      Download QR Coce
                    </a>
                  </div>}
                </div>
                <div className="col-sm-5">
                  <br/>
                  <div className="content-group">
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr style={{borderTop: "1px solid #ddd"}}>
                            <th>Tổng tiền:</th>
                            <td className="text-right">{numeral(invoice_result.sub_total). format("0,0")} đ</td>
                          </tr>
                          <tr>
                            <th>Giảm giá:</th>
                            <td className="text-right">{numeral(invoice_result.discount_money).format("0,0")} đ</td>
                          </tr>
                          <tr>
                            <th>Còn lại:</th>
                            <td className="text-right">{numeral(invoice_result.sub_total - invoice_result.discount_money).format("0,0")} đ</td>
                          </tr>
                          <tr>
                            <th>
                              VAT: <span className="text-regular">(10%)</span>
                            </th>
                            <td className="text-right">{numeral(invoice_result.tax_money).format("0,0")} đ</td>
                          </tr>
                          <tr>
                            <th>Thành tiền:</th>
                            <td className="text-right text-primary">
                              <h5 className="text-semibold">{numeral(invoice_result.total).format("0,0")} đ</h5>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{textAlign:"right"}} className="card-footer">
            <div className="row">
              <div className="col-sm-12">
                {invoice_result && FooterBtnGroup(
                  invoice_result,
                  this.onShowInvoiceRequestModal,
                  this.handleCancelInvoice,
                  this.onInvoicePayment
                )}
                </div>
              </div>
            </div>
            {invoice_result && userProfile && VATRequestInfo(invoice_result, is_agency_invoice,  userProfile.role)}
        </div>}
        {is_show_invoice_request_modal && 
          <InvoiceRequestModal 
            onShowAlertMessage={this.onShowAlertMessage} 
            title="Yêu Cầu Xuất Hoá Đơn" 
            is_agency_invoice={is_agency_invoice} 
            // Nếu là AgencyInvoice, thì lấy Invoice Preload từ AgencyInvoice
            invoiceObject={invoice_result.invoice ? invoice_result.invoice : invoice_result} 
            show={is_show_invoice_request_modal} 
            onHide={this.onHideInvoiceRequestModal} />
        }
      </React.Fragment> 
    );
  }
}

async function downloadImage(imageSrc) {
  const image = await fetch(imageSrc)
  const imageBlog = await image.blob()
  const imageURL = URL.createObjectURL(imageBlog)

  const link = document.createElement('a')
  link.href = imageURL
  link.download = 'qrcode'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const mapState = (state, props) => ({
  userProfile: state.userProfile.data
});

const mapDispatch = dispatch => ({
  
});

export default (connect(mapState, mapDispatch)(withRouter(InvoiceDetail)));