import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form';
import BlockUI from '../../component/BlockUI';

var _ = require('lodash');
var numeral = require('numeral');

const mapState = state => ({
  cart: state.cart,
  login: state.app.login
});

const mapDispatch = (dispatch) => ({
  loadCart: () => dispatch.cart.loadCart(),
  emptyCart: () => dispatch.cart.emptyCart()
});

function TaduAccountCheckBox(method_selected, handleCheckOnChange, user_billing_static, submit_error) {
  let inner_check_box_html = <img src="/assets/img/uncheck-icon.png" width="50" height="50" alt="uncheck-logo" /> ;
  var isCheckAvailable = true;
  if(["TADU_ACCOUNT","REWARD_ACCOUNT"].includes(method_selected)) {
    inner_check_box_html = <img src="/assets/img/check-icon.png" width="50" height="50" alt="check-logo"/>;
    isCheckAvailable = false;
  }
  const innner_box_html =
    <div onClick={()=> isCheckAvailable && handleCheckOnChange("TADU_ACCOUNT")} style={{ marginBottom:"20px" }}  className="col-md-4">
      <div className="card" style={{ backgroundColor:'white' }} >
        <br/>
          <div style={{ margin:'10px', height: "150px" }}>
            <p style={{ fontSize:'20px' }}>
              {inner_check_box_html}
              Tài khoản Tadu
            </p>
            <p style={{fontSize: 15}}>
              <label htmlFor={"TADU_ACCOUNT"}><input name="TADU_ACCOUNT" id={"TADU_ACCOUNT"} checked={method_selected == "TADU_ACCOUNT"} type="radio" onClick={()=>handleCheckOnChange("TADU_ACCOUNT")}/> Tài Khoản Chính: <strong className="text-orange">{user_billing_static && numeral(user_billing_static.primary).format("0,0")} đ</strong></label>
              <br/>
              <label htmlFor={"REWARD_ACCOUNT"}><input name="REWARD_ACCOUNT" id={"REWARD_ACCOUNT"} checked={method_selected == "REWARD_ACCOUNT"} type="radio" onClick={()=>handleCheckOnChange("REWARD_ACCOUNT")}/> Tài Khoản Khuyến Mãi: <strong className="text-orange">{user_billing_static &&  numeral(user_billing_static.secondary).format("0,0")} đ</strong></label>
            </p>
            {submit_error && <span className="form-text text-danger">* {submit_error}</span>}
          </div>
        <br/>
        <br/>
        <br/>
      </div>  
    </div>

  return innner_box_html;
}

function BankAccountCheckBox(method_selected, handleCheckOnChange) {
  let inner_check_box_html = <img src="/assets/img/uncheck-icon.png" width="50" height="50" alt="uncheck-logo" />;
  var isCheckAvailable = true;
  if(method_selected === "BANK_TRANSFER") {
    inner_check_box_html = <img src="/assets/img/check-icon.png" width="50" height="50" alt="check-logo"/>;
    isCheckAvailable = false;
  }
  const innner_box_html =
    <div onClick={()=> isCheckAvailable && handleCheckOnChange("BANK_TRANSFER")} style={{ marginBottom:"20px" }}  className="col-md-4">
      <div className="card" style={{ backgroundColor:'white' }} >
        <br/>
        <div style={{ margin:'10px', height: "150px" }}>
            <p style={{ fontSize:'20px' }}>
              {inner_check_box_html}
              QR Code <img src="https://www.vietqr.io/en/img/vietqr%20api%20-%20payment%20kit.png" width="70" height="20"/>
            </p>
            <br/>
            <p>
              <a target="_blank" href="https://trogiup.tadu.vn/huong-dan-thanh-toan" rel="noopener noreferrer" style={{ color:'blue' }}> Danh sách tài khoản ngân hàng</a>
            </p>
            <p> 
              Thanh toán bằng QR code hộ trợ tất cả các app ngân hàng
            </p>
            <strong style={{color: '#43A047'}}>
              Miễn phí - thanh toán và kích hoạt dịch vụ ngay lập tức
            </strong>
        </div>
        <br/>
        <br/>
        <br/>
      </div>  
    </div>
  return innner_box_html;
}

function MomoCheckBox(method_selected, handleCheckOnChange) {
  let inner_check_box_html = <img src="/assets/img/uncheck-icon.png"  width="50" height="50" alt="uncheck-logo" /> ;
  var isCheckAvailable = true;
  if(method_selected === "MOMO_TRANSFER") {
    inner_check_box_html = <img src="/assets/img/check-icon.png" width="50" height="50" alt="check-logo"/>;
    isCheckAvailable = false;
  }
  const innner_box_html =
    <div onClick={()=> isCheckAvailable && handleCheckOnChange("MOMO_TRANSFER")} style={{ marginBottom:"20px" }}  className="col-md-4">
      <div className="card" style={{ backgroundColor:'white' }} >
        <br/>
          <div style={{ margin:'10px', height: "150px" }}>
            <p style={{ fontSize:'20px' }}>
              {inner_check_box_html}
              Thanh Toán Bằng <img src="/assets/img/logo-momo.jpeg" width="50" height="50" />
            </p>
            <p> 
              * Bạn Cần Có: Mật khẩu đăng nhập ví MoMo.
            </p>
            <p> 
              Số điện thoại đăng kí với MoMo vẫn đang hoạt động để nhận mã OTP.
            </p>
            <p>
              Đã cài đặt ứng dụng MoMo trên điện thoại
            </p>
            <strong style={{color: '#43A047'}}>
              Miễn phí - thanh toán và kích hoạt dịch vụ nhanh
            </strong>
          </div>
        <br/>
        <br/>
        <br/>
      </div>  
    </div>
  return innner_box_html;
}

function PaypalCheckBox(method_selected, handleCheckOnChange) {
  let inner_check_box_html = <img src="/assets/img/uncheck-icon.png" width="50" height="50"  alt="uncheck-logo" /> ;
  var isCheckAvailable = true;
  if(method_selected === "PAYPAL") {
    inner_check_box_html = <img src="/assets/img/check-icon.png" width="50" height="50" alt="check-logo" />;
    isCheckAvailable = false;
  }
  const innner_box_html =
    <div onClick={()=> isCheckAvailable && handleCheckOnChange("PAYPAL")} style={{ marginBottom:"20px" }}  className="col-md-4">
      <div className="card" style={{ backgroundColor:'white' }} >
        <br/>
          <div style={{ margin:'10px', height: "150px" }}>
            <p style={{ fontSize:'20px' }}>
              {inner_check_box_html}
              Paypal / Thẻ Visa 
            </p>
            <p> 
              Thanh toán bằng tài khoản Paypal
            </p>
            <p> 
              Thanh toán bằng thẻ Visa, Credit, Master
            </p>
            <strong style={{color: '#43A047'}}>
              Miễn phí - thanh toán và kích hoạt dịch vụ nhanh
            </strong>
          </div>
        <br/>
        <br/>
        <br/>
      </div>  
    </div>
  return innner_box_html;
}

function VNPAYCheckBox(method_selected, handleCheckOnChange) {
  let inner_check_box_html = <img src="/assets/img/uncheck-icon.png"  width="50" height="50" alt="uncheck-logo" /> ;
  var isCheckAvailable = true;
  if(method_selected === "VNPAY") {
    inner_check_box_html = <img src="/assets/img/check-icon.png" width="50" height="50" alt="check-logo"/>;
    isCheckAvailable = false;
  }
  const innner_box_html =
    <div onClick={()=> isCheckAvailable && handleCheckOnChange("VNPAY")} style={{ marginBottom:"20px" }}  className="col-md-4">
      <div className="card" style={{ backgroundColor:'white' }} >
        <br/>
          <div style={{ margin:'10px', height: "150px" }}>
            <p style={{ fontSize:'20px' }}>
              {inner_check_box_html}
              Thanh Toán Qua Thẻ ATM và tài khoản ngân hàng 
            </p>
            <div className="bank-type">
              <div style={{marginRight:5, float: 'left'}}>
                <img style={{paddingBottom: 9}} src="/assets/img/vietcombank-vector-logo.png" width="80" height="30"/>
              </div>
              <div style={{marginRight:5, float: 'left'}}>
                <img src="/assets/img/ACB_Logo.png" width="75" height="18"/>
              </div> 
              <div style={{marginRight:5, float: 'left'}}>
                <img src="/assets/img/sacombank-logo.png" width="80" height="18"/>
              </div>
              <div style={{marginRight:5, float: 'left'}}>
                <img src="/assets/img/Logo-VNPAYQR.png" width="70" height="20"/>
              </div>
            </div>
            <br/>
            <br/>
            <p> 
              Sử dụng ứng dụng Mobile Banking quét mã VNPAY-QR.
            </p>
            <p> 
              Thẻ ATM và tài khoản ngân hàng
            </p>
            <strong style={{color: '#43A047'}}>
              Miễn phí - thanh toán và kích hoạt dịch vụ nhanh
            </strong>
          </div>
        <br/>
        <br/>
        <br/>
      </div>  
    </div>
  return innner_box_html;
}

class PaymentMethodOptionPage extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      method_selected: "BANK_TRANSFER",
      submit_error: null,
      isLoading: false
    }
    this.handleCheckOnChange = this.handleCheckOnChange.bind(this);
    this.checkTypePayment = this.checkTypePayment.bind(this);
    this.submit = this.submit.bind(this);
  }
  
  handleCheckOnChange(method_select_value) {
    this.setState({
      method_selected: method_select_value,
      submit_error: null
    })
  }

  checkTypePayment(payment_type, response_data) {
    const { history, emptyCart } = this.props;
    if(payment_type === "BANK_TRANSFER") {
      emptyCart().then(() => {
        history.push("/products/payment-method/"+response_data.data);
      });
    }
    if(payment_type.startsWith("TADU_ACCOUNT")) {
      emptyCart().then(() => {
        history.push("/products/payment-method/"+response_data.data);
      });
    }
    if(payment_type.startsWith("REWARD_ACCOUNT")) {
      emptyCart().then(() => {
        history.push("/products/payment-method/"+response_data.data);
      });
    }
    if(payment_type === "PAYPAL") {
      // Get paypal checkout url
      const redirect_url = response_data && _.find(response_data.subData.links, (i) => i.method === "REDIRECT");
      window.location.href = redirect_url.href
    }
    if(payment_type === "MOMO_TRANSFER" && response_data.subData.errorCode === 0) {
      window.location.href = response_data.subData.payUrl
    }
    if(payment_type === "VNPAY") {
      window.location.href = response_data.subData
    }
    
  }

  submit(payment_type_selected) {
    const self = this;
    this.setState({
      isLoading: true
    })
    return axios({
      method: 'post',
      url: window.api_url + '/user/cart/payment',
      data: {
        payment_type: payment_type_selected
      },
      withCredentials: true
    }).then(function (response) {
      if(response.data.isError){
        self.setState({
          submit_error: ["TADU_ACCOUNT","REWARD_ACCOUNT"].includes(payment_type_selected) && response.data.error 
        })
        self.setState({
          isLoading: false
        })
      }else {
        self.checkTypePayment(payment_type_selected, response.data);
      }
    });
  }

  componentDidMount() {
    const {loadCart} = this.props;
    const self = this;
    loadCart();
    return axios({
      method: 'get',
      url: window.api_url + '/user/billing/static',
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError){
        self.setState({
          user_billing_static: response.data.data
        })
      }
    });
  }

  render() {
    const { handleSubmit, cart} = this.props;
    const { method_selected, user_billing_static, submit_error, isLoading } = this.state;
    return (
      <div className="container">
        {isLoading && <BlockUI/>}
        {cart.items && cart.items.length === 0 && cart.total === 0 && <div className="row">
          <div className="col-md-12">
            <div className="card card-body text-align-center">
              <h6>Không có sản phẩm nào trong giỏ hàng của bạn!</h6>
            </div>
          </div>
        </div>}
        {cart.items && cart.items.length > 0 && cart.total > 0 && <React.Fragment>
          <div className="row">
            <h1 className="page-title sub-page-title">PHƯƠNG THỨC THANH TOÁN</h1>
          </div>
          <form onSubmit={handleSubmit(this.submit)}>
            <div className="row">
              {/*{VNPAYCheckBox(method_selected, this.handleCheckOnChange)}*/}
              {/*{MomoCheckBox(method_selected, this.handleCheckOnChange)}*/}
              {BankAccountCheckBox(method_selected, this.handleCheckOnChange)}
              {TaduAccountCheckBox(method_selected, this.handleCheckOnChange, user_billing_static, submit_error)}
              {PaypalCheckBox(method_selected, this.handleCheckOnChange)}
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                  <a type="button" onClick={()=>this.submit(method_selected)} className="center btn bg-warning" id="nextBtn">Tiếp theo</a>
              </div>
            </div>
          </form> 
        </React.Fragment>}
      </div>
    )
  }
}
const PaymentMethodOptionPageWrapper = reduxForm({
  form: 'paymentMethodOptionPage'
})(PaymentMethodOptionPage);

export default connect(mapState,mapDispatch)(withRouter(PaymentMethodOptionPageWrapper));