import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { RenderInputWithDiv } from '../../../component/formInput';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';


class ChangeNameModal extends React.Component {

    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    submit(values) {
        const { onHide, userProduct, reset } = this.props;
        axios({
            method: 'post',
            url: window.api_url + '/user/service/changename',
            data: {
                user_product_id: userProduct.id,
                name: values.name
            },
            withCredentials: true
        }).then(function (response) {
           if(!response.data.isError) {
            reset();
                onHide();
           }
        })
	}
	

    handleHideModal = () => {
        const { onHide, reset } = this.props;
        reset();
        onHide();
    }

    render() {

        const { title, submitting, handleSubmit, userProduct } = this.props;
        const modalConfig = { enforceFocus: false, backdrop: 'static', show: this.props.show, bssize: "medium", onHide: this.props.onHide, submitting: this.props.submitting };
		
		return <Modal
				bsPrefix = "modal"
				{...modalConfig}
				dialogClassName="modal-open">
                	<Modal.Header closeButton>
                	    <Modal.Title id="contained-modal-title-sm">{title}</Modal.Title>
                	</Modal.Header>
                	<form onSubmit={handleSubmit(this.submit)}>
                	    <Modal.Body>
                	            <Field name="name" placeholder="Tên dịch vụ..." component={RenderInputWithDiv}></Field>
                	    </Modal.Body>
                	    <Modal.Footer>
                	        <div className="text-right">
                	            <a className="btn" onClick={this.handleHideModal}>Đóng</a>
                	            <button type="submit" className="btn bg-primary" disabled={submitting}>Cập nhật</button>
                	        </div>
                	    </Modal.Footer>
                	</form>
            </Modal>

    }
}
const mapState = (state,props) => ({
    initialValues : {
        name: props.userProduct.name
    }
});

const mapDispatch = dispatch => ({
    
});

export default (connect(mapState, mapDispatch)(
    reduxForm({
        form: 'ChangeNameModal',
        destroyOnUnmount: true,
        enableReinitialize: true,
    })(withRouter(ChangeNameModal))));
