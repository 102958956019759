import { ImageUtils } from '../../../utils/javascriptUtils';

var numeral = require("numeral");
var _ = require('lodash');

function CheckItemRegisStatus(item) {
	let item_status = "Đăng ký mới"
	if(item.is_renew) {
		item_status = "Gia Hạn"
	}else if(item.is_upgrade) {
		item_status = "Nâng Cấp"
	} else if (item.is_tranfer) {
		item_status = "Chuyển về TADU"
	}
	return item_status;
}

function InvoiceStatusCase(invoice_status) {
	switch(invoice_status) {
		case "PAID": {
			return "ĐÃ THANH TOÁN";
		}
		case "UNPAID": {
			return "CHỜ THANH TOÁN";
		}
		case "TRIAL": {
			return "DÙNG THỬ";
		}
		case "REFUND": {
			return "ĐÃ HOÀN TIỀN";
		}
		case "NEW": {
			return "MỚI";
		}
		case "CANCEL": {
			return "HỦY";
		}
	}
}

// Tạo header cho Template PDF
function InvoiceHeaders(logo) {
	return {
		columns: [
			{
				columns:[
					{
						image: logo,
						fit: [100,100],
						alignment: 'left',
						margin : [20, 0, 0 , 0],
					}
				]
			},
			{
				type: 'none',
				ul: [
					{ text: 'CÔNG TY CỔ PHẦN CÔNG NGHỆ TADU', style: 'content', bold: true },
					{ text: 'Địa chỉ: Số 37/2C/10 Đường C18, P12 Quận Tân Bình, TP.HCM', style: 'content', bold: true},
					{ text: 'Điện thoại: 18006980\n', style: 'content', bold: true },
				]
			}
		]
	}
}
// Tạo Thông tin đơn hàng cho Template PDF
function InvoiceInfo(invoice) {
	return {
		columns: [
			{
				type: 'none',
				ul: [
					{ text: 'Thông tin khách hàng:', style: 'subheader' },
					{ text: 'Họ tên:', style: 'label' }, { text: invoice.user.full_name, style: 'content' },
					{ text: 'Địa chỉ:', style: 'label' }, { text: invoice.user.address, style: 'content' },
					{ text: 'Phường/ Quận:', style: 'label' }, { text: invoice.user.ward, style: 'content' },
					{ text: 'Thành phố:', style: 'label' }, { text: invoice.user.city, style: 'content' },
					{ text: 'Quốc tịch:', style: 'label' }, { text: invoice.user.nation, style: 'content' },
					{ text: 'Điện thoại:', style: 'label' }, { text: invoice.user.phone, style: 'content' }
				]
			},
			{
				type: 'none',
				ul: [
					{ text: 'Thông tin đơn hàng:', style: 'subheader', alignment: 'left' },
					{ text: 'Mã đơn hàng: ', style: 'label', alignment: 'left' }, { text: invoice.code, style: 'content', alignment: 'left' },
					{ text: 'Ngày đăng ký: ', style: 'label', alignment: 'left' }, { text: invoice.created_date, style: 'content', alignment: 'left' },
					invoice.payment_date && [
						{ text: 'Ngày thanh toán: ', style: 'label', alignment: 'left' }, { text: invoice.payment_date, style: 'content', alignment: 'left' }
					],
				]
			},
			{
				type: 'none',
				ul: [
					{ text: 'Thông Tin Thanh Toán:', style: 'subheader' },
					{ text: 'Tổng Tiền:', style: 'label' }, { text: numeral(invoice.total).format("0,0"), style: 'content' },
					{ text: 'Trạng thái:', style: 'label' }, { text: InvoiceStatusCase(invoice.invoice_status), style: 'content' },
					{ text: 'Hình thức thanh toán: ', style: 'label' }, { text: invoice.payment_type, style: 'content' },
				]
			}
		]
	}
}
// 
function ProductTypeLabel(item, templates) {
	
	let product_type_label = {
		text: [
			{ text: item.product.name }
		]
	}
	const vps_label =  _.find(templates, (i) => i.id == item.template_id)   

	if(item.product.product_type == "VPS" && vps_label) {
		product_type_label = {
			text: [
				{ text: item.product.name + " " + vps_label.name + '\n' },
				{ text: vps_label.version, color: 'grey' },
			]
		}
	}
	
	if(item.product.product_type == ("LINUX_HOSTING" || "WINDOW_HOSTING")) {
		product_type_label = {
			text: [
				{ text: item.product.product_type + " " + item.product.name + '\n' },
				{ text: item.domain, color: 'grey' },
			]
		}
	}

	if(item.product.product_type == ("VN_DOMAIN" || "COM_DOMAIN")) {
		product_type_label = {
			text: [
				{ text: item.domain + '\n' },
				{ text: item.product.name, color: 'grey' },
			]
		}
	}
	
	return product_type_label;
}


function ServiceItemTable(invoice, templates) {
	let total_price = 0;
	let bodyRows = [
			[{text: "STT"} ,{ text: "Loại" }, { text: "Dịch vụ" }, { text: "Tháng" }, { text: "Thành tiền" }]
		]
	
	_.forEach(invoice.invoice_items, (i, key) => {
		// Tính giá dựa theo loại sản phẩm.
		// Vd: theo năm hoặc theo tháng.
		total_price = (i.price_is_year || i.is_upgrade) ? i.price : i.price * i.month;
		bodyRows.push(
			[
				{ text: key + 1 },
				{ text: CheckItemRegisStatus(i) },
				ProductTypeLabel(i, templates),
				{ text: i.month },
				{ text: numeral(total_price).format("0,0") + "đ", alignment: "right" }]
		)
	})

	return [
		{
			style: 'tableExample',
			table: {
				headerRows: 1,
				widths: ["5%","15%","40%","10%","30%",],
				body: bodyRows
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					return (rowIndex == 0) ? '#CCCCCC' : null;
				}
			}
		},
		{ text: "\n\n" },
		{
			columns: [
				{text: "\n"},
				{
					style: 'tableExample',
					table: {
						alignment: "right",
						widths: ["30%", "70%"],
						body: [
							[{ text: "Tổng tiền" }, { text: numeral(invoice.sub_total).format("0,0") + " đ" , alignment: "right"}],
							[{ text: "Giảm giá" }, { text: numeral(invoice.discount_money).format("0,0") + " đ", alignment: "right"}],
							[{ text: "Còn lại" }, { text: numeral(invoice.sub_total - invoice.discount_money).format("0,0") + " đ", alignment: "right"}],
							[{ text: "VAT (10%)" }, { text: numeral(invoice.tax_money).format("0,0") +" đ", alignment: "right"}],
							[{ text: "Thành tiền" }, { text: numeral(invoice.total).format("0,0") + " đ", alignment: "right"}]
						]
					}
				}
			], 
			
		}
	]
}

const InvoicePDFTemplate = {
	getDataExport: (invoice, logo, templates) => {
		const InvoicePDFObject = {
			pageOrientation: 'portrait',
			content: [
				InvoiceHeaders(logo),
				{ text: '\nĐơn Hàng', style: 'header', alignment: 'center' },
				InvoiceInfo(invoice),
				{text: "\nDanh sách đơn hàng\n", style: "subheader"},
				ServiceItemTable(invoice, templates),
			],
			styles: {
				header: {
					listStyleType: 'none',
					fontSize: 15,
					bold: true,
					margin: [0, 0, 0, 10]
				},
				subheader: {
					fontSize: 13,
					float: 'right',
					bold: true,
					margin: [0, 10, 0, 5]
				},
				tableExample: {
				margin: [0, 0, 0, 0]
				},
				tableHeader: {
					bold: true,
					fontSize: 11,
					color: 'black'
				},
				label: {
					fontSize: 10,
					bold: true,
					margin: [0, 10, 0, 5]
				},
				content: {
					fontSize: 9,
					float:'right'
				}
			},
			defaultStyle: {
				columnGap: 10
			},
		}
	return InvoicePDFObject;
	}	
}
export { InvoicePDFTemplate };



