

import React from 'react';

class CheckDomainGuideline extends React.Component {
    render() {
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold"> Kiểm tra tên miền</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card panel-flat">
                            <div class="card-body">
                                <p>Kiểm tra domain đã có người mua hay chưa.</p>
                                <p style={{color:"red"}}>
                                    <strong>Lưu ý: </strong>
                                    http://me.xxxx.com/ là đường dẫn thương hiệu riêng của đại lý. <a target="_blank" href="https://trogiup.tadu.cloud/thiet-lap-thuong-hieu-rieng/">Hướng dẫn cấu hình thương hiệu riêng</a>.
                                </p>
                                <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px" }}>Ứng dụng</p>
                                <p>Dùng để xây dựng tính năng check domain khi khách hàng đặt mua Share Hosting, SSL, Email Server.</p>
                                <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px" }}>Sử dụng</p>
                                <ul style={{ listStyle: "none", padding: "25px", border: "1px solid #ddd" }}>
                                    <li style={{paddingBottom: "10px"}}>
                                        <i class="icon-files-empty"></i> GET
                                        <span style={{float: "right"}}>Method</span>
                                    </li>
                                    <li style={{paddingBottom: "10px"}}>
                                        <i class="icon-link"></i> http://me.xxxx.com/rest/check-domain/<span class="label label-default">domain</span>
                                        <span style={{float: "right"}}>Link</span>
                                    </li>
                                    <li>
                                        <i class="icon-list"></i> application/json
                                        <span style={{float: "right"}}>Content-Type</span>
                                    </li>
                                </ul>
                                <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px" }}>URL fields</p>
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Biến</th>
                                        <th>Loại</th>
                                        <th>Mô tả</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{textTransform: "uppercase"}}>domain</td>
                                        <td>Bắt buộc nhập</td>
                                        <td>
                                            <ul>
                                                <li>
                                                    Domain phải đúng định dạng (xxxx.com, xxxx.vn,...)
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <p style={{fontSize: "18px", textDecoration: "underline", marginTop: "25px" }}>Kết quả trả về</p>
                                <ul>
                                    <li><strong>"isExist":true</strong>: Tên miền này đã có người mua rồi.</li>
                                    <li><strong>"isExist":false</strong>: Tên miền này chưa có người mua.</li>
                                    <li><strong>"error":"Tên miền không đúng định dạng</strong>: Báo lỗi tên miền không đúng định dạng.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CheckDomainGuideline;