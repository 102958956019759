import React from 'react';
var moment = require('moment');

function UserActiveStatus(is_active) {
    return(
        <React.Fragment>
            {is_active && <span className="badge badge-flat border-primary text-primary-600">ĐÃ KÍCH HOẠT</span>}
            {!is_active && <span className="badge badge-flat border-warning text-warning-600">CHƯA KÍCH HOẠT</span>}
        </React.Fragment>
    )
}


export default UserActiveStatus;