import React from 'react';
import axios from 'axios';
import SearchForm from '../../component/SearchForm';
import { Link, withRouter } from 'react-router-dom';
import Pagination from '../../component/Pagination';
import InvoiceStatus from '../../component/InvoiceStatus';
import AlertLabel from '../../component/AlertLabel';
import { Field, reduxForm } from 'redux-form';
import { RenderDatePicker } from '../../component/formInput';
import { connect } from 'react-redux';
import moment from 'moment';
import UserActiveStatus from '../../component/UserActiveStatus';
var _ = require('lodash');

function CustomerRows(data) {
  const billing_rows = _.map(data, function(i, index) {
    return (
      <tr key={index}>
        <td>
          {i.email}
          <br/>
          <small>{i.full_name} - {i.phone}</small>
        </td>
        <td>
          {UserActiveStatus(i.is_active)}
        </td>
        <td>
          <div className="icons-list">
            <div className="dropdown">
              <a href="#" className="list-icons-item" data-toggle="dropdown">
                <i className="icon-menu9"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to={"/user/agency/customer-edit/"+ i.id} className="dropdown-item">
                  <i className="icon-list-numbered"></i> Sửa
                </Link>
              </div>
            </div>
          </div>
        </td>
      </tr>
      )
  })
  return billing_rows;
}

class CustomerTable extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      table_data: [],
      table_query: {
        current_page: 1,
        number_of_row: 20,
        sort_asc: false,
        search_value: ""
      }
    }
  }

  componentDidMount() {
    this.reload(this.state.table_query);
  }

  gotoPage = (page) => {
    const table_query = {
      ...this.state.table_query,
      current_page: page
    };
    this.reload(table_query);
  }
  
  reload = (table_query) => {
    const self = this;
    const {account_type} = this.props; 

    axios({
      method: 'post',
      url: window.api_url + '/user/agency/customer-list',
      withCredentials: true,
      data: table_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        table_query: table_query,
        table_data: response.data.data
      })
    });
  }

  onSearchTxtChange = (event) => {
    const table_query = {
      ...this.state.table_query,
      search_value: event.target.value
    };

    this.setState({
      ...this.state,
      table_query: table_query
    });
  }

  onSearchSubmit = (event) => {
    const table_query = {
      ...this.state.table_query,
      current_page: 1
    };

    this.reload(table_query);
    event.preventDefault();
  }

  clearSearch = () => {
    const table_query = {
      ...this.state.table_query,
      current_page: 1,
      search_value: ""
    };
    this.reload(table_query);
  }
  render() {
    const { handleSubmit } = this.props;
    const { table_query, table_data } = this.state;
    const { title } = this.props;
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold"> Danh Sách Khách Hàng</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body">
              <SearchForm 
                placeHolder="Email, số điện thoại"
                onSearchSubmit={this.onSearchSubmit} 
                onSearchTxtChange={this.onSearchTxtChange}
                searchValue={table_query.search_value}
                onEsc={this.clearSearch}/>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="table-responsive">
                <table className="table table-togglable table-hover default footable-loaded footable">
                  <thead>
                  <tr>
                    <th data-toggle="true" className="footable-visible footable-first-column">
                      Email
                      <br/>
                      <small className="display-block text-muted text-size-small">Họ Tên - Điện Thoại</small></th>
                    <th data-hide="phone,tablet" className="">
                      Trạng thái
                    </th>
                    <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                      <i className="icon-menu-open2"></i>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                    {table_data && table_data.data && CustomerRows(table_data.data)}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination query={table_query} data={table_data} handle={this.gotoPage}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const CustomerTableWrapper = reduxForm({
  form: 'CustomerTable',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CustomerTable);

export default withRouter(CustomerTableWrapper);