import React from 'react';
import SideBar from './SideBar';
import TopBar from './TopBar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const mapState = state => ({
    userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({

});

class MainLayout extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // When page is fully loaded
        window.App.initCore();
        window.App.initAfterLoad();
    }

    render() {
        return (
            <React.Fragment>
                <TopBar/>
                <div className="page-content">
                    <SideBar/>
                    <div className="content-wrapper">
                        <div onClick={()=>window.hide_sidebar()} className="content hide-sidebar">
                            {this.props.children}
                        </div>
                        {/* <ContactGroup /> */}
                        <div className="navbar navbar-expand-lg navbar-light">
                            <div className="text-center d-lg-none w-100">
                                <button type="button" className="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
                                    <i className="icon-unfold mr-2"></i> Footer
                                </button>
                            </div>
                            <div className="navbar-collapse collapse" id="navbar-footer">
                                <span className="navbar-text">
                                    &copy; 2015 - 2020 - Công ty cổ phần Công Nghệ Tadu - <a href="http://tadu.cloud" target="_blank">Tadu.cloud</a> - <Link to={"/user/change_log"}>v1.0.5</Link>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(mapState, mapDispatch)(MainLayout);
