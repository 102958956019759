import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { StringUtils } from '../../utils/javascriptUtils';

var _ = require('lodash');

function GetTemplateLogo(template_type) {
  switch(template_type) {
    case "Plesk": {
      return "/assets/img/Plesk_Logo.svg.png";
    }
    case "Debian": {
      return "/assets/img/debian-logo-vector.svg";
    }
    case "DirectAdmin": {
      return "/assets/img/1280px-DirectAdminLogo.svg.png";
    }
    case "CentOS": {
      return "/assets/img/1280px-Centos-logo-light.svg.png";
    }
    case "Ubuntu": {
      return "/assets/img/ubuntu-logo32.png";
    }
    case "Windows": {
      return "/assets/img/windows-logo-windows-symbol-meaning-history-and-evolution-4.png";
    }
    case "Rocky Linux": {
      return "/assets/img/Rocky_Linux_wordmark.svg.png";
    }
  }
}

function GetSelectedTemplate(data, template_id) {
  return _.find(data, (i) => i.id == template_id)
}

class VPSTemplateOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version_selected: ""
    }
  }

  // Thực hiện lưu version vào state.
  onTemplateChange = (value) => {
    const { handleTemplateChange, templateGroup } = this.props;
    // Nếu cấu hình đã được chọn thì lấy giá trị đã được chọn trước đó.
    // Ngược lại lấy giá trị đầu tiên của Danh Sách Cấu Hình.
    const version_value = value != "" ? value : templateGroup.versions[0].id;
    this.setState({
      version_selected: version_value
    })
    handleTemplateChange(version_value);
  }
  
  render() {
    const { templateGroup, handleTemplateChange, selectedTemplate } = this.props;
    const { version_selected } = this.state;
    const template_selected = GetSelectedTemplate(templateGroup.versions, selectedTemplate);
    const template_versions = _.map(templateGroup.versions, (i, index) => <option key={index} value={i.id}>{i.version}</option>);
    return (
      <React.Fragment>
        <div className="card" style={{ float:"left", width: 300,  backgroundColor:'white', margin:10 }}>
        <div style={{margin:10}}>
          <div onClick={() => this.onTemplateChange(version_selected)} key={templateGroup.type}>
            <span><img src={GetTemplateLogo(templateGroup.type)} width={30} height={30}/><strong>  {templateGroup.type}</strong> {template_selected && <img width={16} src="/assets/img/checkmark.png"/>}</span> 
            <p className="text-muted">{template_selected ? <strong className="text-primary">{template_selected.version}</strong> : "Chọn Phiên Bản"}</p>
          </div>
          <select 
              className="form-control"
              style={{fontSize: 11}}
              value={version_selected}
              onChange={(event) => this.onTemplateChange(event.target.value)}>
                {template_versions}
            </select>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

class VpsOrderPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      product_price: null,
      selected_template: null,
    }
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
    this.onNextBtnClick = this.onNextBtnClick.bind(this);
    this.onNoteTxtChange = this.onNoteTxtChange.bind(this);
  }

  getVPSPrice = () => {
    const { productId } = this.props.routeProps.match.params;
    const self = this;

      axios({
      method: 'get',
      url: window.api_url + '/product/price/vps',
      withCredentials: true
    }).then(function (response) {
      let price = _.find(response.data.data, function(p) { return p.product_id == productId});

      self.setState({
        ...self.state,
        product_price: price
      })
    });
  }

  getCloudServiceProPrice = () => {
    const { productId } = this.props.routeProps.match.params;
    const self = this;

    axios({
      method: 'get',
      url: window.api_url + '/product/price/cloud_server_pro',
      withCredentials: true
    }).then(function (response) {
      let price = _.find(response.data.data, function(p) { return p.product_id == productId});

      self.setState({
        ...self.state,
        product_price: price
      })
    });
  }

  componentDidMount() {
    const self = this;
    const { productId } = this.props.routeProps.match.params;
    const { type } = this.props;
    axios({
      method: 'get',
      url: window.api_url + '/product/vps/template',
      withCredentials: true
    }).then(function (response) {
      //vps 0 and 1 do not support windows
      let templates = response.data.data;
      if (productId == 375 || productId == 2 || productId == 398 || productId == 399) {
        templates = _.filter(templates, (i) => i.is_linux );
      }

      if (productId == 375 || productId == 398) {
        templates = _.filter(templates, (i) => i.name !== 'DirectAdmin');
      }

      self.setState({
        ...self.state,
        templates: templates,
        selected_template: response.data.data[0].id
      })
    });

    if(type == "VPS") {
      this.getVPSPrice();
    }
    if(type == "CLOUD_SERVER_PRO") {
      this.getCloudServiceProPrice();
    }
    
  }

  handleTemplateChange(selectedValue) {
    if(!StringUtils.isEmptyOrSpaces(selectedValue.toString())) {
      // Loại bỏ giá trị của các selectbox còn lại.
      var elements = document.getElementsByTagName("select");
      for (var i=0; i <elements.length; i++) {
        elements[i].selectedIndex = "";
      }
      this.setState({
        ...this.state,
        selected_template: parseInt(selectedValue)
      });
    }
  }
  
  onNextBtnClick() {
    const {selected_template, product_price} = this.state;
    const {addItem, updateCart, history} = this.props;

    const item = {
      id: uuidv4(),
      group_id: null,
      product: product_price.product,
      month: 12,
      quantity: 1,
      domain_name: null,
      domain_profile_id: null,
      template_id: selected_template, //template id để sử dung cho vps.
      user_product_id: null, //Dịch vụ cần gia hạn
      is_renew: false,
      is_upgrade: false,
      is_tranfer: false,
      is_delivery: false,
      parent_id: null,
      price_is_year: product_price.is_year,
      order: 0 //Vị trí của item trong giỏ hàng
    }
    addItem(item);

    updateCart().then(() => {
      history.push('/products/cart');
    });
  }

  onNoteTxtChange(event) {
    const {setNote} = this.props;
    setNote(event.target.value);
  }

  render() {
    const {cart, title} = this.props;
    const {templates, selected_template} = this.state;
    // Group các template lại 
    const group_templates_by_type = _(templates)
      .groupBy(x => x.name)
      .map((value, key_name) => ({type: key_name, versions: value}))
      .value();
    
    const VPSTemplateGroup = _.map(group_templates_by_type, (template_group, index) => 
      <VPSTemplateOption 
        key = {index}
        templateGroup = {template_group}
        handleTemplateChange = {this.handleTemplateChange}
        selectedTemplate = {selected_template}
      />)
      
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">{title}</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <h6>Hệ điều hành - Template:</h6>
        <div className="row">
          <div style={{width:"100%"}} className="card text-center">
            <div className="card-body" style={{whiteSpace: 'nowrap',width: "inherit"}}>
              {VPSTemplateGroup}
            </div> 
          </div>
        </div>
        <h6>Yêu cầu hỗ trợ:</h6>
        <div className="row">
          <div className="card card-body">
            <br/>
            <textarea className="form-control" rows="5" name="note" value={cart && cart.note} onChange={this.onNoteTxtChange}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-align-right">
            <button className="btn btn-warning btn-xlg" onClick={this.onNextBtnClick}>
              Tiếp tục <span className="icon-arrow-right7"></span>
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  cart: state.cart
});

const mapDispatch = (dispatch) => ({
  addItem: (item) => dispatch.cart.addItem(item),
  setNote: (note) => dispatch.cart.setNote(note),
  updateCart: () => dispatch.cart.updateCart()
});

export default connect(mapState, mapDispatch)(withRouter(VpsOrderPage));