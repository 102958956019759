import React from 'react';

function generatePassword() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

class PasswordFormField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowPassword: false
        };
        this.togglePassword = this.togglePassword.bind(this);
    }

    togglePassword() {
        this.setState({
            isShowPassword: !this.state.isShowPassword
        });
    }

    render() {
        const {input, label, placeholder, required, type, meta: { touched, error }} = this.props;
        const {isShowPassword} = this.state;

        return (
            <React.Fragment>
                <div className="form-group row">
                    <label className={"col-form-label col-lg-2 " + (error != null ? 'font-weight-semibold text-danger' : '')}>
                        {label} {required && <span style={{color: "red"}}>*</span>}
                    </label>
                    <div className="col-lg-10">
                        <div className="input-group">
                            {isShowPassword && <input className={"form-control " + (error != null ? 'border-danger' : '')} {...input} placeholder={placeholder} type="text"/>}
                            {!isShowPassword && <input className={"form-control " + (error != null ? 'border-danger' : '')} {...input} placeholder={placeholder} type="password"/>}
                            <div className="input-group-append">
                                <button type="button" className="btn btn-light btn-icon" onClick={this.togglePassword}>
                                    <i className="icon-eye2"></i>
                                </button>
                                <button className="btn btn-light" type="button" onClick={() => input.onChange(generatePassword())}>
                                    Tạo mật khẩu
                                </button>
                            </div>
                        </div>
                        {touched && error && <span className="form-text text-danger">{error}</span>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PasswordFormField