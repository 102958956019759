import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import AlertLabel from '../../component/AlertLabel';
import { RenderDatePicker, RenderSearchForm } from '../../component/formInput';
import InvoiceStatus from '../../component/InvoiceStatus';
import Pagination from '../../component/Pagination';
import { StringUtils } from '../../utils/javascriptUtils';
import InvoiceStatusSelect from '../../component/InvoiceStatusSelect';

var numeral = require('numeral');
var _ = require('lodash');

function InvoiceDatePicker(from_date, to_date, onDateChange, clearDataFilter) {
  return ( 
    <React.Fragment>
      <div style={{display: 'inline-flex', marginRight:10 }}>
        <span className="input-group-text" style={{height:'fit-content'}}>
          <i style={{ height:20 }} className="icon-calendar22"></i>
        </span>
        <Field
          name="from_date"
          placeholder="Từ ngày"
          dateFormat="dd/MM/yyyy"
          onChangeAction={(value)=>onDateChange(value, to_date)}
          component={RenderDatePicker}
          />
      </div>
      <div style={{display: 'inline-flex', marginRight:10 }}>
        <span className="input-group-text" style={{height:'fit-content'}}>
          <i style={{ height: 20 }} className="icon-calendar22"></i>
        </span>
        <Field
          name="to_date"
          placeholder="Đến ngày"
          dateFormat="dd/MM/yyyy"
          onChangeAction={(value)=>onDateChange(from_date, value)}
          component={RenderDatePicker}/>
      </div>
      <div style={{display: 'inline-flex',height:'fit-content'}}>
        <button onClick={()=>clearDataFilter()} style={{whiteSpace:'nowrap', width:'inherit'}} className="btn btn-warning">x</button>
      </div>
    </React.Fragment>
  )
}

function InvoiceRows(data, handleCancelInvoice) {
  let detail_url = "/user/invoice/detail/"
  const inner_invoice_rows = _.map(data, (i, key) => {
    if(i.invoice_id) {
      detail_url = "/user/invoice/agency/detail/"
    }
    return (
      <tr key={key}>
        <td>
          <Link to={detail_url + i.id}>{i.code}</Link>
        </td>
        <td>
          {i.payment_type}
        </td>
        <td>
          {InvoiceStatus(i.invoice_status)}
        </td>
        <td>
          {i.created_date}
        </td>
        <td>
          {numeral(i.total).format('0,0')} đ
        </td>
        <td>
          <div className="icons-list">
            <div className="dropdown">
              <a href="#" className="list-icons-item" data-toggle="dropdown">
                <i className="icon-menu9"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to={detail_url + i.id} className="dropdown-item">
                  <i className="icon-list-numbered"></i> Chi tiết
                </Link>
                {i.invoice_status == "NEW" && <a to={"#"} onClick={() => handleCancelInvoice(i.id)} className="dropdown-item">
                  <i className="icon-cross2"></i> Huỷ Đơn Hàng
                </a>}
              </div>
            </div>
          </div>
        </td>
    </tr>)
  })
  return inner_invoice_rows;
}


class InvoiceTable extends React.Component {
  constructor(props) {
    super(props);
    const { invoiceStatus } = this.props.routeProps.match.params;
    const search_value = new URLSearchParams(this.props.location.search).get("search")
    this.state = {
      table_data: [],
      table_query: {
        current_page: 1,
        number_of_row: 20,
        sort_by: "",
        sort_asc: false,
        search_field: "search",
        search_value: search_value ? search_value : "",
        invoice_status: invoiceStatus,
        alert_label: null,
        from_date: null,
        to_date: null
      },
      
      invoice_static: {}
    }
  }

  componentDidMount() {
    this.listReload(this.state.table_query);
    this.staicReload(this.state.table_query);
  }
  
  gotoPage = (page) => {
    const table_query = {
      ...this.state.table_query,
      current_page: page
    };
    this.listReload(table_query);
  }
  
  // Tải và cập nhật số lượng theo loại đơn hàng.
  staicReload(table_query) {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/invoice/static',
      withCredentials: true,
      data: {
        from_date: table_query.from_date,
        to_date: table_query.to_date
      }
    }).then(function (response) {
      self.setState({
        ...self.state,
        invoice_static: response.data.data
      })
    });
  }
  
  listReload = (table_query) => {
    const self = this;
    const { invoice_status } = this.state;
    axios({
      method: 'post',
      url: window.api_url + '/user/invoice/list',
      withCredentials: true,
      data: table_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        table_query: table_query,
        table_data: response.data.data
      })
    });
  }
  
  handleCancelInvoice = (invoice_id) => {
    const self = this;
    axios({
      method: 'post',
      url: window.api_url + '/user/invoice_cancel',
      withCredentials: true,
      data: {
        invoice_id: invoice_id
      }
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          alert_label: {status: "SUCCESS", message: "Hủy đơn hàng thành công."}
        })
        self.listReload(self.state.table_query);
      } else {
        self.setState({
          alert_label: {status: "ERROR", message: "Hủy đơn hàng thất bại."}
        })
      }
    });
  }

  onInvoiceStatusChange = (invoice_status) => {
    const { history } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1,
      invoice_status: invoice_status
    };

    this.listReload(table_query);
    history.push("/user/invoice/"+ invoice_status)
  }

  onSearchSubmit = (values) => {
    const search_value = values.search ? values.search : "";
    const { history } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: search_value,
      current_page: 1,
      invoice_status: "all"
    };
    history.push("/user/invoice?search=" + search_value);
    this.listReload(table_query);
  }

  onDateChange = (from_date, to_date) => {
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1,
      from_date: moment(from_date).format("YYYY-MM-DD hh:mm:ss"),
      to_date: moment(to_date).format("YYYY-MM-DD hh:mm:ss")
    };
    
    this.setState({
      ...this.state,
      table_query: table_query
    });

    if(from_date && to_date) {
      this.listReload(table_query);
      this.staicReload(table_query);
    }
  }

  clearDataFilter = () => {
    const { reset } = this.props;
    const table_query = {
      ...this.state.table_query,
      search_value: "",
      current_page: 1,
      from_date: null,
      to_date: null
    };
    this.setState({
      ...this.state,
      table_query: table_query
    });
    reset();
    this.listReload(table_query);
    this.staicReload(table_query);
  }
  
  onKeyPess = (key_code) => {
    const { history } = this.props;
    if (key_code == "Escape") {
      history.push("/user/invoice/all")
      this.clearDataFilter();
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const { table_query, table_data, alert_label, invoice_static, from_date, to_date } = this.state;
    const { title } = this.props;
    return(
      <React.Fragment> 
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">{title}</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body">
              <form style={{display:"inline-flex"}} onSubmit={handleSubmit(this.onSearchSubmit)}>
                <div style={{ width:"100%", paddingRight:5}}>
                  <Field
                    name="search"
                    placeholder="Mã hoá đơn"
                    defaultValue = {table_query.search_value}
                    onKeyAction={(ev)=> this.onKeyPess(ev)}
                    component={RenderSearchForm}
                  />
                </div>
              </form>
              {StringUtils.isEmptyOrSpaces(table_query.search_value) && <div className="row">
                <div className="col-md-6">
                  <InvoiceStatusSelect invoice_static={invoice_static} onInvoiceStatusChange={this.onInvoiceStatusChange} />
                </div>
                <div className="col-md-6">
                  <form style={{ float: 'right', display: 'inline-flex' }} onSubmit={handleSubmit(this.onDateChange)} className="form-horizontal">
                    {InvoiceDatePicker(table_query.from_date, table_query.to_date, this.onDateChange, this.clearDataFilter)}
                  </form>
                </div>
              </div>}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {alert_label && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
            <div className="card">
              <div className="table-responsive">
                <table className="table table-togglable table-hover default footable-loaded footable">
                  <thead>
                    <tr>
                      <th>Mã Đơn Hàng</th>
                      <th>Phương Thức TT</th>
                      <th style={{width: "130px"}}>Trạng Thái</th>
                      <th>Ngày Đăng Ký</th>
                      <th>Tổng Tiền</th>
                      <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                          <i className="icon-menu-open2"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_data.data && invoice_static && InvoiceRows(table_data.data, this.handleCancelInvoice)}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination query={table_query} data={table_data} handle={this.gotoPage}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
});

const mapDispatch = (dispatch) => ({
 
});

const InvoiceTableWrapper = reduxForm({
  form: 'InvoiceTable',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(InvoiceTable);

export default connect(mapState,mapDispatch)(withRouter(InvoiceTableWrapper));