import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, Field, SubmissionError } from 'redux-form';
import { RenderInputWithDiv, RenderHTMLSelect } from '../../../component/formInput';

class InvoiceRequestModal extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      }

  }
  componentDidMount() {

  }
  submit = (values) => {
    const { onHide, onShowAlertMessage, invoiceObject } = this.props;
    const invoice_id = invoiceObject.id
    return axios({
      method: 'post',
      url: window.api_url+ '/user/invoice/vat_request',
      data: {
        id: invoice_id,
        vat_company_name: values.vat_company_name,
        vat_company_address: values.vat_company_address,
        vat_mst: values.vat_mst,
        express_address: values.express_address,
        vat_bill_type: values.vat_bill_type,
        is_vat_bill: true,
        is_notify: true,
        vat_type: values.vat_type,
        vat_status: values.vat_status ? values.vat_status : "ANEW"
      },
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        onShowAlertMessage("SUCCESS", "Lưu thông tin công ty thành công.")
        onHide();
      }else {
        throw new SubmissionError(response.data.error);
      }
    })
  }
  render() {
    const { title, handleSubmit, submitting, onHide, userProfile } = this.props;
    const modalConfig = { backdrop: 'static', show: this.props.show, bssize: "medium", onHide: this.props.onHide, submitting: this.props.submitting };
    const vat_bill_options = [
      { label: "Đã thanh toán chuyển khoản", value: "BANK_TRANSFER" },
      { label: "Trừ tiền trong tài khoản chính Tadu", value: "TADU_ACCOUNT" },
      { label: "Trừ tiền trong tài khoản khuyến mãi Tadu", value: "REWARD_ACCOUNT" }
    ]
    
    const vat_types = [
      { label: "Giá đại lý", value: "AGENCY" },
      { label: "Giá khách hàng của đại lý", value: "CUSTOMER" },
    ]

    const newModal = <div style={{ width: '30%' }}>
    <Modal
      {...modalConfig}
      aria-labelledby="contained-modal-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-sm"><center>{title}</center> </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form-horizontal" role="form" onSubmit={handleSubmit(this.submit)}>
          <Field name="vat_company_name" label="Tên công ty" placeholder="Vui lòng nhập tên công ty..." component={RenderInputWithDiv}></Field>
          <Field name="vat_company_address" label="Địa chỉ" placeholder="Vui lòng nhập địa chỉ công ty..." component={RenderInputWithDiv}></Field>
          <Field name="vat_mst" label="Mã số thuế" placeholder="Vui lòng nhập mã số thuế..." component={RenderInputWithDiv}></Field>
          <Field name="express_address" label="Email nhận hóa đơn" placeholder="Vui lòng nhập email người nhận..." component={RenderInputWithDiv}></Field>
          {userProfile && userProfile.role == "AGENCY" && <Field name="vat_type" placeholder="Vui lòng chọn xuất hoá đơn theo giá..." label="Xuất hoá đơn theo giá" options={vat_types} component={RenderHTMLSelect}></Field>}
          <Field name="vat_bill_type" label="Thanh toán VAT" placeholder="Vui lòng chọn phương thức thanh toán..." options={vat_bill_options} component={RenderHTMLSelect}></Field>
          <div className="text-right">
            <a onClick={()=>onHide()} className={"btn"}>
              Đóng
            </a>
            <button type="submit" className={"btn bg-blue btn-primary " + (submitting ? "disabled" : "")}>
              Cập nhật <i className="icon-arrow-right14 position-right"></i>
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
    </div>
    return newModal;
  }
};
const mapState = (state, props) => ({
  initialValues: {
    ...props.invoiceObject
  },
  userProfile: state.userProfile.data
});

const mapDispatch = dispatch => ({
  
});

export default (connect(mapState, mapDispatch)(
  reduxForm({
    form: 'InvoiceRequestModal',
    destroyOnUnmount: true,
    enableReinitialize: true
  })(withRouter(InvoiceRequestModal))));
