import axios from 'axios';
import { objectToFormData } from 'object-to-formdata';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import AlertLabel from '../../component/AlertLabel';
import { RenderCheckbox, RenderFileUpload, RenderInputWithDiv, RenderSwitch, RenderTextEditor } from '../../component/formInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BlockUI from '../../component/BlockUI';
import FileRender from '../../component/FileRender';

const mapState = state => ({
  initialValues: state.agencyProfile.data,
});

const mapDispatch = (dispatch) => ({
  loadAgencyAsync: () => dispatch.agencyProfile.loadAgencyAsync()
});

function FormTitleGroup(title, sub_title) {
  return (
    <React.Fragment>
    <div style={{ borderBottom: "solid 1px", fontSize: "15px" }} className="row">
      <div className="col-form-label col-lg-12">
        <span style={{color:  "#FF8D00"}}>{title}</span>
        <br />
        <small className="text-muted" >{sub_title}</small>
      </div>  
    </div>
    <br />
    </React.Fragment>
  )
}
function FieldNoteLabel(label, is_alert) {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-2">
        </div>  
        <div className="col-lg-10">
          <div className={is_alert ? "alert alert-success" : "text-muted"} style={{ fontSize: '13px' }}>{label}</div>
        </div>
      </div>
      <br/>
    </React.Fragment>
  )
}

class AgencyInfoConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoPreview: null,
      faviconPreview: null,
      alert_label: null,
      isLoading: false
    }
  }
   
  componentDidMount() {
    const { loadAgencyAsync } = this.props;
    loadAgencyAsync();
  }

  onSubmit = (values) => {
    const self = this;
    const data_value = {
      has_custom_brand: values.has_custom_brand,
      domain: values.domain,
      font_end: values.font_end,
      is_dns_config: values.is_dns_config,
      is_ticket: values.is_ticket,
      name: values.name,
      address: values.address,
      email: values.email,
      hotline: values.hotline,
      logo_upload: values.logo_upload && values.logo_upload[0],
      favicon_upload: values.favicon_upload && values.favicon_upload[0],
      bank: values.bank,
      renew_email_notify_customer: values.renew_email_notify_customer
    }
    // convert Object to FormData
    const formData = objectToFormData(data_value);
    this.setState({
      isLoading: true
    })
    return axios({
      method: 'post',
      url: window.api_url + '/agency/info/update',
      data: formData,
      withCredentials: true
    }).then(function (response) {
        self.setState({
          isLoading: false
        })
      if(!response.data.isError) {
        toast.success("Cập nhật đại lý thành công !.");
        
      } else {
        toast.error("Có lỗi trong quá trình lưu trữ, vui lòng kiểm tra lại dữ liệu !")
        throw new SubmissionError(response.data.error)
      }
    });
  }

  onPreviewImg = (value, field_name) => {
    if(field_name === "LOGO") {
      this.setState({
        logoPreview: URL.createObjectURL(value[0])
      })
    }
    if(field_name === "FAVICON") {
      this.setState({
        faviconPreview: URL.createObjectURL(value[0])
      })
    }
  }

  render() {
    const { handleSubmit, submitting, initialValues } = this.props;
    const { logoPreview, faviconPreview, alert_label, isLoading } = this.state;
    return (
      <div>
        {isLoading && <BlockUI/>}
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Cấu Hình Đại Lý</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body" style={{ backgroundColor: 'white' }} >
            <ToastContainer />
              {alert_label && <AlertLabel status = {alert_label.status} message = {alert_label.message}/>}
              {initialValues && <form onSubmit={handleSubmit(this.onSubmit)}>
                <div style={{ margin: '10px' }}>
                  {FormTitleGroup("Thông tin chung", "Cung cấp các thông tin về tên miền và trang chủ.")}
                  <Field
                    disabled={true}
                    name="has_custom_brand"
                    leftLabel="Thương hiệu TADU"
                    rightLabel="Thương hiệu riêng"
                    component={RenderSwitch}
                    label="Phân loại" />
                  {FieldNoteLabel("Khi dùng thương hiệu riêng, khách hàng của đại lý sẽ không thể sử dụng tính năng gửi hỗ trợ (ticket), không thể login vào id.tadu.cloud", true)}
                  <Field
                    name={"domain"}
                    type="text"
                    placeholder="Nhập tên miền..."
                    component={RenderInputWithDiv}
                    required
                    label="Tên miền "
                    subLabel="(không có http://)" />
                  {FieldNoteLabel("Tên miền này sẽ dùng để thay thế id.tadu.cloud ", false)}
                  <Field
                    name={"font_end"}
                    component={RenderInputWithDiv}
                    placeholder="Nhập tên trang chủ..."
                    required
                    label="Trang chủ"
                    subLabel="(không có http://)" />
                  {FieldNoteLabel("Tên miền trang chủ website bán hàng của đại lý, ví dụ: nếu trang đại lý là me.zzzz.vn thường trang bán hàng sẽ là zzzz.vn", false)}
                  {/* {FormTitleGroup("Thông tin quản trị DNS", "Cung cấp thông tin DNS của tên miền "+ initialValues.domain +" nếu cần Tadu hỗ trợ.")} */}
                  {/* <Field
                    name={"is_dns_config"}
                    component={RenderCheckbox}
                    label=" "
                    checkLabel={" Cấu hình trang quản lý (check vào đây nếu " + initialValues.domain + " chưa hoạt động)"} /> */}
                  {FormTitleGroup("Chức năng", "Bật / tắt tính năng dành cho khách hàng của đại lý")}
                  <Field
                    name={"is_ticket"}
                    component={RenderCheckbox}
                    label=" "
                    checkLabel=" Bật tính năng hỗ trợ ticket (check vào đây nếu đại lý muốn khách hàng có thể sử dụng tính năng hỗ trợ qua ticket) " />
                  {FieldNoteLabel("Lưu ý: Những hỗ trợ này đại lý phải tự trả lời cho khách hàng của mình. Để đảm bảo uy tính, đại lý chỉ nên mở tính năng này khi có nhân sự trực tiếp để hỗ trợ khách hàng", true)}
                  <Field
                    name={"renew_email_notify_customer"}
                    component={RenderCheckbox}
                    label=" "
                    checkLabel=" Bật / tắt gửi mail thông báo gia hạn đến khách hàng (check vào đây nếu đại lý muốn khách hàng nhận được mail thông báo gia hạn.) " />
                    {FormTitleGroup("Thông tin công ty", "Thông tin này sẽ được hiển thị trong các email gửi khách hàng của đại lý.")}
                  <Field
                    name={"name"}
                    component={RenderInputWithDiv}
                    placeholder="Nhập tên công ty..."
                    label="Tên công ty" />
                  <Field
                    name={"address"}
                    component={RenderInputWithDiv}
                    placeholder="Nhập địa chỉ công ty..."
                    label="Địa chỉ công ty" />
                  <Field
                    name={"email"}
                    component={RenderInputWithDiv}
                    placeholder="Nhập địa chỉ email..."
                    label="Email" />
                  {FieldNoteLabel("Email này sẽ được dung để gửi cá thông tin (đơn hàng, thông báo hết hạn,...) đến khách hàng của đại lý.")}
                  <Field
                    name={"hotline"}
                    type="text"
                    component={RenderInputWithDiv}
                    placeholder="Nhập số hotline..."
                    label="Hotline" />
                  <div className="row">
                    <div className="col-lg-2">
                    </div>  
                    <div className="col-lg-10">
                      <FileRender directory="logo-upload" src_input={logoPreview} file_name={initialValues.logo} isDisplay={true} width={250} height={85} />
                    </div>
                  </div>
                  <Field
                    name="logo_upload"
                    fileAccept=".jpg, .png"
                    component={RenderFileUpload}
                    label="Logo"
                    onChangeAction={(value) => this.onPreviewImg(value, "LOGO")} />
                  <div className="row">
                    <div className="col-lg-2">
                    </div>  
                    <div className="col-lg-10">
                      <FileRender directory="favicon-upload" src_input={faviconPreview}  file_name={initialValues.favicon} isDisplay={true} width={100} height={30} />
                    </div>
                  </div>
                  <Field
                    name="favicon_upload"
                    fileAccept=".jpg, .png"
                    component={RenderFileUpload}
                    label="Favicon"
                    onChangeAction={(value) => this.onPreviewImg(value, "FAVICON")} />
                  {FormTitleGroup("Thông tin ngân hàng đại lý", "Thông tin này sẽ được hiển thị trong email đơn hàng gửi khách hàng của đại lý thay thế thông tin ngân hàng TADU")}
                  <Field
                    name={"bank"}
                    component={RenderTextEditor}
                  />
                </div>
                <div style={{ margin: '10px' }}>
                  <button type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
                    Cập nhật
                  </button>
                </div>
              </form>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const AgencyInfoConfigWrapper = reduxForm({
  form: 'domainProfile',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AgencyInfoConfig);

export default connect(mapState,mapDispatch)(withRouter(AgencyInfoConfigWrapper)); 

