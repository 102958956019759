import React from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import UserProductStatus from '../../component/UserProductStatus';
import Pagination from '../../component/Pagination';
import SearchForm from '../../component/SearchForm';
import ProductName from './ProductName';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ChangeUserModal from './modals/ChangeUserModal';
import moment from 'moment';
import { DateUtil } from '../../utils/javascriptUtils';
import ServiceStatusSelect from './ServiceStatusSelect';
var _ = require('lodash');

const mapState = state => ({
  cart: state.cart,
  userProfile: state.userProfile.data,

});

const mapDispatch = (dispatch) => ({
  addItem: (item) => dispatch.cart.addItem(item),
  setNote: (note) => dispatch.cart.setNote(note),
  updateCart: () => dispatch.cart.updateCart(),
  setAgencyCustomer: (agency_customer_id) => dispatch.cart.setAgencyCustomer(agency_customer_id),
  addDomainWhois: (item) => dispatch.cart.addDomainWhois(item),
  renewAddonService: (item) => dispatch.cart.renewAddonService(item),
});

function IsServiceExpired(user_product) {
  const now = moment();
  const expired = moment(user_product.expired);
  const duration = Math.round(expired.diff(now, "days", true));
  return duration <= 0;
}

function IsServiceExpiring30Days(user_product) {
  const now = moment();
  const expired = moment(user_product.expired);
  const duration = Math.round(expired.diff(now, "days", true));
  return duration > 0 && duration <= 30;
}

function ProductTypeCase(productType) {
  switch(productType) {
    case "domain": {
      return {product_types: ["VN_DOMAIN", "COM_DOMAIN"]};
    }
    case "vps" : {
      return  {product_types: ["VPS", "CLOUD_SERVER_PRO"]};
    }
    case "hosting" : {
      return {product_types: ["LINUX_HOSTING", "WINDOW_HOSTING"]};
    }
    case "email" : {
      return {product_types: ["EMAIL"]};
    }
    case "other" : {
      return {product_types: ["TADU_PHONE", "TADU_INVOICE", "CLOUD_BACKUP", "DIGITAL_SIGNATURE", "SSL"]};
    }
    default: {
      return {};
    }
  }
}

function VPSManagement(user_product) {
  return (
    <React.Fragment key={"VPS_Management_" + user_product.id}>
      {!IsServiceExpired(user_product) && <Link to={"/user/cloud/server/manage/" + user_product.id} className="dropdown-item">
        <i className="icon-file-presentation"></i> Quản Lý Dịch Vụ
      </Link>}
      {/* {!IsServiceExpired(user_product) && user_product.domain.startsWith("v") && <a href="https://cloud.sdns.vn:4083" target="_blank" rel="noreferrer" className="dropdown-item"><i className="icon-cloud"></i>  Cloud Server Panel</a>} */}
    </React.Fragment>
  )
}

function AddMoreIPDropbox(user_product, getIPServicePrice) {
  return (
    <React.Fragment key={"AddMoreIP_" + user_product.id}>
      {!IsServiceExpired(user_product) && <Link to="/products/cart" onClick={()=>getIPServicePrice(user_product)} className="dropdown-item">
        <i className="icon-earth"></i> Mua thêm IP
      </Link>}
    </React.Fragment>
  )
}

function hostingConfigDropbox(user_product, handleHostingConfig) {
  return (
    <a key={"HostingConfig_" + user_product.id} onClick={()=>handleHostingConfig(user_product)} className="dropdown-item">
      <i className="icon-gear"></i> Cấu hình (Cpanel hoặc Plesk)
    </a>
  )
}

function DNSConfigDropbox(user_product, dnsDirectUrl) {
  return (
    <React.Fragment key={"DNS_GROUP_" + user_product.id}>
        <a onClick={()=>dnsDirectUrl(user_product.domain)} className="dropdown-item">
          <i className="icon-circle-right2"></i> Cấu hình DNS
        </a>
    </React.Fragment>
  )
}

function changeServicePasswdDropbox(user_product, onServiceChangePasswd) {
  return (
    <Link key={"ChangePwd_" + user_product.id} to="#" onClick={() => onServiceChangePasswd(user_product.id)} className="dropdown-item">
      <i className="icon-mail5"></i> Gửi lại thông tin dịch vụ
    </Link>
  )
}

function ServiceUpgradeDropbox(user_product) {
  return (
    <React.Fragment key={"ServiceUpgrade_" + user_product.id}>
      {!IsServiceExpired(user_product) && <Link to={"/service/upgrade/"+user_product.id} className="dropdown-item">
        <i className="icon-rating3"></i> Nâng cấp
      </Link>}
    </React.Fragment>
  )
}

function AddWhoisPrivacyDropbox(user_product, getWhoIsPrivacyPrice) {
  return (
    <React.Fragment key={"AddWhoIs_" + user_product.id}>
      {!IsServiceExpired(user_product) && user_product.service_addons.length == 0 && user_product.product.product_type == "COM_DOMAIN" && <Link  to="/products/cart" onClick={() => getWhoIsPrivacyPrice(user_product)} className="dropdown-item" >
        <i className="icon-file-locked"></i> Ẩn Thông tin tên miền
      </Link>}
    </React.Fragment>
  )
}

function ServiceDropboxByType(user_product, getWhoIsPrivacyPrice, getIPServicePrice, onServiceChangePasswd, dnsDirectUrl, handleHostingConfig) {
  
  let inner_dropbox = null;
  if(["VN_DOMAIN", "COM_DOMAIN"].includes(user_product.product.product_type)) {
    inner_dropbox = [
      // changeServicePasswdDropbox(user_product, onServiceChangePasswd),
      AddWhoisPrivacyDropbox(user_product, getWhoIsPrivacyPrice),
      DNSConfigDropbox(user_product, dnsDirectUrl)
    ];
  }

  if(["VPS", "CLOUD_SERVER_PRO"].includes(user_product.product.product_type)) {
    inner_dropbox = [
      VPSManagement(user_product),
      !["VPS_8", "CLOUD_SERVER_PRO_3"].includes(user_product.product.code) && ServiceUpgradeDropbox(user_product),
      AddMoreIPDropbox(user_product, getIPServicePrice),
    ];
  }

  if(["LINUX_HOSTING", "WINDOW_HOSTING"].includes(user_product.product.product_type)) {
    inner_dropbox = [
      ServiceUpgradeDropbox(user_product),
      AddMoreIPDropbox(user_product, getIPServicePrice),
      // changeServicePasswdDropbox(user_product, onServiceChangePasswd),
      hostingConfigDropbox(user_product, handleHostingConfig)
    ];
  }

  if(user_product.product.product_type === "EMAIL") {
    inner_dropbox = [
      ServiceUpgradeDropbox(user_product)
    ];
  }

  if(user_product.product.product_type === "TADU_PHONE") {
    inner_dropbox = [
      ServiceUpgradeDropbox(user_product)
    ]
  }
  if(user_product.product.product_type === "CLOUD_BACKUP" && user_product.product.code != "CLOUD_BACKUP_8") {
    inner_dropbox = [
      ServiceUpgradeDropbox(user_product)
    ]
  }

  return inner_dropbox;
    
} 

function AddonService(user_product, addon_services, onRemoveIp) {
    const whois_addon_item = _.find(addon_services, (i) => i.product.product_type == "WHOIS_PRIVACY" && i.parent_id === user_product.id);
    const ip_addon_item = _.filter(addon_services, (i) => i.product.product_type == "IP" && i.parent_id === user_product.id);
    let inner_addon_service = whois_addon_item && <small style={{color: 'orange'}}>+ Whois Privacy</small>
    if(ip_addon_item.length > 0) {
      inner_addon_service = (
        <small style={{ color: "orange" }} >
            {_.map(ip_addon_item, (i, index) => 
              <span key={index}>IP: {i.ip_address} <br/></span>
            )}
            <span style={{color:"#42A5F5", cursor: 'pointer'}}></span> 
        </small>
      )
    }
  return inner_addon_service;
}


class UserProductRows extends React.Component {
  render() {
    const { user_product_list, table_query } = this.props;
    const { reload, handleServiceCheckBox, renewItem, onServiceChangePasswd } = this.props;
    const { showChangeUserModal, getWhoIsPrivacyPrice, getIPServicePrice } = this.props;
    const { dnsDirectUrl, handleHostingConfig, onRemoveIp } = this.props;
    
    const inner_user_product_rows = user_product_list.map((user_product, key) => {
      return (
        !user_product.parent_id && <tr className={IsServiceExpiring30Days(user_product) ? "alpha-warning" : ""} key={key}>
          <td>
            <input onChange={(e)=>handleServiceCheckBox(e.target.checked,user_product)} value={user_product} type="checkbox"/>
          </td>
          <td>
            <ProductName key={key} reload={reload} tableQuery={table_query} userProduct={user_product}/>
            {AddonService(user_product, user_product.service_addons, onRemoveIp)}
          </td>
          <td>
            {user_product.user.email}
            <br/>
            <small className="text-muted">{user_product.user.full_name}</small>
          </td>
          <td>{UserProductStatus(user_product)}</td>
          <td>{moment(user_product.registered).format("DD-MM-YYYY HH:mm:ss")}</td>
          <td>{moment(user_product.expired).format("DD-MM-YYYY HH:mm:ss")}</td>
          <td>
            <div className="icons-list">
              <div className="dropdown">
                <a href="#" className="list-icons-item" data-toggle="dropdown">
                  <i className="icon-menu9"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a onClick={()=> renewItem(user_product)} className="dropdown-item">
                    <i className="icon-rotate-cw2"></i> Gia hạn dịch vụ
                  </a>
                  {ServiceDropboxByType(user_product, getWhoIsPrivacyPrice, getIPServicePrice, onServiceChangePasswd, dnsDirectUrl, handleHostingConfig)}
                  {user_product.status == "RUNNING" && <a onClick={() => showChangeUserModal(user_product)} className="dropdown-item">
                    <i className="icon-square-left"></i> Chuyển chủ sở hữu
                  </a>}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )
    })
    return inner_user_product_rows;
  }
}
const UserProductWrapper = connect(mapState, mapDispatch)(UserProductRows);

class ServicePage extends React.Component {

  constructor(props) {
    super(props);
    const {serviceStatus} = this.props.routeProps.match.params;
    const { productType } = this.props;
    this.state = {
      table_data: [],
      table_query: {
        current_page: 1,
        number_of_row: 30,
        sort_by: "",
        sort_asc: false,
        search_field: "search",
        search_value: null,
        filter: ProductTypeCase(productType),
        service_status: serviceStatus
      },
      service_checked_list: [],
      is_show_modal_change_user: false,
      user_product_selected: {}
    }
  }


  componentDidMount() {
    this.reload(this.state.table_query);
  }

  gotoPage = (page) => {
    const table_query = {
      ...this.state.table_query,
      current_page: page
    };
    this.reload(table_query);
  }

  reload = (table_query) => {
    const self = this;
    axios({
      method: 'post',
      url: window.api_url + '/user/service/' + table_query.service_status,
      withCredentials: true,
      data: table_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        table_query: table_query,
        table_data: response.data.data
      })
    });
  }

  onSearchTxtChange = (event) => {
    const table_query = {
      ...this.state.table_query,
      search_value: event.target.value
    };

    this.setState({
      ...this.state,
      table_query: table_query
    });
  }

  onSearchSubmit = (event) => {
    const table_query = {
      ...this.state.table_query,
      current_page: 1
    };

    this.reload(table_query);
    event.preventDefault();
  }
  
  onChangeServiceStatus = (service_status) => {
    const { productType, history } = this.props;
    const table_query = { 
      ...this.state.table_query,
      service_status: service_status
    }
    history.push("/service/"+ service_status +"/" + productType);
    this.reload(table_query);
  }

  clearSearch = () => {
    const table_query = {
      ...this.state.table_query,
      current_page: 1,
      search_value: null
    };
    this.reload(table_query);
  }

  handleServiceCheckBox = (isChecked,item_selected) => {
    let services_checked = this.state.service_checked_list;
    if(isChecked && !services_checked.includes(item_selected)) {
      // Thêm  dịch vụ vào nếu người dùng checked
      services_checked.push(item_selected)
    }else {
      // xoá đi item đã tồn tại nếu người dùng uncheck
      _.remove(services_checked, (i) => i.id == item_selected.id)
    }
    this.setState({service_checked_list: services_checked })
  }
  
  // Dùng cho các dịch vụ gia hạn
  renewItem = (service) => {
    const { renewAddonService, updateCart, history } = this.props;
    const default_month = service.product.product_type == "TADU_INVOICE" ? 120 : 12
    const data = {
      id: uuidv4(),
      group_id: null,
      product: service.product,
      day: 0,
      month: default_month,
      quantity: 1,
      ip_address: service.ip_address,
      domain_name: service.domain,
      domain_profile_id: service.domain_profile_id,
      template_id: service.template_id, //template id để sử dung cho vps.
      user_product_id: service.id, //Dịch vụ cần gia hạn
      is_renew: true, // chỉ sử dụng cho dịch vụ gia hạn
      is_upgrade: false, // chỉ sử dụng cho dịch vụ nâng cấp
      is_tranfer: false,
      is_delivery: false,
      parent_id: null,
      order: 0 //Vị trí của item trong giỏ hàng
    }
    // Gia hạn các dịch vụ addon nếu có, IP, WHOIS_PRIVACY
    _.forEach(service.service_addons, (item) => {
        renewAddonService(item);
    });
    this.setServiceToCart(data, service.user)
  }

  // Dùng cho dịch vụ đăng ký mới 
  // Mua thêm IP hoặc whois_privacy
  regisItem = (service_parent, addon_product) => {
    const date_duration = DateUtil.DateDuration(moment(), moment(service_parent.expired));
    // tìm ngày và tháng của dịch vụ còn lại để tính toán tiền cho whois hoặc IP mua thêm
    const day = date_duration.days
    const month = date_duration.months + (date_duration.years * 12)
    const data = {
        id: uuidv4(),
        group_id: null,
        product: addon_product,
        day: day ? day : 0,
        month: month ? month : 0,
        quantity: 1,
        ip_address: service_parent.ip_address,
        domain_name: service_parent.domain,
        domain_profile_id: service_parent.domain_profile_id,
        template_id: service_parent.template_id, //template id để sử dung cho vps.
        user_product_id: service_parent.id, 
        service_parent: service_parent, 
        is_renew: false, // chỉ sử dụng cho dịch vụ gia hạn
        is_upgrade: false, // chỉ sử dụng cho dịch vụ nâng cấp
        is_tranfer: false,
        is_delivery: false,
        parent_id: null,
        order: 0 //Vị trí của item trong giỏ hàng
    }
    this.setServiceToCart(data, service_parent.user)
  }
  
  setServiceToCart = (data, user_service) => {
    const { addItem, updateCart, setAgencyCustomer, userProfile, history } = this.props;
    if(userProfile.role == "AGENCY" && user_service.id != userProfile.id && user_service.role != "AGENCY") {
      setAgencyCustomer(user_service.id);
    }else {
      setAgencyCustomer(null);
    }
    addItem(data);
    updateCart().then(
        history.push("/products/cart")
    );
  }

  getIPServicePrice = (service) => {
    const self = this;
    axios({
      method: 'get',
      url: window.api_url + '/product/price/ip',
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.regisItem(service, response.data.data[0].product)
      }
    });
  }

  getWhoIsPrivacyPrice = (service) => {
    const {cart, addDomainWhois} = this.props;
    const self = this;
    const parent_item_exist = _.find(cart.items, (i) => i.user_product_id ==  service.id);
    if(!parent_item_exist) {
      axios({
        method: 'get',
        url: window.api_url + '/product/price/whois',
        withCredentials: true
      }).then(function (response) {
        if(!response.data.isError) {
          self.regisItem(service, response.data.data[0].product)
        }
      });
    }else {
      addDomainWhois(parent_item_exist);
    }
    
  }

  onServiceChangePasswd = (user_product_id) => {
    axios({
      method: 'post',
      url: window.api_url + '/user/service/send_mail',
      withCredentials: true,
      data: {
        user_product_id: user_product_id
      }
    }).then(function (response) {
      if(!response.data.isError){
        alert("Gửi mail thành công!")
      }
    });
  }

  onBtnProductExtention = () => {
    const { service_checked_list } = this.state;
    const { history } = this.props;
    // thêm tất cả các service mà người dùng checked vào giỏ hàng gia hạn.
    _.forEach(service_checked_list, (i) => this.renewItem(i))
    history.push('/products/cart');
  }

  showChangeUserModal = (user_product) => {
    this.setState({
      is_show_modal_change_user: true,
      user_product_selected: user_product
    })
  }

  hideChangeUserModal = () => {
    this.setState({
      is_show_modal_change_user: false,
      service_checked_list:[]
    })
    this.reload(this.state.table_query);
  }
  
  onRemoveIp = (user_product_id) => {
    const self = this;
    const alert_confirm = window.confirm("Bạn có chắc muốn rút lại IP này !");
    if(alert_confirm) {
      axios({
        method: 'post',
        url: window.api_url + '/user/service/change/status',
        withCredentials: true,
        data: {
          user_product_id: user_product_id,
          status: "DELETED"
        }
      }).then(function (response) {
        if(!response.data.isError) {
          self.reload(self.state.table_query);
        }
      });
    }
  }

  handleHostingConfig = (user_product) => {
    let url = '/user/service/cpanel/manage'
    // Nếu Hệ điều hành của Hosting là window.
    // Nơi cấu hình sẽ là plesk
    if (user_product.product.product_type == "WINDOW_HOSTING") {
      url = '/user/service/plesk/manage'
    }
    axios({
      method: 'post',
      url: window.api_url + url,
      withCredentials: true,
      data: {
        user_product_id: user_product.id
      }
    }).then(function (response) {
      if(!response.data.isError) {
        window.open(response.data.data,"_blank");
      }
    });
  }

  dnsDirectUrl = (domain) => {
    axios({
      method: 'post',
      url: window.api_url + '/user/service/dns/manage/' + domain,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        const sdns_login_url = "https://sdns.vn/rest/domain/" + domain + "/login/" + response.data.data;
        window.open(sdns_login_url, '_blank', 'noopener');
      }
    });
  }

  render() {
    const {table_query, table_data, service_checked_list, is_show_modal_change_user, user_product_selected} = this.state;
    const { title, productType } = this.props;
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">{title}</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body">
              <SearchForm 
                placeHolder="Tên miền, IP, tên VPS"
                onSearchSubmit={this.onSearchSubmit} 
                onSearchTxtChange={this.onSearchTxtChange}
                searchValue={table_query.search_value}
                onEsc={this.clearSearch}/>
              <br/>
              <div style={{margin:10}} className="text-align-left">
                <ServiceStatusSelect productType={productType} onChangeServiceStatus={this.onChangeServiceStatus} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="table-responsive-sm">
                <table className="table table-togglable table-hover default footable-loaded footable">
                  <thead>
                    <tr>
                      <th></th>
                      <th data-toggle="true">Dịch vụ</th>
                      <th data-toggle="true">Thông tin khách hàng</th>
                      <th data-hidden="phone">Trạng thái</th>
                      <th data-hidden="phone">Ngày tạo</th>
                      <th data-hidden="phone">Ngày hết hạn</th>
                      <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                        <i className="icon-menu-open2"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {table_data.data && <UserProductWrapper
                      user_product_list = {table_data.data}
                      table_query = {table_query}
                      reload = {this.reload}
                      handleServiceCheckBox = {this.handleServiceCheckBox}
                      renewItem = {this.renewItem}
                      showChangeUserModal = {this.showChangeUserModal}
                      getWhoIsPrivacyPrice = {this.getWhoIsPrivacyPrice}
                      getIPServicePrice = {this.getIPServicePrice}
                      onServiceChangePasswd = {this.onServiceChangePasswd}
                      dnsDirectUrl = {this.dnsDirectUrl}
                      handleHostingConfig = {this.handleHostingConfig} 
                      onRemoveIp = {this.onRemoveIp}
                    />}
                  </tbody>
                </table>

                <div className="card-footer text-left">
                  <button disabled={service_checked_list.length <= 0} className="btn bg-warning-400 btn-xs heading-btn" onClick={()=> this.onBtnProductExtention()}>Gia hạn dịch vụ <i className="icon-arrow-right15"></i></button>
                </div>
              </div>
              <ChangeUserModal title="Chuyển đổi chủ sở hữu dịch vụ" show={is_show_modal_change_user} onHide={this.hideChangeUserModal} userProductId={user_product_selected.id}></ChangeUserModal>
            </div>
            <Pagination query={table_query} data={table_data} handle={this.gotoPage}/>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default (connect(mapState, mapDispatch))(withRouter(ServicePage));
