import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

var numeral = require('numeral');

function ServiceUpgradeRows(service_list, onRadioCheck) {
  const inner_serivice_rows = service_list.map((i, key)=>{
    return ( 
      <div key={key} className="card" style={{marginBottom: "20px"}}>
        <div style={{display:"inline-flex", margin: "20px"}} className="radio">
          <div style={{paddingRight:'10px'}} className="choice">
            <input id={"upgrade_service_"+key} name={"upgrade_service_item"} type="radio" onChange={()=>onRadioCheck(i)}/>
          </div>
          <label htmlFor={"upgrade_service_"+key}>
            {i.upgrade_item.product.name} (Giá: <span className="text-danger">{numeral(i.upgrade_item_price).format("0,0")}/tháng</span> ) - Tổng phí nâng cấp:   <span className="text-danger">{numeral(i.total_upgrade_price).format("0,0")} đ</span>
          </label>
        </div>
      </div>
    )
  })
  return inner_serivice_rows;
}

class ServiceUpgradePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result_data: null,
      service_upgrade_selected: null
    }
  }

  componentDidMount() {
    const self = this;
    const { user_product_id } = this.props.routeProps.match.params;
    
    axios({
      method: 'get',
      url: window.api_url + '/user/service/upgrade/'+ user_product_id,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          ...self.state,
          result_data: response.data.data
        })
      }
    });
  }

  onRadioCheck = (service_selected) => {
    this.setState({
      service_upgrade_selected: service_selected
    })
  }

  onNextBtnClick = () => {
    const {result_data, service_upgrade_selected} = this.state;
    const { addItem, updateCart, history, setAgencyCustomer, userProfile } = this.props;
    const item = {
      id: uuidv4(),
      product: service_upgrade_selected.upgrade_item.product,
      month: result_data.duration_date.months + (result_data.duration_date.years *12), 
      day: result_data.duration_date.days,
      quantity: 1,
      domain_name: result_data.user_product.domain,
      domain_profile_id: null,
      ip_address: result_data.user_product.ip_address,
      template_id: result_data.user_product.template_id, //template id để sử dung cho vps.
      user_product_id: result_data.user_product.id, //Dịch vụ Nâng cấp 
      is_renew: false,
      is_upgrade: true,
      is_tranfer: false,
      is_delivery: false,
      parent_id: null,
      update_price: service_upgrade_selected.total_upgrade_price,
      order: 0 //Vị trí của item trong giỏ hàng
    }
    userProfile.role == "AGENCY" && setAgencyCustomer(result_data.user_product.user_id);
    addItem(item); 
    updateCart().then(() => {
      history.push('/products/cart');
    });
  }

  render() {
    const { result_data, service_upgrade_selected } = this.state;
    return(
      <React.Fragment>
        {!result_data && <div className="card">
          <div className="card-body">
            <span>Dịch vụ không tồn tại</span>
          </div>
        </div>}
        {result_data && 
        <div>
          <div className="page-header">
            <div className="page-header-content header-elements-md-inline">
              <div className="page-title d-flex">
                <h4>
                  <i className="icon-paragraph-justify2 mr-2"></i>
                  <span className="text-semibold">Nâng Cấp Dịch Vụ</span>
                </h4>
              </div>
            </div>
          </div>
          <br/>
          <div className="card card-white">
            <div className="card-body">
              <div style={{marginBottom: '30px'}}>
                Thời gian còn lại: {" "}  
                <span className="text-primary">
                    {result_data.duration_date.days} ngày {result_data.duration_date.months} tháng {result_data.duration_date.years} năm
                </span>
                <br/>
                Đơn giá cũ: <span className="text-danger">{numeral(result_data.current_item_price).format("0,0")} đ</span>
                <br/>
                Cách tính: <span className="text-danger">phí chênh lệch 1 tháng * số tháng + phí chênh lệch 1 tháng / 30 ngày * số ngày</span>
              </div>
              {ServiceUpgradeRows(result_data.services_upgrade_price, this.onRadioCheck)}
              <div className="text-right">
                <button disabled={!service_upgrade_selected && true} onClick={()=>this.onNextBtnClick()} type="submit" className="btn btn-warning btn-xlg" style={{marginBottom: "10px"}}>Tiếp tục <span className="icon-arrow-right7"></span></button>
              </div>
            </div>
          </div>
        </div>}
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  cart: state.cart,
	userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({
  addItem: (item) => dispatch.cart.addItem(item),
  setNote: (note) => dispatch.cart.setNote(note),
  updateCart: () => dispatch.cart.updateCart(),
  setAgencyCustomer: (agency_customer_id) => dispatch.cart.setAgencyCustomer(agency_customer_id)

});

export default connect(mapState, mapDispatch)(withRouter(ServiceUpgradePage));