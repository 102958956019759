
import React from 'react'


class AlertLabel extends React.Component {

    render() {
        const {status, message} = this.props;
        let inner_alert_label = (
                <div className="alert alert-success alert-styled-left alert-dismissible">
                       {message}
                </div>
            )
        if(status === "ERROR") {
            inner_alert_label = (
                <div className="alert alert-warning alert-styled-left alert-dismissible">
                       {message}
                </div>
            )
        }
        if(status === "INFO") {
            inner_alert_label = (
                <div className="alert alert-info alert-styled-left alert-dismissible">
                    {message}
                </div>
            )
        }
        return inner_alert_label;
            
    }

}

export default AlertLabel;