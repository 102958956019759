import React from 'react'


function TicketStatus(status) {
    return(
        <React.Fragment>
            {status == "DREPLIED" && <span className="badge badge-success">ĐÃ TRẢ LỜI</span>}
            {status == "ANEW" && <span className="badge badge-info">MỚI</span>}
            {status == "BPROCESS" && <span className="badge badge-danger">ĐANG XỬ LÝ</span>}
            {status == "FCLOSED" && <span className="badge badge-secondary">ĐÓNG</span>}
        </React.Fragment>
    )
}

export default TicketStatus;