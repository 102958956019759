import axios from 'axios';
import moment from 'moment';
import { objectToFormData } from 'object-to-formdata';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, formValueSelector, reduxForm, SubmissionError } from 'redux-form';
import { v4 as uuidv4 } from 'uuid';
import { RenderCheckbox, RenderFileUpload, RenderHTMLSelect, RenderInputWithDiv, RenderSelect, RenderTextArea, RenderTextEditor } from '../../../component/formInput';
import BlockUI from '../../../component/BlockUI';
import { SocketUtils, StringUtils } from '../../../utils/javascriptUtils';

var _ = require('lodash');

const selector = formValueSelector("ticketForm")


const validate = values => {
  const errors = {};
  if(values.service_type != "OTHER" && !values.user_product_id) {
    errors.user_product_id = "Vui lòng chọn dịch vụ cần hỗ trợ !";
  }
  return errors;
}

const mapState = state => ({
  service_type_selected: selector(state,"service_type"),
  userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({
  updateField: (fieldName, value) =>
  dispatch({
    meta: { form: "ticketForm", field: fieldName },
    payload: value,
    type: "@@redux-form/CHANGE"
  })
});

class TicketForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department_options: [],
      user_product_data: [],
      isLoading: false
    }
    this.loadUserProductByType = this.loadUserProductByType.bind(this);
    this.channel = SocketUtils.socketStore();
    this.channel.join();
  }
  loadUserProductByType(value) {
    const self = this;
    const { updateField } = this.props;
    // Reset selectbox field when change user_product
    updateField("user_product_id", null);
    if(value != "OTHER") {
      axios({
        method: 'post',
        url: window.api_url + '/user/service/list',
        data: value.split(', '),
        withCredentials: true
      }).then(function (response) {
        const user_product_data = _.remove(response.data.data, (i) => !["EXPIRED","PAUSE","SUSPEND","DELETED"].includes(i.status))
        self.setState({
          user_product_data: user_product_data,
        })
      });
    }else {
      self.setState({
        user_product_data: []
      })
    }
  }
  
  componentDidMount() { 
    const self = this;
    axios({
      method: 'get',
      url: window.api_url + '/user/department',
      withCredentials: true
    }).then(function (response) {
      const department_options = _.map(response.data.data, function(i) {
        return {label:i.name, value: i.id};
      });
      self.setState({
        department_options: department_options,
      })
    });
  }

  sendSocketChannel = (userProfile, agency_ticket_id) => {
    let channel_name = "crm_ticket_modify";
    let params = {body: "Success To Send Ticket Modify"};
    // Use for user has Agency available ticket support.
    if(userProfile && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      channel_name = "agency_ticket_modify";
      params = {agency_ticket_id: agency_ticket_id, body: "Success To Send Agency Ticket Modify"};
    }
    this.channel.push(channel_name, params);
  }
  
  submit = (values) => {
    const self = this;
    const { history, userProfile } = this.props;
    const { user_product_data } = this.state;
    const user_product = _.find(user_product_data, (i) => i.id == values.user_product_id);
    const reset_password_note = values.note_check ? " Reset lại mật khẩu, sau khi xử lý xong gửi lại thông tin qua ticket này." : ""
    // kiểm tra quyền truy cập để gửi yêu cầu hỗ trợ
    let request_url = '/user/ticket/create';
    // Sử dụng cho khách hàng của đại lý có mở chức năng trả lời ticket.
    if(userProfile && userProfile.agency_id && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      request_url = "/user/agency/ticket/create";
    }
    const data_value = {
      code: uuidv4(),
      employee_id: 0, 
      department_id: values.department_id,
      status: "ANEW",
      priority: 0,
      user_product_id: values.user_product_id,
      title: values.title,
      content: values.content,
      ip_address: user_product && user_product.ip_address ? user_product.ip_address : " ", 
      is_loop: false,
      is_loop_sold: false,
      admin_read: false,
      is_notify: true,
      ticket_from: "id.tadu.cloud",
      created_date: moment().format("YYYY-MM-DD HH:mm:ss"),            
      updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      note: values.note ? (values.note + reset_password_note) : reset_password_note,
      file_upload: values.file_upload && values.file_upload[0]
    }
    // convert Object to FormData
    const formData = objectToFormData(data_value);
    this.setState({
        isLoading: true
    })
    return axios({
      method: 'post',
      url: window.api_url + request_url,
      data: formData,
      withCredentials: true
    }).then(function (response) {
      self.setState({
        isLoading: false
      })
      if(!response.data.isError) {
        self.sendSocketChannel(userProfile, response.data.data);
        history.push("/user/ticket/detail/"+ response.data.data)
      }else {
        throw new SubmissionError(response.data.error)
      }
      });
  }

  render() {
    const { handleSubmit, submitting, service_type_selected } = this.props;
    const {department_options, isLoading, user_product_data  } = this.state;
    const product_type_options = [
      {label: "Tên miền - Domain", value: "COM_DOMAIN, VN_DOMAIN"},
      {label: "Hosting", value: "LINUX_HOSTING, WINDOW_HOSTING"},
      {label: "Máy chủ ảo VPS", value: "VPS, CLOUD_SERVER_PRO"},
      {label: "Email Server", value: "EMAIL"},
      {label: "Khác", value: "OTHER" }
    ];
    // Customize label for user_product_options
    const user_product_options = _.map(user_product_data, function (i) {
        // chỉ show các service có tên do người dùng đặt.
        const product_name = !StringUtils.isEmptyOrSpaces(i.name) ? " ( " + i.name + " ) " : ""
        if(!["VPS", "CLOUD_SERVER_PRO"].includes(i.product.product_type)){
          return {label:i.product.name + " - " + i.domain , value: i.id}
        } else {
          return {label:i.product.name + " - " + i.ip_address + product_name , value: i.id} 
        }
    });
    return (
      <React.Fragment>
        {isLoading && <BlockUI /> }
        <div className="page-header">
            <h4>
              <i className=" icon-paragraph-justify2 position-left"></i>
              <span className="text-semibold"> Gửi Yêu Cầu Hỗ Trợ</span>
            </h4>
          </div>
          <div className="card" style={{ backgroundColor:'white' }} >
            <form onSubmit={handleSubmit(this.submit)}>
                <br/>
                {department_options.length > 0 && <div style={{margin:20}} className="form-group">
                  <Field
                    name="department_id"
                    disabled
                    options={department_options}
                    defaultValue={department_options[0]}
                    label="Phòng ban"
                    placeholder="Chọn phòng ban"
                    component={RenderSelect}
                  />
                  <Field
                    name="service_type"
                    label="Loại Dịch Vụ"
                    placeholder="Chọn loại dịch vụ"
                    options={product_type_options}
                    component={RenderSelect}
                    onChangeAction={(value) => this.loadUserProductByType(value)}
                  />
                  <Field
                    name="user_product_id"
                    label="Dịch vụ cần hỗ trợ"
                    placeholder="Vui lòng chọn đúng dịch vụ cần hỗ trợ"
                    options={user_product_options}
                    component={RenderSelect}
                  />
                  {service_type_selected && service_type_selected.includes("VPS") && <Field
                    name="note"
                    label="Thông tin quản trị VPS (SSH, Direct Admin, ...)"
                    rows="4"
                    component={RenderTextArea}
                  />}
                  {service_type_selected && service_type_selected.includes("VPS") && <Field
                    name="note_check"
                    label=" "
                    checkLabel="Reset lại mật khẩu, sau khi xử lý xong gửi lại thông tin qua ticket này."
                    component={RenderCheckbox}
                  />}
                  <Field
                    name="title"
                    label="Tiêu đề"
                    placeholder="Tiêu để cần hỗ trợ"
                    component={RenderInputWithDiv}
                  />
                  <Field
                    name="content"
                    label="Nội Dung"
                    placeholder="Tiêu để cần hỗ trợ"
                    defaultValue=""
                    component={RenderTextEditor}
                  />
                  <Field
                    name="file_upload"
                    label="File đính kèm"
                    fileAccept="image/*,.zip"
                    component={RenderFileUpload}
                  />
                </div>}
                <div className="modal-footer row">
                  <div className="col-sm-2">

                  </div>
                  <div className="col-sm-10">
                    <button  type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
                      Gửi
                    </button>
                  </div>
                </div>
            </form>
        </div>
      </React.Fragment>
    )
  }
}


const TicketFormWrapper = reduxForm({
  form: 'ticketForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(TicketForm);

export default connect(mapState, mapDispatch)(withRouter(TicketFormWrapper));