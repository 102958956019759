import axios from 'axios';
import moment from 'moment';
import { objectToFormData } from 'object-to-formdata';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { RenderFileUpload, RenderTextEditor } from '../../component/formInput';
import TicketStatus from '../../component/TicketStatus';
import { StringUtils, SocketUtils } from '../../utils/javascriptUtils';
import BlockUI from '../../component/BlockUI';
import FileRender from '../../component/FileRender';

var _ = require('lodash');

function TicketTable(data) {
return (
  <div className="table-responsive">
     <table className="table table-togglable table-hover">
       <thead>
       <tr>
         <th data-toggle="true">Mã</th>
         <th data-hide="phone">Phòng ban</th>
         <th data-hide="phone">Trạng thái</th>
         <th data-hide="phone,tablet">Ngày</th>
         <th data-hide="phone,tablet">Dịch vụ</th>
       </tr>
       </thead>
       <tbody>
         <tr>
           <td>{data.code}</td>
           <td>{data.department.name}</td>
           <td><span className="label label-success">{TicketStatus(data.status)}</span></td>
           <td>{data.updated_date}</td>
           <td>
             {data.user_product && data.user_product.ip_address && <strong>{data.user_product.ip_address}<br/></strong>}
             {data.user_product ? data.user_product.domain : "Khác" }
             <span className="display-block text-muted">{data.user_product && data.user_product.product.name}</span>
           </td>
         </tr>
       </tbody>
     </table>
  </div>)
}

function TicketCommentRows(ticket_comments, customer_id, onLikeBtn) {
const inner_ticket_rows = _.map(ticket_comments, function(i, key) {
  const button_style = { borderRadius: "5px", borderColor: "transparent", fontSize: 10, backgroundColor:  i.total_like > 0 ? "#64B5F6" : "#999999" }; 
  const inner_button_like = (
    <div key={key} className="card-footer">
      <div>
        <button onClick={()=>onLikeBtn(i.id, i.total_like)} style={button_style}>
          <i style={{color: 'white'}} className="icon-thumbs-up3"></i>
        </button>
        &nbsp;
        <span className="badge badge-pill bg-warning-400 ml-auto ml-md-0">
          {i.total_like > 0 && "+ " + i.total_like }
        </span> 
      </div>
    </div>)
  let inner_border_top_color = "#00bcd4"; //Info

  if(customer_id == i.user.id){
    inner_border_top_color = "#ff5722"; // Warning
  }
  return (
    <div key={key} className="row">
      <div className="col-md-12">
        <div style={{borderTop:"2px solid " +inner_border_top_color }} className={"card"}>
          <div className="card-body">
            <div className="panel-heading">
              <h6 className="panel-title text-info">
                {i.user.full_name} - [ {i.created_Date} ]
              </h6>
              {ReactHtmlParser(i.content)}
            </div>
          </div>
          {!StringUtils.isEmptyOrSpaces(i.file_url) && <div className="card-footer">
            <i className="icon-download7"></i> 
            <FileRender directory="ticket-upload" file_name={i.file_url} isDisplay={false} />
          </div>}
          {customer_id != i.user.id && inner_button_like}
        </div>
      </div>
    </div>
  )
})

return inner_ticket_rows;
}

class TicketDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ticket: null,
      isLoading: false
    }
    this.submit = this.submit.bind(this);
    this.reload = this.reload.bind(this);
    this.channel = SocketUtils.socketStore();
    this.channel.join();
  }

  socketLiveReload() {
    const self = this;
    const {userProfile, ticketModifyReload, agencyTicketModifyReload} = this.props;
    let channel_name = "tadu_customer_ticket_modify";
    if(userProfile && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      channel_name = "agency_customer_ticket_modify";
      agencyTicketModifyReload();
    }
    this.channel.on(channel_name, response => {
      // Chỉ live reload với đúng khách hàng của ticket này.
      if(userProfile && userProfile.email == response.customer_email) {
        self.reload();
        ticketModifyReload();
      }
    })
  }

  sendSocketChannel = (userProfile, ticket_id) => {
    let channel_name = "crm_ticket_modify";
    let params = {body: "Success To Send Ticket Modify"};
    // Use for user has Agency available ticket support.
    if(userProfile && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      channel_name = "agency_ticket_modify";
      params = {agency_ticket_id: ticket_id, body: "Success To Send Agency Ticket Modify"};
    }
    this.channel.push(channel_name, params);
  }

  reload() {
    const { ticket_id } = this.props.match.params;
    const self = this;
    const { userProfile, ticketModifyReload } = this.props;
    let url_request = '/user/ticket/detail/'+ticket_id;
    // Use for user has Agency available ticket support.
    if(userProfile && userProfile.agency_id && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      url_request = '/user/agency/ticket/detail/' + ticket_id;
    }
    axios({
      method: 'get',
      url: window.api_url + url_request,
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          ticket: response.data.data
        })
        ticketModifyReload();
      }
    });
  }

  submit(values) {
    const { ticket_id } = this.props.match.params;
    const { reset, ticketModifyReload, userProfile } = this.props;
    const self = this;
    let url_request = '/user/ticket_comment/create';
    // Use for user has Agency available ticket support.
    if(userProfile && userProfile.agency_id && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      url_request = "/user/agency/ticket_comment/create";
    }
    const data_value = {
      ticket_id: ticket_id,
      content: values.content,
      created_Date: moment().format("YYYY-MM-DD HH:mm:ss"),
      ticket_from: "id.tadu.cloud",
      admin_read: false, // TODO admin read,
      note: " ",
      is_tranfer: false,
      file_upload: values.file_upload && values.file_upload[0],
    }
    // convert Object to FormData
    const formData = objectToFormData(data_value);
    this.setState({
      isLoading: true
    })

    return axios({
      method: 'post',
      url: window.api_url + url_request,
      data: formData,
      withCredentials: true
    }).then(function (response) {
      self.setState({
        isLoading: false
      })
      if(!response.data.isError){
        self.reload();
        self.sendSocketChannel(userProfile, ticket_id);
        ticketModifyReload();
        reset();
      }else {
        throw new SubmissionError(response.data.error)
      }
    });
  }

  
  componentDidMount() {
    this.reload();
    this.socketLiveReload();
  }
  
  componentDidUpdate(prevProps, prevState) {
    const ticket_id = prevProps.routeProps.match.params.ticket_id;
    const new_ticket_id = this.props.routeProps.match.params.ticket_id;
    if(ticket_id != null && ticket_id != new_ticket_id) {
      this.reload();
    }
  }
  
  onLikeBtn = (ticket_comment_id, total_like) => {
    const { ticket_id } = this.props.match.params;
    const { userProfile } = this.props;
    const self = this;
    let url_request = "/user/ticket_comment/like";
    // Use for user has Agency available ticket support.
    if(userProfile && userProfile.is_ticket && userProfile.role == "CUSTOMER") {
      url_request = "/agency/ticket_comment/like";
    }
    axios({
      method: 'post',
      url: window.api_url + url_request,
      data: {
        id: ticket_comment_id,
        total_like: total_like + 1
      },
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError){
        self.reload();
        self.sendSocketChannel(userProfile, ticket_id);
      }
    });
  }
  
  render() {
    const {handleSubmit, submitting} = this.props;
    const { ticket, isLoading } = this.state;
    const {userProfile} = this.props;
    return(
      <React.Fragment>
        {isLoading && <BlockUI/>}
        {/* Content area */}
        {!ticket && <div>
          <div className="card card-body">
            <span> Không tìm thấy dịch vụ hỗ trợ !</span>
          </div>
        </div>}
        {ticket && <React.Fragment>
          {/* Page header */}
          <div className="page-header">
              <h4>
                  <i className=" icon-paragraph-justify2 position-left"></i>
                  <span className="text-semibold"> Mã Hỗ Trợ <a>#{ticket.code}</a></span>
              </h4>
          </div>
          {/* Page header */}
          <div className="row">
              <div className="col-md-12">
                  <div className="card">
                      <div className="card-body">
                          {TicketTable(ticket)}
                      </div>
                  </div>
              </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card" style={{borderTop:"2px solid #ff5722"}}>
                <div className="card-body">
                  <div className="panel-heading">
                    <h6 className="panel-title text-warning">
                    {ticket.title} - [ {ticket.created_date} ]
                    </h6>
                  </div>
                  <div className="panel-body">
                    {ReactHtmlParser(ticket.content)} 
                    <br/>
                  </div>
                </div>
                {!StringUtils.isEmptyOrSpaces(ticket.file_url) && <div className="card-footer">
                  <i className="icon-download7"></i> 
                  <FileRender directory="ticket-upload" file_name={ticket.file_url} isDisplay={false} />
                </div>}
              </div>
            </div>
          </div>
          {TicketCommentRows(ticket.ticket_comments, ticket.user_id, this.onLikeBtn)}
          <div className="card panel-white">
            <div>
              <form onSubmit={handleSubmit(this.submit)}>
                <br/>
                <div style={{margin:20}} className="form-group">
                  <Field
                    name="content"
                    placeholder="Tiêu để cần hỗ trợ"
                    defaultValue=""
                    component={RenderTextEditor}
                  />
                  <Field
                    name="file_upload"
                    label="File đính kèm"
                    fileAccept="image/*,.zip"
                    component={RenderFileUpload}
                  />
                </div>
                <div className="modal-footer">
                    <button type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
                      Gửi
                    </button>
                </div>
              </form>
            </div>
          </div>
        </React.Fragment>}
        {/*content area */}
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({
  updateField: (fieldName, value) =>
  dispatch({
    meta: { form: "ticketDetail", field: fieldName },
    payload: value,
    type: "@@redux-form/CHANGE"
  }),
  ticketModifyReload: () => dispatch.messageModify.ticketModifyReload(),
  agencyTicketModifyReload: () => dispatch.messageModify.agencyTicketModifyReload()
});

const TicketDetailWrapper = reduxForm({
  form: 'ticketDetail',
  destroyOnUnmount: true,
  enableReinitialize: true,
  // validate
})(withRouter(TicketDetail));

export default connect(mapState, mapDispatch)(withRouter(TicketDetailWrapper));
