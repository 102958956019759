import React from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { province_exts } from '../../models/domain_ext';
import { connect } from 'react-redux';

var numeral = require('numeral');
var _ = require('lodash');

// Truyền vào danh sách tên miền và mảng cần group.
// VD: ["VN"] || ["COM_VN", "NET.VN"]
function VNDomainGroup(domain_list, array_group) {
    const domain_matches = _.filter(domain_list, (domain) => array_group.includes(domain.ext_code));
    const ext_list = domain_matches && _.map(domain_matches,( i ) => i.domain_name );

    const province_domain = array_group.includes("PROVINCE_EXT")  ? "|  Tên miền địa giới hành chính" : "";
    const item = domain_matches[0];
    return (
      domain_matches.length > 0 && <tr key= {array_group}>
        <td>
          {/* Nhóm lại những tên miền theo khu vực tỉnh thành */}
          { _.join(ext_list," | ")}{province_domain} 
        </td>
        <td>
          <strong>{numeral(item.regis_price_with_vat).format("0,0")}<sup>đ/năm</sup></strong>
          <br/>
            <small>(Chi phí dịch vụ: {numeral(item.regis_service_price).format("0,0")}<sup>đ/năm</sup>)</small>
        </td>
        <td>
          <strong>{numeral(item.renew_price_with_vat).format("0,0")}<sup>đ/năm</sup></strong>
          <br/>
            <small>(Chi phí dịch vụ: {numeral(item.renew_service_price).format("0,0")}<sup>đ/năm</sup>)</small>
        </td>
      </tr>
    )  
}

function COMDomainGroup(domain_list, array_group) {
  const domain_matches = _.filter(domain_list, (domain) => array_group.includes(domain.ext_code));
  const ext_list = domain_matches && _.map(domain_matches,( i ) => i.domain_name );
  const item = domain_matches[0];
  return (
    domain_matches.length > 0 && <tr key= {array_group}>
      <td>
        { _.join(ext_list," | ")} 
      </td>
      <td>
        <strong>{numeral(item.regis_price_with_vat).format("0,0")}<sup>đ/năm</sup></strong>
      </td>
      <td>
        <strong>{numeral(item.renew_price_with_vat).format("0,0")}<sup>đ/năm</sup></strong>
      </td>
    </tr>
  )  
}

function GroupDomainRemain(domain_list, exist_domain) {
  const domain_matches = domain_list.filter((domain) => !exist_domain.includes(domain.ext_code));
  var groups = _.groupBy(domain_matches, function(value){
      return value.regis_price_with_vat + "#" + value.renew_price_with_vat;
  });
  var data = _.map(groups, function(group){
    return {
      regis_price_with_vat: group[0].regis_price_with_vat,
      renew_price_with_vat: group[0].renew_price_with_vat,
      domain_name_list: _.map(group, 'domain_name'),
      ext_code: _.map(group, 'ext_code')
    }
  });
  
  return data.length > 0 && _.map(data, (item, key) => (
      domain_matches.length > 0 && <tr key= {item.ext_code}>
        <td>
          { _.join(item.domain_name_list," | ")} 
        </td>
        <td>
          <strong>{numeral(item.regis_price_with_vat).format("0,0")}<sup>đ/năm</sup></strong>
        </td>
        <td>
          <strong>{numeral(item.renew_price_with_vat).format("0,0")}<sup>đ/năm</sup></strong>
        </td>
      </tr>
  ))

}

function VNDomainRows(data) {
  let vn_domain_rows = [];
  vn_domain_rows = vn_domain_rows.concat(VNDomainGroup(data, ["VN"]))
    .concat(VNDomainGroup(data, ["COM_VN"]))
    .concat(VNDomainGroup(data, ["NET_VN", "BIZ_VN"]))
    .concat(VNDomainGroup(data, ["NAME_VN"]))
    .concat(VNDomainGroup(data, ["ORG_VN","GOV_VN","PRO_VN", "INFO_VN", "INIT_VN", "AC_VN", "HEATH_VN", "PROVINCE_EXT"]));
  return vn_domain_rows;
}

function COMDomainRows(data) {
  let com_domain_rows = [];
  const domain_has_groups = ["COM","ASIA","NET","ORG","INFO","COM_DE","ICU","RODEO","COMPANY","BUSINESS","IN_NET","WORK","LINK","CLICK","JP_NET","TOP","US","GB"];
  com_domain_rows = com_domain_rows.concat(COMDomainGroup(data,	["COM"]))
  .concat(COMDomainGroup(data, ["ASIA"]))
  .concat(COMDomainGroup(data, ["NET"]))
  .concat(COMDomainGroup(data, ["ORG"]))
  .concat(COMDomainGroup(data, ["INFO"]))
  .concat(COMDomainGroup(data, ["COM_DE"]))
  .concat(COMDomainGroup(data, ["ICU"]))
  .concat(COMDomainGroup(data, ["RODEO"]))
  .concat(COMDomainGroup(data, ["COMPANY", "BUSINESS"]))
  .concat(COMDomainGroup(data, ["IN_NET"]))
  .concat(COMDomainGroup(data, ["WORK"]))
  .concat(COMDomainGroup(data, ["LINK"]))
  .concat(COMDomainGroup(data, ["CLICK"]))
  .concat(COMDomainGroup(data, ["JP_NET"]))
  .concat(COMDomainGroup(data, ["TOP"]))
  .concat(COMDomainGroup(data, ["US"]))
  .concat(COMDomainGroup(data, ["GB"]))
  .concat(GroupDomainRemain(data, domain_has_groups)); // show ra những tên miền còn lại
  return com_domain_rows;
}



class DomainPriceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result_data: [],
      vn_domain_discount: {},
      com_domain_discount: {}
    }
  }

  componentDidMount() {
    const self = this;
    return axios({
      method: 'get',
      url: window.api_url + '/product/domain/prices',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        result_data: response.data.data
      })
      self.get_agency_pricing_by_level();
    });
  }

  get_agency_pricing_by_level() {
    const self = this;
    const {userProfile} = this.props;
    if(userProfile && userProfile.role == "AGENCY") {
      axios({
        method: 'get',
        url: window.api_url + '/agency/pricing/product_type/'+"VN_DOMAIN"+'/level/'+ userProfile.agency_level,
        withCredentials: true
        }).then(function (response) {
        self.setState({
          ...self.state,
          vn_domain_discount: response.data.data
        })
      });
      axios({
        method: 'get',
        url: window.api_url + '/agency/pricing/product_type/'+"COM_DOMAIN"+'/level/'+ userProfile.agency_level,
        withCredentials: true
        }).then(function (response) {
        self.setState({
          ...self.state,
          com_domain_discount: response.data.data
        })
      });
    }
  }

  render() {
    const {result_data, vn_domain_discount, com_domain_discount} = this.state;
    const { userProfile } = this.props;
    const vn_domain_data = _.find(result_data, (i) => i.product_type == "VN_DOMAIN");
    const com_domain_data = _.find(result_data, (i) => i.product_type == "COM_DOMAIN");
    return(
      <React.Fragment>
        
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Bảng giá tên miền</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body panel-flat">
              <div>
                <h2>TÊN MIỀN VIỆT NAM</h2>
                <div className="card">
                  <table className="table table-togglable table-hover footable-loaded footable default">
                    <thead className="alpha-slate">
                    <tr>
                      <th data-toggle="true" className="footable-visible footable-first-column">TÊN MIỀN .VN
                        <br/>
                        {userProfile && userProfile.role == "AGENCY" && <small className="text-muted">(% Chiết Khấu Theo Phí Dịch Vụ)</small>}
                      </th>
                      <th style={{whiteSpace: 'nowrap'}} data-toggle="true" className="footable-visible footable-first-column">
                        ĐĂNG KÝ MỚI
                        <br/>
                        <small className="text-muted">(Giá đã bao gồm VAT)</small>
                        <br/>
                        {userProfile && userProfile.role == "AGENCY" && <small className="text-muted">(Chiết Khấu {vn_domain_discount.register_discount}%)</small> }
                      </th>
                      <th style={{whiteSpace: 'nowrap'}} data-toggle="true" className="footable-visible footable-first-column">
                        GIA HẠN
                        <br/>
                        <small className="text-muted">(Giá đã bao gồm VAT)</small>
                        <br/>
                        {userProfile && userProfile.role == "AGENCY" && <small className="text-muted">(Chiết Khấu {vn_domain_discount.renew_discount}%)</small> }
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                      {vn_domain_data && VNDomainRows(vn_domain_data.domain_list)}
                    </tbody>
                  </table>
                </div>
                <h2>TÊN MIỀN QUỐC TẾ</h2>
                <div className="card">
                  <table className="table table-togglable table-hover footable-loaded footable default">
                    <thead className="alpha-slate">
                    <tr>
                      <th data-toggle="true" className="footable-visible footable-first-column">TÊN MIỀN
                        <br/>
                        {userProfile && userProfile.role == "AGENCY" && <small className="text-muted">(% Chiết Khấu Theo Phí Dịch Vụ)</small>}
                      </th>
                      <th data-toggle="true" className="footable-visible footable-first-column">
                        ĐĂNG KÝ MỚI
                        <br/>
                        <small className="text-muted">(Giá đã bao gồm VAT)</small>
                        <br/>
                        {userProfile && userProfile.role == "AGENCY" && <small className="text-muted">(Chiết Khấu {com_domain_discount.register_discount}%)</small>}
                      </th>
                      <th data-toggle="true" className="footable-visible footable-first-column">
                        GIA HẠN
                        <br/>
                        <small className="text-muted">(Giá đã bao gồm VAT)</small>
                        <br/>
                        {userProfile && userProfile.role == "AGENCY" &&<small className="text-muted">(Chiết Khấu {com_domain_discount.renew_discount}%)</small>}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                      {com_domain_data && COMDomainRows(com_domain_data.domain_list)}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  userProfile: state.userProfile.data,
});

const mapDispatch = (dispatch) => ({
});
export default connect(mapState, mapDispatch)(withRouter(DomainPriceTable));



