import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { CartUtils } from '../utils/javascriptUtils';

var _ = require('lodash');
export const count = {
  // initial state
  state: 0,
  reducers: {
    // handle state changes with pure functions
    increment(state, payload) {
      return state + payload
    }
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async incrementAsync(payload, rootState) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch.count.increment(payload)
    }
  })
};

export const app = {
    state: {
      isLoading: true,
      login: false
    },
    reducers: {
      signin(state) {
        return {
          ...state,
          isLoading: false,
          login: true
        };
      },
      signout(state) {
        return {
          ...state,
          isLoading: false,
          login: false
        };
      },
    },
    effects: (dispatch) => ({
      checkTokenAsync() {
        const user_permissions = ["CUSTOMER", "AGENCY"];
        
        axios({
          method: 'get',
          url: window.api_url + '/auth/user/info',
          withCredentials: true
        }).then(function (response) {
          const auth_data = response.data.data;

            // Khách hàng lẻ và đại lý tadu.
            if(auth_data && user_permissions.includes(auth_data.role) && !auth_data.has_custom_brand && !auth_data.is_lock && auth_data.is_active) {
              dispatch.app.signin();
            }
            // Đại lý thương hiệu riêng.
            else if(auth_data && auth_data.role == "AGENCY" && auth_data.has_custom_brand && !auth_data.is_lock && auth_data.is_active) {
              dispatch.app.signin();
            }
            else {
              dispatch.app.signoutAsync();
            }
        }).catch(function (error) {
            if (error.response && error.response.status === 403) {
              dispatch.app.signout();
            } else {
              console.log(error);
            }
        });
      },
      async signoutAsync() {
        await axios({
          method: 'post',
          url: window.api_url + '/auth/logout',
          withCredentials: true
        });
        dispatch.app.signout();
        window.location.href = "/";
      },
      async getUserToken() {
        await axios({
          method: 'get',
          url: window.api_url + "/auth/token",
          withCredentials: true
        }).then(function (response) {
          window.userToken = response.data.data;
        })
      },
    })
}

export const customerAgency = {
  state: {
    profile: null,
    list: []
  },
  reducers: {
    loadProfile(state, data) {
      return {
        ...state,
        profile: data
      }
    },
    loadList(state, data) {
      return {
        ...state,
        list: data
      }
    }
  },
  effects: (dispatch) => ({
    async loadCustomerProfile(customer_id) {
      const res = await axios({
        method: 'get',
        url: window.api_url + '/user/agency/customer/' + customer_id,
        withCredentials: true
      });
      dispatch.customerAgency.loadProfile(res.data.data);
    },
    async loadCustomerList() {
      const res = await axios({
        method: 'get',
        url: window.api_url + '/user/agency/customer-list',
        withCredentials: true
      });
      dispatch.customerAgency.loadList(res.data.data);
    }
  })
}

export const userProfile = {
    state: {
      data: null,
      agency_customer: null,
      agency_customer_list: []
    },
    reducers: {
      load(state, data) {
        return {
          ...state,
          data: data
        };
      },
      loadCustomer(state, data) {
        return {
          ...state,
          agency_customer: data
        };
      },
      loadCustomerList(state, data) {
        return {
          ...state,
          agency_customer_list: data
        };
      }
    },
    effects: (dispatch) => ({
      async loadUserAsync() {
        const res = await axios({
          method: 'get',
          url: window.api_url + '/auth/user/info',
          withCredentials: true
        });
        dispatch.userProfile.load(res.data.data);
      }
    })
}

export const agencyProfile = {
  state: {
    data: null
  },
  reducers: {
    load(state, data) {
      return {
        ...state,
        data: data
      };
    }
  },
  effects: (dispatch) => ({
    async loadAgencyAsync() {
      const res = await axios({
        method: 'get',
        url: window.api_url + '/agency/info',
        withCredentials: true
      });
      dispatch.agencyProfile.load(res.data.data);
    }
  })
}

export const cart = {
  state: {
    items: [],
    discount_code: null,
    agency_customer_id: null, // Sử dụng để gán ID của KH đại lý vào nếu đơn hàng được đại lý đăng ký dùm.
    discount_money: 0,
    request_support: "",
    payment_type: "",
    cod_address: "",
    note: "",
    customer_id: null,
    tax_money: 0,
    // total_without_tax: 0,
    sub_total: 0,
    total: 0
  },
  reducers: {
    setCustomer(state, customer_id) {
      return {
        ...state,
        customer_id: customer_id
      };
    },
    addItem(state, item) {
      item.group_id = CartUtils.ItemGroupIdGenerator(state);
      state.items = CartUtils.CheckServiceExist(item, state);
      
      return {
        ...state,
        items: state.items.concat(item)
      };
    },
    setCart(state, cart) {
      return cart;	
    },
    setNote(state, note) {
      return {
        ...state,
        note: note
      };
    },
    setDiscountCode(state, discount_code) {
      return {
        ...state,
        discount_code: discount_code
      };
    },
    setDiscountMoney(state, discount_money) {
        return {
          ...state,
          discount_money: discount_money
        };
      },
    setAgencyCustomer(state, new_agency_customer_id) { // Set agency_customer_id into cart, if agency regis service for their customer.
      state.items = CartUtils.CheckAgencyCustomerCart(state, state.agency_customer_id, new_agency_customer_id);
      
      return {
        ...state,
        items: state.items,
        agency_customer_id: new_agency_customer_id
      }
    }
  },
  effects: (dispatch) => ({
    async loadCart() {
      const res = await axios({
        method: 'get',
        url: window.api_url + '/user/cart',
        withCredentials: true
      });
    
      dispatch.cart.setCart(res.data.data);
    },
    async updateCart(_, rootState) {
      const res = await axios({
        method: 'post',
        url: window.api_url + '/user/cart',
        withCredentials: true,
        data: rootState.cart
      });
    
      dispatch.cart.setCart(res.data.data);
    },
    async deleteItem(item_group_id, rootState) {
      // Xoá item + addon item nếu có.
      const items = _.remove(rootState.cart.items, (i) => i.parent_id != item_group_id && i.group_id != item_group_id)
      const res = await axios({
        method: 'post',
        url: window.api_url + '/user/cart',
        withCredentials: true,
        data: {
          ...rootState.cart,
          items: items
        }
      });
    
      dispatch.cart.setCart(res.data.data);
    },
    async emptyCart(_, rootState) {
      const res = await axios({
        method: 'delete',
        url: window.api_url + '/user/cart',
        withCredentials: true,
        data: rootState.cart
      });
    
      dispatch.cart.setCart(res.data.data);
    },
    async addDomainWhois(item, rootState) {
      const addon_item = _.find(rootState.cart.items, (i) => i.parent_id == item.group_id);

      if (addon_item == null) {
        const rep = await axios({
          method: 'get',
          url: window.api_url + '/product/price/whois',
          withCredentials: true
        });

        const whois_item = {
          id: uuidv4(),
          group_id: null,
          product: rep.data.data[0].product,
          price:rep.data.data[0],
          month: item.month,
          quantity: 1,
          domain_name: item.domain_name,
          domain_profile_id: null,
          template_id: null, //template id để sử dung cho vps.
          user_product_id: item.user_product_id, //Dịch vụ cần gia hạn
          is_renew: false,
          is_upgrade: false,
          is_tranfer: false,
          is_delivery: false,
          parent_id: item.group_id,
          order: 0 //Vị trí của item trong giỏ hàng
        }

        dispatch.cart.addItem(whois_item);
        dispatch.cart.updateCart();
      } else {
        dispatch.cart.deleteItem(addon_item.group_id);
      }
    },
    // Dùng để gia hạn dịch vụ addon đi theo dịch vụ chính
    renewAddonService(addon_item, rootState) {
      // Tìm ra addon parent để lấy group_id
      const addon_parent = _.find(rootState.cart.items, (i) => addon_item.parent_id == i.user_product_id);
      const ip_data = {
        id: uuidv4(),
        group_id: null,
        product: addon_item.product,
        day: 0,
        month: 12,
        quantity: 1,
        ip_address: addon_item.ip_address,
        domain_name: addon_item.domain,
        domain_profile_id: null,
        template_id: null, //template id để sử dung cho vps.
        user_product_id: addon_parent.user_product_id, //Dịch vụ cần gia hạn
        service_parent: addon_parent, 
        is_renew: true, // chỉ sử dụng cho dịch vụ gia hạn
        is_upgrade: false, // chỉ sử dụng cho dịch vụ nâng cấp
        is_tranfer: false,
        is_delivery: false,
        parent_id: addon_parent.group_id,
        order: 0 //Vị trí của item trong giỏ hàng
      }
      dispatch.cart.addItem(ip_data);
    }
  })
}

export const messageModify = {
    state: {
      ticket_data: [],
      agency_ticket_data: [],
      lastestTicketNotifyStatic: 0
    },
    reducers: {
      setTicketData(state, data) {
        return {
          ...state,
          ticket_data: data
        }
      },
      setAgencyTicketData(state, data) {
        return {
          ...state,
          agency_ticket_data: data
        }
      },
      setLastestTicketNotifyStatic(state, data) {
        return {
          ...state,
          lastestTicketNotifyStatic: data
        }
      }
    },
    effects: (dispatch) => ({
      // Tải và cập nhật số lượng yêu cầu hỗ trợ mới phản hồi từ kỹ thuật.
      async ticketModifyReload() {
        axios({
          method: 'get',
          url: window.api_url + "/user/ticket/replied/list",
          withCredentials: true
        }).then(function (response) {
          dispatch.messageModify.setTicketData(response.data.data);
        });
      },
      // Tải và cập nhật số lượng yêu cầu hỗ trợ khách hàng của lại gửi hoặc yêu cầu mới.
      async agencyTicketModifyReload() {
        // Chỉ sử dụng cho đại lý có "mở" chức năng hỗ trợ ticket.
        axios({
          method: 'get',
          url: window.api_url + "/agency/ticket/new-response/list",
          withCredentials: true
        }).then(function (response) {
          dispatch.messageModify.setAgencyTicketData(response.data.data);
        });
      },
      // Tải và cập nhật số lượng yêu cầu hỗ trợ khách hàng của lại gửi hoặc yêu cầu mới.
      async getLastestTicketNotifyStatic() {
        // Chỉ sử dụng cho đại lý có "mở" chức năng hỗ trợ ticket.
        axios({
          method: 'get',
          url: window.api_url + "/user/ticket/lastest_notify_static",
          withCredentials: true
        }).then(function (response) {
          dispatch.messageModify.setLastestTicketNotifyStatic(response.data.data);
        });
      }
  })
}

