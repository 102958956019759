import React from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';

var numeral = require('numeral');
var _ = require('lodash');

function MessageRows(data) {
    const inner_message_rows = _.map(data, (i, key) => {
            return (
                <tr style={{backgroundColor: i.is_read && "#f1f1f1"}} key= {key}>
                    <td>
                        <Link to={"/user/message/detail/"+i.id}>
                           {i.campaign_message.title}
                        </Link>
                    </td>
                    <td>
                        {i.campaign_message.created_date}
                    </td>
                    <td>
                        <div className="icons-list">
                            <div className="dropdown">
                                <a href="#" className="list-icons-item" data-toggle="dropdown">
                                    <i className="icon-menu9"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <Link to={"/user/message/detail/"+i.id} className="dropdown-item">
                                        <i className="icon-list-numbered"></i> Chi tiết
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>)  
    })
    return inner_message_rows;
        
}

class CampaignMessageTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            result_data: []
        }
    }

    componentDidMount() {
        const self = this;
        axios({
            method: 'get',
            url: window.api_url + '/user/campaign_message',
            withCredentials: true
        }).then(function (response) {
            self.setState({
                ...self.state,
                result_data: response.data.data
            })
        });
    }

    render() {
        const {result_data} = this.state;
        return(
            <React.Fragment>
                
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold">Thông Báo</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card panel-flat">
                            <div className="card-body">
                                <table className="table table-togglable table-hover footable-loaded footable default">
                                    <thead>
                                    <tr>
                                        <th data-toggle="true" className="footable-visible footable-first-column"></th>
                                        <th data-hide="phone" className="footable-visible"></th>

                                        <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {MessageRows(result_data)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}


export default withRouter(CampaignMessageTable);