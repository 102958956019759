import React from 'react';

class RegisDomainGuideline extends React.Component {
    render() {
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold"> Đăng ký tên miền</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card panel-flat">
                            <div class="card-body">
                                <p>Lấy đường dẫn gắn vào button kiểm tra domain ngoài trang chủ để khách hàng có thể kiểm tra và đăng ký domain.</p>
                                <p style={{color:"red"}} >
                                    <strong>Lưu ý:</strong>
                                    http://me.xxxx.com/ là đường dẫn thương hiệu riêng của đại lý. <a target="_blank" href="https://trogiup.tadu.vn/thiet-lap-thuong-hieu-rieng/">Hướng dẫn cấu hình thương hiệu riêng</a>.
                                </p>
                                <p style={{fontSize: "18px",  textDecoration: "underline", marginTop: "25px" }} >Ứng dụng</p>
                                <p>Dùng xây dựng tính năng kiểm tra và đăng ký domain như ngoài trang chủ <a href="https://tadu.cloud/">tadu.cloud</a></p>
                                <p style={{fontSize: "18px",  textDecoration: "underline", marginTop: "25px" }} >Sử dụng</p>
                                <ul style={{listStyle: "none", padding: "25px", border: "1px solid #ddd"}}>
                                    <li style={{paddingBottom: "10px"}}>
                                        <i class="icon-files-empty"></i> GET
                                        <span style={{float: "right"}}>Method</span>
                                    </li>
                                    <li style={{paddingBottom: "10px"}}>
                                        <i class="icon-link"></i> http://me.xxxx.com/dang-ky/ten-mien/<span class="label label-default">domain</span>
                                        <span style={{float: "right"}}>Link</span>
                                    </li>
                                    <li>
                                        <i class="icon-list"></i> application/json
                                        <span style={{float: "right"}}>Content-Type</span>
                                    </li>
                                </ul>
                                <p style={{fontSize: "18px",  textDecoration: "underline", marginTop: "25px" }} >URL fields</p>
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>Biến</th>
                                        <th>Loại</th>
                                        <th>Mô tả</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td style={{textTransform: "uppercase"}}>domain</td>
                                        <td>Bắt buộc nhập</td>
                                        <td>
                                            <ul>
                                                <li>
                                                    <strong>xxxx.com</strong>: sẽ kiểm tra chính xác và duy nhất domain này.
                                                </li>
                                                <li>
                                                    <strong>xxxx</strong>: sẽ kiểm tra xxxx.com, xxxx.com.vn, xxxx.vn,...
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default RegisDomainGuideline;