import React from 'react';

function createArray(start, end) {
  var result = [];
  for (var i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

let getCandidatePagingGroups = (totalPages, currentPage) => {
  let max_page_in_group = 7;
  // Kiểm tra tổng số trang dữ liệu có vượt quá 20 không.
  // Nếu vượt quá, cần group lại theo nhóm trang để tránh vỡ layout
  if (totalPages <= 20) { 
    return [createArray(1, totalPages)];
  }

  if (currentPage < max_page_in_group) { 
    return [createArray(1, max_page_in_group), [totalPages - 1, totalPages]];
  }else if (currentPage == max_page_in_group) {
    return [createArray(1, max_page_in_group + 1), [totalPages - 1, totalPages]];
  }else if (currentPage > (totalPages - max_page_in_group)) {
    return [[1, 2], createArray(totalPages - max_page_in_group, totalPages)]
  }else if (currentPage > max_page_in_group && currentPage <= (totalPages - max_page_in_group)) {
    return [[1,2], createArray(currentPage - 2, currentPage + 2), [totalPages - 1, totalPages]];
  }
}

class Pagination extends React.Component {

  constructor(props) {
    super(props);
    }
    
  render() {
      const {query, data, handle} = this.props;
      var rows = [];
      const pagingGroups = data.number_of_pages && getCandidatePagingGroups(data.number_of_pages, query.current_page)
      if(pagingGroups) {
        for (var i = 0; i < pagingGroups.length; i++ ) {
          const group = pagingGroups[i];
          for (var j = 0; j < group.length; j++) {
            const page_selected = group[j];
            if (query.current_page == group[j]) {
              rows.push(<li key={group[j]} className="page-item active"> <button className="btn bg-primary" className="page-link" >{query.current_page}</button></li>);
            } else {
              rows.push(<li key={group[j]}><a href="#" className="page-link"  onClick={() => handle(page_selected)}>{group[j]}</a></li>);
            }
          }
          // Group nhóm trang lại nếu số lượng trang vượt quá 20
          if (i != pagingGroups.length - 1) {
            rows.push(<li className="page-item" key={100000 + i} className="disabled"><button disabled className="btn" className="page-link">...</button></li>);
          }
        }
      }
      return(
        <React.Fragment>
          <div style={{display:'flex', flexDirection:'column'}}>
            {data.number_of_pages > 1 && <ul className="pagination align-self-center">
              {rows}
            </ul>}
          </div>
        </React.Fragment>
      )
  }
}

export default Pagination;