import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios from 'axios';

const mapState = state => ({
  cart: state.cart,
  login: state.app.login,
  currentEmail: state.app.currentEmail
});

const mapDispatch = (dispatch) => ({
  loadCart: () => dispatch.cart.loadCart(),
  emptyCart:() => dispatch.cart.emptyCart()
});

function PayPalInnerHtml(invoice_code) {
  return (
    <React.Fragment>
      <br/>
      <h4><strong>Chúc mừng bạn đã thanh toán thành công đơn hàng <span style={{color: "#FF8D00"}}>{invoice_code}</span></strong></h4>
      <br/>
    </React.Fragment>)
}

class PayPalSuccessPage extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      invoice_code: null,
      isLoading: true
    }
  }

  componentDidMount() {
    const { emptyCart } = this.props;
    const payer_id = new URLSearchParams(this.props.location.search).get("PayerID")
    const payment_id = new URLSearchParams(this.props.location.search).get("paymentId")
    const self = this;
    axios({
      method: 'post',
      url: window.api_url + '/user/paypal-execute',
      data: {
        payer_id: payer_id,
        payment_id: payment_id
      },
      withCredentials: true
    }).then(function (response) {
      self.setState({
        isLoading: false
      })
      if(!response.data.isError) {
        self.setState({
          ...self.state,
          invoice_code: response.data.data
        })
        emptyCart();
      }
    });
  }
  render() {
    const { invoice_code, isLoading } = this.state;
    return (
      <div className="container">
      
      <React.Fragment>
        <div className="row">
          <h1 className="page-title sub-page-title">TRẠNG THÁI THANH TOÁN</h1>
        </div>
        {!isLoading && invoice_code && <div style={{ backgroundColor: "white"}}>
          <div style={{margin:"20px"}}>
            {PayPalInnerHtml(invoice_code)}
          </div>
        </div>}
        {isLoading &&<img width="30" src="/assets/img/squares.gif" />}
        {!invoice_code && !isLoading && <div className="card" style={{ backgroundColor: "white"}}>
          <span style={{margin:"20px"}}>Không tìm thấy đơn hàng này !.</span>
        </div>}
      </React.Fragment>
      </div>
    )
  }
}

export default connect(mapState,mapDispatch)(withRouter(PayPalSuccessPage));