import React from 'react';
import {Route, Link} from "react-router-dom";
import {routes} from '../layout/Routes'
import axios from 'axios';
import { connect } from 'react-redux';

function AgencyItemLinkSubMenu({is_agency, path, children}) {
let inner_agency_sub_menu = (
  <Route path={path} exact={path === '/'} children={({ match }) => (
    <li style={{ display: !is_agency && 'none'}} className={"nav-item nav-item-submenu " + (match != null ? 'nav-item-expanded nav-item-open' : '')}>
      {children}
    </li>
  )}/>)
return inner_agency_sub_menu;
}

function ItemLinkSubMenu({is_agency, path, children}) {
  return(
    <Route path={path} exact={path === '/'} children={({ match }) => (
      <li style={{ display: is_agency && 'none'}} className={"nav-item nav-item-submenu " + (match != null ? 'nav-item-expanded nav-item-open' : '')}>
        {children}
      </li>
    )}/>
  )
}

function ItemLink({path, title, iconCls, badgeCls1, badgeCls2, badgeNumberExpiring}) {
  return (
    <Route
      path={path}
      exact={path === '/'}
      children={({ match }) => (
        <li onClick={()=> window.hide_sidebar()} className={"hide-sidebar nav-item " + (path === '/' ? "first-nav-item" : "")}>
          <Link to={path} className={"nav-link " + (match != null ? 'active' : '')}>
            {iconCls && <i className={iconCls} />}
            <div className="row">
                <span>{title}</span>
                {badgeCls2 && badgeNumberExpiring > 0 && <span style={{right: "1.20rem", position: "absolute"}} className={"badge align-self-right  " + badgeCls2}>{badgeNumberExpiring}</span>}
            </div>
          </Link>
        </li>
      )}
    />
  )
}

class SideBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      allServiceStatic: {},
      ticketStatic: {},
      domainStatic: {},
      vpsStatic: {},
      hostingStatic: {},
      emailStatic: {},
      otherServiceStatic: {}
    };
  }

  componentDidMount() {
    const self = this;

    axios({
      method: 'get',
      url: window.api_url + '/user/service/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        allServiceStatic: response.data.data
      })
    });
    
    axios({
      method: 'get',
      url: window.api_url + '/user/vps/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        vpsStatic: response.data.data
      })
    });
    
    axios({
      method: 'get',
      url: window.api_url + '/user/domain/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        domainStatic: response.data.data
      })
    });
    
    axios({
      method: 'get',
      url: window.api_url + '/user/email/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        emailStatic: response.data.data
      })
    });
    
    axios({
      method: 'get',
      url: window.api_url + '/user/hosting/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        hostingStatic: response.data.data
      })
    });
    
    axios({
      method: 'get',
      url: window.api_url + '/user/other/static',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        otherServiceStatic: response.data.data
      })
    });
  }

  render() {
    const { domainStatic, allServiceStatic } = this.state;
    const {hostingStatic, emailStatic, otherServiceStatic, vpsStatic } = this.state;
    const { userProfile } = this.props;
    const is_agency = userProfile && userProfile.role == "AGENCY";
    const agency_has_custom_brand = userProfile && userProfile.has_custom_brand;
    const is_ticket = userProfile && userProfile.is_ticket;
    return (
      <React.Fragment>
        <div className="sidebar sidebar-dark sidebar-main sidebar-expand-md">
          <div className="sidebar-mobile-toggler text-center">
            <a href="#" className="sidebar-mobile-main-toggle">
              <i className="icon-arrow-left8"/>
            </a>
            Navigation
            <a href="#" className="sidebar-mobile-expand">
              <i className="icon-screen-full"/>
              <i className="icon-screen-normal"/>
            </a>
          </div>

          <div className="sidebar-content">
            <div className="card card-sidebar-mobile">
              <ul className="nav nav-sidebar" data-nav-type="accordion">
                <li className="nav-item-header">
                  <div className="text-uppercase font-size-xs line-height-xs">Tadu.cloud</div>
                </li>

                <ItemLink path="/" title="Dashboard" iconCls="icon-grid"/>

                <ItemLinkSubMenu path="/service">
                  <a href="#" className="nav-link">
                    <i className="icon-cloud2"></i> <span>Dịch Vụ</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="Dịch Vụ">
                    <ItemLink path="/service/running/all" title="Tất cả" badgeCls1="bg-blue-400" badgeCls2="bg-warning-400" />
                    <ItemLink path="/service/running/domain" title="Tên Miền" badgeCls1="bg-blue-400" badgeCls2="bg-warning-400"  badgeNumberExpiring={domainStatic.expiring} />
                    <ItemLink path="/service/running/vps" title="Cloud Server" badgeCls1="bg-blue-400" badgeCls2="bg-warning-400"  badgeNumberExpiring={vpsStatic.expiring}  />
                    <ItemLink path="/service/running/hosting" title="Hosting" badgeCls1="bg-blue-400" badgeCls2="bg-warning-400"  badgeNumberExpiring={hostingStatic.expiring} />
                    <ItemLink path="/service/running/email" title="Email" badgeCls1="bg-blue-400" badgeCls2="bg-warning-400"  badgeNumberExpiring={emailStatic.expiring}  />
                    <ItemLink path="/service/running/other" title="Khác" badgeCls1="bg-blue-400" badgeCls2="bg-warning-400"  badgeNumberExpiring={otherServiceStatic.expiring}  />
                    <ItemLink path="/service/domain/pending" title="Tên miền cần cập nhật CMND" badgeCls1="" badgeCls2="badge-danger" badgeNumberExpiring={domainStatic.domain_need_upload_photo} />
                  </ul>
                </ItemLinkSubMenu>

                <ItemLinkSubMenu path="/products">
                  <a href="#" className="nav-link">
                    <i className="icon-cart5"></i> <span>Đăng Ký Mới</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="Đăng Ký Mới">
                    <ItemLink path="/products/vps" title="Đăng ký Cloud Server" />
                    <ItemLink path="/products/cloud_server_pro" title="Đăng Ký Cloud Server Pro"/>
                    <ItemLink path="/products/hosting" title="Đăng ký Hosting" />
                    <ItemLink path="/products/email" title="Đăng ký Email Server" />
                    <ItemLink path="/products/ssl" title="Đăng Ký SSL" />
                    <ItemLink path="/products/taduphone" title="Đăng Ký Tổng Đài Ảo" />
                    <ItemLink path="/products/tadu_invoice" title="Hoá Đơn Điện Tử"/>
                    <ItemLink path="/products/cloud_backup" title="Dịch Vụ Cloud Backup"/>
                    <ItemLink path="/products/digital_signature" title="Dịch Vụ Chữ Ký Số"/>
                  </ul>
                </ItemLinkSubMenu>

                <ItemLinkSubMenu path="/user/ticket">
                  <a href="#" className="nav-link">
                    <i className="icon-comment-discussion"></i> <span>Hỗ Trợ (24/7)</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="Hỗ Trợ (24/7)">
                    <ItemLink path="/user/ticket/create" title="Gửi yêu cầu mới"/>
                    <ItemLink path="/user/ticket/all" title="Yêu cầu đã gửi" />
                  </ul>
                </ItemLinkSubMenu>
                <div style={{display: !is_ticket && 'none'}}>
                  <AgencyItemLinkSubMenu is_agency = {is_agency} path="/user/agency/ticket">
                    <a href="#" className="nav-link">
                      <i className="icon-bubbles"></i> <span>Trả Lời Hỗ Trợ</span>
                    </a>
                    <ul className="nav nav-group-sub" data-submenu-title="Trả Lời Hỗ Trợ">
                      <ItemLink path="/user/agency/ticket" title="Trả Lời Hỗ Trợ"/>
                    </ul>
                  </AgencyItemLinkSubMenu> 
                </div>

                <AgencyItemLinkSubMenu is_agency = {is_agency} path="/user/agency/invoice">
                  <a href="#" className="nav-link">
                    <i className="icon-calculator"></i> <span>Đơn Hàng</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="Đơn Hàng">
                    <ItemLink path="/user/agency/invoice/all" title="Danh sách đơn hàng"/>
                  </ul>
                </AgencyItemLinkSubMenu> 

                <ItemLinkSubMenu is_agency = {is_agency} path="/user/invoice">
                  <a href="#" className="nav-link">
                    <i className="icon-calculator"></i> <span>Đơn Hàng</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="Đơn Hàng">
                    <ItemLink path="/user/invoice/all" title="Tất cả đơn hàng" />
                  </ul>
                </ItemLinkSubMenu>

                <ItemLinkSubMenu path="/user/billing">
                  <a href="#" className="nav-link">
                    <i className="icon-cash3"></i> <span>Quản Lý Tài Khoản</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="Tài khoản">
                    <ItemLink path="/user/billing/primary" title="Tài khoản chính" />
                    <ItemLink path="/user/billing/secondary" title="Tài khoản khuyến mãi" />
                    <ItemLink path="/user/billing/account/deposit" title="Nạp tiền vào tài khoản" />
                  </ul>
                </ItemLinkSubMenu>

                {/* Nếu là đại lý, bản khai lúc này sẽ nằm ở Menu khách hàng */}
                <ItemLinkSubMenu is_agency = {is_agency} path="/user/domain_profile">
                  <a href="#" className="nav-link">
                    <i className="icon-profile"></i> <span>Bản Khai</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="Bản khai">
                    <ItemLink path="/user/domain/profile/" title="Danh sách" badgeCls="bg-blue-400"/>
                  </ul>
                </ItemLinkSubMenu>
                <AgencyItemLinkSubMenu is_agency = {is_agency} path="/user/agency/customer">
                  <a href="#" className="nav-link">
                    <i className="icon-users4"></i> <span>Khách Hàng</span>
                  </a>
                  <ul className="nav nav-group-sub" data-submenu-title="customer">
                    <ItemLink path="/user/agency/customer/new" title="Thêm mới"/>
                    <ItemLink path="/user/agency/customer/list" title="Danh sách"/>
                    <ItemLink path="/user/agency/customer/domain_profile" title="Bản khai"/>
                  </ul>
                </AgencyItemLinkSubMenu>
                {/* <AgencyItemLinkSubMenu is_agency = {is_agency} path="/user/agency-config">
                    <a href="#" className="nav-link">
                        <i className="icon-embed2"></i> <span>Giao diện</span>
                    </a>
                    <ul className="nav nav-group-sub" data-submenu-title="Giao diện">
                        <ItemLink path="/user/agency-config/css/edit" title="Sửa giao diện (CSS)"/>
                    </ul>
                </AgencyItemLinkSubMenu> */}
                <div style={{display: !agency_has_custom_brand && 'none'}}>
                    <AgencyItemLinkSubMenu is_agency = {is_agency} path="/user/api">
                      <a href="#" className="nav-link">
                        <i className="icon-cogs"></i> <span>API</span>
                      </a>
                      <ul className="nav nav-group-sub" data-submenu-title="api">
                        <ItemLink path="/user/api/intro" title="Giới thiệu chung" />
                        <li className={"nav-item"}>
                          <a className="nav-link" target="_blank" href="/assets/tadu-guideline/tadu-api-example-kit.rar">Download ví dụ mẫu</a>
                        </li>
                        <li className={"nav-item"}>
                          <a className="nav-link" target="_blank" href="/assets/tadu-guideline/tadu_plugin.zip">Download plugin wordpress </a>
                        </li>
                        <ItemLink path="/user/api/wordpress-guide-line" title="Hướng dẫn sử dụng wordpress plugin"/>
                        <ItemLink path="/user/api/get-product-list" title="Lấy danh sách sản phẩm"/>
                        <ItemLink path="/user/api/get-product-by-id" title="Lấy sản phẩm theo ID"/>
                        <ItemLink path="/user/api/check-domain-guide-line" title="Kiểm tra tên miền"/>
                        <ItemLink path="/user/api/regis-domain-guide-line" title="Đăng ký tên miền"/>
                      </ul>
                    </AgencyItemLinkSubMenu>
                </div>
                    <AgencyItemLinkSubMenu is_agency = {is_agency} path="/agency/config">
                        <a href="#" className="nav-link">
                          <i className="icon-wrench3"></i> <span>Cấu hình</span>
                        </a>
                        <ul className="nav nav-group-sub" data-submenu-title="/agency/config">
                          <ItemLink path="/agency/config/info" title="Thông tin đại lý"/>
                          {agency_has_custom_brand && <ItemLink path="/agency/config/product/all" title="Sản phẩm"/>}
                          {/* <ItemLink path="/agency/config/time/order" title="Thời gian đặt hàng"/> */}
                          {agency_has_custom_brand && <ItemLink path="/agency/config/meta" title="Trang hoàn tất đơn hàng"/>}
                        </ul>
                    </AgencyItemLinkSubMenu>
                {/* Bảng giá tên miền */}
                <AgencyItemLinkSubMenu is_agency = {is_agency} path="/user/domain">
                    <a href="#" className="nav-link">
                      <i className="icon-stack-text"></i> <span>Bảng giá tên miền</span>
                    </a>
                    <ul className="nav nav-group-sub" data-submenu-title="/user/domain">
                      <ItemLink path="/user/domain/prices" title="Bảng giá tên miền"/>
                    </ul>
                </AgencyItemLinkSubMenu>
                {/* <ItemLinkSubMenu path="/user/service-combo-package">
                    <a href="#" className="nav-link">
                      <img className="icon" height="25" width="25" src="/assets/img/new-20.gif"/><i></i><span>Gói combo</span>
                    </a>
                    <ul className="nav nav-group-sub" data-submenu-title="/user/domain">
                      <ItemLink path="/user/service-combo-package" title="Gói dịch vụ khuyến mãi"/>
                    </ul>
                </ItemLinkSubMenu> */}
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  userProfile: state.userProfile.data
});

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, mapDispatch)(SideBar);