import axios from 'axios';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { RenderInputWithDiv } from '../../../component/formInput';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import isEmail from 'sane-email-validation';
import { ToastContainer, toast } from 'react-toastify';

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Vui lòng nhập email.';
  } else if (!isEmail(values.email)) {
    errors.email = 'Email không hợp lệ.';
  };
  return errors;
}

class ChangeUserModal extends React.Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  handleOnHide = () => {
    const { onHide, destroy } = this.props;
    destroy();
    onHide();
  }

  submit(values) {
    const { onHide, userProductId, destroy } = this.props;
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/service/changeuser',
      data: {
        user_product_id: userProductId,
        email: values.email
      },
      withCredentials: true
    }).then(function (response) {
      const alert_confirm = window.confirm("Bạn có chắc chuyển chủ sở hữu dịch vụ này ?.");
      if (!response.data.isError && alert_confirm) {
        toast.success("Chuyển chủ sở hữu thành công!.")
        self.handleOnHide();
      } else {
        throw new SubmissionError({ "email":response.data.error })
      }
    })
  }

  render() {
    const { title, submitting, handleSubmit } = this.props;
    const modalConfig = { backdrop: 'static', show: this.props.show, bssize: "medium", onHide: this.props.onHide, submitting: this.props.submitting };
    return <div style={{ width: '30%' }}>
      <ToastContainer />
      <Modal {...modalConfig} aria-labelledby="contained-modal-title-lg">
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title id="contained-modal-title-sm">{title}</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.submit)}>
          <Modal.Body>
            <div style={{paddingBottom:20,borderBottom:"1px solid #ddd"}}>
              <h5>Điều Khoản:</h5>
              <span>Điều khoản: Quyết định chuyển quyền sở hữu dịch vụ này do quý khách đưa ra, Tadu sẽ không giải quyết những tranh chấp sau quyết định này, quý khách cần xem xét kỹ.</span>
              <br/>
            </div>
            <div style={{paddingTop:20}}> 
              <h5> Nhập email quý khách muốn chuyển dịch vụ qua </h5>
              <Field name="email" placeholder="example@gmail.com" component={RenderInputWithDiv}></Field>
            </div>  
          </Modal.Body>
          <Modal.Footer>
            <div className="text-right">
              <a style={{marginRight: 10}} className="btn" onClick={() => this.handleOnHide()}>Đóng</a>
              <button type="submit" className="btn bg-warning" disabled={submitting}>Quyết định chuyển</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  }
}
const mapState = state => ({

});

const mapDispatch = dispatch => ({
    
});

export default (connect(mapState, mapDispatch)(
  reduxForm({
    form: 'ChangeUserModal',
    destroyOnUnmount: true,
    enableReinitialize: true,
    validate
  })(withRouter(ChangeUserModal))));
