import React from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

var _ = require('lodash');
var numeral = require('numeral');

function ProductOptionInner(index_key, item){
  const product_meta = _.map(item.product_meta, (i, key) => <p key={key}>{i.name} <strong>{i.value && ": " + i.value}</strong></p>);
  return (
    <div key={index_key} className="col-md-3">
      <div className="card card-body dashboard-panel product-item">
        <h1>{item.product.name}</h1>
        {product_meta}
        <p>Giá chỉ từ</p>
        <h6 className="text-orange">
          <strong>{numeral(item.price12).format('0,0')} đ / tháng</strong>
        </h6>
        <p style={{ fontSize: "12px" }}>(Giá trên chưa bao gồm VAT)</p>
        <br/>
        <p>
          <Link to={"/products/cloud_server_pro/order/" + item.product.id} className="btn btn-warning btn-xlg" type="button">
            Đặt Mua Ngay
          </Link>
        </p>
      </div>
    </div>)
}
class CloudServerProPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product_prices: []
    }
  }

  componentDidMount() {
    const self = this;
    axios({
      method: 'get',
      url: window.api_url + "/product/price_with_meta/cloud_server_pro",
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        product_prices: response.data.data
      })
    });
  }

  render() {
    const { product_prices } = this.state;
    const product_rows = _.map(product_prices, (item, key) => ProductOptionInner(key, item)); 
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Đăng Ký Cloud Server Pro</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
           {product_rows}
        </div>
      </React.Fragment>
    )
  }
}
export default CloudServerProPage;

