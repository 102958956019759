import React from 'react';
import axios from 'axios';
import SearchForm from '../../component/SearchForm';
import { Link, withRouter } from 'react-router-dom';
import Pagination from '../../component/Pagination';
import InvoiceStatus from '../../component/InvoiceStatus';
import AlertLabel from '../../component/AlertLabel';
import DomainProfileStatus from '../../component/DomainProfileStatus';
import FileRender from '../../component/FileRender';

var numeral = require('numeral');
var _ = require('lodash');

function InnerCheckIdUpload(item) {
  let inner_td = (
    <td className="footable-visible">
      <FileRender directory="id-upload" file_name={item.image_1} isDisplay={true} height={50} />
      <br/>
      <FileRender directory="id-upload" file_name={item.image_2} isDisplay={true} height={50} />
    </td>
  )

  if(!item.image_1) {
    inner_td = <td style={{color:'red'}}>Chưa có</td> 
  }
  if(item.is_company) {
    inner_td = (
      <td style={{ color:"green" }}>
        <span>
          Công Ty
        </span>
      </td>)
  } 
  return inner_td;
}

function DomainProfileRows(data) {
  const domain_profile_rows = _.map(data, function(i, index) {
    
    return (
      <tr key={index} style={{borderBottom: "1px solid #ddd"}}>
        <td>
          <Link to={"/user/domain/profile/detail/"+i.id}>{i.user.email}</Link>
          <small className="display-block text-muted text-size-small">
            {i.user.full_name} - {i.user.phone}
          </small>
        </td>
        <td>
          {DomainProfileStatus(i.is_company)}
        </td>
        <td>
          {i.email} - {i.full_name}
          <br/>
          <small className="display-block text-muted text-size-small">
            {i.is_company ? i.vat_no : i.id_number},
            {i.address},
            {i.city},
            {i.nation}
          </small>
        </td>
          {InnerCheckIdUpload(i)}
        <td>
          <div className="icons-list">
            <div className="dropdown">
              <a href="#" className="list-icons-item" data-toggle="dropdown">
                <i className="icon-menu9"></i>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to={"/user/domain/profile/detail/"+i.id} className="dropdown-item">
                  <i className="icon-list-numbered"></i> Chi tiết
                </Link>
                {<Link to={"/user/domain/profile/id_upload/"+i.id} className="dropdown-item">
                  <i className="icon-upload4"></i> {i.is_company ? "Cập nhật giấy phép kinh doanh" : "Cập nhật CMND" }
                </Link>}
              </div>
            </div>
          </div>
        </td>
      </tr>
    )
  })
  return domain_profile_rows;
}

class DomainProfileTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
      table_query: {
        current_page: 1,
        number_of_row: 20,
        sort_by: "",
        sort_asc: false,
        search_field: "search",
        search_value: "",
        alert_label: null
      }
    }
  }

  componentDidMount() {
    this.reload(this.state.table_query);
  }

  gotoPage = (page) => {
    const table_query = {
      ...this.state.table_query,
      current_page: page
    };
    this.reload(table_query);
  }

  reload = (table_query) => {
    const self = this;

    axios({
      method: 'post',
      url: window.api_url + '/user/domain/profile/list',
      withCredentials: true,
      data: table_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        table_query: table_query,
        table_data: response.data.data
      })
    });
  }

  onSearchTxtChange = (event) => {
    const table_query = {
      ...this.state.table_query,
      search_value: event.target.value
    };

    this.setState({
      ...this.state,
      table_query: table_query
    });
  }

  onSearchSubmit = (event) => {
    const table_query = {
      ...this.state.table_query,
      current_page: 1
    };

    this.reload(table_query);
    event.preventDefault();
  }

  clearSearch = () => {
    const table_query = {
      ...this.state.table_query,
      current_page: 1,
      search_value: ""
    };
    this.reload(table_query);
  }

  render() {
    const { table_query, table_data, alert_label } = this.state;
    return (
      <React.Fragment>

        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Danh Sách Bản Khai</span>
              </h4>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="card card-body">
              <SearchForm
                placeHolder="Email bản khai"
                onSearchSubmit={this.onSearchSubmit}
                onSearchTxtChange={this.onSearchTxtChange}
                searchValue={table_query.search_value}
                onEsc={this.clearSearch} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {alert_label && <AlertLabel status={alert_label.status} message={alert_label.message} />}
            <div className="card card-body table-responsive">
              <table className="table table-togglable table-hover default footable-loaded footable">
                <thead>
                  <tr>
                    <th data-toggle="true" className="footable-visible footable-first-column">Khách Hàng</th>
                    <th data-hide="phone" className="footable-visible">Loại</th>
                    <th data-hide="phone" className="footable-visible">Chủ thể</th>
                    <th data-hide="phone" className="footable-visible">CMND</th>
                    <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}><i className="icon-menu-open2"></i></th>
                  </tr>
                </thead>
                <tbody>
                  {table_data.data && DomainProfileRows(table_data.data)}
                </tbody>
              </table>
            </div>
            <Pagination query={table_query} data={table_data} handle={this.gotoPage} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default withRouter(DomainProfileTable);