import React from 'react';

var _ = require('lodash');

function TicketStatic(data) {
    let ticket_static ={
        anew: 0,
        bprocess: 0,
        dreplied: 0,
        fclosed: 0
    } 
    _.forEach(data, function(i) {
        if(i.status == "ANEW") {
            ticket_static.anew = i.count_number;
        }
        if(i.status == "BPROCESS") {
            ticket_static.bprocess = i.count_number;
        }
        if(i.status == "DREPLIED") {
            ticket_static.dreplied = i.count_number;
        }
        if(i.status == "FCLOSED") {
            ticket_static.fclosed = i.count_number;
        }
    })
    return ticket_static;
}

class TicketStaticSelect extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { onTicketStaticChange, ticket_static } = this.props;
    const ticket_static_data = TicketStatic(ticket_static);
    const styles = {
			color: "white",
			marginRight: 3,
			borderColor: 'transparent',
			borderRadius: "3px"
		}
    return(
        <React.Fragment>
            <button onClick={()=> onTicketStaticChange("all")} style={styles} className="badge bg-indigo">TẤT CẢ YÊU CẦU </button>
            <button onClick={()=> onTicketStaticChange("anew")} style={styles} className="badge badge-info">YÊU CẦU MỚI GỬI: {ticket_static_data.anew}</button>
            <button onClick={()=> onTicketStaticChange("bprocess")} style={styles} className="badge badge-danger">ĐANG XỬ LÝ: {ticket_static_data.bprocess}</button>
            <button onClick={()=> onTicketStaticChange("dreplied")} style={styles} className="badge badge-success">ĐÃ TRẢ LỜI: {ticket_static_data.dreplied}</button>
            <button onClick={()=> onTicketStaticChange("fclosed")} style={styles}className="badge badge-secondary">ĐÃ ĐÓNG: {ticket_static_data.fclosed}</button>
        </React.Fragment>
    )
  }
}


export default TicketStaticSelect;