import React from "react";
import LoginPage from '../pages/LoginPage';
import ForgetPasswordPage from '../pages/ForgetPasswordPage';
import UpdatePasswordPage from '../pages/UpdatePasswordPage';
import RegisterPage from '../pages/RegisterPage';
import UserActivePage from '../pages/UserActivePage';
import UserProfilePage from '../pages/UserProfilePage';
import UserChangePassword from '../pages/UserChangePassword';
import DashboardPage from '../pages/dashboard';
import ServicePage from '../pages/services/ServicePage';
import DomainPending from '../pages/services/DomainPending';
import VpsPage from '../pages/products/VpsPage';
import HostingPage from '../pages/products/HostingPage';
import SSLPage from "../pages/products/SSLPage";
import TaduPhonePage from "../pages/products/TaduPhonePage";
import EmailPage from "../pages/products/EmailPage";
import DomainPage from "../pages/products/DomainPage";
import VpsOrderPage from "../pages/products/VpsOrderPage";
import ShopingCartPage from "../pages/cart/ShopingCartPage";
import SuccessOrderPage from "../pages/payment/SuccessOrderPage";
import PayPalSuccessPage from "../pages/payment/PayPalSuccessPage";
import PaymentMethodOptionPage from "../pages/payment/PaymentMethodOptionPage";
import DomainProfileWrapper from "../forms/DomainProfile";
import InvoiceTable from "../pages/invoice/InvoiceTable";
import InvoiceDetail from "../pages/invoice/InvoiceDetail";
import TicketTable from "../pages/ticket/TicketTable";
import TicketFormWrapper from "../pages/ticket/form/TicketForm";
import TicketDetail from "../pages/ticket/TicketDetail";
import ServiceUpgradePage from "../pages/services/ServiceUpgradePage";
import BillingTable from "../pages/billing/BillingTable";
import DomainProfileTable from "../pages/domain_profile/DomainProfileTable";
import DomainProfileDetail from "../pages/domain_profile/DomainProfileDetail";
import IDUploadForm from "../pages/domain_profile/IDUploadForm";
import FeedbackForm from "../pages/feedback/FeedbackForm";
import IntroPage from "../pages/agency/IntroPage";
import WordpressGuideline from "../pages/agency/WordpressGuideline";
import GetProductList from "../pages/agency/GetProductList";
import GetProductById from "../pages/agency/GetProductById";
import CheckDomainGuideline from "../pages/agency/CheckDomainGuideline";
import RegisDomainGuideline from "../pages/agency/RegisDomainGuideline";
import CSSConfig from "../pages/agency/CSSConfig";
import CustomerForm from "../pages/agency/form/CustomerForm";
import CustomerTable from "../pages/agency/CustomerTable";
import CampaignMessageTable from "../pages/message/CampaignMessageTable";
import CampaignMessageDetail from "../pages/message/CampaignMessageDetail";
import AgencyTicketTable from "../pages/agency/AgencyTicketTable";
import AgencyTicketDetail from "../pages/agency/AgencyTicketDetail";
import MomoReturnPage from "../pages/payment/MomoReturnPage";
import VNPayReturnPage from "../pages/payment/VNPayReturnPage";
import TaduInvoicePage from "../pages/products/TaduInvoicePage";
import AgencyInvoiceTable from "../pages/agency/AgencyInvoiceTable";
import DomainTransferPageWrapper from "../pages/products/DomainTransferPage";
import AgencyInfoConfig from "../pages/agency/AgencyInfoConfig";
import ProductConfig from "../pages/agency/ProductConfig";
import OrderTimeConfig from "../pages/agency/OrderTimeConfig";
import AgencyMetaConfig from "../pages/agency/AgencyMetaConfig";
import CloudBackupPage from "../pages/products/CloudBackupPage";
import DomainPriceTable from "../pages/domain/DomainPriceTable";
import DigitalSignaturePage from "../pages/products/DigitalSignaturePage";
import VPSManagePage from "../pages/services/VPSManagePage";
import ChangeLogPage from "../pages/change_log/ChangeLogPage";
import CloudServerProPage from "../pages/products/CloudServerProPage";
import MobilePaymentRedirect from "../pages/mobile_redirect/MobilePaymentRedirect"
import DepositByBankTransfer from "../pages/billing/DepositByBankTransfer";

// import SdnsConfigPage from "../pages/sdns/SdnsConfigPage";

export const routes = [
  {
    path: "/",
    exact: true,
    render: () => {
      return (
        <DashboardPage/>
      )
    }
  },
  {
    path: "/user_profile",
    exact: true,
    render: () => {
      return (
        <UserProfilePage/>
      )
    }
  },
  {
    path: "/change_password",
    exact: true,
    render: () => {
      return (
        <UserChangePassword/>
      )
    }
  },
  {
    path: "/service/:serviceStatus?/all",
    exact: true,
    render: (routeProps) => {
      return (
        <ServicePage productType="all" title="Tất Cả Dịch Vụ" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/service/:serviceStatus?/domain",
    exact: true,
    render: (routeProps) => {
      return (
        <ServicePage productType="domain" title="Dịch Vụ Tên Miền" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/service/:serviceStatus?/vps",
    exact: true,
    render: (routeProps) => {
      return (
        <ServicePage productType="vps" title="Dịch Vụ Cloud Server" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/service/:serviceStatus?/hosting",
    exact: true,
    render: (routeProps) => {
      return (
        <ServicePage productType="hosting" title="Dịch Vụ Hosting" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/service/:serviceStatus?/email",
    exact: true,
    render: (routeProps) => {
      return (
        <ServicePage productType="email" title="Dịch Vụ Email" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/service/:serviceStatus?/other",
    exact: true,
    render: (routeProps) => {
      return (
        <ServicePage productType="other" title="Dịch Vụ Khác" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/service/domain/pending",
    exact: true,
    render: () => {
      return (
        <DomainPending/>
      )
    }
  },
  {
    path: "/service/upgrade/:user_product_id",
    exact: true,
    render: (routeProps) => {
      return (
        <ServiceUpgradePage routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/products/vps",
    exact: true,
    render: () => {
      return (
        <VpsPage/>
      )
    }
  },
  {
    path: "/products/vps/order/:productId",
    exact: true,
    render: (routeProps) => {
      return (
        <VpsOrderPage title="Đăng Ký Mới Cloud Server" type="VPS" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/products/cloud_server_pro/order/:productId",
    exact: true,
    render: (routeProps) => {
      return (
        <VpsOrderPage title="Đăng Ký Mới Powerful Cloud Server" type="CLOUD_SERVER_PRO" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/products/hosting",
    exact: true,
    render: () => {
      return (
        <HostingPage/>
      )
    }
  },
  {
    path: "/products/email",
    exact: true,
    render: () => {
      return (
        <EmailPage/>
      )
    }
  },
  {
    path: "/products/ssl",
    exact: true,
    render: () => {
      return (
        <SSLPage/>
      )
    }
  },
  {
    path: "/products/taduphone",
    exact: true,
    render: () => {
      return (
        <TaduPhonePage/>
      )
    }
  },
  {
    path: "/products/tadu_invoice",
    exact: true,
    render: () => {
      return (
        <TaduInvoicePage/>
      )
    }
  },
  {
    path: "/products/cloud_backup",
    exact: true,
    render: () => {
      return (
        <CloudBackupPage/>
      )
    }
  },
  {
    path: "/products/domain",
    exact: true,
    render: () => {
      return (
        <DomainPage/>
      )
    }
  },
  {
    path: "/products/domain-transfer",
    exact: true,
    render: () => {
      return (
        <DomainTransferPageWrapper/>
      )
    }
  },
  {
    path: "/products/digital_signature",
    exact: true,
    render: () => {
      return (
        <DigitalSignaturePage/>
      )
    }
  },
  {
    path: "/products/cloud_server_pro",
    exact: true,
    render: () => {
      return (
        <CloudServerProPage/>
      )
    }
  },
  {
    path: "/products/cart",
    exact: true,
    render: () => {
      return (
        <ShopingCartPage/>
      )
    }
  },
  {
    path: "/products/domain-profile",
    exact: true,
    render: () => {
      return (
        <DomainProfileWrapper />
      )
    }
  },
  {
    path: "/products/payment-method",
    exact: true,
    render: () => {
      return (
        <PaymentMethodOptionPage/>
      )
    }
  },
  {
    path: "/products/payment-method/:invoice_code",
    exact: true,
    render: () => {
      return (
        <SuccessOrderPage/>
      )
    }
  },
  {
    path: "/paypal-return",
    exact: true,
    render: () => {
      return (
        <PayPalSuccessPage />
      )
    }
  },
  {
    path: "/momo-return",
    exact: true,
    render: () => {
      return (
        <MomoReturnPage />
      )
    }
  },
  {
    path: "/vnpay-return",
    exact: true,
    render: (routeProps) => {
      return (
        <VNPayReturnPage routeProps={routeProps} />
      )
    }
  },
  {
    path: "/user/ticket/create",
    exact: true,
    render: () => {
      return (
        <TicketFormWrapper/>
      )
    }
  },
  {
    path: "/user/ticket/:ticket_status?",
    exact: true,
    render: (routeProps) => {
      return (
        <TicketTable routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/user/ticket/detail/:ticket_id",
    exact: true,
    render: (routeProps) => {
      return (
        <TicketDetail routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/user/invoice/:invoiceStatus?",
    exact: true,
    render: (routeProps) => {
      return (
        <InvoiceTable title="Danh Sách Đơn Hàng" routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/user/invoice/detail/:invoice_id",
    exact: true,
    render: (routeProps) => {
      return (
        <InvoiceDetail is_agency_invoice={false} routeProps = {routeProps} />
      )
    }
  },
  {
    path: "/user/invoice/agency/detail/:invoice_id",
    exact: true,
    render: (routeProps) => {
      return (
        <InvoiceDetail is_agency_invoice={true} routeProps = {routeProps} />
      )
    }
  },
  {
    path: "/user/billing/primary",
    exact: true,
    render: () => {
      return (
        <BillingTable title={"Sao kê tài khoản chính"} account_type={"primary"}/>
      )
    }
  },
  {
    path: "/user/billing/secondary",
    exact: true,
    render: () => {
      return (
        <BillingTable title={"Sao kê tài khuyến mãi"} account_type={"secondary"}/>
      )
    }
  },
  {
    path: "/user/billing/account/deposit",
    exact: true,
    render: () => {
      return (
        <DepositByBankTransfer />
      )
    }
  },
  {
    path: "/user/domain/profile/",
    exact: true,
    render: () => {
      return (
        <DomainProfileTable />
      )
    }
  },
  {
    path: "/user/domain/profile/detail/:domain_profile_id",
    exact: true,
    render: () => {
      return (
        <DomainProfileDetail />
      )
    }
  },
  {
    path: "/user/domain/profile/id_upload/:domain_profile_id",
    exact: true,
    render: () => {
      return (
        <IDUploadForm />
      )
    }
  },
  {
    path: "/user/feedback",
    exact: true,
    render: () => {
      return (
        <FeedbackForm />
      )
    }
  },
  {
    path: "/user/message",
    exact: true,
    render: () => {
      return (
        <CampaignMessageTable />
      )
    }
  },
  {
    path: "/user/message/detail/:user_campaign_id",
    exact: true,
    render: () => {
      return (
        <CampaignMessageDetail />
      )
    }
  },
  {
    path: "/user/cloud/server/manage/:user_product_id",
    exact: true,
    render: (routeProps) => { 
        return(
          <VPSManagePage routeProps={routeProps} />
        )
    }
  },
  {
    path: "/user/change_log",
    exact: true,
    render: (routeProps) => {
      return (
        <ChangeLogPage routeProps={routeProps} />
      )
    }
  },
];

export const agencyRoutes = [
  {
    path: "/user/agency/ticket/:ticket_status?",
    exact: true,
    render: (routeProps) => {
      return (
        <AgencyTicketTable routeProps={routeProps} />
      )
    }
  },
  {
    path: "/user/agency/invoice/:invoiceStatus?",
    exact: true,
    render: (routeProps) => {
      return (
        <AgencyInvoiceTable title="Danh sách đơn hàng" base_path={"/user/agency/invoice"} routeProps={routeProps} />
      )
    }
  },
  {
    path: "/user/agency/ticket/detail/:agency_ticket_id",
    exact: true,
    render: (routeProps) => {
      return (
        <AgencyTicketDetail routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/user/agency/customer/domain_profile",
    exact: true,
    render: () => {
      return (
        <DomainProfileTable />
      )
    }
  },
  {
    path: "/user/agency/customer/new",
    exact: true,
    render: () => {
      return (
        <CustomerForm title={"Thêm Mới Khách Hàng"}/>
      )
    }
  },
  {
    path: "/user/agency/customer/list",
    exact: true,
    render: () => {
      return (
        <CustomerTable /> 
      )
    }
  },
  {
    path: "/user/agency/customer-edit/:customer_id",
    exact: true,
    render: () => {
      return (
        <CustomerForm title={"Chỉnh Sửa Khách Hàng"}/>
      )
    }
  },
  {
    path: "/agency/config/info",
    exact: true,
    render: (routeProps) => {
      return (
        <AgencyInfoConfig routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/user/domain/prices",
    exact: true,
    render: () => {
      return (
        <DomainPriceTable />
      )
    }
  }
]

export const customBrandRoutes = [
  {
    path: "/user/api/intro",
    exact: true,
    render: () => {
      return (
          <IntroPage />
        )
      }
    },
    {
      path: "/user/api/wordpress-guide-line",
      exact: true,
      render: () => {
        return (
          <WordpressGuideline />
        )
      }
    },
    {
      path: "/user/api/get-product-list",
      exact: true,
      render: () => {
        return (
          <GetProductList />
        )
      }
    },
    {
      path: "/user/api/get-product-by-id",
      exact: true,
      render: () => {
        return (
          <GetProductById />
        )
      }
    },
    {
      path: "/user/api/check-domain-guide-line",
      exact: true,
      render: () => {
        return (
          <CheckDomainGuideline />
        )
      }
    },
    {
      path: "/user/api/regis-domain-guide-line",
      exact: true,
      render: () => {
        return (
          <RegisDomainGuideline />
        )
      }
    },
    // {
    //   path: "/user/agency-config/css/edit",
    //   exact: true,
    //   render: () => {
    //     return (
    //       <CSSConfig />
    //     )
    //   }
    // },
    {
      path: "/agency/config/info",
      exact: true,
      render: (routeProps) => {
        return (
          <AgencyInfoConfig routeProps={routeProps}/>
        )
      }
    },
    {
      path: "/agency/config/product/:product_type?",
      exact: true,
      render: (routeProps) => {
        return (
          <ProductConfig routeProps={routeProps}/>
        )
      }
    },
  //   {
  //     path: "/agency/config/time/order",
  //     exact: true,
  //     render: (routeProps) => {
  //       return (
  //         <OrderTimeConfig routeProps={routeProps}/>
  //       )
  //     }
  //   },
  {
    path: "/agency/config/meta",
    exact: true,
    render: (routeProps) => {
      return (
        <AgencyMetaConfig routeProps={routeProps}/>
      )
    }
  }
]

export const loginRoutes = [
  {
    path: "/",
    exact: true,
    render: () => {
      return (
        <LoginPage/>
      )
    }
  },
  {
    path: "/forget_password",
    exact: true,
    render: () => {
      return (
        <ForgetPasswordPage />
      )
    }
  },
  {
    path: "/update_password/:token",
    exact: true,
    render: (routeProps) => {
      return (
        <UpdatePasswordPage routeProps={routeProps}/>
      )
    }
  },
  {
    path: "/register",
    exact: true,
    render: () => {
      return (
        <RegisterPage/>
      )
    }
  },
  {
    path: "/active/:token",
    exact: true,
    render: (routeProps) => {
      return (
        <UserActivePage routeProps={routeProps}/>
      )
    }
  },
];

export const redirectRoutes = [
    {
        path: "/mobile/:mobile_device/:payment_method?",
        exact: true,
        render: () => {
          return (
            <MobilePaymentRedirect />
          )
        }
    },
    {
        path: "/mobile/:mobile_device/products/cart",
        exact: true,
        render: () => {
          return (
            <MobilePaymentRedirect />
          )
        }
    },
   
]