import axios from "axios";
import moment from "moment";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import FileRender from "../../component/FileRender";

var numeral = require("numeral");
var _ = require('lodash');

function PersonalType(type) {
    let inner_personal_type = <legend style={{ textTransform: "uppercase" , color: "#FF8D00"}}>Thông tin chủ thể</legend>
    if(type == "MANAGE") {
        inner_personal_type = <legend style={{textTransform: "uppercase", color: "#FF8D00"}}>Người quản lý tên miền</legend>
        
    }else if(type == "TECH" ) {
        inner_personal_type = <legend style={{textTransform: "uppercase", color: "#FF8D00"}}>Người quản lý kỹ thuật</legend>

    }else if(type == "PAY" ) {
        inner_personal_type = <legend style={{textTransform: "uppercase", color: "#FF8D00"}}>Người thanh toán</legend>

    }else if(type == "REP") {
        inner_personal_type = <legend style={{textTransform: "uppercase", color: "#FF8D00"}}>Người thay mặt cơ quan làm thủ tục đăng ký tên miền</legend>
    }
    return inner_personal_type;
}

function CompanyDomainProfile(company_profile, personal_profile) {
    const personal_profile_inner_rows = _.map(personal_profile, (i, index) => PersonalDomainProfile(i, index) )
    return (
        <React.Fragment>
            <fieldset style={{marginTop: "10px"}}>
                <legend style={{textTransform: "uppercase", color: "#FF8D00"}}>Thông tin chủ thể</legend>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Tên chủ thể </div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.full_name}</strong>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Tên giao dịch quốc tế (nếu có) </div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.inter_name}</strong>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Mã số thuế </div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.vat_no}</strong>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Địa chỉ </div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.address}</strong>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Tỉnh / Thành phố :</div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.city}</strong>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Quốc gia </div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.nation}</strong>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Điện thoại </div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.phone}</strong>
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Email </div>
                    <div className="col-sm-8">
                        <strong>: {company_profile.email}</strong>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>Giấy phép kinh doanh</div>
                    <div className="col-sm-8">
                        <FileRender directory="id-upload" file_name={company_profile.image_1} isDisplay={true} height={150} />
                    </div>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-sm-12" style={{textAlign: "center"}}>
                        <Link className="btn btn-primary" to={"/user/domain/profile/id_upload/"+company_profile.id} style={{marginLeft: "10px"}}>Cập nhật Giấy phép kinh doanh <span className="icon-arrow-right7"></span></Link>
                    </div>
                </div>
            </fieldset>
            {personal_profile_inner_rows}
        </React.Fragment>

    )
}

function PersonalDomainProfile(data, key) {
    return ( 
        <fieldset key={key} style={{marginTop: "10px"}}>
            {PersonalType(data.type)}
            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Họ tên chủ thể </div>
                <div className="col-sm-8">
                    <strong>: {data.full_name}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Giới tính </div>
                <div className="col-sm-8">
                    <strong>: {data.gender}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Ngày sinh (DD/MM/YYYY) </div>
                <div className="col-sm-8">
                    <strong>: {moment(data.birthday).format("DD/MM/YYYY")}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Số CMND </div>
                <div className="col-sm-8">
                    <strong>: {data.id_number}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Địa chỉ </div>
                <div className="col-sm-8">
                    <strong>: {data.address}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Tỉnh / Thành phố </div>
                <div className="col-sm-8">
                    <strong>: {data.city}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Quốc gia </div>
                <div className="col-sm-8">
                    <strong>: {data.nation}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Điện thoại </div>
                <div className="col-sm-8">
                    <strong>: {data.phone}</strong>
                </div>
            </div>

            <div className="form-group row">
                <div className="col-sm-4" style={{textAlign: "right"}}>Email </div>
                <div className="col-sm-8">
                    <strong>: {data.email}</strong>
                </div>
            </div>
            
            {!data.parent_id && 
            <React.Fragment>
                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>CMND mặt trước </div>
                    <div className="col-sm-8">
                        <FileRender directory="id-upload" file_name={data.image_1} isDisplay={true} height={150} />
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-sm-4" style={{textAlign: "right"}}>CMND mặt sau </div>
                    <div className="col-sm-8">
                        <FileRender directory="id-upload" file_name={data.image_2} isDisplay={true} height={150} />
                    </div>
                </div>
                <br/>
                <div className="form-group row">
                    <div className="col-sm-12" style={{textAlign: "center"}}>
                        <Link className="btn btn-primary" to={"/user/domain/profile/id_upload/"+data.id} style={{marginLeft: "10px"}}>Cập nhật CMND <span className="icon-arrow-right7"></span></Link>
                    </div>
                </div>
            </React.Fragment>}
        </fieldset>
    )
}

class DomainProfileDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domain_profile: null,
            alert_label: null
        };
    }

    reload = () => {
        const { domain_profile_id } = this.props.match.params;
        const self = this;
        
        axios({
          method: 'get',
          url: window.api_url + '/user/domain/profile/detail/' + domain_profile_id,
          withCredentials: true
        }).then(function (response) {
            self.setState({
                ...self.state,
                domain_profile: response.data.data,
          })
        });
    }

    componentDidMount() {
        this.reload(); 
    }

  render() {
    const { domain_profile } = this.state;
    return (
        <React.Fragment>
            <div className="page-header">
                <div className="page-header-content header-elements-md-inline">
                    <div className="page-title d-flex">
                        <h4>
                            <i className="icon-paragraph-justify2 mr-2"></i>
                            <span className="text-semibold">Chi tiết bản khai</span>
                        </h4>
                    </div>
                </div>
            </div>
            <br/>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {domain_profile && domain_profile.parent_profile.is_company && CompanyDomainProfile(domain_profile.parent_profile, domain_profile.sub_profiles)}
                            {domain_profile && !domain_profile.parent_profile.is_company && PersonalDomainProfile(domain_profile.parent_profile, "PERSONAL_PROFILE_")}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
  }
}

export default withRouter(DomainProfileDetail);
