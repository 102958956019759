import moment from 'moment';
import { domainExts, genericDomainExts, notAllowExts } from '../models/domain_ext';
import {Socket} from "phoenix";
import { v4 as uuidv4 } from 'uuid';
var _ = require('lodash');
  

const DomainUtils = {
  CheckIsDomainExt: (domain_search) => {
    return (
      domainExts.includes("."+domain_search.substring(domain_search.indexOf('.')+1)) || 
      genericDomainExts.includes("."+domain_search.substring(domain_search.indexOf('.')+1))
    );
  },
  CheckIsValidDomain: (domain_search) => { 
    // reg kiểm tra tên miền hợp lệ
    // 1. "hợp lệ" trả về Object
    // 2. "không hợp lệ" trả về null
    const reg_domain = new RegExp(/^(?:(?!.*_.*)(?:(?:(?:\w[\.\-]?)*)\w)+)((?:(?:(?:\w[\.\-]?){0,62})\w)+)\.(\w{2,})$/); 
    // reg kiểm tra có ký tự đặc biệt
    // 1. "hợp lệ" trả về Object
    // 2. "không hợp lệ" trả về null
    const regex = new RegExp(/^(?!.*_.*)(\w{0,62}(?!.*_.*))([-]{0,1})(\w{2,10})$/);
    // Dùng để kiểm tra các các đuôi tên miền hợp lệ. 
    const is_valid_ext = (
      domainExts.includes("."+StringUtils.GetStringAfterSymbol(domain_search, ".", 1)) || 
      genericDomainExts.includes("."+StringUtils.GetStringAfterSymbol(domain_search, ".", 1))
    )
    // Dùng để kiểm tra các tên miền có hỗ trợ hay không. 
    const is_not_allow_ext = notAllowExts.includes("."+StringUtils.GetStringAfterSymbol(domain_search, ".", 1))
  
    // Nếu người dùng không nhập đuôi tên miền tự động ghép domain_ext vào
    if(!is_valid_ext && domain_search.match(regex)) { 
      return true;
    // Nếu người dùng có nhập đuôi tên miền kiểm tra tên miền có hợp lệ & được hỗ trợ hay không không
    }else if(is_valid_ext && domain_search.match(reg_domain) && !is_not_allow_ext) {
      return true;
    }
    else {
      return false;
    }
  }
  
}
const CartUtils = {
  ItemGroupIdGenerator: (cart) => {
    let last_group_id = _.maxBy(cart.items, (i) => i.group_id) 
    if(last_group_id) {
      last_group_id = last_group_id.group_id + 1;
    } else {
      last_group_id = 1
    }
    return last_group_id;
  },
  // Kiểm tra sản phẩm đã tồn tại, không được trùng lập trong giỏ hàng.
  // Trừ các mặt hàng đăng ký mới, (Không tính tên miền)
  CheckServiceExist: (new_item, cart) => {
    // Sử dụng sản phẩm gia hạn, nâng cấp.
    if (new_item.user_product_id && new_item.product.product_type != "WHOIS_PRIVACY" && new_item.product.product_type != "IP") {
      return _.filter(cart.items, (i) => (i.user_product_id != new_item.user_product_id));
    }else {
      return cart.items;
    }
  },

  CheckAgencyCustomerCart: (cart ,old_customer_id, new_customer_id) => {
    // Kiểm tra giỏ hàng có dịch vụ gia hạn hoặc update của KH của đại lý.
    const has_agency_customer_service = _.filter(cart.items, (i) => i.is_renew || i.is_upgrade); 
    // Empty Cart nếu đại lý nâng cấp hoặc gia hạn cho một KH khác
    // Đại lý chỉ có thể ra một đơn hàng cho một khách hàng, không thể gợp chung nhiều khách hàng trong 1 đơn hàng.
    if (has_agency_customer_service.length > 0 && (old_customer_id != new_customer_id)) {
      return [];
    }else {
      return cart.items;
    }
  },
  // Kiểm tra tên miền đã tồn tại.
  FindDomainExist: (domain, cart) => {
    const product_types = ["VN_DOMAIN", "COM_DOMAIN"]
    return _.find(cart.items, (i) => product_types.includes(i.product.product_type) && i.domain_name == domain);
  }
}

const DateUtil = {
  DateDuration: (start_date, end_date) =>{
    const years = moment(end_date, "DD/MM/YYYY").diff(start_date, "years");
    start_date = start_date.add(years,"years")
    const months = moment(end_date, "DD/MM/YYYY").diff(start_date, "months");
    start_date = start_date.add(months,"months")
    const days = moment(end_date, "DD/MM/YYYY").diff(start_date, "days");

    return {
      days: days,
      months: months,
      years: years 
    }
  },
  secondToTime: (second_val) => {
    return new Date(second_val * 1000).toISOString().substr(11, 8)
  },
	secondToDate: (second_val) => {
    return moment(new Date(second_val * 1000)).format("DD-MM")
	}
}
const StringUtils = {
  isEmptyOrSpaces(str){
    return str === null || str.match(/^ *$/) !== null;
  },
  GetStringAfterSymbol : (str, special_character, number_of_range) => {
    return str.substring(str.indexOf(special_character,number_of_range) + 1)
  }
}

const ImageUtils = {
  getBase64Image(src, callback) {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = img.naturalHeight;
      canvas.width = img.naturalWidth;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL("image/png");
      callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src = src;
    }
  }
}

const FormatterUtils = {
  round2Decimals: (num) => {
    return parseFloat(Math.round(num * 100) / 100);
  },
  
}

const NumberUtils = {
    getPercentOfTwoNumber: (outOffNumber, total_value) => {
			return parseFloat((outOffNumber * 100) / total_value).toFixed(2);
    },
    byteToGb: (bytes) => {
			return parseFloat(bytes / 1073741824).toFixed(2)
    },
		MBtoGB: (KBVal) => {
			return parseFloat(KBVal / 1024).toFixed(2)
		},
		ByteToMB: (byteVal) => {
			return parseFloat(byteVal / 1048576).toFixed(2)
		},
		ByteToKB: (byteVal) => {
			return parseFloat(byteVal / 1024).toFixed(2)
		}
}

const SocketUtils = {
  socketStore: () => {
    let socket_url = null;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      socket_url = "ws:/localhost:4000/socket";
    } else {
      socket_url = "wss:/api3.tadu.cloud/socket";
    }
    let socket = new Socket(socket_url, {params:
      {token: uuidv4()}
    });
    socket.connect();
    return socket.channel("socket:channel", {});
  }
}
const StringCheck = {
  endsWithAny:(suffixes, string)=> {
    return suffixes.some(function (suffix) {
      return string.endsWith(suffix);
    });
  }
}


export {
  DateUtil,
  DomainUtils,
  CartUtils,
  StringUtils,
  ImageUtils,
  FormatterUtils,
  SocketUtils,
  StringCheck,
  NumberUtils
};