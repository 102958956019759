import { ImageUtils } from '../../../utils/javascriptUtils';

function ProductTypeLabelCase(productType) {
  switch(productType.toString()) {
    case "WINDOW_HOSTING,LINUX_HOSTING" : {
      return "DV Hosting"
    }
    case "SSL" : {
      return "DV SSL"
    }
    case "VPS" : {
      return "DV CLOUD SERVER"
    }
    case "EMAIL" : {
      return "DV EMAIL SERVER"
    }
    case "TADU_PHONE" : {
      return "DV TỔNG ĐÀI ẢO"
    }
    case "TADU_INVOICE" : {
      return "DV HOÁ ĐƠN ĐIỆN TỬ"
    }
    case "CLOUD_BACKUP" : {
      return "DV CLOUD BACKUP"
    }
    default: {
      return "TẤT CẢ DV"
    }
  }
}

var numeral = require("numeral");
var _ = require('lodash');
// Tạo header cho Template PDF
function TotalPurchaseHeaders(logo) {
  return {
  columns: [
    {
    columns:[
      {
      image: logo,
      fit: [100,100],
      alignment: 'left',
      margin : [20, 0, 0 , 0],
      }
    ]
    },
    {
    type: 'none',
    ul: [
      { text: 'CÔNG TY CỔ PHẦN CÔNG NGHỆ TADU', style: 'content', bold: true },
      { text: 'Địa chỉ: Số 37/2C/10 Đường C18, P12 Quận Tân Bình, TP.HCM', style: 'content', bold: true},
      { text: 'Điện thoại: 18006980\n', style: 'content', bold: true },
    ]
    }
  ]
  }
}
// Tạo Thông tin đơn hàng cho Template PDF
function TotalPurchaseInfo(agencyProfile) {
  return {
    columns: [
      {
      type: 'none',
      ul: [
        { text: 'Thông tin đại lý:', style: 'subheader' },
        { text: 'Tên Đại Lý:', style: 'label' }, { text: agencyProfile.name, style: 'content' },
        { text: 'Email:', style: 'label' }, { text: agencyProfile.email, style: 'content' },
        { text: 'Địa chỉ:', style: 'label' }, { text: agencyProfile.address, style: 'content' },
        { text: 'Điện thoại:', style: 'label' }, { text: agencyProfile.hotline, style: 'content' },
      ]
      },
    ]
  }
}


function InvoiceTotalDetail(data_query, invoice_sum_group, invoice_sum_group_without_vat) {
  const month_list = _.range(data_query.from, (data_query.to +1));
  const sum_invoice = _.sumBy(invoice_sum_group, (i) => i.invoice_sum);
  const sum_invoice_without_vat = _.sumBy(invoice_sum_group_without_vat, (i) => i.invoice_without_vat_sum);

  // HEADERS 
  let bodyRows = [
      [
        {text: "THÁNG"} ,{ text: "TỔNG DOANH SỐ TẤT CẢ DV \n (đã bao gồm tên miền và VAT)" },{ text: "TỔNG DOANH SỐ " + ProductTypeLabelCase(data_query.product_types) + "\n (không bao gồm tên miền và VAT)" },
      ],
      [
        {text: "", fillColor: '#cccccc'} ,{ text: " (Đơn vị vnđ)" , alignment: "right",fillColor: '#cccccc'},{ text: " (Đơn vị vnđ)" , alignment: "right", fillColor: '#cccccc'}
      ]
    ]

  const invoice_sum_matches = _.map(month_list, function (i, index) {
    // Find and merge invoice_sum_group to month list duration
    const sum_object = _.find(invoice_sum_group, (total_group) => total_group.month == i)
      if (!sum_object) {
        return {month: i, invoice_sum: 0}
      }
    return sum_object;
  })

  const invoice_sum_without_vat_matches = _.map(month_list, function (i, index) {
    // Find and merge invoice_sum_group_without_vat to month list duration
    const sum_object = _.find(invoice_sum_group_without_vat, (total_group) => total_group.month == i)
      if (!sum_object) {
        return {month: i, invoice_without_vat_sum: 0}
      }
    return sum_object;
  })

  const merge_invoice_matches = _.map(invoice_sum_matches, function(p){
  return _.merge(p, _.find(invoice_sum_without_vat_matches, {month: p.month}))
  })
  
  // BODY SUM DETAIL
  _.forEach(merge_invoice_matches, (i, key) => {
    bodyRows.push(
      [
        { text: i.month },
        { text: numeral(i.invoice_sum).format("0,0"), alignment: "right"},
        { text: numeral(i.invoice_without_vat_sum).format("0,0"), alignment: "right"}
      ]
    )
  })

  // SUM ROWS
  bodyRows.push([
    { text: "TỔNG", bold: true}, 
    { text: numeral(sum_invoice).format("0,0"), alignment: "right", bold: true},
    { text: numeral(sum_invoice_without_vat).format("0,0"), alignment: "right", bold: true}
  ]);
  return [
    {
      style: 'tableExample',
      table: {
        headerRows: 1,
        widths: ["10%","45%", "45%"],
        body: bodyRows
      },
      layout: {
        fillColor: function (rowIndex, node, columnIndex) {
          return (rowIndex == 0) ? '#CCCCCC' : null;
        }
      }
    }
  ]
}

const TotalPurchasePDF = {
  getDataExport: (data_query, invoice_sum_group, invoice_sum_group_without_vat, agencyProfile, logo) => {
    const TotalPurchasePDFObject = {
      pageOrientation: 'portrait',
      content: [
      TotalPurchaseHeaders(logo),
      { text: '\nDOANH SỐ ĐẠI LÝ', style: 'header', alignment: 'center' },
      { text: 'Từ tháng ' + data_query.from +' đến tháng ' + data_query.to + ' năm ' + data_query.year, style: 'subheader', alignment: 'center' },
        TotalPurchaseInfo(agencyProfile),
        {text: "\nBẢNG THỐNG KÊ\n", style: "subheader", alignment: 'center'},
        InvoiceTotalDetail(data_query, invoice_sum_group, invoice_sum_group_without_vat),
      ],
      styles: {
        header: {
          listStyleType: 'none',
          fontSize: 15,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 13,
          float: 'right',
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 0, 0, 0]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        label: {
          fontSize: 10,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        content: {
          fontSize: 9,
          float:'right'
        }
      },
      defaultStyle: {
        columnGap: 10
      },
    }
    return TotalPurchasePDFObject;
  }	
}
export { TotalPurchasePDF };



