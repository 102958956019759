import React from 'react';

function ProductTypeLabelCase(productType) {
  switch(productType) {
    case "domain": {
      return "Tên miền"
    }
    case "vps" : {
      return "Cloud Server"
    }
    case "cloud_server_pro" : {
      return "Cloud Server Pro"
    }
    case "hosting" : {
      return "Hosting"
    }
    case "ssl" : {
      return "SSL"
    }
    case "email" : {
      return "Email Server"
    }
    case "whois" : {
      return "Who Is Privacy"
    }
    case "IP" : {
      return "IP"
    }
    default: {
      return "Tất cả dịch vụ"
    }
  }
}

function DropboxButton(onProductSelect, product_type_selected) {
    return (
      <React.Fragment>
        <div className="btn-group">
          <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
            {ProductTypeLabelCase(product_type_selected)}
          </button>
          <div className="dropdown-menu" x-placement="bottom-start">
            <a onClick={() => onProductSelect("all")} className="dropdown-item">TẤT CẢ DỊCH VỤ</a>
            <a onClick={() => onProductSelect("domain")} className="dropdown-item">TÊN MIỀN</a>
            <a onClick={() => onProductSelect("hosting")} className="dropdown-item">HOSTING</a>
            <a onClick={() => onProductSelect("ssl")} className="dropdown-item">SSL</a>
            <a onClick={() => onProductSelect("vps")} className="dropdown-item">CLOUD SERVER</a>
            <a onClick={() => onProductSelect("cloud_server_pro")} className="dropdown-item">CLOUD SERVER PRO</a>
            <a onClick={() => onProductSelect("email")} className="dropdown-item">EMAIL SERVER</a>
            <a onClick={() => onProductSelect("whois")} className="dropdown-item">WHO IS PRIVACY</a>
            <a onClick={() => onProductSelect("ip")} className="dropdown-item">IP</a>
          </div>
        </div>
      </React.Fragment>
    )
}

class ProductSelect extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    
  }


  render() {
    const {product_type_selected, onProductSelect} = this.props;
    return(
      <React.Fragment>
        {DropboxButton(onProductSelect, product_type_selected)}
      </React.Fragment>
    )
  }

}


export default ProductSelect;