import axios from 'axios';
import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

class CampaignMessageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result_data: null
    }
  }

  componentDidMount() {
    const self = this;
    const { user_campaign_id } = this.props.match.params;
    axios({
      method: 'get',
      url: window.api_url + '/user/campaign_message/detail/' + user_campaign_id,
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        result_data: response.data.data
      })
    });
  }

  render() {
    const {result_data} = this.state;
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Thông Báo</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        {!result_data && <div className="row">
          <div className="col-md-12">
              <div className="card card-flat">
                <div className="card-body">
                  <div className="row">
                    <p>Thông báo không tồn tại</p>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        {result_data && <div className="row">
          <div className="col-md-12">
            <div className="card card-flat">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <h3>{result_data.campaign_message.title}</h3>
                  </div>
                  <div className="col-md-4" style={{textAlign: "right"}}>
                    {result_data.campaign_message.created_date}
                  </div>
                </div>
                <hr/>
                <div>
                  {ReactHtmlParser(result_data.campaign_message.body)}
                </div>
              </div>
            </div>
          </div>
        </div>}
      </React.Fragment>
    )
  }
}


export default withRouter(CampaignMessageDetail);