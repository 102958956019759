import React from 'react'
import '../css/login.css';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';

class UserActivePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDone: false,
      isError: false
    };
  }

  componentDidMount() {
    const self = this;
    const {token} = this.props.routeProps.match.params;
    
    return axios({
      method: 'get',
      url: window.api_url + '/auth/active/' + token,
      withCredentials: true
    }).then(function (response) {
      if (response.data.isError) {
        self.setState({isDone: false, isError: true});
      } else {
        self.setState({isDone: true, isError: false});
      }
    });
  }


  render() {
    const {error, handleSubmit, submitting, pristine, reset} = this.props;
    const {isDone, isError} = this.state;

    return (
      <React.Fragment>
        <div className="navbar navbar-inverse">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <img src="/assets/img/lglogo.png" alt="tadu.cloud"/>
            </a>
          </div>
        </div>
        <div className="page-content">
          <div className="content-wrapper">
            <div className="content d-flex justify-content-center align-items-center">
              <form className="login-form">
                <div className="card mb-0">
                  <div className="card-body login-body">
                    <div className="text-center mb-3">
                      <div className="text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1" style={{border: "none"}}>
                        <a href="/">
                          <img width="205" src="/assets/img/lglogo.png" alt="tadu.cloud"/>
                        </a>
                      </div>
                    </div>

                    {isError && <div className="alert alert-danger border-0">
                      Token bị sai hoặc hết hạn!
                    </div>}

                    {isDone && <div className="alert alert-success border-0">
                      Tài khoản của quý khách đã được kích hoạt thành công!
                    </div>}

                    {(isDone || isError) && <div className="form-group">
                      <RouterLink className="btn btn-light btn-block" to="/">
                        Trở về trang đăng nhập
                      </RouterLink>
                    </div>}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

}


export default UserActivePage;