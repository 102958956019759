export const countries = [
    {
      value : "VIET_NAM",
      label : "Viet Nam",
      code : "VN"
    },
    {
      value : "AFGHANIS",
      label : "Afghanistan",
      code : "AF"
    },
    {
      value : "ALAND_ISLANDS",
      label : "Aland Islands",
      code : "AX"
    },
    {
      value : "ALBANIA",
      label : "Albania",
      code : "AL"
    },
    {
      value : "ALGERIA",
      label : "Algeria",
      code : "DZ"
    },
    {
      value : "AMERICAN_SAMOA",
      label : "American Samoa",
      code : "AS"
    },
    {
      value : "ANDORRA",
      label : "Andorra",
      code : "AD"
    },
    {
      value : "ANGOLA",
      label : "Anglola",
      code : "AO"
    },
    {
      value : "ANGUILLA",
      label : "Angulla",
      code : "AI"
    },
    {
      value : "ANTARCTICA",
      label : "Antarctica",
      code : "AQ"
    },
    {
      value : "ANTIGUA_AND_BARBUDA",
      label : "Antigua and Barbuda",
      code : "AG"
    },
    {
      value : "ARGENTINA",
      label : "Argentina",
      code : "AR"
    },
    {
      value : "ARMENIA",
      label : "Armenia",
      code : "AM"
    },
    {
      value : "ARUBA",
      label : "Aruba",
      code : "AW"
    },
    {
      value : "AUSTRALIA",
      label : "Australia",
      code : "AU"
    },
    {
      value : "AUSTRIA",
      label : "Austria",
      code : "AT"
    },
    {
      value : "AZERBAIJAN",
      label : "Azerbaijan",
      code : "AZ"
    },
    {
      value : "BAHAMAS",
      label : "Bahamas",
      code : "BS"
    },
    {
      value : "BAHRAIN",
      label : "Bahrain",
      code : "BH"
    },
    {
      value : "BANGLADESH",
      label : "Bangladesh",
      code : "BD"
    },
    {
      value : "BARBADOS",
      label : "Barbados",
      code : "BB"
    },
    {
      value : "BELARUS",
      label : "Belarus",
      code : "BY"
    },
    {
      value : "BELGIUM",
      label : "Belgium",
      code : "BE"
    },
    {
      value : "BELIZE",
      label : "Belize",
      code : "BZ"
    },
    {
      value : "BENIN",
      label : "Benin",
      code : "BJ"
    },
    {
      value : "BERMUDA",
      label : "Bermuda",
      code : "BM"
    },
    {
      value : "BHUTAN",
      label : "Bhutan",
      code : "BT"
    },
    {
      value : "BOLIVIA",
      label : "Bolivia",
      code : "BO"
    },
    {
      value : "BOSNIA_AND_HERZEGOVINA",
      label : "Bosnia and Herzegovina",
      code : "BA"
    },
    {
      value : "BOTSWANA",
      label : "Botswana",
      code : "BW"
    },
    {
      value : "BOUTVET_ISLAND",
      label : "Bouvet Island",
      code : "BV"
    },
    {
      value : "BRUNEI_DARUSSALAM",
      label : "Brunei Darussalam",
      code : "BN"
    },
    {
      value : "BRAZIL",
      label : "Brazil",
      code : "BR"
    },
    {
      value : "BRITISH_INDIAN_OCEAN_TERRITORY",
      label : "British Indian Ocean Territory",
      code : "IO"
    },
    {
      value : "BULGARIA",
      label : "Bulgaria",
      code : "BG"
    },
    {
      value : "BURKIAN_FASO",
      label : "Burkina Faso",
      code : "BF"
    },
    {
      value : "BURUNDI",
      label : "Burundi",
      code : "BI"
    },
    {
      value : "CAMBODIA",
      label : "Cambodia",
      code : "KH"
    },
    {
      value : "CAMEROON",
      label : "Cameroon",
      code : "CM"
    },
    {
      value : "CANADA",
      label : "Canada",
      code : "CA"
    },
    {
      value : "CAPE_VERDE",
      label : "Cape Verde",
      code : "CV"
    },
    {
      value : "CAYMAN_ISLANDS",
      label : "Cayman Islands",
      code : "KY"
    },
    {
      value : "CENTRAL_AFRICAN_REPUBLIC",
      label : "Central African Republic",
      code : "CF"
    },
    {
      value : "CHAD",
      label : "Chad",
      code : "TD"
    },
    {
      value : "CHILE",
      label : "Chile",
      code : "CL"
    },
    {
      value : "CHINA",
      label : "China",
      code : "CN"
    },
    {
      value : "CHRISTMAS_ISLAND",
      label : "Christmas Island",
      code : "CX"
    },
    {
      value : "COCOS_ISLANDS",
      label : "Cocos (Keeling) Islands",
      code : "CC"
    },
    {
      value : "COLOMBIA",
      label : "Colombia",
      code : "CO"
    },
    {
      value : "COMOROS",
      label : "Comoros",
      code : "KM"
    },
    {
      value : "CONGO",
      label : "Congo",
      code : "CG"
    },
    {
      value : "CONGO_THE_DEMOCRATIC_REPUBLIC_OF_THE",
      label : "Congo The Democratic Republic of the",
      code : "CD"
    },
    {
      value : "COOK_ISLANDS",
      label : "Cook Islands",
      code : "CK"
    },
    {
      value : "COSTA_RICA",
      label : "Costa Rica",
      code : "CR"
    },
    {
      value : "COTE_INVOIRE",
      label : "Cote Ivoire",
      code : "CI"
    },
    {
      value : "CROATIA",
      label : "Croatia",
      code : "HR"
    },
    {
      value : "CUBA",
      label : "Cuba",
      code : "CU"
    },
    {
      value : "CYPRUS",
      label : "Cyprus",
      code : "CY"
    },
    {
      value : "CZECH_REPUBLIC",
      label : "Czech Republic",
      code : "CZ"
    },
    {
      value : "DENMARK",
      label : "Denmark",
      code : "DK"
    },
    {
      value : "DJIBOUTI",
      label : "Djibouti",
      code : "DJ"
    },
    {
      value : "DOMINICA",
      label : "Dominica",
      code : "DM"
    },
    {
      value : "DOMINICA_REPUBLIC",
      label : "Dominican Republic",
      code : "DO"
    },
    {
      value : "ECUADOR",
      label : "Ecuador",
      code : "EC"
    },
    {
      value : "EGYPT",
      label : "Egypt",
      code : "EG"
    },
    {
      value : "EL_SALVADOR",
      label : "El Salvador",
      code : "SV"
    },
    {
      value : "EQUATORIAL_GUINEA",
      label : "Equatorial Guinea",
      code : "GQ"
    },
    {
      value : "ERITREA",
      label : "Eritrea",
      code : "ER"
    },
    {
      value : "ESTONIA",
      label : "Estonia",
      code : "EE"
    },
    {
      value : "ETHIOPIA",
      label : "Ethiopia",
      code : "ET"
    },
    {
      value : "FALKLAND_ISLANDS",
      label : "Falkland Islands (Malvinas)",
      code : "FK"
    },
    {
      value : "FAROE_ISLAND",
      label : "Faroe Islands",
      code : "FO"
    },
    {
      value : "FIJI",
      label : "Fiji",
      code : "FJ"
    },
    {
      value : "FINLAND",
      label : "Finland",
      code : "FI"
    },
    {
      value : "FRANCE",
      label : "France",
      code : "FR"
    },
    {
      value : "FRENCH_GUIANA",
      label : "French Guiana",
      code : "GF"
    },
    {
      value : "FRENCH_POLYNESIA",
      label : "French Polynesia",
      code : "PF"
    },
    {
      value : "FRENCH_SOUTHERM_TERRITORIES",
      label : "French Southern Territories",
      code : "TF"
    },
    {
      value : "GABON",
      label : "Gabon",
      code : "GA"
    },
    {
      value : "GAMBIA",
      label : "Gambia",
      code : "GM"
    },
    {
      value : "GEORGIA",
      label : "Georgia",
      code : "GE"
    },
    {
      value : "GERMANY",
      label : "Germany",
      code : "DE"
    },
    {
      value : "GHANA",
      label : "Ghana",
      code : "GH"
    },
    {
      value : "GIBRALTAR",
      label : "Gibraltar",
      code : "GI"
    },
    {
      value : "GREECE",
      label : "Greece",
      code : "GR"
    },
    {
      value : "GREENLAND",
      label : "Greenland",
      code : "GL"
    },
    {
      value : "GRENADA",
      label : "Grenada",
      code : "GD"
    },
    {
      value : "GUADELOUPE",
      label : "Guadeloupe",
      code : "GP"
    },
    {
      value : "GUAM",
      label : "Guam",
      code : "GU"
    },
    {
      value : "GUATEMALA",
      label : "Guatemala",
      code : "GT"
    },
    {
      value : "GUERNSEY",
      label : "Guernsey",
      code : "GG"
    },
    {
      value : "GUINEA",
      label : "Guinea",
      code : "GN"
    },
    {
      value : "GUINEA_BISSAU",
      label : "Guinea-Bissau",
      code : "GW"
    },
    {
      value : "GUYANA",
      label : "Guyana",
      code : "GY"
    },
    {
      value : "HAITI",
      label : "Haiti",
      code : "HT"
    },
    {
      value : "HEARD_ISLANDS_AND_MCDONALD_ISLANDS",
      label : "Heard Island and Mcdonald Islands",
      code : "HM"
    },
    {
      value : "HOLY_SEE",
      label : "Holy See (Vatican City State)",
      code : "VA"
    },
    {
      value : "HONDURAS",
      label : "Honduras",
      code : "HN"
    },
    {
      value : "HONG_KONG",
      label : "Hong Kong",
      code : "HK"
    },
    {
      value : "HUNGARY",
      label : "Hungary",
      code : "HU"
    },
    {
      value : "ICELAND",
      label : "Iceland",
      code : "IS"
    },
    {
      value : "INDIA",
      label : "India",
      code : "IN"
    },
    {
      value : "INDONESIA",
      label : "Indonesia",
      code : "ID"
    },
    {
      value : "IRAN",
      label : "Iran Islamic Republic Of",
      code : "IR"
    },
    {
      value : "IRAQ",
      label : "Iraq",
      code : "IQ"
    },
    {
      value : "IRELAND",
      label : "Ireland",
      code : "IE"
    },
    {
      value : "ISLE_OF_MAN",
      label : "Isle of Man",
      code : "IM"
    },
    {
      value : "ISRAEL",
      label : "Israel",
      code : "IL"
    },
    {
      value : "ITALY",
      label : "Italy",
      code : "IT"
    },
    {
      value : "JAMAICA",
      label : "Jamaica",
      code : "JM"
    },
    {
      value : "JAPAN",
      label : "Japan",
      code : "JP"
    },
    {
      value : "JERSEY",
      label : "Jersey",
      code : "JE"
    },
    {
      value : "JORDAN",
      label : "Jordan",
      code : "JO"
    },
    {
      value : "KAZAKHSTAN",
      label : "Kazakhstan",
      code : "KZ"
    },
    {
      value : "KENYA",
      label : "Kenya",
      code : "KE"
    },
    {
      value : "KIRIBATI",
      label : "Kiribati",
      code : "KI"
    },
    {
      value : "KOREA_DEMOCRATIC",
      label : "Korea Democratic People Republic of",
      code : "KP"
    },
    {
      value : "KOREA_REPUBLIC",
      label : "Korea Republic of",
      code : "KR"
    },
    {
      value : "KUWAIT",
      label : "Kuwait",
      code : "KW"
    },
    {
      value : "KYRGYSTAN",
      label : "Kyrgyzstan",
      code : "KG"
    },
    {
      value : "LAO",
      label : "Lao People Democratic Republic",
      code : "LA"
    },
    {
      value : "LATVIA",
      label : "Latvia",
      code : "LV"
    },
    {
      value : "LEBANO",
      label : "Lebanon",
      code : "LB"
    },
    {
      value : "LESOTHO",
      label : "Lesotho",
      code : "LS"
    },
    {
      value : "LIBERIA",
      label : "Liberia",
      code : "LR"
    },
    {
      value : "LIBYAN_ARAB_JAMAHIRIA",
      label : "Libyan Arab Jamahiriya",
      code : "LY"
    },
    {
      value : "LIECHTENSTEIN",
      label : "Liechtenstein",
      code : "LI"
    },
    {
      value : "LITHUANIA",
      label : "Lithuania",
      code : "LT"
    },
    {
      value : "LUXEMBOURG",
      label : "Luxembourg",
      code : "LU"
    },
    {
      value : "MACAO",
      label : "Macao",
      code : "MO"
    },
    {
      value : "MACEDONIA",
      label : "Macedonia The Former Yugoslav Republic of",
      code : "MK"
    },
    {
      value : "MADAGASCAR",
      label : "Madagascar",
      code : "MG"
    },
    {
      value : "MALAWI",
      label : "Malawi",
      code : "MG"
    },
    {
      value : "MALAYSIA",
      label : "Malaysia",
      code : "MY"
    },
    {
      value : "MADIVES",
      label : "Maldives",
      code : "MV"
    },
    {
      value : "MALI",
      label : "Mali",
      code : "ML"
    },
    {
      value : "MALTA",
      label : "Malta",
      code : "MT"
    },
    {
      value : "MARSHALL_ISLANDS",
      label : "Marshall Islands",
      code : "MH"
    },
    {
      value : "MARTINIQUE",
      label : "Martinique",
      code : "MQ"
    },
    {
      value : "MAURITANIA",
      label : "Mauritania",
      code : "MR"
    },
    {
      value : "MAURITIUS",
      label : "Mauritius",
      code : "MU"
    },
    {
      value : "MAYOTTE",
      label : "Mayotte",
      code : "YT"
    },
    {
      value : "MEXICO",
      label : "Mexico",
      code : "MX"
    },
    {
      value : "MICRONESIA",
      label : "Micronesia Federated States of",
      code : "FM"
    },
    {
      value : "MOLDOVA",
      label : "Moldova, Republic of",
      code : "MD"
    },
    {
      value : "MONACO",
      label : "Monaco",
      code : "MC"
    },
    {
      value : "MOGOLIA",
      label : "Mongolia",
      code : "MN"
    },
    {
      value : "MONTSERRAT",
      label : "Montserrat",
      code : "MS"
    },
    {
      value : "MORCOCCO",
      label : "Morocco",
      code : "MA"
    },
    {
      value : "MOZAMBIQUE",
      label : "Mozambique",
      code : "MZ"
    },
    {
      value : "MYANMAR",
      label : "Myanmar",
      code : "MM"
    },
    {
      value : "NAMIBIA",
      label : "Namibia",
      code : "NA"
    },
    {
      value : "NAURU",
      label : "Nauru",
      code : "NR"
    },
    {
      value : "NEPAL",
      label : "Nepal",
      code : "NP"
    },
    {
      value : "NETHERLANDS",
      label : "Netherlands",
      code : "NL"
    },
    {
      value : "NETHERLANDS_ANTILLES",
      label : "Netherlands Antilles",
      code : "AN"
    },
    {
      value : "NEW_CALEDONIA",
      label : "New Caledonia",
      code : "NC"
    },
    {
      value : "NEW_ZEALANDS",
      label : "New Zealand",
      code : "NZ"
    },
    {
      value : "NICARAGUA",
      label : "Nicaragua",
      code : "NI"
    },
    {
      value : "NIGER",
      label : "Niger",
      code : "NE"
    },
    {
      value : "NIGERIA",
      label : "Nigeria",
      code : "NG"
    },
    {
      value : "NIUE",
      label : "Niue",
      code : "NU"
    },
    {
      value : "NORTOLK_ISLAND",
      label : "Norfolk Island",
      code : "NF"
    },
    {
      value : "NORTHERN_MATIANA_ISLANDS",
      label : "Northern Mariana Islands",
      code : "MP"
    },
    {
      value : "NORWAY",
      label : "Norway",
      code : "NO"
    },
    {
      value : "OMAN",
      label : "Oman",
      code : "OM"
    },
    {
      value : "PAKISTAN",
      label : "Pakistan",
      code : "PK"
    },
    {
      value : "PALAU",
      label : "Palau",
      code : "PW"
    },
    {
      value : "PALESTIMOAM_TERITORY",
      label : "Palestinian Territory Occupied",
      code : "PS"
    },
    {
      value : "PANAMA",
      label : "Panama",
      code : "PA"
    },
    {
      value : "PAPUA_NEW_GUINEA",
      label : "Papua New Guinea",
      code : "PG"
    },
    {
      value : "PARAGUAY",
      label : "Paraguay",
      code : "PY"
    },
    {
      value : "PERU",
      label : "Peru",
      code : "PE"
    },
    {
      value : "PHILIPPINES",
      label : "Philippines",
      code : "PH"
    },
    {
      value : "PITCAIRN",
      label : "Pitcairn",
      code : "PN"
    },
    {
      value : "POLAND",
      label : "Poland",
      code : "PL"
    },
    {
      value : "PORTUGAL",
      label : "Portugal",
      code : "PT"
    },
    {
      value : "PUERTO_RICO",
      label : "Puerto Rico",
      code : "PR"
    },
    {
      value : "QUATAR",
      label : "Qatar",
      code : "QA"
    },
    {
      value : "REUNION",
      label : "Reunion",
      code : "RE"
    },
    {
      value : "ROMANIA",
      label : "Romania",
      code : "RO"
    },
    {
      value : "RUSSIAN",
      label : "Russian Federation",
      code : "RU"
    },
    {
      value : "RWANDA",
      label : "RWANDA",
      code : "RW"
    },
    {
      value : "SAINT_HELENA",
      label : "Saint Helena ",
      code : "SH"
    },
    {
      value : "SAINT_KITTS_AND_NEVIS",
      label : "Saint Kitts and Nevis",
      code : "KN"
    },
    {
      value : "SAINT_LUCIA",
      label : "Saint Lucia",
      code : "LC"
    },
    {
      value : "SAINT_PIERRE",
      label : "Saint Pierre and Miquelon",
      code : "PM"
    },
    {
      value : "SAINT_VINCENT",
      label : "Saint Vincent and the Grenadines",
      code : "VC"
    },
    {
      value : "SAMOA",
      label : "Samoa",
      code : "WS"
    },
    {
      value : "SAN_MARIO",
      label : "San Marino",
      code : "SM"
    },
    {
      value : "SAO_TOME_AND_PRINCIPE",
      label : "Sao Tome and Principe",
      code : "ST"
    },
    {
      value : "SAUDI_ARABIA",
      label : "Saudi Arabia",
      code : "SA"
    },
    {
      value : "SENEGAL",
      label : "Senegal",
      code : "SN"
    },
    {
      value : "SERBIA_AND_MONTENEGRO",
      label : "Serbia and Montenegro",
      code : "CS"
    },
    {
      value : "SEYCHELLES",
      label : "Seychelles",
      code : "SC"
    },
    {
      value : "SIERRA_LEONE",
      label : "Sierra Leone",
      code : "SL"
    },
    {
      value : "SINGAPORE",
      label : "Singapore",
      code : "SG"
    },
    {
      value : "SLOVAKIA",
      label : "Slovakia",
      code : "SK"
    },
    {
      value : "SOLOVENIA",
      label : "Slovenia",
      code : "SI"
    },
    {
      value : "SOLOMON_ISLANDS",
      label : "Solomon Islands",
      code : "SB"
    },
    {
      value : "SOMALI",
      label : "Somalia",
      code : "SO"
    },
    {
      value : "SOUTH_AFRICA",
      label : "South Africa",
      code : "ZA"
    },
    {
      value : "SOUTH_GEORGIA_AND_THE_SOUTH_ISLANDS",
      label : "South Georgia and the South Sandwich Islands",
      code : "GS"
    },
    {
      value : "SPAIN",
      label : "Spain",
      code : "ES"
    },
    {
      value : "SRI_LANKA",
      label : "Sri Lanka",
      code : "LK"
    },
    {
      value : "SUDAN",
      label : "Sudan",
      code : "SD"
    },
    {
      value : "SURINAME",
      label : "Suriname",
      code : "SR"
    },
    {
      value : "SVALBARD_AND_JAN_MAYEN",
      label : "Svalbard and Jan Mayen",
      code : "SJ"
    },
    {
      value : "SWAZILAND",
      label : "Swaziland",
      code : "SZ"
    },
    {
      value : "SWEDEN",
      label : "Sweden",
      code : "SE"
    },
    {
      value : "SWITZERLAND",
      label : "Switzerland",
      code : "CH"
    },
    {
      value : "SYRIAN_ARAB_REPUBLIC",
      label : "Syrian Arab Republic",
      code : "SY"
    },
    {
      value : "TAIWAN",
      label : "Taiwan",
      code : "TW"
    },
    {
      value : "TAJIKISTAN",
      label : "Tajikistan",
      code : "TJ"
    },
    {
      value : "TANZANIA",
      label : "Tanzania",
      code : "TZ"
    },
    {
      value : "THAILAND",
      label : "Thailand",
      code : "TH"
    },
    {
      value : "TIMOR_LESTE",
      label : "Timor-Leste",
      code : "TL"
    },
    {
      value : "TOGO",
      label : "Togo",
      code : "TG"
    },
    {
      value : "TOKELAU",
      label : "Tokelau",
      code : "TK"
    },
    {
      value : "TONGA",
      label : "Tonga",
      code : "TO"
    },
    {
      value : "TRINIDAD_AND_TOBAGO",
      label : "Trinidad and Tobago",
      code : "TT"
    },
    {
      value : "TUNISIA",
      label : "Tunisia",
      code : "TN"
    },
    {
      value : "TURKEY",
      label : "Turkey",
      code : "TR"
    },
    {
      value : "TURKMENISTAN",
      label : "Turkmenistan",
      code : "TM"
    },
    {
      value : "TURK_CAICOS_ISLANDS",
      label : "Turks and Caicos Islands",
      code : "TC"
    },
    {
      value : "TUVALU",
      label : "Tuvalu",
      code : "TV"
    },
    {
      value : "UGANDA",
      label : "Uganda",
      code : "UG"
    },
    {
      value : "UKRAINE",
      label : "Ukraine",
      code : "UA"
    },
    {
      value : "UNITED_ARAB_EMIRATES",
      label : "United Arab Emirates",
      code : "AE"
    },
    {
      value : "UNITED_KINGDOM",
      label : "United Kingdom",
      code : "GB"
    },
    {
      value : "UNITED_STATES",
      label : "United States",
      code : "US"
    },
    {
      value : "UNITED_STATES_MINOR_OUTLYING_ISLAND",
      label : "United States Minor Outlying Islands",
      code : "UM"
    },
    {
      value : "URUQUAY",
      label : "Uruguay",
      code : "UY"
    },
    {
      value : "UZERKISTAN",
      label : "Uzbekistan",
      code : "UZ"
    },
    {
      value : "VANUATU",
      label : "Vanuatu",
      code : "VU"
    },
    {
      value : "VENEZUELA",
      label : "Venezuela",
      code : "VE"
    },
    {
      value : "VIRGIN_ISLANDS_BRITISH",
      label : "Virgin Islands, British",
      code : "VG"
    },
    {
      value : "VIRGIN_ISLANDS_US",
      label : "Virgin Islands, U.S.",
      code : "VI"
    },
    {
      value : "WALLOS_AND_FUTUNA",
      label : "Wallis and Futuna",
      code : "WF"
    },
    {
      value : "WESTERN_SAHARA",
      label : "Western Sahara",
      code : "EH"
    },
    {
      value : "YEMEN",
      label : "Yemen",
      code : "YE"
    },
    {
      value : "ZAMBIA",
      label : "Zambia",
      code : "ZM"
    },
    {
      value : "ZIMBABUE",
      label : "Zimbabwe",
      code : "ZW"
    }
]  