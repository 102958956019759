import React from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import InvoiceStatus from '../../component/InvoiceStatus';

var numeral = require('numeral');
var _ = require('lodash');

function InvoiceRows(data) {
    const inner_invoice_row = _.map(data,(invoice, key) => (
        <tr key={key}>
            <td>
                <Link to={"/user/invoice/detail/"+invoice.id}>{invoice.code}</Link>
            </td>
            <td>
                {invoice.user.email}
                <br/>
                <small className="display-block text-muted text-size-small">{invoice.user.full_name} - {invoice.user.phone}</small>
            </td>
            <td>{invoice.payment_type}</td>
            <td>{InvoiceStatus(invoice.invoice_status)}</td>
            <td>{invoice.created_date}</td>
            <td>{numeral(invoice.total).format('0,0')} đ</td>
            <td>{numeral(invoice.agency_invoice && invoice.agency_invoice.total).format('0,0')} đ</td>
            <td>
                <div className="icons-list">
                    <div className="dropdown">
                        <a href="#" className="list-icons-item" data-toggle="dropdown">
                            <i className="icon-menu9"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                            <Link to={"/user/invoice/detail/"+invoice.id} className="dropdown-item">
                                <i className="icon-list-numbered"></i> Chi tiết
                            </Link>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    ))
    return inner_invoice_row;
}

class RecentAgencyOrderTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            invoices: []
        }
    }

    componentDidMount() {
        const self = this;

        axios({
            method: 'get',
            url: window.api_url + '/user/invoice/last',
            withCredentials: true
        }).then(function (response) {
            self.setState({
                ...self.state,
                invoices: response.data.data
            })
        });
    }

    render() {
        const {invoices} = this.state;

        return(
            <React.Fragment>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Mã đơn hàng</th>
                                <th>Khách hàng</th>
                                <th>PT thanh toán</th>
                                <th>Trạng Thái</th>
                                <th>Ngày đăng ký</th>
                                <th>Giá mua vào</th>
                                <th>Giá bán ra</th>
                                <th className="text-center footable-visible footable-last-column" style={{width: "30px"}}>
                                    <i className="icon-menu-open2"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {InvoiceRows(invoices)}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }

}


export default RecentAgencyOrderTable;