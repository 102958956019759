import React from 'react'
import axios from 'axios';
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';
import { Link, withRouter } from 'react-router-dom';
import ShowWhoisModal from './modals/ShowWhoisModal';
import { DomainUtils, CartUtils } from '../../utils/javascriptUtils';
import { RenderSearchForm } from '../../component/formInput';
import { Field, reduxForm } from 'redux-form';
import { domainExts } from '../../models/domain_ext';

var numeral = require('numeral');
var _ = require('lodash');

function DomainOrderRows(domain,domainPriceResult, domainPrice, isDomainSelect, handleAddDomain, handleRemoveDomain) {
  return (
    <div className="card card-body container-fluid main-extent">
        <div className="row">
          <div className="col-md-8">
            <h6 style={{ paddingTop: "5px" }}><strong>{domain}</strong></h6>
          </div>
          <div className="col-md-2 price" style={{ paddingTop: "5px" }}>
            <h6>{domainPrice} đ / năm</h6>
          </div>
          <div className="col-md-2">
            {OrderStatus(domain, domainPriceResult, isDomainSelect, handleAddDomain, handleRemoveDomain)}
          </div>
        </div>
    </div>
  ) 
}

function VNDomainOrderRows(domainSearch, ext, domainPriceResult, domainPrice, isDomainSelect, handleAddDomain, handleRemoveDomain) {
  const domain = domainSearch + ext;
  const domain_name_length = domain.split('.')[0].length; // lấy độ dài của tên miền
  let vn_domain_inner_rows = DomainOrderRows(domain, domainPriceResult, domainPrice, isDomainSelect, handleAddDomain, handleRemoveDomain)
  // Kiểm tra tên miền có lớn hơn 2 ký tự
  if(domain_name_length <= 2) {
    vn_domain_inner_rows = (
      <div className="card card-body container-fluid main-extent" >
        <div className="panel-heading">
          <span className="icon icon-info22 mr-1"></span>
          Rất tiếc,<strong className="text-danger"> {domain}</strong> không hỗ trợ tên miền <strong className="text-danger"> 1 hoặc 2 </strong> ký tự !
        </div>
      </div>
    )
  }
  return vn_domain_inner_rows
}

function DomainExistRows(domain, handleShowWhoisModal) {
  return (
    <div className="card card-body container-fluid main-extent" >
      <div className="panel-heading">
        <span className="icon icon-info22 mr-1"></span>
        Rất tiếc, <strong className="text-danger">{domain}</strong> đã được mua bởi người khác.
        <a href="#" className={"bg-primary"} className="btnwhois" onClick={() => handleShowWhoisModal()}> Xem whois</a>
      </div>
    </div>
  )
}

function DomainPremiumRows(domain) {
  return (
    <div className="card card-body container-fluid main-extent" >
      <div className="panel-heading">
        <span className="icon icon-info22 mr-1"></span>
        Rất tiếc, <strong className="text-danger">{domain}</strong> là tên miền đặc biệt, vui lòng liên hệ số <strong >1800 6980 (nhánh 1)</strong> để được báo giá.
      </div>
    </div>
  )
}

// show ra 1 hoặc các tên miền người dùng tìm kiếm.
function DomainRow(searchDomain, addItem, updateCart, deleteItem, cart, setCart) {
  let domain_row = null;
  const is_domain_valid = DomainUtils.CheckIsValidDomain(searchDomain);
  const is_domain_ext = DomainUtils.CheckIsDomainExt(searchDomain);

  // xử lý nếu người dùng nhập sai định dạng tên miền
  if(!is_domain_valid) {
    domain_row =
      <div className="card card-body container-fluid main-extent"> 
        <span>Tên miền <strong className="text-orange">{searchDomain}</strong> không hỗ trợ !</span>
      </div>
  // Nếu người dùng có nhập đuôi tên miền và đúng định dạng, DomainItem sẽ trả về đúng tên miền tìm kiếm
  }else if(searchDomain && is_domain_ext && is_domain_valid) { 
    domain_row = (
      <DomainItem 
        cart={cart}
        domainSearch={searchDomain} 
        ext={""}
        key={searchDomain} 
        setCart={setCart}
        addItem={addItem}
        updateCart={updateCart}
        deleteItem={deleteItem} 
      />)
  // Nếu người dùng không nhập đuôi tên miền tự động gán các extension có sẳn vào                  
  }else if(searchDomain && !is_domain_ext && is_domain_valid) { 
    domain_row = _.map(domainExts, function (ext, key){
      return (
        <DomainItem 
            cart={cart}
            domainSearch={searchDomain} 
            key={searchDomain + key} 
            addItem={addItem}
            ext= {ext}
            setCart={setCart}
            updateCart={updateCart}
            deleteItem={deleteItem} 
          />
          )
        })
  }
  return domain_row;
}

function OrderStatus(domain, domainPriceResult, isDomainSelect, handleAddDomain, handleRemoveDomain) {
  let inline_html_status_order = 
    <div style={{whiteSpace: 'nowrap'}}>
      <button onClick={() => handleAddDomain(domain, domainPriceResult)} className="btn btn-primary bg-blue btndomainorder">
        Đặt mua
      </button>
    </div>

  if (isDomainSelect) { //CHECK DOMAIN ORDER SELECTED
    inline_html_status_order = 
      <div>
        <a style={{ fontSize: '14px' }} >
          <i style={{ color: 'blue' }} className="icon-check"></i> Đã Đăng Ký
        </a>
        <br/>
        <a onClick={() => handleRemoveDomain()} style={{ fontSize: '14px' }} >
          <i style={{ color: 'red' }} className="icon-bin"></i> Xoá bỏ
        </a>
       </div>
  }

  return inline_html_status_order;
}

class DomainItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      domainPriceResult: {},
      isLoading: true,
      isDomainSelect: false,
      idItemSelect: null,
      is_show_whois_modal: false,
    };

    this.handleAddDomain = this.handleAddDomain.bind(this);
    this.handleRemoveDomain = this.handleRemoveDomain.bind(this);   
    this.handleShowWhoisModal = this.handleShowWhoisModal.bind(this);
    this.handleHideModal = this.handleHideModal.bind(this);    
  }
  handleShowWhoisModal() {
    this.setState({
      is_show_whois_modal:true
    })
  }   
  handleHideModal() {
    this.setState({
      is_show_whois_modal:false
    })
  }

  handleAddDomain(domainName, domainPriceResult) {
    const { addItem, updateCart } = this.props;

    const item = {
      id: uuidv4(),
      group_id: null,
      product: domainPriceResult.price.product,
      price_is_year: domainPriceResult.price.is_year,
      // price: domainPriceResult.price,
      month: 12,
      quantity: 1,
      domain_name: domainName,
      domain_profile_id: null,
      template_id: null, //template id để sử dung cho vps.
      user_product_id: null, //Dịch vụ cần gia hạn
      is_renew: false,
      is_upgrade: false,
      is_tranfer: false,
      is_delivery: false,
      parent_id: null,
      order: 0 //Vị trí của item trong giỏ hàng
    }
    this.setState({
      isDomainSelect: true,
      idItemSelect: item.id
    })
    
    addItem(item);
    updateCart();
  }

  handleRemoveDomain() {
    const { deleteItem, cart, updateCart, setCart } = this.props;
    const { idItemSelect } = this.state;

    const domain_remove = _.find(cart.items, (i) =>  i.id == idItemSelect)
    deleteItem(domain_remove.group_id);
    this.setState({
      isDomainSelect: false
    })
  }
  
  componentDidMount() {
    const self = this;
    const { domainSearch, ext, cart } = this.props;
    const domain = domainSearch + ext;
    axios({
      method: 'get',
      url: window.api_url + '/domain/' + domain + "/check",
      withCredentials: true
    }).then(function (response) {
      // Kiểm tra các dịch vụ tên miền đã tồn tại trong giỏ hàng hay chưa.
      const domain_exist = CartUtils.FindDomainExist(domain, cart);
      self.setState({
        ...self.state,
        isLoading: false,
        domainPriceResult: response.data.data,
        isDomainSelect: domain_exist && true,
        idItemSelect: domain_exist && domain_exist.id
      })
    });
  }

  render() {
    const { domainSearch, ext } = this.props;
    const { domainPriceResult, isLoading, is_show_whois_modal, isDomainSelect } = this.state;
    const domain = domainSearch + ext;
    const is_vn_domain = domain.split('.')[1].includes("vn");
    var domainPrice = 0;
    if (!isLoading && domainPriceResult && !domainPriceResult.is_exist) { // Calculate Domain Price
      domainPrice = numeral(domainPriceResult.price.price12 +
        domainPriceResult.price.init_price +
        domainPriceResult.price.init_price_service +
        domainPriceResult.price.price_service).format('0,0');
    }
    return (
      domainPriceResult && <React.Fragment>
        {isLoading && <div className="card card-body container-fluid main-extent" > 
          <img width="24" src="/assets/img/squares.gif" alt="Loading Animation" />
        </div>}
        {!isLoading && !domainPriceResult.is_exist && !domainPriceResult.premium && !is_vn_domain &&
          DomainOrderRows(domain, 
          domainPriceResult, 
          domainPrice, 
          isDomainSelect, 
          this.handleAddDomain, 
          this.handleRemoveDomain
        )}

        {/* Các tên miền VN cần phải được kiểm tra độ dài, vì hiện tại tên miền từ 1,2 ký tự đã không còn được khả dụng */}
        {!isLoading && !domainPriceResult.is_exist && !domainPriceResult.premium && is_vn_domain && 
          VNDomainOrderRows(
            domainSearch,
            ext,
            domainPriceResult,
            domainPrice,
            isDomainSelect,
            this.handleAddDomain,
            this.handleRemoveDomain
        )}

        {!isLoading && domainPriceResult.is_exist && DomainExistRows(domain, this.handleShowWhoisModal)}
        {!isLoading && !domainPriceResult.is_exist && domainPriceResult.premium && DomainPremiumRows(domain)}
        {is_show_whois_modal && <ShowWhoisModal title="Thông tin whois" domain={domain} show={is_show_whois_modal} onHide={this.handleHideModal}></ShowWhoisModal>}
      </React.Fragment>
    )
  }

}
class DomainPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      domain: "",
      searchDomain: "",
      generic_domains_check: []
    }
    this.findDomain = this.findDomain.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
  }

  findDomain(domain_name) {
    this.setState({
      ...this.state,
      searchDomain: domain_name.toLowerCase().replace(/^(www\.)/,"").trim()
    });
  }
  
  componentDidMount() {
    const { loadCart } = this.props;
    const domain_value = new URLSearchParams(this.props.location.search).get("search")
    loadCart().then(() => {
      if(domain_value) {
        this.findDomain(domain_value)
      }
    });
  }

  onSearchSubmit(values) {
    const { history } = this.props;
    if(values && values.search) {
      history.push("?search=" + values.search)
      this.findDomain(values.search)
    }
  }

  render() {
    const { searchDomain } = this.state;
    const { addItem, updateCart, deleteItem, handleSubmit, cart, setCart } = this.props;
    return (
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Đăng Ký Tên Miền</span>
              </h4>
            </div>
          </div>
        </div>
        <br />
        <div className="card card-body">
          <form style={{display:"inline-flex"}} onSubmit={handleSubmit(this.onSearchSubmit)}>
            <div style={{ width:"98%", paddingRight:5}}>
              <Field
                name="search"
                defaultValue={searchDomain}
                placeholder="Nhập tên miền..."
                component={RenderSearchForm}
              />
            </div>
            <div>
              <Link to="/products/cart" style={{ whiteSpace: 'nowrap',width: "inherit"}} className="btn bg-warning"> 
                <i className="icon-cart5 text-white"></i>
              </Link> 
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-md-12">
            {searchDomain && DomainRow(searchDomain, addItem, updateCart, deleteItem, cart, setCart)}
          </div>
        </div>            
        {searchDomain && <div className="row">
          <div className="col-md-12 text-align-right">
            <Link to="/products/cart" className="btn btn-warning btn-xlg">
              Tiếp tục <span className="icon-arrow-right7"></span>
            </Link>
          </div>
        </div>}
      </React.Fragment>
    )
  }
}

const mapState = state => ({
  cart: state.cart
});

const mapDispatch = (dispatch) => ({
  addItem: (item) => dispatch.cart.addItem(item),
  deleteItem: (item) => dispatch.cart.deleteItem(item),
  loadCart: () => dispatch.cart.loadCart(),
  updateCart: () => dispatch.cart.updateCart(),
  setCart: (cart) => dispatch.cart.setCart(cart),
});

export default (connect(mapState, mapDispatch)(
  reduxForm({
    form: 'DomainPage',
    destroyOnUnmount: true,
    enableReinitialize: true,
  })(withRouter(DomainPage))));	
