import React from 'react'

class ReduxLoginFormField extends React.Component {
    render() {
        const {input, iconClass, placeholder, warning, type, meta: { touched, error }} = this.props;

        return (
            <React.Fragment>
                <div className="form-group form-group-feedback form-group-feedback-left">
                    <input {...input} className={"form-control " +  (error != null ? 'border-danger' : '')} placeholder={placeholder}  type={type}/>
                    <div className="form-control-feedback">
                        <i className={iconClass + " text-muted"}></i>
                    </div>
                    {touched && error && <span className="form-text text-danger">{error}</span>}
                </div>
            </React.Fragment>
        )
    }
}

export default ReduxLoginFormField;