import React from 'react';
class IntroPage extends React.Component {
    render() {
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold"> Giới Thiệu chung</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card panel-flat">
                            <div className="card-body">
                                Nắm bắt được nhu cầu các đại lý (sử dụng thương hiệu riêng) đều mong muốn sở hữu một trang bán hàng riêng dành cho thương hiệu của mình.
                                <br/>
                                Tadu xin giới thiệu đến quý đại lý hệ thống API giúp quý đại lý có thể xây dựng ra trang bán hàng (như
                                <a href="https://tadu.cloud/"> tadu.cloud</a>) hoàn chỉnh để phục vụ nhu cầu phát triển thị trường thuận lợi hơn.
                                <br/>
                                Tadu đã cố gắng đơn giản hóa các API này để giúp quý đại lý không tốn nhiều thời gian tìm hiểu và sử dụng.
                                <br/><br/>
                                <a target="_blank" href="/assets/tadu-guideline/tadu-api-example-kit.rar" className="btn btn-primary">Download ví dụ mẫu</a>
                                <br/>
                                <br/>
                                Đối với những đại lý quen sử dụng CMS Wordpress và mong muốn xây dựng trang bán hàng bằng CMS này, quý đại lý có thể tải plugin Tadu đã xây dựng sẵn. Với plugin này, quý đại lý không cần lập trình xây dựng tính năng mà chỉ cần CSS lại cho phù hợp với nhận diện thương hiệu của mình.
                                <br/><br/>
                                <a target="_blank" href="/assets/tadu-guideline/tadu_plugin.zip" className="btn btn-primary">Download plugin wordpress tại đây</a>
                                <br/><br/>
                                Quý khách cần vào <a href="#">cấu hình thông tin đại lý</a> nhập vào đường dẫn <strong>trang chủ</strong> để hoạt động đủ tính năng.
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default IntroPage;