import React from 'react'
import '../css/style.css';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import ReduxFormField from "../component/ReduxFormField";
import axios from 'axios';

class UserChangePasswordPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isDone: false
        };
    }

    submit(values) {
        const {reset} = this.props;

        const self = this;
        self.setState({isDone: false});

        return axios({
            method: 'post',
            url: window.api_url + '/auth/user/change_password',
            data: {
                ...values
            },
            withCredentials: true
        }).then(function (response) {
            if (response.data.isError) {
                throw new SubmissionError(response.data.error);
            } else {
                reset();
                self.setState({isDone: true});
            }
        });
    }

    render() {

        const {error, handleSubmit, submitting, pristine, reset} = this.props;
        const {isDone} = this.state;

        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold">Thay Đổi Mật Khẩu</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="card">
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={handleSubmit(this.submit.bind(this))}>
                            <Field
                                name="password"
                                type="text"
                                label="Mật khẩu"
                                iconClass="icon-mention"
                                placeholder="Mật khẩu"
                                component={ReduxFormField}/>

                            <div className="text-center">
                                <button type="submit" className={"btn bg-blue btn-primary " + (submitting ? "disabled" : "")}>
                                    Cập nhật <i className="icon-arrow-right14 position-right"></i>
                                </button>
                            </div>

                            <br/>
                            {isDone && <div className="alert alert-success border-0">
                                Cập nhật thành công!
                            </div>}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const UserChangePasswordPageWrapper = reduxForm({
    form: 'changePassword'
})(UserChangePasswordPage);

export default UserChangePasswordPageWrapper;