import React from 'react';
import axios from 'axios';

class DepositByBankTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        user: null
    };
    const self = this;

    axios({
      method: 'get',
      url: window.api_url + '/auth/user/info',
      withCredentials: true
    }).then(function (response) {
      if(!response.data.isError) {
        self.setState({
          ...self.state,
          user: response.data.data
        })
      }
    });
  }

  render() {
    const { user } = this.state;
    const qrUrl = user && "https://img.vietqr.io/image/VCB-1014480066-print.png?addInfo='NAP" + user.token.toUpperCase() + "'" + '&accountName=Tran%20Thi%20Huynh%20Trang'
    return (
        <React.Fragment>
            <div className="page-header">
                <div className="page-header-content header-elements-md-inline">
                    <div className="page-title d-flex">
                        <h4>
                            <i className="icon-paragraph-justify2 mr-2"></i>
                            <span className="text-semibold">Nạp tiền vào tài khoản</span>
                        </h4>
                    </div>
                </div>
            </div>
            <br/>
            <div style={{ backgroundColor: "white"}} className="card">
                <div style={{margin:"20px"}}>
                    <h4><strong>Thông tin chuyển khoản ngân hàng</strong></h4>
                    <br/>
                    {user && <p>
                        Để nạp tiền vào tài khoản Tadu, trong nội dung chuyển khoản quý khách chỉ cần điền <strong style={{color: 'red'}}>NAP{user.token.toUpperCase()}</strong>.
                    </p>}
                    <table style={{border: 'none'}}>
                        <tbody>
                            <tr>
                              <td style={{border: 'none', width: '120px', paddingTop: '15px'}}>
                                <center>
                                    {user && <img src={qrUrl} width="400px"/>}
                                </center>
                              </td>
                              <td style={{border: 'none', verticalAlign: 'top', paddingTop: '15px'}}>
                                <strong>Vietcombank - Ngân Hàng TMCP Ngoại Thương Việt Nam CN Tân Sơn Nhất</strong>
                                <br/>
                                Chủ tài khoản: <strong>Trần Thị Huỳnh Trang</strong>
                                <br/>
                                Tài khoản: <strong>101.4480.066</strong>
                                <br/>
                              </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <p>Tadu xin cám ơn!</p>
                </div>
            </div>
        </React.Fragment>
    )
  }
}

export default DepositByBankTransfer

