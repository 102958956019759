import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { RenderInputWithDiv, RenderTextArea } from '../../../component/formInput';
import PasswordFormField from '../../../component/PasswordFormField';

const mapState = state => ({
    initialValues: state.customerAgency.profile,
});

const mapDispatch = (dispatch) => ({
    loadCustomerProfile: (customer_id) => dispatch.customerAgency.loadCustomerProfile(customer_id),
    updateField: (fieldName, value) =>
    dispatch({
        meta: { form: "CustomerForm", field: fieldName },
        payload: value,
        type: "@@redux-form/CHANGE"
    }),
});

class CustomerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
        this.submit = this.submit.bind(this);
    }
    
    componentDidMount() { 
        const { customer_id } = this.props.match.params;
        const { loadCustomerProfile } = this.props;
        if(customer_id) {
            loadCustomerProfile(customer_id);
        }
    }
    
    submit(values) {
        const { history } = this.props;
        const { customer_id } = this.props.match.params;
        let url = '/user/agency/add_customer';
        let body_object = {
            email: values.email,
            password: values.password,
            full_name: values.full_name,
            phone: values.phone,
            note: values.note,
            is_active: values.is_active,
            role: "CUSTOMER",
        }
        // dùng cho việc chỉnh sửa khách hàng của đại lý 
        if(customer_id) {
            url = '/user/agency/update_customer'
            body_object.id = customer_id
        }

        return axios({
            method: 'post',
            url: window.api_url + url,
            data: body_object,
            withCredentials: true
        }).then(function (response) {
            if (response.data.isError) {
                throw new SubmissionError(response.data.error);
            } else {
                history.push("/user/agency/customer/list")
            }
        });
    }

    render() {
        const { handleSubmit, submitting, title } = this.props;
        const { customer_id } = this.props.match.params;

        return (
            <React.Fragment>
                <div className="page-header">
                    <h4>
                        <i className=" icon-paragraph-justify2 position-left"></i>
                        <span className="text-semibold"> {title}</span>
                    </h4>
                </div>
                <div className="card" style={{ backgroundColor:'white' }} >
                    <form onSubmit={handleSubmit(this.submit)}>
                        <br/>
                        <div style={{margin:20}} className="form-group">
                            <Field
                                disabled={customer_id && true}
                                name="email"
                                label="Email"
                                placeholder="Nhập email"
                                component={RenderInputWithDiv}
                            />
                            {!customer_id && <Field
                                name="password"
                                label="Mật khẩu"
                                placeholder="Nhập mật khẩu"
                                component={PasswordFormField}
                            />}
                            <Field
                                name="full_name"
                                label="Họ tên"
                                placeholder="Họ Tên"
                                component={RenderInputWithDiv}
                            />
                            <Field
                                name="phone"
                                label="Số điện thoại"
                                placeholder="Số điện thoại"
                                component={RenderInputWithDiv}
                            />
                            <Field
                                name="note"
                                label="Ghi chú"
                                placeholder="Ghi chú"
                                style={{width: "100%"}}
                                component={RenderTextArea}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
                                Lưu
                            </button>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        )
    }
}

const CustomerFormWrapper = reduxForm({
    form: 'CustomerForm',
    destroyOnUnmount: true,
    enableReinitialize: true
    // validate
})(CustomerForm);

export default connect(mapState, mapDispatch)(withRouter(CustomerFormWrapper));