import React from 'react';
import axios from 'axios';

class LabelButton extends React.Component {
  render() {
    const { onChangeServiceStatus, service_static} = this.props;
    const styles = {
      color: "white",
      marginRight: 3,
      borderColor: 'transparent',
      borderRadius: "3px"
    }
    return (
      <React.Fragment>
       <button style={styles} onClick={() => onChangeServiceStatus("running")} className="badge bg-primary">ĐANG SỬ DỤNG: {service_static.running}</button>
       <button style={styles} onClick={() => onChangeServiceStatus("expiring")} className="badge badge-warning">SẮP HẾT HẠN: {service_static.expiring}</button>
       <button style={styles} onClick={() => onChangeServiceStatus("expired")} className="badge badge-danger">HẾT HẠN {service_static.suspend}</button>
      </React.Fragment>
    )
  }
}

class ServiceStatusSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      service_static: {}
    }
  }
  
  componentDidMount() {
    const self = this;
    const { productType } = this.props;
    const api_url = productType == "all" ? '/user/service/static' : '/user/' + productType + '/static'
    axios({
      method: 'get',
      url: window.api_url + api_url,
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        service_static: response.data.data
      })
    });
  }


  render() {
    const { onChangeServiceStatus } = this.props;
    const { service_static } = this.state;
    return(
      <React.Fragment>
        <LabelButton 
          onChangeServiceStatus = {onChangeServiceStatus}
           service_static={service_static}
        />
      </React.Fragment>
    )
  }

}


export default ServiceStatusSelect;