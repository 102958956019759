import vi from 'date-fns/locale/vi';
import React from 'react';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from "react-select";
import Switch from "react-switch";
import { FormatterUtils } from '../utils/javascriptUtils';
import FileRender from './FileRender';
require('react-datepicker/dist/react-datepicker.css');
var _ = require('lodash');

const createRenderer = render => ({ input, meta, label, subLabel,required, isVerticalLabel, iconClass, ...rest  }) => {
  var fieldRender = 
    <div className={"form-group"}>
      {render(input, label, rest)}
      {meta.error && meta.touched ?
      <span className="form-text text-danger">* {meta.error}</span> : null}
    </div>
  if (label) {
    fieldRender = 
    <div className={"form-group row"}>
      <label className={"col-form-label col-lg-2 text-left" + (meta.touched && meta.error ? 'font-weight-semibold text-danger text-left' : '')}>
        {label} {required && <span style={{color: "red"}}>*</span>}
      </label>
      <div className={"col-form-control col-lg-10"}>
        {render(input, label, rest)}
        {meta.touched && meta.error && <span className="form-text text-danger">{meta.error}</span>}
      </div>
      <small className="col-form col-lg-2 text-left text-muted">{subLabel}</small>
    </div>
  } else if (iconClass) {
    fieldRender = 
    <div className="form-group has-feedback has-feedback-left">
      {render(input, label, rest)}
      <div className="form-control-feedback">
        <i className={[iconClass, "text-muted"].join(" ")}></i>
      </div>
    </div>
  } 
  return fieldRender;
}

const RenderTextArea = createRenderer((input, label, { style, placeholder, rows, cols,disabled,autoFocus }) => {
  return (
    <textarea {...input} disabled={disabled} style={style} placeholder={placeholder} rows={rows} cols={cols} autoFocus={autoFocus} className="form-control"></textarea>
  )
})

const RenderInputWithDiv = createRenderer((input, label, {placeholder, disabled, defaultValue, readOnly, autoFocus,onChangeAction }) => {
  function handleInputChange(ev) {
    if (onChangeAction) {
      onChangeAction(ev.target.value);
    }
    input.onChange(ev);
  }
  return(
    <input {...input} disabled={disabled} placeholder={placeholder} value={input.value} onChange={handleInputChange} readOnly={readOnly} autoFocus={autoFocus} className="form-control" />
  )
})

const RenderNumberInput = createRenderer((input, label, {placeholder, disabled, defaultValue, readOnly, autoFocus,onChangeAction }) => {
    function handleInputChange(ev) {
      if (onChangeAction) {
        onChangeAction(ev.target.value);
      }
      input.onChange(ev);
    }
    if(defaultValue != null && !input.value) {
      input.onChange(defaultValue);
    }
    return(
      <input {...input} type="number" disabled={disabled} placeholder={placeholder} value={input.value} onChange={handleInputChange} readOnly={readOnly} autoFocus={autoFocus} className="form-control" />
    )
  })

const RenderPasswordInput = createRenderer((input, label, {placeholder, disabled, defaultValue, readOnly, autoFocus,onChangeAction }) => {
    function handleInputChange(ev) {
      if (onChangeAction) {
        onChangeAction(ev.target.value);
      }
      input.onChange(ev);
    }
    return(
      <input {...input} type="password" disabled={disabled} placeholder={placeholder} value={input.value} onChange={handleInputChange} readOnly={readOnly} autoFocus={autoFocus} className="form-control" />
    )
  })

const RenderSelect = createRenderer((input, label, { placeholder,defaultValue,getOptionLabel,getOptionValue, options, disabled, onChangeAction, menuPlacement }) => {
  const handleInputChange = (inputData) => {
    const data_selected = inputData ? inputData.value : "";
    if (onChangeAction) {
      onChangeAction(data_selected);
    }
    input.onChange(data_selected);
  }
  let default_value = "";
  if(!input.value && defaultValue && defaultValue.value) { // Set Defaultvalue
    default_value = defaultValue;
    input.onChange(defaultValue.value);

  }
  const select_value = input.value && options && options.length > 0 ? _.find(options, (i) => i == input) : default_value

  return (
    options && <Select
        name={input.name}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        menuPlacement={"auto"}
        value={select_value}
        defaultValue={defaultValue}
        onBlur= { event => event.preventDefault()}
        onChange={handleInputChange}
        isDisabled={disabled}
        placeholder={placeholder}
    />
  )
})

const RenderHTMLSelect = createRenderer((input, label, {placeholder, defaultValue, options, disabled, onChangeAction }) => {
  function handleInputChange(e) {
    if (onChangeAction) {
      onChangeAction(e.target.value);
    }
    input.onChange(e.target.value);
  }
  // Set defaultValue nếu người dùng có truyền vào.
  if(defaultValue && !input.value) {
    input.onChange(defaultValue);
  }
  return (
    <select {...input} placeholder={placeholder} className={"form-control select2"} onChange={handleInputChange}>
      {placeholder && <option value="" defaultValue={""}>{placeholder}</option>}
        {options.map((i, index) => (
      <option key={index} value={i.value}>{i.label}</option>
    ))}
  </select>
  )
})

const RenderRadioCheckBox = createRenderer((input, label, { placeholder, options, disabled, onChangeAction }) => {
  function handleInputChange(e) {
    if (onChangeAction) {
      onChangeAction(JSON.parse(e.target.value)); // parse string "true" to boolean true
    }
    input.onChange(JSON.parse(e.target.value));
  }
  return (
    options.map((i, index) => (
      <div key={index} style={{marginRight: 10, display: 'inline-flex'}}>
        <input 
        disabled={disabled}
        id={input.name +"_" +index}
        defaultChecked = {input.value == i.value} 
        name={input.name} 
        type="radio" 
        style={{marginRight:5}} 
        value={i.value}
        onChange={handleInputChange}
        />
        <label htmlFor={input.name +"_" +index}>{i.label}</label>
      </div>
    ))
  )
})

const RenderSearchForm = createRenderer((input, label, {placeholder, disabled, readOnly, defaultValue, value, autoFocus,onChangeAction, onKeyAction }) => {
  function handleInputChange(ev) {
  if (onChangeAction) {
    onChangeAction(ev.target.value);
  }
    input.onChange(ev);
  }
  function handleOnKeyDown(ev) {
    if (onKeyAction) {
      onKeyAction(ev.key)
    }
  }

  return (
  <div className="input-group">
    <input {...input} type="text" className="form-control border-right-0" placeholder={placeholder} value = {input.value} onChange={handleInputChange} onKeyDown={handleOnKeyDown} autoFocus={autoFocus} />
    <div className="input-group-append">
      <button type="submit" className="btn btn-primary btn-xlg">Tìm</button>
    </div>
  </div>
  )

})

const RenderDatePicker = createRenderer((input, label, { disabled,readOnly, defaultValue, dateFormat, placeholder, onChangeAction}) => {
  function handleChangeDate(value) {
    if (onChangeAction) {
      onChangeAction(value);
    }
    input.onChange(value);
    }
    if(!input.value && defaultValue) { // set default value
    input.onChange(defaultValue)
  }
  return (
  <DatePicker
    locale={vi}
    placeholderText={placeholder}
    disabled={disabled}
    selected={input.value}
    dateFormat={dateFormat}
    onChange={handleChangeDate}
    readOnly={readOnly}
    showMonthDropdown
    showYearDropdown
    className="form-control">
  </DatePicker>
  )
});

const RenderTextEditor = createRenderer((input, label, { disabled, readOnly, placeholder, defaultValue, onChangeAction }) => {
  function handleChange(value) {
    if (onChangeAction) {
      onChangeAction(value);
    }
    input.onChange(value);
  }
  if(input.value == "<p></p>" || input.value == "<p><br></p>") {
    input.onChange(null);
  }
  return (
    <React.Fragment>
      <ReactQuill
        style={{height: 400}}
        value={input.value}
        // options={config}
        onChange={handleChange}
        className="form-controller"
      />
      <br/>
      <br/>
    </React.Fragment>
  )
});
const RenderFileUpload = createRenderer((input, label, { disabled, fileAccept, placeholder, defaultValue, onChangeAction, src_preview, width, height, is_preview}) => {
  function handleInputChange(ev) {
    if (onChangeAction) {
      onChangeAction(ev.target.files);
    }
    input.onChange(ev.target.files);
  }
  return ( 
    <React.Fragment>
      {/* Img Preview */}
      {is_preview && <FileRender directory="id-upload" src_input={src_preview} file_name={defaultValue} isDisplay={true} width={width} height={height} />}
      <input accept={fileAccept} type="file" onChange={handleInputChange}/>
    </React.Fragment>
  )
});

const RenderCheckbox = createRenderer((input, label, { name, checkLabel }) => {
  function onChange(ev) {
  input.onChange(ev);
  }
  return (
  <React.Fragment>
    <input
      name={input.name}
      type="checkbox"
      onChange={onChange.bind(this)}
      checked={input.value}
      id={input.name}
    /> <label htmlFor={input.name}>{checkLabel}</label>
  </React.Fragment>
  )
})

const RenderSwitch = createRenderer((input, label, { placeholder,leftLabel, rightLabel, options, disabled, onChangeAction }) => {
  function handleInputChange(value) {
    if (onChangeAction) {
      onChangeAction(value);
    }
    input.onChange(value);
  }
  return (
    <div>
      <label style={{ padding: 10 }}>{leftLabel}</label>
        <Switch
          onChange={handleInputChange}
          checked={input.value}
          disabled={disabled}
          // className="react-switch"
        />
      <label style={{ padding: 10 }}>{rightLabel}</label>
    </div>
  )
})

//  MoneyFormat
const RenderMoneyFormat = createRenderer((input, label, {placeholder, autoFocus, disabled, thousandSeparator, onChangeAction, onEnterAction }) => {
  function onNumberChangeHandler(values) {
    if (onChangeAction) {
      onChangeAction(values.value);
    }
    input.onChange(parseFloat(values.value));
  }
  function handleOnKeyDown(e){
    if(onEnterAction){
      onEnterAction(e);
    }
  }

  return (
    <NumberFormat
      value={input.value ? FormatterUtils.round2Decimals(input.value): ""}
      onBlur={() => input.onBlur(input.value)}
      thousandSeparator={","}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown = {handleOnKeyDown}
      onValueChange={onNumberChangeHandler}
      autoFocus={autoFocus}
      className='form-control'
    />
  );
});



export {
  RenderInputWithDiv,
  RenderSelect,
  RenderDatePicker,
  RenderTextEditor,
  RenderFileUpload,
  RenderSearchForm,
  RenderHTMLSelect,
  RenderRadioCheckBox,
  RenderTextArea,
  RenderCheckbox,
  RenderSwitch,
  RenderMoneyFormat,
  RenderPasswordInput,
  RenderNumberInput
};


