import React from 'react'
import axios from 'axios';
import ModalDomain from './modals/ModalDomain';

var numeral = require('numeral');
var _ = require("lodash")

function HostingOptionInner(index_key ,item, handleShowModal) {
    const hosting_meta = _.map(item.product_meta, (i,key) => <p key={key}>{i.name} <strong>{i.value && ": " + i.value}</strong></p>);
    return (
        <div key={index_key} className="col-md-3">
            <div className="card card-body dashboard-panel product-item">
                <h1>{item.product.name}</h1>
                {hosting_meta}
                <p>Giá chỉ từ</p>
                <h6 className="text-orange">
                    <strong>{numeral(item.price12).format('0,0')} đ / tháng</strong>
                </h6>
                <p style={{ fontSize: "12px" }}>(Giá trên chưa bao gồm VAT)</p>
                <br/>
                <p>
                    <button className="btn btn-warning btn-xlg" onClick={()=> handleShowModal(item)} type="button">
                        Đặt Mua Ngay
                    </button>
                </p>
            </div>
        </div>)
}
class HostingPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product_prices: {},
            is_show_hosting_modal: false,
            product_price_selected: {},
        }
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleHideModal = this.handleHideModal.bind(this);
    }
    handleShowModal(product_price) {
        this.setState({
            is_show_hosting_modal:true,
            product_price_selected:product_price
        });
    }
    handleHideModal() {
        this.setState({
            is_show_hosting_modal: false,
            product_price_selected: {}
        });
    }
    componentDidMount() {
        const self = this;
        axios({
            method: 'get',
            url: window.api_url + '/product/price_with_meta/hosting',
            withCredentials: true
        }).then(function (response) {
            self.setState({
                ...self.state,
                product_prices: response.data.data
            })
        });
    }

    render() {
        const { product_prices, is_show_hosting_modal } = this.state;
        const self = this; 
        var hosting_rows = _.map(product_prices , function (item, key) {
            if(item.product.product_type == "LINUX_HOSTING") {
               return HostingOptionInner(key,item, self.handleShowModal);
            }
        })
        
        return(
            <React.Fragment>
                <div className="page-header">
                    <div className="page-header-content header-elements-md-inline">
                        <div className="page-title d-flex">
                            <h4>
                                <i className="icon-paragraph-justify2 mr-2"></i>
                                <span className="text-semibold">Đăng Ký Hosting</span>
                            </h4>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row">
                    {hosting_rows}
                    {is_show_hosting_modal ? <ModalDomain product_price_selected={this.state.product_price_selected} title="Chọn Tên Miền" type_for={'HOSTING_ORDER'} show={this.state.is_show_hosting_modal} onHide={this.handleHideModal} /> : null}
                </div>
            </React.Fragment>
        )
    }

}

export default HostingPage;