import React from 'react'
import axios from 'axios';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

var numeral = require('numeral');
var _ = require("lodash");

function DigitalSignatureOptionInner(index_key, item, handleAddDigitalSignatureToCart) {
  return (
    <React.Fragment key={index_key}>
      <div key={1} className="col-md-3">
        <div className="card card-body dashboard-panel product-item">
          <h1>1 Năm</h1>
          <p>Giá dịch vụ: {numeral(item.price12).format('0,0')} đ</p>
          <p>VAT: {numeral(item.price12 * 0.1).format('0,0')} đ</p>
          <p>Giá chỉ từ</p>
          <h6 className="text-orange">
            <strong>{numeral(item.price12 + (item.price12 * 0.1)).format('0,0')} đ</strong>
          </h6>
          <p style={{ fontSize: "12px" }}>(Giá trên đã bao gồm VAT)</p>
          <br/>
          <p>
            <button className="btn btn-warning btn-xlg" onClick={()=>handleAddDigitalSignatureToCart(item, 12)}>
              Đặt Mua Ngay
            </button> 
          </p>
        </div>
      </div>
      <div key={2} className="col-md-3">
        <div className="card card-body dashboard-panel product-item">
          <h1>2 Năm</h1>
          <p>Giá dịch vụ: {numeral(item.price24).format('0,0')} đ</p>
          <p>VAT: {numeral(item.price24 * 0.1).format('0,0')} đ</p>
          <p>Giá chỉ từ</p>
          <h6 className="text-orange">
            <strong>{numeral(item.price24 + (item.price24 * 0.1)).format('0,0')} đ</strong>
          </h6>
          <p style={{ fontSize: "12px" }}>(Giá trên đã bao gồm VAT)</p>
          <br/>
          <p>
            <button className="btn btn-warning btn-xlg" onClick={()=>handleAddDigitalSignatureToCart(item, 24)}>
              Đặt Mua Ngay
            </button> 
          </p>
        </div>
      </div>
      <div key={3} className="col-md-3">
        <div className="card card-body dashboard-panel product-item">
          <h1>3 Năm</h1>
          <p>Giá dịch vụ: {numeral(item.price36).format('0,0')} đ</p>
          <p>VAT: {numeral(item.price36 * 0.1).format('0,0')} đ</p>
          <p>Giá chỉ từ</p>
          <h6 className="text-orange">
            <strong>{numeral(item.price36 + (item.price36 * 0.1)).format('0,0')} đ</strong>
          </h6>
          <p style={{ fontSize: "12px" }}>(Giá trên đã bao gồm VAT)</p>
          <br/>
          <p>
            <button className="btn btn-warning btn-xlg" onClick={()=>handleAddDigitalSignatureToCart(item, 36)}>
              Đặt Mua Ngay
            </button> 
          </p>
        </div>
      </div>
      <div key={4} className="col-md-3">
        <div className="card card-body dashboard-panel product-item">
          <h1>4 Năm</h1>
          <p>Giá dịch vụ: {numeral(item.price48).format('0,0')} đ</p>
          <p>VAT: {numeral(item.price48 * 0.1).format('0,0')} đ</p>
          <p>Giá chỉ từ</p>
          <h6 className="text-orange">
            <strong>{numeral(item.price48 + (item.price48 * 0.1)).format('0,0')} đ</strong>
          </h6>
          <p style={{ fontSize: "12px" }}>(Giá trên đã bao gồm VAT)</p>
          <br/>
          <p>
            <button className="btn btn-warning btn-xlg" onClick={()=>handleAddDigitalSignatureToCart(item, 48)}>
              Đặt Mua Ngay
            </button> 
          </p>
        </div>
      </div>
    </React.Fragment>
    )
}
class DigitalSignaturePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      product_prices: {}
    }
    this.handleAddDigitalSignatureToCart = this.handleAddDigitalSignatureToCart.bind(this);
  }
  handleAddDigitalSignatureToCart(product_price, month) {
    const {addItem, updateCart, history} = this.props;
    const item = {
      id: uuidv4(),
      group_id: null,
      product: product_price.product,
      price_is_year: product_price.is_year,
      month: month,
      quantity: 1,
      domain_name: null,
      domain_profile_id: null,
      template_id: null, //template id để sử dung cho vps.
      user_product_id: null, //Dịch vụ cần gia hạn
      is_renew: false,
      is_upgrade: false,
      is_tranfer: false,
      is_delivery: false,
      parent_id: null,
      order: 0 //Vị trí của item trong giỏ hàng
    }
    addItem(item);
    updateCart().then(() => {
      history.push('/products/cart');
    });
  }
  componentDidMount() {
    const self = this;

    axios({
      method: 'get',
      url: window.api_url + '/product/price/digital_signature',
      withCredentials: true
    }).then(function (response) {
      self.setState({
        ...self.state,
        product_prices: response.data.data
      })
    });
  }
  
  render() {
    const {product_prices} = this.state;
    const digital_signature_rows = _.map(product_prices , (item, key) => DigitalSignatureOptionInner(key, item, this.handleAddDigitalSignatureToCart));
    return(
      <React.Fragment>
        <div className="page-header">
          <div className="page-header-content header-elements-md-inline">
            <div className="page-title d-flex">
              <h4>
                <i className="icon-paragraph-justify2 mr-2"></i>
                <span className="text-semibold">Đăng Ký Mới Chữ Ký Số</span>
              </h4>
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          {digital_signature_rows}
        </div>
      </React.Fragment>
    )
  }

}
const mapState = state => ({
  
});

const mapDispatch = (dispatch) => ({
  addItem: (item) => dispatch.cart.addItem(item),
  updateCart: () => dispatch.cart.updateCart()
});
export default connect(mapState, mapDispatch)(withRouter(DigitalSignaturePage));