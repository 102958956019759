import axios from 'axios';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React from 'react';
import { defaults, Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormatterUtils, ImageUtils } from '../../utils/javascriptUtils';
import { TotalPurchasePDF } from './pdf_template/TotalPurchasePDF';

var _ = require('lodash');
var numeral = require('numeral');

const current_year = parseInt(moment().format("YYYY"));
let year_list = _.range(2018, current_year + 1);

// Set Responsive for line chart
defaults.global.maintainAspectRatio = false;


function ProductTypeLabelCase(productType) {
    switch(productType.toString()) {
      case "WINDOW_HOSTING,LINUX_HOSTING" : {
        return "Hosting"
      }
      case "SSL" : {
        return "SSL"
      }
      case "VPS" : {
        return "Cloud Server"
      }
      case "CLOUD_SERVER_PRO" : {
        return "Cloud Server Pro"
      }
      case "EMAIL" : {
        return "Email Server"
      }
      case "TADU_PHONE" : {
        return "Tổng Đài Ảo"
      }
      case "TADU_INVOICE" : {
        return "Hoá Đơn Điện Tử"
      }
      case "DIGITAL_SIGNATURE" : {
        return "Chữ Ký Số"
      }
      case "CLOUD_BACKUP" : {
          return "Cloud Backup"
      }
      default: {
        return "Tất cả dịch vụ"
      }
    }
  }
  
  
function DropboxButton(onProductTypeSelect, product_type_selected) {
  return (
    <React.Fragment>
      <div className="btn-group">
        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
        {ProductTypeLabelCase(product_type_selected)}
        </button>
        <div className="dropdown-menu" x-placement="bottom-start">
        <a onClick={() => onProductTypeSelect("ALL")} className="dropdown-item">TẤT CẢ DỊCH VỤ</a>
        <a onClick={() => onProductTypeSelect(["WINDOW_HOSTING","LINUX_HOSTING"])} className="dropdown-item">HOSTING</a>
        <a onClick={() => onProductTypeSelect(["SSL"])} className="dropdown-item">SSL</a>
        <a onClick={() => onProductTypeSelect(["VPS"])} className="dropdown-item">CLOUD SERVER</a>
        <a onClick={() => onProductTypeSelect(["CLOUD_SERVER_PRO"])} className="dropdown-item">CLOUD SERVER PRO</a>
        <a onClick={() => onProductTypeSelect(["DIGITAL_SIGNATURE"])} className="dropdown-item">CHỮ KÝ SỐ</a>
        <a onClick={() => onProductTypeSelect(["EMAIL"])} className="dropdown-item">EMAIL SERVER</a>
        <a onClick={() => onProductTypeSelect(["TADU_PHONE"])} className="dropdown-item">TỔNG ĐÀI ẢO</a>
        <a onClick={() => onProductTypeSelect(["TADU_INVOICE"])} className="dropdown-item">HOÁ ĐƠN ĐIỆN TỬ</a>
        <a onClick={() => onProductTypeSelect(["CLOUD_BACKUP"])} className="dropdown-item">CLOUD BACKUP</a>
        </div>
      </div>
    </React.Fragment>
  )
}

function LineChartData(invoice_sum_group, invoice_without_vat_group, from, to) {
  const month_list = _.range(from, (to +1));
  // Map month list by duration.
  const invoice_sum_matches = _.map(month_list, function (i, index) {
    // Find and merge agency_report_group to month list duration
    const sum_object = _.find(invoice_sum_group, (total_group) => total_group.month == i)
      if (!sum_object) {
        return {month: i, invoice_sum: 0}
      }
    return sum_object;
  })
  
  const invoice_sum_without_vat_matches = _.map(month_list, function (i, index) {
    // Find and merge agency_report_group to month list duration
    const sum_object = _.find(invoice_without_vat_group, (total_group) => total_group.month == i)
      if (!sum_object) {
        return {month: i, invoice_without_vat_sum: 0}
      }
    return sum_object;
  })

  const data = {
    labels: _.map(invoice_sum_matches, (i) => "Th." + i.month),
    datasets: [
      {
        label: 'TỔNG TIỀN ĐƠN HÀNG ĐÃ THANH TOÁN (ĐƠN VỊ TRIỆU) ',
        type:'bar',
        lineTension: 0.1,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'red',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: _.map(invoice_sum_matches, (i) => FormatterUtils.round2Decimals(i.invoice_sum / 1000000)),
      },
      {
        label: 'DOANH SỐ ĐẠI LÝ (ĐƠN VỊ TRIỆU)',
        type:'line',
        data: _.map(invoice_sum_without_vat_matches, (i) => FormatterUtils.round2Decimals(i.invoice_without_vat_sum / 1000000)),
        fill: false,
        borderColor: '#EC932F',
        backgroundColor: '#EC932F',
        pointBorderColor: '#EC932F',
        pointBackgroundColor: '#EC932F',
        pointHoverBackgroundColor: '#EC932F',
        pointHoverBorderColor: '#EC932F',
      }
    ]
  };

  return data
}

class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice_sum_group: null,
      invoice_without_vat_group: null,
      data_query: {
        from: 1,
        to: 12,
        year: current_year,
        product_types: "ALL",
      },
      logo_tadu: null
    }
  }
  
  loadTotalInvoiceWithoutVAT(data_query) {
    const self = this;
    return axios({
      method: 'post',
      url: window.api_url + '/user/agency/invoice_without_vat/report',
      withCredentials: true,
      data: data_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        data_query: data_query,
        invoice_without_vat_group: response.data.data
      })
    });
  }

  loadInvoiceReport(data_query) {
    const self = this;

    return axios({
      method: 'post',
      url: window.api_url + '/user/agency/invoice/report',
      withCredentials: true,
      data: data_query
    }).then(function (response) {
      self.setState({
        ...self.state,
        data_query: data_query,
        invoice_sum_group: response.data.data
      })
    });
  }

  componentDidMount() {
    const { data_query } = this.state;
    const { loadAgencyAsync } = this.props;
    const self = this;
    this.loadInvoiceReport(data_query);
    this.loadTotalInvoiceWithoutVAT(data_query);
    loadAgencyAsync();
    ImageUtils.getBase64Image("/assets/img/lglogo.png", function(callback)	{
      self.setState({logo_tadu: callback}); 
    });
  }

  sortReportByDuration = (from, to, year, product_types) => {
    const { data_query } = this.state;
    const data = {
      ...data_query,
      year: year,
      from: from,
      to: to
    }
    this.loadInvoiceReport(data)
    this.loadTotalInvoiceWithoutVAT(data)
  }
  
  onProductTypeSelect = (product_type) => {
    const { data_query } = this.state;
    const data = {
      ...data_query,
      product_types: product_type
    }
    this.loadTotalInvoiceWithoutVAT(data)
  } 
    
  exportPdf = () => {
    const { data_query, invoice_sum_group, invoice_without_vat_group, logo_tadu } = this.state;
    const { agencyProfile } = this.props;
    const data_export = TotalPurchasePDF.getDataExport(data_query, invoice_sum_group, invoice_without_vat_group, agencyProfile, logo_tadu)
    if (data_export) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(data_export).download('doanh-so-dai-ly-'+data_query.from+'-'+data_query.to+'-'+data_query.year);
    }   
  }
  render() {
    const { invoice_sum_group, invoice_without_vat_group, data_query } = this.state;
    const from =  data_query.from;
    const to =  data_query.to;
    const year = data_query.year;
    const product_types = data_query.product_types;
    const year_option = _.map(year_list, (i,index) => <option key={index} value={i}>Năm: {i}</option>);
    const sum_invoice = _.sumBy(invoice_sum_group, (i) => i.invoice_sum);
    const sum_invoice_without_vat = _.sumBy(invoice_without_vat_group, (i) => i.invoice_without_vat_sum);

    return(
      <React.Fragment>
        <div>
          <h2>Thống Kê Đơn Hàng</h2>
          <button style={{float:"right"}} type="button" onClick={()=>this.exportPdf()} className="btn btn-default btn-xs">
            <h6 style={{margin:5}} className="panel-title"><i className="icon-printer position-left"></i> In</h6>
          </button>
          <select
            style={{ maxWidth: "100px", minWidth: "350px" }} 
            className="form-control"
            value={year}
            onChange={(event) => this.sortReportByDuration(from, to, parseInt(event.target.value))}>
            {year_option}
          </select>
        
          <button onClick={()=> this.sortReportByDuration(1,1, year)} style={{margin: 5}} className="btn bg-primary">1 Tháng</button>
          <button onClick={()=> this.sortReportByDuration(1,3, year)} style={{margin: 5}} className="btn bg-primary">3 Tháng</button>
          <button onClick={()=> this.sortReportByDuration(1,6, year)} style={{margin: 5}} className="btn bg-primary">6 Tháng</button>
          <button onClick={()=> this.sortReportByDuration(1,12, year)} style={{margin: 5}} className="btn bg-primary">1 Năm</button>
          <br/>
          <br/>
          {DropboxButton(this.onProductTypeSelect, product_types)}
          {Array.isArray(invoice_sum_group) && <article style={{height: "60vh"}} className="canvas-container">
            <Line data={LineChartData(invoice_sum_group, invoice_without_vat_group, data_query.from, data_query.to)} height={150} width={500} />
       		</article>}
          <center>
            <strong>BIỂU ĐỒ DOANH SỐ TỪ THÁNG {from} ĐẾN THÁNG {to} NĂM {year}</strong>
            <br/>
            <strong>TỔNG ĐƠN HÀNG ĐÃ THANH TOÁN: <span style={{color: "#4bc0c0",fontSize: "20px"}}>{numeral(sum_invoice).format("0,0")}</span> VNĐ <i className="icon-stats-bars2" style={{fontSize: 20, color: "#4bc0c0"}}></i></strong> 
            <br/>
            <strong>TỔNG DOANH SỐ <span style={{textTransform: "uppercase"}}>{ProductTypeLabelCase(data_query.product_types)}</span> (không tính tên miền và VAT): <span className="price">{numeral(sum_invoice_without_vat).format("0,0")}</span> VNĐ</strong> <i className="price icon-statistics"></i>
          </center>
        </div>
      </React.Fragment>
    )
  }

}

const mapState = state => ({
  agencyProfile: state.agencyProfile.data,
});

const mapDispatch = (dispatch) => ({
  loadAgencyAsync: () => dispatch.agencyProfile.loadAgencyAsync()
});

export default connect(mapState,mapDispatch)(withRouter(LineChart));