import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormValues, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import { RenderDatePicker, RenderInputWithDiv, RenderSelect, RenderHTMLSelect, RenderRadioCheckBox } from '../component/formInput';
import { cities } from '../models/cities';
import { countries } from '../models/countries';
import { genders } from '../models/genders';
import { withRouter } from 'react-router';

var _ = require('lodash');
const selector = formValueSelector("domainProfile")
const mapState = state => ({
  // load dữ liệu mặc định cho các selectbox
  initialValues: {
    gender: "NAM",
    nation: "VIET_NAM",
    city: "HO_CHI_MINH",
    manager_gender: "NAM",
    manager_nation: "VIET_NAM",
    manager_city: "HO_CHI_MINH",
    tech_gender: "NAM",
    tech_nation: "VIET_NAM",
    tech_city: "HO_CHI_MINH",
    pay_gender: "NAM",
    pay_nation: "VIET_NAM",
    pay_city: "HO_CHI_MINH",
    rep_gender: "NAM", 
    rep_nation: "VIET_NAM", 
    rep_city: "HO_CHI_MINH", 
  },
  cart: state.cart,
  domainSelected: selector(state,"domain_profile_id"),
  form_value: getFormValues("domainProfile")(state) // get all value from MainPersonal
});

const mapDispatch = (dispatch) => ({
  loadCart: () => dispatch.cart.loadCart(),
  updateField: (fieldName, value) =>
    dispatch({
      meta: { form: "domainProfile", field: fieldName },
      payload: value,
      type: "@@redux-form/CHANGE"
    }),
  setCart: (cart) => dispatch.cart.setCart(cart),
  updateCart: () => dispatch.cart.updateCart(),
});

function UpdatePersonalProfileField(updateField, preffix, data) {
  updateField(preffix+"id", data.id); 
  updateField(preffix+"full_name", data.full_name);
  updateField(preffix+"gender", data.gender);
  updateField(preffix+"birthday", Date.parse(data.birthday));
  updateField(preffix+"id_number", data.id_number);
  updateField(preffix+"nation", data.nation);
  updateField(preffix+"city", data.city);
  updateField(preffix+"address", data.address);
  updateField(preffix+"phone", data.phone);
  updateField(preffix+"email", data.email);
}

function UpdateCompanyProfileField(updateField, company_data, user_domain_profile_options) {
  updateField("id",company_data.id); 
  updateField("full_name",company_data.full_name); 
  updateField("nation",company_data.nation); 
  updateField("city",company_data.city); 
  updateField("address",company_data.address); 
  updateField("phone",company_data.phone); 
  updateField("email",company_data.email); 
  updateField("inter_name",company_data.inter_name); 
  updateField("vat_no",company_data.vat_no); 
  _.forEach(user_domain_profile_options, function (i) {
    if(company_data.id === i.parent_id && i.type === "MANAGE" ) {
      UpdatePersonalProfileField(updateField, "manager_", i);
    }
    if(company_data.id === i.parent_id && i.type === "TECH") {
      UpdatePersonalProfileField(updateField, "tech_", i);
    }
    if(company_data.id === i.parent_id && i.type === "PAY") {
      UpdatePersonalProfileField(updateField, "pay_", i);
    }
    if(company_data.id === i.parent_id && i.type === "REP") {
      UpdatePersonalProfileField(updateField, "rep_", i);
    }
  })
}

function PersonalTitleInner (key_name) {
  let inner_personal_type_html =  
      <div className="col-form-label col-lg-2 text-left">
        <span style={{ color: 'orange' }}>Thông tin cá nhân</span>
      </div>
  if(key_name === "manager_") {
    inner_personal_type_html = 
      <div className="col-form-label col-lg-2 text-left">
        <span style={{ color: 'orange' }}>Người quản lý tên miền</span>
      </div>
  }
  if(key_name === "tech_") {
    inner_personal_type_html = 
      <div className="col-form-label col-lg-2 text-left">
        <span style={{ color: 'orange' }}>Người quản lý kỹ thuật</span>
      </div>
  }
  if(key_name === "pay_") {
    inner_personal_type_html = 
      <div className="col-form-label col-lg-2 text-left">
        <span style={{ color: 'orange' }}>Người thanh toán</span>
      </div>
  }
  if(key_name === "rep_") {
    inner_personal_type_html = 
      <div className="col-form-label col-lg-2 text-left">
        <span style={{ color: 'orange' }}>Người thay mặt cơ quan làm thủ tục đăng ký tên miền</span>
      </div>
  }
  return inner_personal_type_html;
}

function PersonalProfileForm(key_name, is_field_disable) {
  return (
    <fieldset disabled={is_field_disable}>
      <Field
        name={key_name + "full_name"}
        type="text"
        placeholder="Vui lòng tiếng Việt có dấu"
        component={RenderInputWithDiv}
        required
        label="Họ và tên chủ thể"/>

      <Field
        name={key_name + "gender"}
        options={genders}
        component={RenderHTMLSelect}
        required
        label="Giới tính"/>
        
      <Field
        name={key_name + "birthday"}
        placeholder="dd/mm/yyyy"
        dateFormat="dd/MM/yyyy"
        readOnly={is_field_disable}
        component={RenderDatePicker}
        required
        label="Ngày sinh"/>

      <Field
        name={key_name + "id_number"}
        type="text"
        component={RenderInputWithDiv}
        required
        label="Số CMND"/>

      <Field
        name={key_name + "nation"}
        options={countries}
        component={RenderHTMLSelect}
        required
        label="Quốc gia"/>

      <Field
        name={key_name + "city"}
        options={cities}
        component={RenderHTMLSelect}
        required
        label="Tỉnh thành / thành phố"/>

      <Field
        name={key_name + "address"}
        type="text"
        component={RenderInputWithDiv}
        required
        label="Địa chỉ"/>

      <Field
        name={key_name + "phone"}
        type="text"
        component={RenderInputWithDiv}
        required
        label="Điện thoại"/>

      <Field
        name={key_name + "email"}
        type="text"
        component={RenderInputWithDiv}
        required
        label="Email"/>
    </fieldset>
  )
}

function CompanyProfileForm(is_field_disable) {
  return (
    <fieldset disabled={is_field_disable}>
      <Field
        name="full_name"
        placeholder="Vui lòng tiếng Việt có dấu"
        component={RenderInputWithDiv}
        label="Họ và tên chủ thể"/>

      <Field
        name="inter_name"
        placeholder="Nhập tên giao dịch quốc tế (nếu có)"
        component={RenderInputWithDiv}
        label="Tên giao dịch quốc tế"/>

      <Field
        name="vat_no"
        component={RenderInputWithDiv}
        label="Mã số thuế"/>

      <Field
        name="address"
        component={RenderInputWithDiv}
        label="Địa chỉ"/>

      <Field
        name="nation"
        options={countries}
        component={RenderHTMLSelect}
        required
        label="Quốc gia"/>

      <Field
        name="city"
        options={cities}
        component={RenderHTMLSelect}
        required
        label="Tỉnh thành / thành phố"/>

      <Field
        name="phone"
        component={RenderInputWithDiv}
        label="Điện thoại / Fax"/>

      <Field
        name="email"
        component={RenderInputWithDiv}
        label="Email"/>
    </fieldset>
  )
}

class MainPersonalProfileComponent extends React.Component {
  render() {
    const { key_name, is_load_profile } = this.props;
    return (
      <div style={{ borderTop:'1px solid', borderTopColor:'lightgrey'}}>
        <br/>
        <div className="row item-row">
          {PersonalTitleInner(key_name)}
        </div>
        {PersonalProfileForm(key_name,is_load_profile)}
        <br/> 
      </div>
    ); 
  }
}

class CompanyProfileComponent extends React.Component {
  render() {
    const { is_load_profile } = this.props;
    return (
      <div style={{ borderTop:'1px solid', borderTopColor:'lightgrey'}}>
        <br/>
        <div className="row item-row">
          <div className="col-form-label col-lg-2">
          <span style={{ color: 'orange' }}>Thông tin công ty</span>
          </div>
        </div>
        {CompanyProfileForm(is_load_profile)}
      </div>
    );
  }
}

class SubProfileComponent extends React.Component {
  render() {
    const { key_name, is_load_profile, handleFillDataFromManager, disable_keys_form } = this.props;
    // Nếu người dùng click vào "Lấy dữ liệu từ người quản lý" hoặc "chọn bản khai có sẵn"
    // Vô hiệu hoá nhập liệu form đấy
    const is_disabled_field = is_load_profile || (disable_keys_form.includes(key_name));
    return ( 
      <div style={{ borderTop:'1px solid', borderTopColor:'lightgrey', margin:'10px' }}>
        <br/>
        <div className="row item-row">
          {PersonalTitleInner(key_name)}
          <div className="col-form-label col-lg-9">
            <a onClick={() => handleFillDataFromManager(key_name)} style={{ color:'blue' }}>Lấy thông tin người quản lý tên miền</a>
          </div>
        </div>
          {PersonalProfileForm(key_name, is_disabled_field)}
        <br/> 
      </div>
    );
  }
}

function PersonalType(is_load_profile) {
  return <MainPersonalProfileComponentWrapper key_name={""} is_load_profile={is_load_profile} />
}

function CompanyType(is_load_profile , handleFillDataFromManager, disable_keys_form) {
  const key_name_list = ["tech_", "pay_", "rep_" ]
  const sub_profile_form_list = _.map(key_name_list, (i, index) => 
    <SubProfileComponentWrapper 
      key={index}
      key_name={i} 
      handleFillDataFromManager={handleFillDataFromManager}
      is_load_profile={is_load_profile}
      disable_keys_form={disable_keys_form}
    />
  )

  return (
    <React.Fragment>
      <CompanyProfileComponentWrapper is_load_profile={is_load_profile} />
      <MainPersonalProfileComponentWrapper key_name={"manager_"} is_load_profile={is_load_profile} />
      {sub_profile_form_list}
    </React.Fragment>
  )
}

class DomainProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_company: false,
      user_domain_profile_options: [],
      is_load_profile: false, // nếu người dùng load profile có sẳn, trạng thái chuyển sang true và disabled form
      disable_keys_form: [] // Dùng để tìm ra form của chức vụ đã load dữ liệu từ người quản lý
    }
    this.loadDomainProfileTemplate = this.loadDomainProfileTemplate.bind(this);
    this.handleChangeProfileType = this.handleChangeProfileType.bind(this);
    this.setDomainProfileIdToCart = this.setDomainProfileIdToCart.bind(this);    
    this.handleFillDataFromManager = this.handleFillDataFromManager.bind(this); 
    this.addPersonalDomainProfile = this.addPersonalDomainProfile.bind(this);
    this.addCompanyDomainProfile = this.addCompanyDomainProfile.bind(this);
  }
  // Sử dụng lại thông tin của người quản lý tên miền
  handleFillDataFromManager(key) {
    const { updateField, form_value } = this.props;
    // Đổ dữ liệu vào các Field
    // Chú thích: key ở đây để xác định các ô nhập theo chức vụ VD: tech_ , pay_ , rep_  
    updateField(key + "full_name", form_value.manager_full_name);
    updateField(key + "gender", form_value.manager_gender);
    updateField(key + "birthday", form_value.manager_birthday);
    updateField(key + "id_number", form_value.manager_id_number);
    updateField(key + "nation", form_value.manager_nation);
    updateField(key + "city", form_value.manager_city);
    updateField(key + "address", form_value.manager_address);
    updateField(key + "phone", form_value.manager_phone);
    updateField(key + "email", form_value.manager_email);
    
    let currents_keys_disabled = this.state.disable_keys_form;
    if(!currents_keys_disabled.includes(key)){
      currents_keys_disabled.push(key)
    }
  }

  setDomainProfileIdToCart(domain_profile_id) {
    const { cart, setCart, updateCart, history } = this.props;
    const domain_types = ["COM_DOMAIN", "VN_DOMAIN", "DIGITAL_SIGNATURE"];
    _.map(cart.items, function (item) { // Set domain_profile_id to domain_items in cart
      if(domain_types.includes(item.product.product_type) && !item.is_renew) {
        return (
          item.domain_profile_id = domain_profile_id,
          setCart(cart)
        )
      }
    })
    // UpdateCart and direct to payment-method
    updateCart().then(()=>{
      history.push('/products/payment-method');
    })
  }

  handleChangeProfileType(is_company) {
    const { updateField, reset } = this.props;
    this.setState({
      is_company: is_company,
      is_load_profile: false
    });
    reset(); // Reset lại form sau khi người dùng chuyển qua lại các loại hình Profile
    updateField("domain_profile_id", null);
  }
  // Tải Domain có sẳn từ hệ thống
  loadDomainProfileTemplate(domain_profile_id_selected) {
    const { updateField,reset } = this.props;
    const { user_domain_profile_options, is_company } = this.state;
    const domainData = _.find(user_domain_profile_options, (i)=> i.id === parseInt(domain_profile_id_selected)); // Get domain data to fill form
    
    if(!is_company && domainData) { // Đổ dữ liệu vào form Cá Nhân
      this.setState({
        is_load_profile: true // set trạng thái để disabled form sau khi chọn profile có sẳn
      })
      UpdatePersonalProfileField(updateField, "", domainData);

    }else if(is_company && domainData) { // Đổ dữ liệu vào form Doanh Nghiệp
      this.setState({
        is_load_profile: true // set trạng thái để disabled form sau khi chọn profile có sẳn
      })
      UpdateCompanyProfileField(updateField, domainData, user_domain_profile_options);

    } else if(!domainData) {
      this.setState({
        is_load_profile: false
      })
      reset();
    }
  }
  
  addPersonalDomainProfile(values) {
    const self = this;
    const { cart } = this.props;
    if(!values.id) {
    return axios({
      method: 'post',
      url: window.api_url + '/user/domain/profile/personal',
      data: {
        agency_customer_id: cart.agency_customer_id,
        full_name: values.full_name,
        default_profile: false,
        gender: values.gender,
        birthday: moment(values.birthday).format("YYYY-MM-DD"),
        id_number: values.id_number,
        nation: values.nation,
        city: values.city,
        address: values.address,
        phone: values.phone,
        email: values.email,
        inter_name: null,
        vat_no: values.vat_no,
      },
      withCredentials: true
    }).then(function (response) {
      if(response.data.isError){
        throw new SubmissionError(response.data.error)
      }else {
        return self.setDomainProfileIdToCart(response.data.data);
      }
    });
    }else {
      self.setDomainProfileIdToCart(values.id);
    }
  }

  addCompanyDomainProfile(values) {
    const self = this;
    const { cart } = this.props;

    if(!values.id) { 
      return axios({
        method: 'post',
        url: window.api_url + '/user/domain/profile/company',
        data: {
          agency_customer_id: cart.agency_customer_id,
          full_name: values.full_name,
          is_company: true, // set true for profile type company
          default_profile: false,
          gender: null,
          birthday: null,
          id_number: null,
          nation: values.nation,
          city: values.city,
          address: values.address,
          phone: values.phone,
          email: values.email,
          inter_name: values.inter_name,
          vat_no: values.vat_no,

          manager_full_name: values.manager_full_name,
          manager_gender: values.manager_gender,
          manager_birthday: moment(values.manager_birthday).format("YYYY-MM-DD"),
          manager_id_number: values.manager_id_number,
          manager_nation: values.manager_nation,
          manager_city: values.manager_city,
          manager_address: values.manager_address,
          manager_phone: values.manager_phone,
          manager_email: values.manager_email,
        
          tech_full_name: values.tech_full_name,
          tech_gender: values.tech_gender,
          tech_birthday: moment(values.tech_birthday).format("YYYY-MM-DD"),
          tech_id_number: values.tech_id_number,
          tech_nation: values.tech_nation,
          tech_city: values.tech_city,
          tech_address: values.tech_address,
          tech_phone: values.tech_phone,
          tech_email: values.tech_email,

          pay_full_name: values.pay_full_name,
          pay_gender: values.pay_gender,
          pay_birthday: moment(values.pay_birthday).format("YYYY-MM-DD"),
          pay_id_number: values.pay_id_number,
          pay_nation: values.pay_nation,
          pay_city: values.pay_city,
          pay_address: values.pay_address,
          pay_phone: values.pay_phone,
          pay_email: values.pay_email,

          rep_full_name: values.rep_full_name,
          rep_gender: values.rep_gender,
          rep_birthday:  moment(values.rep_birthday).format("YYYY-MM-DD"),
          rep_id_number: values.rep_id_number,
          rep_nation: values.rep_nation,
          rep_city: values.rep_city,
          rep_address: values.rep_address,
          rep_phone: values.rep_phone,
          rep_email: values.rep_email,
        },
        withCredentials: true
      }).then(function (response) {
        if(response.data.isError){
          throw new SubmissionError(response.data.error)
        }else {
          return self.setDomainProfileIdToCart(response.data.data);
        }
      })
    }else {
        self.setDomainProfileIdToCart(values.id); 
    }
  }
  componentDidMount() {
    const self = this;
    const { cart } = this.props;
    axios({
      method: 'post',
      url: window.api_url + '/user/domain/profile',
      withCredentials: true,
      data: {
        agency_customer_id: cart.agency_customer_id
      }
    }).then(function (response) {
      if(response.data.data){
        self.setState({
          user_domain_profile_options: response.data.data,
        })
      }
    });
  }

  render() {
    const { handleSubmit, submitting, cart } = this.props;
    const { is_company, user_domain_profile_options, is_load_profile, disable_keys_form } = this.state;
    
    // Get domain to show name
    const domainProducts = cart && _.find(cart.items, (i) => i.product.product_type === "COM_DOMAIN" || i.product.product_type === "VN_DOMAIN" || i.product.product_type === "DIGITAL_SIGNATURE");
    
    const profile_type_options = [
      { label:"Cá Nhân", value: false },
      { label:"Doanh Nghiệp", value:true }
    ];

    var domain_profile_options = [];

    _.forEach(user_domain_profile_options,(i,key) => { // Push option Item by form type
      if(!is_company && !i.parent_id && i.is_company === is_company) { // Is Personal
        domain_profile_options.push({ label: i.full_name + " - " + i.email + " - CMND:"  + i.id_number  + " - SĐT" + i.phone , value:i.id});

      } else if (!i.parent_id && is_company && i.is_company === is_company) { // Is Company
        domain_profile_options.push({ label: i.full_name + " - " + i.email + " - MST:"  + (i.vat_no != null ?  i.vat_no : "")  + " - SĐT: " + i.phone , value: i.id});
      }
    })

    let DomainForm = PersonalType(is_load_profile);
    
    if(is_company == true) {
      DomainForm = CompanyType(is_load_profile, this.handleFillDataFromManager, disable_keys_form)
    }
    return (
      <div className="container">
        <div className="row">
          <h1 className="page-title sub-page-title">BẢN KHAI</h1>
        </div>
        {!domainProducts && <div className="row">
          <div className="col-md-12">
            <div className="card card-body text-align-center">
              <h6>Không có sản phẩm tên miền nào trong giỏ hàng của bạn!</h6>
            </div>
          </div>
        </div>}

        {domainProducts && <div className="row">
          <div className="col-md-12">
            <div className="card card-body" style={{ backgroundColor:'white' }} >
              <form onSubmit={handleSubmit(is_company ? this.addCompanyDomainProfile : this.addPersonalDomainProfile)}>
                <div style={{ margin:'30px' }}>
                  <br/>
                  <div className="row item-row">
                    <div className="col-form-label col-lg-2">
                      {domainProducts && domainProducts.domain_name &&<strong>Tên Miền:</strong>}
                    </div>  
                    <div className="col-form-label col-lg-10">
                      <span style={{ fontSize: '14px' ,color:'orange' }}>{domainProducts ? domainProducts.domain_name : "N/A" }</span>
                    </div>
                  </div>
                  <Field
                    name="is_company"
                    options={profile_type_options}
                    defaultValue={false}
                    component={RenderRadioCheckBox}
                    onChangeAction={(value)=>this.handleChangeProfileType(value)}
                    label="Loại bản khai"/>
                  <Field
                    name="domain_profile_id"
                    options={domain_profile_options}
                    placeholder="Chọn bản khai có sẳn"
                    component={RenderSelect}
                    onChangeAction={(value)=>this.loadDomainProfileTemplate(value)}
                    label="Chọn bản khai có sẳn"/>
                </div> 
                <div style={{ margin:"30px" }}>
                  {DomainForm}
                </div>
                <div className="modal-footer">
                  <button type="submit" className={"btn bg-primary " + (submitting ? "disabled" : "")}>
                    Tiếp Theo 
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>}
      </div>
    )
  }
}

const DomainProfileWrapper = reduxForm({
  form: 'domainProfile',
  destroyOnUnmount: true,
  enableReinitialize: true,
})(DomainProfile);

const MainPersonalProfileComponentWrapper = (connect(mapState, mapDispatch)(MainPersonalProfileComponent));

const CompanyProfileComponentWrapper = (connect(mapState, mapDispatch)(CompanyProfileComponent));

const SubProfileComponentWrapper = (connect(mapState, mapDispatch)(SubProfileComponent));


export default connect(mapState,mapDispatch)(withRouter(DomainProfileWrapper)); 

