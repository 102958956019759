import React from 'react'


class BlockUI extends React.Component {

    componentDidMount() {
        window.page_block();
    }

    componentWillUnmount() {
        window.page_unblock();
    }

    render() {
        return(<div></div>)
    }

}

export default BlockUI;